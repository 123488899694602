import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'form-array-buttons',
  templateUrl: './form-array-buttons.component.html',
  styleUrls: ['./form-array-buttons.component.css']
})
export class FormArrayButtonsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() formBuilderInstance: FormBuilder;
  @Input() controls: string[];
  @Input() formArrayMethod: any;
  @Input() index: number;
  @Input() lastIndex: number;
  @Input() firstIndex: number;
  @Input() classInstance: number;
  @Input() required: boolean = true;


  constructor(public gs: GlobalService) { }

  ngOnInit() {
  }

}
