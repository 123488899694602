import { Component, OnInit, forwardRef, ViewChild, Input, Output, EventEmitter, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, AbstractControl, NgControl, FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, of, Subject, noop, concat } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { GlobalService } from '../../../services/global.service';
import { HttpProviderService } from '../../../services/http-provider.service';

@Component({
  selector: 'm-autocomplete',
  templateUrl: './m-autocomplete.component.html',
  styleUrls: ['./m-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: MAutocompleteComponent,
      multi: true
    }
  ]
})
export class MAutocompleteComponent implements OnInit, ControlValueAccessor {

  @ViewChild('locSelect', { static: false }) locSelect:NgSelectComponent;

  @Input() name: string;
  @Input() val: any;

  @Input() label;
  @Input() labelSmall;

  @Input() bindLabel;
  @Input() bindValue;

  @Input() errorMsg = "Invalid Input";
  @Input() errorHint;
  @Input() clearable = true;
  @Input() multiple = false;
  @Input() addTag = false;

  @Input() searchable = true;

  @Input() placeholder = "Search Item";

  @Input()
  items: Observable<any> = of([]);
  itemsCity: Observable<any> = of([]);
  itemLoading = false;
  itemsInput = new Subject<string>();

  @Input()
  url;

  @Input()
  method : 'post' | 'get' = "get";

  @Input()
  methodBody = {};

  @Input()
  methodBodyKey = "query";

  @Input()
  responseKey;
  
  // items = [];

  inputType = "text";
  private _type;
  get type(): any {
    return this._type;

  }
  @Input() set type(val: any) {
    this._type = val;
    // this.inputType 
  }

  // Both onChange and onTouched are functions
  public onValChange: (_: any) => void = () => noop;
  public onValTouched: () => void = () => noop;

  @Output()
  onBlur: EventEmitter<any> = new EventEmitter();

  @Output()
  onChange: EventEmitter<any> = new EventEmitter();

  @Output()
  onTouched: EventEmitter<any> = new EventEmitter();

  @Input() formControlName: string;
  public control: AbstractControl;

  @Input()
  displayKey = "label";

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange.emit(val);
    this.onValChange(val);
    this.onValTouched();
  }

  constructor(private injector: Injector, public gs: GlobalService, public hps: HttpProviderService) {
  }

  ngOnInit() {
    // if(this.address) {
    //   this.value = {
    //     raw: "",
    //     address: {}
    //   }
    // }
    this.bindToItemsApi();
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      setTimeout(() => {
        if(this.value && this.value.raw && this.locSelect) {
          // console.log(this.value.raw);
          // this.locSelect.writeValue(this.value.raw);
        }
        this.control = ngControl.control as FormControl;
        this.addValidators();
      });
    } else {
      // Component is missing form control binding
      console.warn('Missing FormControlName directive from host element of the component');
    }
  }

  selectLocation(location, type?, i?) {
    console.log(location)

    if(!location){
      return;
    }

    // formGroup.reset();

    // formGroup.patchValue(location.address);
    // formGroup.controls["latitude"].patchValue(location.lat);
    // formGroup.controls["longitude"].patchValue(location.lon);
    // formGroup.controls["pinCode"].patchValue(location.address.postcode || location.address.postalcode);
    // formGroup.controls["locality"].patchValue(location.address.locality || location.address.village);
    // formGroup.controls["street"].patchValue(location.address.road || location.address.street);
    // formGroup.controls["district"].patchValue(location.address.district || location.address.state_district);
    // formGroup.controls["city"].patchValue(location.address.city || location.address.county || location.address.city_district || location.address.state_district);
  }

  bindToItemsApi(scope?) {
    //console.log("Locations API");
    this.items = concat(
      of([]), // default items
      (<Subject<string>>this.itemsInput).pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.itemLoading = true),
        switchMap(async term => {

          if (!term || !this.url) {
            this.itemLoading = false;
            return [];
          }

          let method = this.hps.http.get(this.url+term);

          if(this.method == "post") {
            let body = this.methodBody;
            body[this.methodBodyKey] = term;
            method = this.hps.http.post(this.url, body);
          }

          try {
            return Promise.resolve(this.responseKey? (await method.toPromise())[this.responseKey]:(await method.toPromise()));
          } catch (error) {
            this.itemLoading = false;
            return of([]);
          }

          // return !term || !this.url ? [] : (method).catch(() => of([])).finally(() => { this.itemLoading = false })
        })
      )
    );
  }

  addValidators() {
    this.control.updateValueAndValidity();
  }

  validate({ value }: FormControl) {
    return null;
  }

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onValChange = fn;
  }
  // We implement this method to keep a reference to the onTouched
  //callback function passed by the forms API
  registerOnTouched(fn) {
    this.onValTouched = fn;
  }
  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

}



