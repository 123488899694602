import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { GlobalService } from 'src/app/modules/shared/services/global.service';

@Component({
  selector: 'dummy',
  templateUrl: './dummy.component.html',
  styleUrls: ['./dummy.component.css']
})
export class DummyComponent implements OnInit {

  public form: FormGroup;
  public current = 0;
  public index = "First-content";
  public tabIndex = 0;
  public searchText = "";

  constructor(public fb: FormBuilder, public gs: GlobalService) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.form = this.fb.group({
      basic: this.fb.group({
        firstName: [''],
        middleName: [''],
        lastName: [''],
      }),
      contact: this.fb.array([this.contactFormArray()]),
    });
  }

  contactFormArray() {
    return this.fb.group({
      phoneNumber: [''],
      address: [''],
    });
  }

  addContact() {
    let control = this.form.get('contact') as FormArray;
    control.push(this.contactFormArray());
    this.tabIndex = this.tabIndex + 1;
  }

  removeContact(i) {
    let control = this.form.get('contact') as FormArray;
    control.removeAt(i);
    this.tabIndex = this.tabIndex - 1;

  }

  printObject() {
    console.log(this.form.value);
  }

}
