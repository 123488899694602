import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../services/global.service';

@Pipe({
  name: 'permission'
})
export class PermissionPipe implements PipeTransform {
  
  public user : any

  constructor(public gs:GlobalService){
    this.user = this.gs.currentUser
  }

  transform(value: any, conditions:any): any {
    
    let status =value;
    let allowedStatus : any[] = [];
    allowedStatus = conditions.status || conditions.numbers;
    let document : string[] = conditions.document;
    let certificate : string[] = conditions.certificate;
    let requirement : string[] = conditions.requirement;
    // console.log(allowedStatus,status,allowedStatus.includes(status));
    if(!isNaN(status)){
      status = parseFloat(status);
    }
    if(allowedStatus && allowedStatus.length){
      return allowedStatus.includes(status);
    }
    
    if(this.user && document){
      return this.user.permissions.documents.includes(document)
    }

    if(this.user && certificate){
      return this.user.permissions.certificates.includes(certificate)
    }

    console.log('Finally returing false from Permissions Pipe')
    return false;
  }

}
