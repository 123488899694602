import { Component } from '@angular/core';
import { GlobalService } from './modules/shared/services/global.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {

    constructor(public gs:GlobalService, public router:Router){
        // this.gs.setPermission();
        this.initialize();
    }

    async initialize(){
        if (!( await this.gs.getCurrentUser(true))) {
            console.log(this.gs.currentUser)
            this.router.navigate(['full/login'])
        } else {
            // console.log(JSON.stringify(this.gs.currentUser));
            this.gs.getMasters();
            // this.gs.getEmployee(this.gs.currentUser._id);
        }
    }

}
