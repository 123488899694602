import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { GlobalService } from 'src/app/modules/shared/services/global.service';

@Component({
  selector: 'app-mesbro-attachment',
  templateUrl: './mesbro-attachment.component.html',
  styleUrls: ['./mesbro-attachment.component.scss']
})
export class MesbroAttachmentComponent implements OnInit {

  @Input()
  id = null;

  @Input()
  fileTransfer = false;

  @Input()
  noFilmOnHover = false;

  @Input()
  remove = true;

  @Input()
  url = null;

  @Input()
  type = 'other';

  @Input()
  name = null;

  @Input()
  size: number;

  @Output()
  onRemove = new EventEmitter();

  constructor(
    public gs: GlobalService
  ) { }

  ngOnInit() {
  }

  // download(url, downloadName) {
  //   if (!this.remove) {
  //     return;
  //   }  
  //   else {
  //     let a = document.createElement("a");
  //     a.target = "_blank";
  //     document.body.appendChild(a);
  //     a.href = url;
  //     console.log(a.href);
  //     a.download = downloadName;
  //     a.click();
  //     document.body.removeChild(a);
  //   }
  // }

  download(url, downloadName) {
    url = url.replace(this.gs.storageUrl, '');

    if (this.gs.isBrowser) {
      console.log("isBrowser");
      console.log(downloadName);
      console.log(url);
      let query = downloadName ? `${url}&fileName=${downloadName}` : url;
      console.log(environment.FILE_MANAGER_API + "get-download-url?filePath=" + query);
      window.open(environment.FILE_MANAGER_API + "get-download-url?filePath=" + query);
    }
  }




}
