import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ChannelCreateRequestReq, ChannelUpdateRequestReq, AttachVideoRequestReq, AddPlaylistToChannelReq, FeaturedUpdateRequestReq, RemoveLikeRequestReq, LinkRequestReq, ListAddReq, ListUpdateReq, ListFilterReq, PlaylistCreateRequestReq, PlaylistUpdateRequestReq, PlaylistAttachVideoRequestReq, ListSubscribersReq, RemoveRequestReq, UpdateSettingReq, SearchBodyVideoReq, ReportUserItemBodyVideoReq } from '../interfaces/videos';

export class Videos {

    BASE_URL = environment.VIDEO_API;

    constructor(public http: HttpClient) { }

    // Channel - v1.0.1

    // This function is used to create new channel

    createChannel(body: any) {
        return this.http.post(this.BASE_URL + "channel/add", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to update a channel

    updateChannel(body: any) {
        return this.http.put(this.BASE_URL + "channel/update", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to add video to channel

    addVideoToChannel(body: AttachVideoRequestReq) {
        return this.http.post(this.BASE_URL + "channel/add-video", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to add playlist to channel

    addPlaylistToChannel(body: AddPlaylistToChannelReq) {
        return this.http.post(this.BASE_URL + "channel/add-playlist", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to remove playlist from channel

    removePlaylistFromChannel(body: AddPlaylistToChannelReq) {
        return this.http.post(this.BASE_URL + "channel/remove-playlist", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to remove videos from channel

    removeVideoFromChannel(body: AttachVideoRequestReq) {
        return this.http.post(this.BASE_URL + "channel/remove-video", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get videos from channel

    getVideosFromChannel(channelId: string, query?: string, page?: number, userId?: string) {

        let queryParams: string = "";

        if (!channelId) {
            return;
        }
        else {
            queryParams += `${channelId}`;
            if (query) {
                queryParams += `&query=${query}`;
            }
            if (page) {
                queryParams += `&page=${page}`;
            }
            if (userId) {
                queryParams += `&userId=${userId}`;
            }
        }

        return this.http.get(this.BASE_URL + "channel/get-videos?channelId=" + queryParams, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get video details from channel

    getVideoDetailsFromChannel(videoId: string, userId?: string) {

        // let query = userId ? `${videoId}&userId=${userId}` : videoId;

        return this.http.get(this.BASE_URL + "video/get/" + videoId, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get all the related channels of a channel

    getRelatedChannelsFromChannel(channelId: string, query?: string, page?: number) {

        let queryParams: string = "";

        if (!channelId) {
            return;
        }
        else {
            queryParams += `${channelId}`;
            if (query) {
                queryParams += `&query=${query}`;
            }
            if (page) {
                queryParams += `&page=${page}`;
            }
        }

        return this.http.get(this.BASE_URL + "channel/get-related-channels?channelId=" + queryParams, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get playlist from channel

    getPlaylistFromChannel(channelId: string, query?: string, page?: number) {

        let queryParams: string = "";

        if (!channelId) {
            return;
        }
        else {
            queryParams += `${channelId}`;
            if (query) {
                queryParams += `&query=${query}`;
            }
            if (page) {
                queryParams += `&page=${page}`;
            }
        }

        return this.http.get(this.BASE_URL + "channel/get-playlist?channelId=" + queryParams, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get channels list

    getChannelsList() {
        return this.http.post(this.BASE_URL + "channel/my-list", {}, {
            observe: 'response',
        }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get channel detail

    getChannelInfo(channelId: string, userId?: string) {

        // let query = userId ? `${channelId}&userId=${userId}` : channelId;

        return this.http.get(this.BASE_URL + "channel/get/"+channelId, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to delete a channel

    deleteChannel(channelId: string) {

        return this.http.get(this.BASE_URL + "channel/delete?channelId=" + channelId, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get videos from channel

    getMoreVideosFromChannel(videoId: string, userId?: string, page?) {

        let query = userId ? `?videoId=${videoId}&userId=${userId}` : `?videoId=${videoId}`;

        if (page) {
            query += "&page=" + page;
        }

        return this.http.get(this.BASE_URL + "channel/get-more-videos" + query, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // Setting - v1.0.1

    // This function is used to change channel

    changeChannel(channelId: string) {

        return this.http.get(this.BASE_URL + "setting/channel/change-default?channelId=" + channelId, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // Config - v1.0.1

    // This function is used to get all configuration

    getConfiguration() {
        return this.http.get(this.BASE_URL + "config/get", {
            observe: 'response'
        }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // Landing Page - v1.0.1

    // This function is used to get landing page information

    getLandingPageInfo() {
        return this.http.get(this.BASE_URL + "landing/get", {
            observe: 'response'
        }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to to update featured videos and channels

    updateLandingPageInfo(body: FeaturedUpdateRequestReq) {
        return this.http.post(this.BASE_URL + "landing/update", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // Favourites - v1.0.1


    getFavouriteVideos(query?: string, page?: number, userId?: string) {


        let queryParams: string = "";


        if (query && page && userId) {

            queryParams = `?query=${query}&page=${page}&userId=${userId}`;

        } else if (query && page) {

            queryParams = `?query=${query}&page=${page}`;

        } else if (query && userId) {

            queryParams = `?query=${query}&userId=${userId}`;

        } else if (page && userId) {

            queryParams = `?page=${page}&userId=${userId}`;

        } else if (query) {

            queryParams = `?query=${query}`;

        } else if (page) {

            queryParams = `?page=${page}`;

        } else if (userId) {

            queryParams = `?userId=${userId}`;

        }


        return this.http.get(this.BASE_URL + "favourites/get-videos" + queryParams, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {

            if (response.status === 200 || response.status === 201) {

                return Promise.resolve(response.body.content);

            }

        }).catch((error: HttpErrorResponse) => {

            return Promise.reject(error);

        })

    }


    clearFavouritesList() {

        return this.http.get(this.BASE_URL + "favourites/clear-list", { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {

            if (response.status === 200 || response.status === 201) {

                return Promise.resolve(response.body.content);

            }

        }).catch((error: HttpErrorResponse) => {

            return Promise.reject(error);

        })

    }


    addVideoToFavouritesList(videoId: string, type: string) {


        if (!videoId || !type) {

            return;

        }


        return this.http.get(this.BASE_URL + "favourites/action" + `?videoId=${videoId}&type=${type}`, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {

            if (response.status === 200 || response.status === 201) {

                return Promise.resolve(response.body.content);

            }

        }).catch((error: HttpErrorResponse) => {

            return Promise.reject(error);

        })

    }

    // Likes / Unlikes - v1.0.1

    // This function is used to remove videos from likes / dislikes

    removeLikesDislikesFromVideos(body: RemoveLikeRequestReq) {
        return this.http.post(this.BASE_URL + "likes/remove", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get videos from likes or unlikes list

    getVideosFromLikesDislikesList(type: string, query?: string, page?: number, userId?: string) {

        let queryParams: string = "";

        if (!type) {
            return;
        }
        else {
            queryParams += `${type}`;
            if (query) {
                queryParams += `&query=${query}`;
            }
            if (page) {
                queryParams += `&page=${page}`;
            }
            if (userId) {
                queryParams += `&userId=${userId}`;
            }
        }

        return this.http.get(this.BASE_URL + "likes/get-videos?type=" + queryParams, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }



    // This function is used to clear likes later or likes history list

    clearLikesDislikesList(type: string) {

        return this.http.get(this.BASE_URL + "likes/clear-list?type=" + type, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to add video to likes or unlikes

    addVideoToLikesOrDislikes(videoId: string, type: string) {

        return this.http.get(this.BASE_URL + "likes/action?videoId=" + `${videoId}&type=${type}`, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // Link Videos - v1.0.1

    // This function is used to link videos to parentId

    linkVideosToParentId(body: LinkRequestReq) {
        return this.http.post(this.BASE_URL + "link/add", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to unlink videos from parentId

    unlinkVideosFromParentId(body: LinkRequestReq) {
        return this.http.post(this.BASE_URL + "link/remove", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get videos from channel

    getLinkedVideos(parentId: string, query: string, page?: number, userId?: string) {

        let queryParams: string = "";

        if (!parentId || query) {
            return;
        }
        else {
            queryParams += `${parentId}&query=${query}`;

            if (page) {
                queryParams += `&page=${page}`;
            }
            if (userId) {
                queryParams += `&userId=${userId}`;
            }
        }

        return this.http.get(this.BASE_URL + "link/get-videos?parentId=" + queryParams, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // Library - v1.0.1

    // This function is used to add a new video

    addNewVideoToList(body: any) {

        return this.http.post(this.BASE_URL + "video/add", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to update a video

    updateVideoFromList(body: any) {

        return this.http.post(this.BASE_URL + "list/update", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get list of filtered videos

    getFilteredListOfVideos(body: ListFilterReq) {

        return this.http.post(this.BASE_URL + "video/my-list", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to remove a video from the list

    removeVideoFromList(id: string) {

        return this.http.get(this.BASE_URL + "list/remove?id=" + id, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get a video playback url

    getVideoPlaybackUrl(id: string, userId?: string) {

        let query = userId ? `${id}&userId=${userId}` : id;

        return this.http.get(this.BASE_URL + "list/get-playback-url?id=" + query, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to update transcoding status

    updateTranscodingStatus(transcodingJobId: string, transcodingJobStatus: string, videoId?: string) {

        let query = videoId ? `${transcodingJobId}&transcodingJobStatus=${transcodingJobStatus}&videoId=${videoId}` : `${transcodingJobId}&transcodingJobStatus=${transcodingJobStatus}`;

        return this.http.get(this.BASE_URL + "list/update-transcoding-status?transcodingJobId=" + query, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // Playlist - v1.0.1

    // This function is used to create new playlist

    createNewPlaylist(body: PlaylistCreateRequestReq) {

        return this.http.post(this.BASE_URL + "playlist/create", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to update a playlist

    updatePlaylist(body: PlaylistUpdateRequestReq) {

        return this.http.post(this.BASE_URL + "playlist/update", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to add video to playlist

    addVideoToPlaylist(body: PlaylistAttachVideoRequestReq) {

        return this.http.post(this.BASE_URL + "playlist/add-video", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to remove videos from playlist

    removeVideoFromPlaylist(body: PlaylistAttachVideoRequestReq) {

        return this.http.post(this.BASE_URL + "playlist/remove-video", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get videos from playlist

    getVideosFromPlayList(playlistId: string, query?: string, page?: number, userId?: string) {

        let queryParams: string = "";

        if (!playlistId) {
            return Promise.resolve([]);
        }
        else {
            queryParams += `${playlistId}`;
            if (query) {
                queryParams += `&query=${query}`;
            }
            if (page) {
                queryParams += `&page=${page}`;
            }
            if (userId) {
                queryParams += `&userId=${userId}`;
            }
        }

        return this.http.get(this.BASE_URL + "playlist/get-videos?playlistId=" + queryParams, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get playlists list

    getListOfPlaylists(query?: string) {

        let queryParams = query ? `?query=${query}` : "";

        return this.http.get(this.BASE_URL + "playlist/get-list" + queryParams, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to delete a playlist

    deletePlaylist(playlistId: string) {

        return this.http.get(this.BASE_URL + "playlist/delete?playlistId=" + playlistId, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get playlist details

    getPlaylistDetails(playlistId: string) {

        return this.http.get(this.BASE_URL + "playlist/get-details?playlistId=" + playlistId, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // Search - v1.0.1

    // This function is used for searching all items.

    searchItems(body: SearchBodyVideoReq) {

        return this.http.post(this.BASE_URL + "playlist/list", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content || response.body);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used for searching all related videos.

    searchRelatedVideos(videoId: string, page?: number) {

        let query = page ? `${videoId}&page=${page}` : videoId;

        return this.http.get(this.BASE_URL + "search/related?videoId=" + query, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // Setting - v1.0.1

    // This function is used to update setting

    updateVideoSettings(body: UpdateSettingReq) {
        return this.http.post(this.BASE_URL + "setting/update", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get setting

    getVideoSettings(channelId: string) {
        return this.http.get(this.BASE_URL + "setting/get?channelId=" + channelId, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }




    // Subscription - v1.0.1

    // This function is used to subscribe to a channel

    subscribeChannel(id: string) {

        return this.http.get(this.BASE_URL + "channel/subscribe?id=" + id, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to unsubscribe a channel

    unsubscribeChannel(id: string) {

        return this.http.get(this.BASE_URL + "channel/unsubscribe?id=" + id, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get list of subscribed channels

    subscribedChannelsList(body: ListSubscribersReq) {

        return this.http.post(this.BASE_URL + "subscription/list", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // Token - v1.0.1

    // This function is used for setting session cookie.

    setSessionCookie(id: string, au: string) {
        return this.http.get(this.BASE_URL + `set?id=${id}&au=${au}`, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used for resetting session cookie.

    resetSessionCookie(au: string) {
        return this.http.get(this.BASE_URL + `reset?au=${au}`, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // Watch history / Watch-later - v1.0.1

    // This function is used to remove videos from watch-later / watch-history

    removeVideosFromWatchLaterOrWatchHistory(body: RemoveRequestReq) {

        return this.http.post(this.BASE_URL + "watch/remove", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get videos from watch later or watch history list

    getVideosFromWatchLaterOrWatchHistory(type: string, query?: string, page?: number, userId?: string) {
        // console.log(type, query);

        let queryParams: string = "";

        if (!type) {
            return;
        }
        else {
            queryParams += `${type}`;
            if (query) {
                queryParams += `&query=${query}`;
            }
            if (page) {
                queryParams += `&page=${page}`;
            }
            if (userId) {
                queryParams += `&userId=${userId}`;
            }
        }


        let req = {
            filters: {
                query: query
            }
        }

        return this.http.post(this.BASE_URL + "watch-later/my-list", req, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to clear watch later or watch history list

    clearWatchLaterListOrWatchHistoryList(type: string) {
        return this.http.get(this.BASE_URL + `watch/clear-list?type=${type}`, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to add video to watch later

    addVideoToWatchLater(videoId: string) {
        return this.http.get(this.BASE_URL + `watch/add-watch-later?videoId=${videoId}`, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used for reporting an user item.

    reportUserItems(body: ReportUserItemBodyVideoReq) {
        return this.http.post(this.BASE_URL + "report/user-items", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }


}
