import { TagInputModule } from 'ngx-chips';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MInputComponent } from './m-input/m-input.component';
import { MNgselectComponent } from './m-ngselect/m-ngselect.component';
import { MAutocompleteComponent } from './m-autocomplete/m-autocomplete.component';
import { MSelectComponent } from './m-select/m-select.component';
// import { MLocationComponent } from './m-location/m-location.component';
import { ClearInputComponent } from './clear-input/clear-input.component';
import { InputErrorComponent } from './input-error/input-error.component';
import { NoDataComponent } from './no-data/no-data.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MTextareaComponent } from './m-textarea/m-textarea.component';
import { MEditorComponent } from './m-editor/m-editor.component';
import { ImageCropperModule } from 'ngx-image-cropper';
// import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxUploaderModule } from 'ngx-uploader';
import { DescriptionLengthComponent } from './description-length/description-length.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { NgZorroAntdModule, NzSelectModule, NzOptionComponent } from 'ng-zorro-antd';
import { MImagePickerComponent } from './m-image-picker/m-image-picker.component';
import { MFilePickerComponent } from './m-file-picker/m-file-picker.component';
import { FileManagerModule } from 'src/app/modules/file-manager/file-manager.module';
import { NgxSmartModalModule } from 'ngx-smart-modal';



@NgModule({
  declarations: [
    DescriptionLengthComponent,
    MInputComponent, 
    MNgselectComponent, 
    MAutocompleteComponent, 
    MSelectComponent, 
    // MLocationComponent,
    ClearInputComponent,
    InputErrorComponent,
    NoDataComponent,
    MTextareaComponent,
    MEditorComponent,
    MImagePickerComponent,
    MFilePickerComponent,
  ],
  imports: [
    TagInputModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    CKEditorModule,
    // NgxGalleryModule,
    ImageCropperModule,
    NgxSmartModalModule,
    NgxUploaderModule,
    NgZorroAntdModule,
    NzSelectModule,
    FileManagerModule,

  ],
  exports: [
    DescriptionLengthComponent,
    FormsModule,
    MInputComponent, 
    MNgselectComponent, 
    MAutocompleteComponent, 
    MSelectComponent, 
    // MLocationComponent,s
    ClearInputComponent,
    InputErrorComponent,
    NoDataComponent,
    NgSelectModule,
    MTextareaComponent,
    MEditorComponent,
    CKEditorModule,
    MImagePickerComponent,
    MFilePickerComponent,
    // NgxGalleryModule,
    ImageCropperModule,
    NgxSmartModalModule,
    NgxUploaderModule,
    NzSelectModule,
    NgZorroAntdModule,
    NzOptionComponent,
    FileManagerModule,

  ]
})
export class MesbroInputsModule { }
