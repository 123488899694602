import { Injectable } from '@angular/core';
import { HttpResponse, HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpProviderService {

  public apiUrl = environment.BASE_URL;

  constructor(public http: HttpClient) { }

  get(application: string, component: string, api: string, id: string, token?) {
    this.apiUrl = this.createAPIEndPoint(application, component, api, id, 'get');
    // console.log(this.apiUrl);
    return this.constructGetCall(token);
  }

  async post(application: string, component: string, api: string, body: any, token?) {
    try {
      this.apiUrl = this.createAPIEndPoint(application, component, api);
      // console.log(this.apiUrl);
      return this.constructPostCall(body, token);
    } catch (error) {
      console.log(error);
    }
    // console.log(body)

  }

  async put(application: string, component: string, api: string, body: any) {
    this.apiUrl = this.createAPIEndPoint(application, component, api);
    console.log(this.apiUrl);
    return this.constructPutCall(body);
  }

  delete(application: string, component: string, api: string, id: string) {
    this.apiUrl = this.createAPIEndPoint(application, component, api, id, 'delete');
    console.log(this.apiUrl);
    return this.constructDeleteCall();
  }

  createAPIEndPoint(application: string, component: string, api: string, id?: string, method?: string): string {

    try {
      
    let returnUrl = `${environment.BASE_URL}api/${application}/${component}/${api}`;

    if (method == 'delete') {
      returnUrl = `${environment.BASE_URL}api/${application}/${component}`;
    }

    if (id) {
      // if (method == "delete") {
      //   returnUrl += `/${id}`;
      // }
      // else {
      //   returnUrl += `?id=${id}`;
      // }
      returnUrl += `/${id}`;
    }
    return returnUrl;
    } catch (error) {
      console.log(error);
    }

  }

  constructGetCall(token?) {
    if (token) {
      return this.http.get(this.apiUrl, { observe: 'response', headers: new HttpHeaders({ 'Authorization': token }) }).toPromise().then((response: HttpResponse<any>) => {
        if (response.status === 200 || response.status === 201) {
          return Promise.resolve(response.body.content || response.body);
        }
      }).catch((error: HttpErrorResponse) => {
        return Promise.reject(error);
      })
    } else {
      return this.http.get(this.apiUrl, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
        if (response.status === 200 || response.status === 201) {
          return Promise.resolve(response.body.content || response.body);
        }
      }).catch((error: HttpErrorResponse) => {
        return Promise.reject(error);
      })
    }
  }

  constructPostCall(body: Object, token?) {
    // console.log(body);
    if (token) {
      return this.http.post(this.apiUrl, body, { observe: 'response', headers: new HttpHeaders({ 'Authorization': token }) }).toPromise().then((response: HttpResponse<any>) => {
        if (response.status === 200 || response.status === 201) {
          return Promise.resolve(response.body.content || response.body);
        }
      }).catch((error: HttpErrorResponse) => {
        return Promise.reject(error);
      })
    } else {
      return this.http.post(this.apiUrl, body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
        if (response.status === 200 || response.status === 201) {
          return Promise.resolve(response.body.content || response.body);
        }
      }).catch((error: HttpErrorResponse) => {
        return Promise.reject(error);
      })
    }
  }

  constructPutCall(body: Object) {
    return this.http.put(this.apiUrl, body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
      if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response.body);
      }
    }).catch((error: HttpErrorResponse) => {
      return Promise.reject(error);
    })
  }

  constructDeleteCall() {
    return this.http.delete(this.apiUrl, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
      if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response.body);
      }
    }).catch((error: HttpErrorResponse) => {
      return Promise.reject(error);
    })
  }

}
