import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
// import { ShareTransferFileReqObject } from '../interfaces/file-manager';

export class FileManager {

    BASE_URL = environment.FILE_MANAGER_API;

    constructor(public http: HttpClient) { }

    // Download - v1.0.1

    // This function is used to get  signed url for download from s3 bucket

    getDownloadUrl(filePath: string, fileName?: string) {
        let query = fileName ? `${filePath}&fileName=${fileName}` : filePath;

        return this.http.get(this.BASE_URL + "get-download-url?filePath=" + query, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 302) {
                console.log('Reached inside Status')
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // Token - v1.0.1

    // This function is used for setting session cookie.

    setSessionCookie(id: string, au: string) {
        return this.http.get(this.BASE_URL + `set?id=${id}&au=${au}`, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used for resetting session cookie.

    resetSessionCookie(au: string) {
        return this.http.get(this.BASE_URL + `reset?au=${au}`, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // Uploads - v1.0.1

    // This function is used to get signed url for direct upload to s3 bucket

    getSignedUrl(type: string, fileName: string, fileType: string, appkey: string, useFileName?: boolean) {

        // let query = useFileName ? `&useFileName=${useFileName}` : '';

        return this.http.get(this.BASE_URL + "upload/get-signed-url?type=" + `${type}&fileName=${fileName}&fileType=${fileType}&appkey=${appkey}`, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to confirm the successful upload

    confirmUpload(uploadToken: string) {
        return this.http.get(this.BASE_URL + "upload/confirm?uploadToken=" + uploadToken, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get content details of an upload

    getUploadDetails(uploadToken: string) {
        return this.http.get(this.BASE_URL + "upload/get-details?uploadToken=" + uploadToken, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    // This function is used to get content details of an upload

    getUploadList(page?: any, query?: string, type?: 'video' | 'image' | 'pdf' | 'doc' | 'general' | '', sharedStatus?: boolean, sharedType?: '1' | '2') {

        //shared type - 1 with me, 2 by me


        let req = {
            "page": page ? page : undefined,
            "filters": {
                "query": query ? query : undefined,
                "type": type ? type : undefined,
                // "sharedStatus": sharedStatus ? sharedStatus : undefined,
                // "sharedType": sharedType ? sharedType : undefined,
                // "basic": {
                //     "query": query ? query : undefined,
                //     "type": type ? type : undefined,
                // }
            }
        }

        // get-shared-list

        let endpoint = sharedType? "get-shared-list":"my-list";

        return this.http.post(environment.BUSINESS_ADMIN + "api/business/file-manager/"+endpoint, req, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    fileTransfer(body) {
        return this.http.post(this.BASE_URL + "transfer/share-files", body, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    getTransferredFiles(id: string) {
        return this.http.get(this.BASE_URL + "transfer/get-files?id=" + id, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    getSignedUrlWithoutAu(type: string, fileName: string, fileType: string, appkey: string, userId: string, useFileName?: boolean) {

        // let query = useFileName ? `&useFileName=${useFileName}` : '';

        return this.http.get(this.BASE_URL + "upload/get-signed-url-internally?type=" + `${type}&fileName=${fileName}&fileType=${fileType}&appkey=${appkey}&userId=${userId}`, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

    confirmUploadWithoutAu(uploadToken: string, userId: string) {
        return this.http.get(this.BASE_URL + "upload/confirm-internally?uploadToken=" + uploadToken + `&userId=${userId}`, { observe: 'response' }).toPromise().then((response: HttpResponse<any>) => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response.body.content);
            }
        }).catch((error: HttpErrorResponse) => {
            return Promise.reject(error);
        })
    }

}
