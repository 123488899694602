export const aptProductNames = [
    { id: "1", name: "FROZEN SURIMI (FROZEN RIBBIONFISH MEAT GRADE C)" },
    { id: "2", name: "NA" },
];

export const countryType = [
    { id: "1", name: "EU" },
    { id: "2", name: "NON EU" },
    { id: "3", name: "CHINA" },
    { id: "4", name: "RUSSIA" },
];

export const brand = [
    { id: "ulka-surimi", name: "ULKA SURMI" },
    // { id: "ulka-brand", name: "ULKA BRAND" },
    { id: "ulka", name: "ULKA" },
    { id: "shree-ulka-surimi", name: "SHREE ULKA SURMI" },
    // { id: "shree-ulka-brand", name: "SHREE ULKA BRAND" },
    { id: "shree-ulka", name: "SHREE ULKA" },
    { id: "luf-bangkok", name: "LUF/BANGKOK" },
    { id: "siam", name: "SIAM" },
    { id: "savramis", name: "SAVRAMIS" },
    { id: "ULKA/SWF", name: "ULKA/SWF" },
    { id: "K.S.H", name: "K.S.H" },
    { id: "SEA MARINE", name: "SEA MARINE" },
    { id: "MARINE WORLD", name: "MARINE WORLD" },
];

export const claimType = [
    { id: "1", name: "QUALITY ISSUE" },
    { id: "2", name: "DEMURRAGE" },
    { id: "3", name: "DEBIT NOTE" },
    { id: "4", name: "MISSING CARTON" },
    { id: "5", name: "EXTRA CARTON" },
];

export const additivesList = [
    { id: "1", name: "E220 - SULFUR DIOXIDE", additive: 'E220' },
    { id: "2", name: "E330 - CITRIC ACID", additive: 'E330' },
    { id: "3", name: "E331 - SODIUM CITRATE", additive: 'E331' },
    { id: "4", name: "E223 - SODIUM METABISULPHITE", concentrationValue: '0.02', additive: 'E223' },
    { id: "5", name: "E452 - POLYPHOSPHATE", additive: 'E452' },
    { id: "6", name: "SALT", additive: 'SALT' },
];

export const ulkaBank = [
    { id: "1", purchase: true, 'waste-management': false, sales: true, accountNumber: "19080210005224", bankName: 'UCO BANK', branch: 'NARIMAN POINT', ifscCode: 'UCBA0001908', bankAccounts: 'Uco Bank A/c 5224' },
    { id: "2", purchase: true, 'waste-management': false, sales: true, accountNumber: "19080210005217", bankName: 'UCO BANK', branch: 'NARIMAN POINT', ifscCode: 'UCBA0001908', bankAccounts: 'Uco Bank A/c No 5217' },
    { id: "3", purchase: true, 'waste-management': false, sales: true, accountNumber: "19080210005514", bankName: 'UCO BANK', branch: 'NARIMAN POINT', ifscCode: 'UCBA0001908', bankAccounts: 'Uco Bank A/c 5514' },
    { id: "4", purchase: true, 'waste-management': false, sales: true, accountNumber: "05290210000205", bankName: 'UCO BANK', branch: 'NARIMAN POINT', ifscCode: 'UCBA0001908', bankAccounts: 'Uco Bank A/c No 0205' },
    { id: "5", purchase: true, 'waste-management': false, sales: true, accountNumber: "01970200000506", bankName: 'UCO BANK', branch: 'NARIMAN POINT', ifscCode: 'UCBA0001908', bankAccounts: 'Uco Bank A/c 0506' },
    { id: "6", purchase: true, 'waste-management': false, sales: true, accountNumber: "05290200152490", bankName: 'UCO BANK', branch: 'NARIMAN POINT', ifscCode: 'UCBA0001908', bankAccounts: 'Uco Bank A/c 2490' },
    { id: "7", purchase: true, 'waste-management': false, sales: true, accountNumber: "19080210005231", bankName: 'UCO BANK', branch: 'NARIMAN POINT', ifscCode: 'UCBA0001908', bankAccounts: 'Uco Bank A/c 5231' },
    { id: "8", purchase: true, 'waste-management': false, sales: true, accountNumber: "01970200001603", bankName: 'UCO BANK', branch: 'PANJI, GOA', ifscCode: 'UCBA0000197', bankAccounts: 'Uco Bank A/c 1603' },
    { id: "9", purchase: true, 'waste-management': false, sales: true, accountNumber: "34951607374", bankName: 'SBI BANK', branch: 'TALOJA', ifscCode: 'SBIN0012944', bankAccounts: 'SBI Bank A/c 7374' },
    { id: "10", purchase: true, 'waste-management': false, sales: true, accountNumber: "0047102000005425", bankName: 'IDBI BANK', branch: 'TALOJA', ifscCode: 'IBKL0000047', bankAccounts: 'IDBI Bank A/c 5425' },
    { id: "11", purchase: true, 'waste-management': false, sales: true, accountNumber: "047102000003827", bankName: 'IDBI BANK', branch: 'TALOJA', ifscCode: 'IBKL0000047', bankAccounts: 'IDBI Bank A/c 3827' },
    { id: '12', purchase: false, 'waste-management': true, sales: false, accountNumber: '0047102000007092', bankName: 'IDBI Bank', branch: 'Indal compound plot no 2 , MIDC taloja  mumbai maharashtra 410208.', ifscCode: 'IBKL0000047', company: 'ulka' }
];

export const adCode = [
    { approvalNumber: "1192", adCode: "0321958" },
    { approvalNumber: "1215", adCode: "321958" },
    // { approvalNumber: "1584", adCode: "6470036" },
    { approvalNumber: "1584", adCode: "6360899" },
    { approvalNumber: "1584_INR", adCode: "0320527" },
    { approvalNumber: "1161", adCode: "321958" },
    { approvalNumber: "1103", adCode: "321958" },
    { approvalNumber: "222", adCode: "321958" },
];

export const surimiDocuments = [
    { id: "1", name: "TRACEABLITY REPORT" },
    { id: "2", name: "TEST REPORT" },
    { id: "3", name: "SURIMI PROCESS FLOW CHART" },
    { id: "4", name: "SPECIFICATION" },
    { id: "5", name: "Quality Report (Analysis Report)" },
    { id: "6", name: "LABELS" },
    { id: "7", name: "INSURANCE POLICY" },
    { id: "8", name: "EXTRA SET OF DOCUMENTS COPY" },
    { id: "9", name: "Cretificate of Production date" },
    { id: "10", name: "Commercial invoice" },
    { id: "11", name: "CATCH STATEMENT" },
    { id: "12", name: "Bill of Exchange" },
];

export const fishDocuments = [
    { id: "1", name: "Packing list" },
    { id: "2", name: "LC" },
    { id: "3", name: "Ingredient list" },
    { id: "4", name: "IMC" },
    { id: "5", name: "IKCEPA" },
    { id: "6", name: "Health Certificate" },
    { id: "7", name: "GSP" },
    { id: "8", name: "Certificate of Origin" },
    { id: "9", name: "Catch Certificate" },
    { id: "10", name: "Buyer Covering letter" },
    { id: "11", name: "Bill of lading" },
    { id: "12", name: "Beneficiary Certificate FAX" },
    { id: "13", name: "Beneficiary Certificate" },
    { id: "14", name: "Bacteriological report (Analysis Report)" },
    { id: "15", name: "APTA" },
    { id: "20", name: "AIFTA certificate" },
];

export const dutyDrawback = [
    { id: "1", name: "MAMAKARI SURIMI-303", product: "MAMAKARI SURIMI", hscode: "3038930", dbkcode: "303", dbkrate: "3.6", capvalue: "10.9" },
    { id: "2", name: "LIZARD SURIMI", product: "LIZARD SURIMI", hscode: "3038950", dbkcode: "303", dbkrate: "3.6", capvalue: "10.9" },
    { id: "3", name: "KINTOKIDAI SURIMI", product: "KINTOKIDAI SURIMI", hscode: "3038980", dbkcode: "303", dbkrate: "3.6", capvalue: "10.9" },
    { id: "4", name: "KIGUCHI SURIMI", product: "KIGUCHI SURIMI", hscode: "3038980", dbkcode: "303", dbkrate: "3.6", capvalue: "10.9" },
    { id: "5", name: "ITOYORI SURIMI", product: "ITOYORI SURIMI", hscode: "3038980", dbkcode: "303", dbkrate: "3.6", capvalue: "10.9" },
    { id: "6", name: "ESO SURIMI", product: "ESO SURIMI", hscode: "664", dbkcode: "", dbkrate: "3.6", capvalue: "" },

];

export const chaName = [
    { id: "Synergy Global Logistics Pvt Ltd", name: "Synergy Global Logistics Pvt Ltd" },
    { id: "Shivansh Forwarding & Clearing Pvt Ltd", name: "Shivansh Forwarding & Clearing Pvt Ltd" },
    { id: "HERMES GROUPS", name: "HERMES GROUPS" },
    { id: "AMOGHA GROUPS", name: "AMOGHA GROUPS" },
    { id: "YASIKA LOGISTICS", name: "YASIKA LOGISTICS" },
    { id: "DHL", name: "DHL" },
    { id: "FED EX", name: "FED EX" },
];

export const approvalNumber = [
    {
        "id": "1",
        "approvalNumber": "1192",
        "countryType": "EU",
        "company": "ulka",
        "division": "surimi",
        "Exporter": "ULKA SEA FOODS PVT. LTD.",
        "exporterAddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
        "packedandprocessedby": "ULKA SEA FOODS PVT. LTD.",
        "packedandprocessedbyaddress": "M-34/2 & M-35, MIDC, TALOJA, DISTRICT: RAIGAD, MAHARASHTRA - 410208, INDIA",
    },
    {
        "id": "2",
        "approvalNumber": "1192",
        "countryType": "NON EU",
        "company": "ulka",
        "division": "surimi",
        "Exporter": "ULKA SEA FOODS PVT. LTD.",
        "exporterAddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
        "packedandprocessedby": "ULKA SEA FOODS PVT. LTD.",
        "packedandprocessedbyaddress": "M-34/2 & M-35, MIDC, TALOJA, DISTRICT: RAIGAD, MAHARASHTRA - 410208, INDIA",
    },
    {
        "id": "3",
        "approvalNumber": "1192",
        "countryType": "CHINA",
        "company": "ulka",
        "division": "surimi",
        "Exporter": "M/s. Ulka Seafoods Pvt. Ltd.",
        "exporterAddress": "M-34/2 & M-35, MIDC, Taloja, District-Raigad, Maharashtra- 410208",
        "packedandprocessedby": "M/s. Ulka Seafoods Pvt. Ltd.",
        "packedandprocessedbyaddress": "M-34/2 & M-35, MIDC, Taloja, District-Raigad, Maharashtra- 410208",
    },
    {
        "id": "4",
        "approvalNumber": "1192",
        "countryType": "RUSSIA",
        "company": "ulka",
        "division": "surimi",
        "Exporter": "ULKA SEA FOODS PVT. LTD.",
        "exporterAddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
        "packedandprocessedby": "M/s. ULKASEAFOODS PVT. LTD.",
        "packedandprocessedbyaddress": "PLOT NO. M-34/2, 35, MIDC, TALOJA, DIST- RAIGAD - 410208, MAHARASHTRA INDIA",
    },
    {
        "id": "5",
        "approvalNumber": "1215",
        "countryType": "EU",
        "company": "ulka",
        "division": "surimi",
        "Exporter": "ULKA SEA FOODS PVT. LTD.",
        "exporterAddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
        "packedandprocessedby": "ULKA SEA FOODS PVT. LTD.",
        "packedandprocessedbyaddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
    },
    {
        "id": "6",
        "approvalNumber": "1215",
        "countryType": "NON EU",
        "company": "ulka",
        "division": "surimi",
        "Exporter": "ULKA SEA FOODS PVT. LTD.",
        "exporterAddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
        "packedandprocessedby": "ULKA SEA FOODS PVT. LTD.",
        "packedandprocessedbyaddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
    },
    {
        "id": "7",
        "approvalNumber": "1215",
        "countryType": "CHINA",
        "company": "ulka",
        "division": "surimi",
        "Exporter": "M/s. Ulka Seafoods Pvt.Ltd.",
        "exporterAddress": "Plot No. M-79, MIDC, Taloja, District Raigad, Maharashtra- 410208",
        "packedandprocessedby": "M/s. Ulka Seafoods Pvt.Ltd.",
        "packedandprocessedbyaddress": "Plot No. M-79, MIDC, Taloja, District Raigad, Maharashtra- 410208",
    },
    {
        "id": "8",
        "approvalNumber": "1215",
        "countryType": "RUSSIA",
        "company": "ulka",
        "division": "surimi",
        "Exporter": "ULKA SEA FOODS PVT. LTD.",
        "exporterAddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
        "packedandprocessedby": "ULKA SEA FOODS PVT. LTD.",
        "packedandprocessedbyaddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
    },
    {
        "id": "9",
        "approvalNumber": "1584",
        "countryType": "EU",
        "company": "shree-ulka",
        "division": "surimi",
        "Exporter": "SHREE ULKA LLP",
        "exporterAddress": "GOLD CREST, FLAT NO.1501, NAWADA PHASE 2, TAL – PANVEL RAIGAD, MAHARASHTRA – 410206 , INDIA",
        "packedandprocessedby": "SHREE ULKA LLP",
        "packedandprocessedbyaddress": "IP-48 A & B , MSEZ MANGALORE DAKSHIN KANNADA KARNATAKA-574509",
    },
    {
        "id": "10",
        "approvalNumber": "1584",
        "countryType": "NON EU",
        "company": "shree-ulka",
        "division": "surimi",
        "Exporter": "SHREE ULKA LLP",
        "exporterAddress": "GOLD CREST, FLAT NO.1501, NAWADA PHASE 2, TAL – PANVEL RAIGAD, MAHARASHTRA – 410206 , INDIA",
        "packedandprocessedby": "SHREE ULKA LLP",
        "packedandprocessedbyaddress": "IP-48 A & B , MSEZ MANGALORE DAKSHIN KANNADA KARNATAKA-574509",
    },
    {
        "id": "11",
        "approvalNumber": "1584",
        "countryType": "CHINA",
        "company": "shree-ulka",
        "division": "surimi",
        "Exporter": "M/s SHREE ULKA LLP",
        "exporterAddress": "IP-48 A & B , MSEZ Mangalore, Dakshin Kannada, Karnataka- 574509, India",
        "packedandprocessedby": "M/s SHREE ULKA LLP",
        "packedandprocessedbyaddress": "IP-48 A & B , MSEZ Mangalore, Dakshin Kannada, Karnataka- 574509, India",
    },
    {
        "id": "12",
        "approvalNumber": "1584",
        "countryType": "RUSSIA",
        "company": "shree-ulka",
        "division": "surimi",
        "Exporter": "SHREE ULKA LLP",
        "exporterAddress": "GOLD CREST, FLAT NO.1501, NAWADA PHASE 2, TAL – PANVEL RAIGAD, MAHARASHTRA – 410206 , INDIA",
        // "packedandprocessedby": "SHREE ULKA LLP",
        "packedandprocessedby": "M/s Shree Ulka LLP",
        // "packedandprocessedbyaddress": "IP-48 A & B , MSEZ MANGALORE DAKSHIN KANNADA KARNATAKA-574509",
        "packedandprocessedbyaddress": "Msez Mangalore Dakshin Kannad Karnataka – 574509",
    },
    {
        "id": "13",
        "approvalNumber": "1161",
        "countryType": "EU",
        "company": "ulka",
        "division": "fish",
        "Exporter": "ULKA SEA FOODS PVT. LTD.",
        "exporterAddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
        "packedandprocessedby": "ULKA SEA FOODS PVT. LTD.",
        "packedandprocessedbyaddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
    },
    {
        "id": "14",
        "approvalNumber": "1161",
        "countryType": "NON EU",
        "company": "ulka",
        "division": "fish",
        "Exporter": "ULKA SEA FOODS PVT. LTD.",
        "exporterAddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
        "packedandprocessedby": "ULKA SEA FOODS PVT. LTD.",
        "packedandprocessedbyaddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
    },
    {
        "id": "15",
        "approvalNumber": "1161",
        "countryType": "CHINA",
        "company": "ulka",
        "division": "fish",
        "Exporter": "M/s. ULKA SEAFOODS (P) LTD. (UNIT No.4)",
        "exporterAddress": "Plot No. M-79, Taloja, MIDC Industrial Area, Dist. Raigad, Pin-410208, Maharashtra, India",
        "packedandprocessedby": "M/s. ULKA SEAFOODS (P) LTD. (UNIT No.4)",
        "packedandprocessedbyaddress": "Plot No. M-79, Taloja, MIDC Industrial Area, Dist. Raigad, Pin-410208, Maharashtra, India",
    },
    {
        "id": "16",
        "approvalNumber": "1161",
        "countryType": "RUSSIA",
        "company": "ulka",
        "division": "fish",
        "Exporter": "ULKA SEA FOODS PVT. LTD.",
        "exporterAddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
        "packedandprocessedby": "ULKA SEA FOODS PVT. LTD.",
        "packedandprocessedbyaddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
    },
    {
        "id": "17",
        "approvalNumber": "1103",
        "countryType": "EU",
        "company": "ulka",
        "division": "fish",
        "Exporter": "ULKA SEA FOODS PVT. LTD.",
        "exporterAddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
        "packedandprocessedby": "ULKA SEA FOODS PVT. LTD.",
        "packedandprocessedbyaddress": "ORGAO, MARCELA GOA - 403107, INDIA",
    },
    {
        "id": "18",
        "approvalNumber": "1103",
        "countryType": "NON EU",
        "company": "ulka",
        "division": "fish",
        "Exporter": "ULKA SEA FOODS PVT. LTD.",
        "exporterAddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
        "packedandprocessedby": "ULKA SEA FOODS PVT. LTD.",
        "packedandprocessedbyaddress": "ORGAO, MARCELA GOA - 403107, INDIA",
    },
    {
        "id": "19",
        "approvalNumber": "1103",
        "countryType": "CHINA",
        "company": "ulka",
        "division": "fish",
        "Exporter": "M/s Ulka Seafoods (P) Ltd (Unit- II)",
        "exporterAddress": "Orgao, Marcela, Goa- 403 107, Goa, INDIA",
        "packedandprocessedby": "M/s Ulka Seafoods (P) Ltd (Unit- II)",
        "packedandprocessedbyaddress": "Orgao, Marcela, Goa- 403 107, Goa, INDIA",
    },
    {
        "id": "20",
        "approvalNumber": "1103",
        "countryType": "RUSSIA",
        "company": "ulka",
        "division": "fish",
        "Exporter": "ULKA SEA FOODS PVT. LTD.",
        "exporterAddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
        "packedandprocessedby": "ULKA SEA FOODS PVT. LTD.",
        "packedandprocessedbyaddress": "ORGAO, MARCELA GOA - 403107, INDIA",
    },
    {
        "id": "21",
        "approvalNumber": "222",
        "countryType": "EU",
        "company": "ulka",
        "division": "fish",
        "Exporter": "ULKA SEA FOODS PVT. LTD.",
        "exporterAddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
        "packedandprocessedby": "ULKA SEA FOODS PVT. LTD.",
        "packedandprocessedbyaddress": "ORGAO, MARCELA GOA - 403107, INDIA",
    },
    {
        "id": "22",
        "approvalNumber": "222",
        "countryType": "NON EU",
        "company": "ulka",
        "division": "fish",
        "Exporter": "ULKA SEA FOODS PVT. LTD.",
        "exporterAddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
        "packedandprocessedby": "ULKA SEA FOODS PVT. LTD.",
        "packedandprocessedbyaddress": "ORGAO, MARCELA GOA - 403107, INDIA",
    },
    {
        "id": "23",
        "approvalNumber": "222",
        "countryType": "CHINA",
        "company": "ulka",
        "division": "fish",
        "Exporter": "M/s Ulka Seafoods (P) Ltd (Unit- II)",
        "exporterAddress": "Orgao, Marcela, Goa- 403 107, Goa, INDIA",
        "packedandprocessedby": "M/s Ulka Seafoods (P) Ltd (Unit- II)",
        "packedandprocessedbyaddress": "Orgao, Marcela, Goa- 403 107, Goa, INDIA",
    },
    {
        "id": "24",
        "approvalNumber": "222",
        "countryType": "RUSSIA",
        "company": "ulka",
        "division": "fish",
        "Exporter": "ULKA SEA FOODS PVT. LTD.",
        "exporterAddress": "M-79,TALOJA MIDC RAIGAD MAHARASHTRA 410208 INDIA",
        "packedandprocessedby": "ULKA SEA FOODS PVT. LTD.",
        "packedandprocessedbyaddress": "ORGAO, MARCELA GOA - 403107, INDIA",
    },
    {
        "id": "25",
        "approvalNumber": "1584_INR",
        "countryType": "RUSSIA",
        "company": "shree-ulka",
        "division": "surimi",
        "Exporter": "SHREE ULKA LLP",
        "exporterAddress": "GOLD CREST, FLAT NO.1501, NAWADA PHASE 2, TAL – PANVEL RAIGAD, MAHARASHTRA – 410206 , INDIA",
        // "packedandprocessedby": "SHREE ULKA LLP",
        "packedandprocessedby": "M/s Shree Ulka LLP",
        // "packedandprocessedbyaddress": "IP-48 A & B , MSEZ MANGALORE DAKSHIN KANNADA KARNATAKA-574509",
        "packedandprocessedbyaddress": "Msez Mangalore Dakshin Kannad Karnataka – 574509",
    },

];

export const modeOfNegotiattion = [
    { id: "1", name: "WHATSAPP" },
    { id: "2", name: "WECHAT" },
    { id: "3", name: "SKYPE" },
    { id: "4", name: "LINE" },
    { id: "5", name: "EMAIL" },
    { id: "6", name: "CALL" },

];

export const moisture = [
    { id: "1", name: "80% +/- 1" },
    { id: "2", name: "79% +/- 1" },
    { id: "3", name: "78% +/- 1" },
    { id: "4", name: "77% +/- 1" },
    { id: "5", name: "76% +/- 1" },
    { id: "6", name: "75% +/- 1" },
    { id: "7", name: "74% +/- 1" },
    { id: "8", name: "73% +/- 1" },
    { id: "9", name: "72% +/- 1" },
    { id: "10", name: "71% +/- 1" },

];

export const sizes = [
    { id: "1/2" },
    { id: "2/4" },
    { id: "U/5" },
    { id: "4/6" },
    { id: "6/7" },
    { id: "6/8" },
    { id: "7/8" },
    { id: "8/12" },
    { id: "11/15" },
    { id: "13/15" },
    { id: "10/20" },
    { id: "16/20" },
    { id: "21/25" },
    { id: "21/30" },
    { id: "26/30" },
    { id: "31/35" },
    { id: "36/40" },
    { id: "31/40" },
    { id: "41/50" },
    { id: "51/60" },
    { id: "51/70" },
    { id: "61/70" },
    { id: "71/90" },
    { id: "91/110" },
    { id: "3/6" },
    { id: "6/10" },
    { id: "10/15" },
    { id: "20/40" },
    { id: "40/60" },
    { id: "60/80" },
    { id: "60/UP" },
    { id: "80/UP" },
    { id: "100/UP" },
    { id: "U/2" },
    { id: "U/3" },
    { id: "U/10" },
    { id: "LARGE" },
    { id: "MEDIUM" },
    { id: "SMALL" },
    { id: "100/200" },
    { id: "BKN" },
    { id: "U/20" },
    { id: "U/50" },
    { id: "50/100" },
    { id: "100/150" },
    { id: "150/300" },
    { id: "30/50" },
    { id: "300/500" },
    { id: "500/700" },
    { id: "700/1000" },
    { id: "1000/2000" },
    { id: "1000/UP" },
    { id: "2000/UP" },
    { id: "U/1" },
    { id: "5/7" },
    { id: "13/20" },
    { id: "200/400" },
    { id: "200/UP" },
    { id: "200/300" },
    { id: "300/400" },
    { id: "400/500" },
    { id: "2000/3000" },
    { id: "5000/UP" },
    { id: "8/10" },
    { id: "500/600" },
    { id: "600/700" },
    { id: "1500/2000" },
    { id: "3000/UP" },
    { id: "500/1000" },
    { id: "5000/7000" },
    { id: "2/3" },
    { id: "3/4" },
    { id: "3/5" },
    { id: "0-1 oz" },
    { id: "1-2 oz" },
    { id: "2-3 oz" },
    { id: "3-4 oz" },
    { id: "4-5 oz" },
    { id: "5-6 oz" },
    { id: "6-7 oz" },
    { id: "7-8 oz" },
    { id: "8-9 oz" },
    { id: "9-10 oz" },
    { id: "20/30" },
    { id: "30/40" },
    { id: "40/50" },
    { id: "50/60" },
    { id: "50/70" },
    { id: "80/100" },
    { id: "31/50" },
    { id: "30/60" },
    { id: "60/90" },
    { id: "80/120" },
    { id: "120/180" },
    { id: "80/120" },
    { id: "400/600" },
    { id: "600/800" },
    { id: "40/UP" },
    { id: "20/25" },
    { id: "13/18" },
    { id: "150/UP" },
    { id: "100/1000" },
    { id: "200/1000" },
    { id: "150/200" },
    { id: "700/800" },
    { id: "800/900" },
    { id: "900/1000" },
    { id: "BIG BROKEN" },
    { id: "SMALL BROKEN" },
    { id: "ASSORTED" },
    { id: "BROKEN" },
    { id: "10/12" },
    { id: "40/55" },
    { id: "45/55" },
    { id: "90/120" },
    { id: "20/60" },
    { id: "20/50" },
    { id: "U/15" },
    { id: "60/70" },
    { id: "70/80" },
    { id: "70/90" },
    { id: "4/5" },

]

export const treatments = [
    { id: "(TREATED)" },
    { id: "(UNTREATED)" },
    { id: "(TREATED WITH E 223)" },
    { id: "(TREATED WITH E330/E331)" },
    { id: "(TREATED WITH SALT)" },
    { id: "(TREATED WITH SALT/E223/E330/E331)" },
    { id: "(TREATED WITH PAPRIKA)" },
    { id: "(TREATED WITH E452)" },
    { id: "(WITH COLOUR)" },
    { id: "(WITHOUR COLOUR)" },
]

export const glazes = [
    { id: "BLOCK" },
    { id: "BULK" },
    { id: "NET WEIGHT" },
    { id: "1%" },
    { id: "2%" },
    { id: "3%" },
    { id: "4%" },
    { id: "5%" },
    { id: "6%" },
    { id: "7%" },
    { id: "8%" },
    { id: "9%" },
    { id: "10%" },
    { id: "11%" },
    { id: "12%" },
    { id: "13%" },
    { id: "14%" },
    { id: "15%" },
    { id: "16%" },
    { id: "17%" },
    { id: "18%" },
    { id: "19%" },
    { id: "20%" },
    { id: "21%" },
    { id: "22%" },
    { id: "23%" },
    { id: "24%" },
    { id: "25%" },
    { id: "26%" },
    { id: "27%" },
    { id: "28%" },
    { id: "29%" },
    { id: "30%" },
    { id: "31%" },
    { id: "32%" },
    { id: "33%" },
    { id: "34%" },
    { id: "35%" },
    { id: "36%" },
    { id: "37%" },
    { id: "38%" },
    { id: "39%" },
    { id: "40%" },
    { id: "41%" },
    { id: "42%" },
    { id: "43%" },
    { id: "44%" },
    { id: "45%" },
    { id: "6/15%" },
    { id: "6/20%" },
    { id: "6/25%" },
    { id: "6/30%" },
    { id: "6/35%" },
    { id: "6/40%" },
    { id: "6/45%" },
    { id: "10/15%" },
    { id: "10/20%" },
    { id: "10/25%" },
    { id: "10/30%" },
    { id: "10/35%" },
    { id: "10/40%" },
    { id: "10/45%" },
    { id: "15/20%" },
    { id: "15/25%" },
    { id: "15/30%" },
    { id: "15/32%" },
    { id: "15/35%" },
    { id: "15/40%" },
    { id: "15/45%" },
    { id: "18/32%" },
    { id: "20/30%" },
    { id: "20/35%" },
    { id: "20/40%" },
    { id: "20/45%" },
    { id: "18/37%" },
    { id: "20/37%" },
    { id: "20/42%" },
    { id: "18/38%" },
    { id: "50%" },
    { id: "14%" },
]

export const declaredGlazes = [
    { id: "BLOCK" },
    { id: "BULK" },
    { id: "NET WEIGHT" },
    { id: "5%" },
    { id: "7%" },
    { id: "10%" },
    { id: "15%" },
    { id: "17%" },
    { id: "20%" },
    { id: "25%" },
    { id: "30%" },
    { id: "35%" },
    { id: "40%" },
    { id: "45%" },
    { id: "50%" },
    { id: "14%" },
    { id: "8%" },

]


export const surimiPackings = [
    // { id: "1", unit: 'KGS', singleUnit: 'KG', name: "20 KGS", packing: "20 KGS", weight: "20" },
    { id: "1", unit: 'KG', singleUnit: 'KG', name: "2 X 10 KG", packing: "2 X 10 KG", weight: "20" }
    // { id: "3", unit: 'KG', singleUnit: 'KG', name: "2 X 10 KG", packing: "2 X 10 KG", weight: "20" }
]

export const packings = [
    { id: "1", unit: 'KGS', singleUnit: 'KG', name: "20 KG", packing: "20 KG", weight: "20", fillWeight: '20' },
    { id: "2", unit: 'KG', singleUnit: 'KG', name: "2 X 10 KG", packing: "2 X 10 KG", weight: "20", fillWeight: '2' },
    { id: "3", unit: 'GMS', singleUnit: 'GM', name: "20 X 500 GM", packing: "20 X 500 GM", weight: "10", fillWeight: '500 GM' },
    { id: "4", unit: 'GMS', singleUnit: 'GM', name: "10 X 800 GM", packing: "10 X 800 GM", weight: "8", fillWeight: '800 GM' },
    { id: "6", unit: 'KGS', singleUnit: 'KG', name: "10 KG BULK", packing: "10 KG BULK" },
    { id: "7", unit: 'GMS', singleUnit: 'GM', name: "30 X 330 GM", packing: "30 X 330 GM", weight: "9.9", fillWeight: '330 GM' },
    { id: "8", unit: 'GMS', singleUnit: 'GM', name: "10 X 450 GM", packing: "10 X 450 GM", weight: "4.5", fillWeight: '450 GM' },
    // { id: "8", unit: 'LBS', singleUnit: 'LB', name: "10 X 2 LBS", packing: "10 X 2 LBS" }, COMMENED ON 10 August 2020
    { id: "9", unit: 'KGS', singleUnit: 'KG', name: "6 X 2 KG", packing: "6 X 2 KG", weight: "12", fillWeight: '2' },
    { id: "10", unit: 'KGS', singleUnit: 'KG', name: "10 X 1 KG", packing: "10 X 1 KG", weight: "10", fillWeight: '1' },
    // { id: "3", unit : 'KGS', singleUnit : 'KG', name: "1 X 10 KG" , packing: "1 X 10 KG"},
    { id: "11", unit: 'KGS', singleUnit: 'KG', name: "10 X 2 KG", packing: "10 X 2 KG", weight: "20", fillWeight: '2' },
    { id: "12", unit: 'KGS', singleUnit: 'KG', name: "4 X 4 KG", packing: "4 X 4 KG", weight: "16", fillWeight: '4' },
    { id: "13", unit: 'KGS', singleUnit: 'KG', name: "4 X 5 KG", packing: "4 X 5 KG", weight: "20", fillWeight: '5' },
    { id: "14", unit: 'KGS', singleUnit: 'KG', name: "5 X 4 KG", packing: "5 X 4 KG", weight: "20", fillWeight: '4' },
    { id: "15", unit: 'GMS', singleUnit: 'GM', name: "25 X 400 GM", packing: "25 X 400 GM", weight: "10", fillWeight: '400 GM' },
    { id: "17", unit: 'GMS', singleUnit: 'GM', name: "10 X 500 GM", packing: "10 X 500 GM", weight: "5", fillWeight: '500 GM' },
    { id: "18", unit: 'GMS', singleUnit: 'GM', name: "10 X 400 GM", packing: "10 X 400 GM",weight: "4", fillWeight: '400 GM'  },
    { id: "20", unit: 'KGS', singleUnit: 'KG', name: "8 X 2 KG", packing: "8 X 2 KG", weight: "16", fillWeight: '2' },
    { id: "21", unit: 'GMS', singleUnit: 'GM', name: "24 X 500 GM", packing: "24 X 500 GM", weight: "12", fillWeight: '500 GM' },
    { id: "22", unit: 'KGS', singleUnit: 'KG', name: "15 X 1 KG", packing: "15 X 1 KG", weight:'15' },
    { id: "23", unit: 'GMS', singleUnit: 'GM', name: "12 X 900 GM", packing: "12 X 900 GM", weight : '10.8' },
    { id: "24", unit: 'KGS', singleUnit: 'KG', name: "20 X 1 KG", packing: "20 X 1 KG", weight:'20' },
    { id: "25", unit: 'KGS', singleUnit: 'KG', name: "3 X 4 KG", packing: "3 X 4 KG", weight: "12", fillWeight: '4' },
    { id: "26", unit: 'KGS', singleUnit: 'KG', name: "6 X 4 KG", packing: "6 X 4 KG", weight: "24", fillWeight: '4' },
    { id: "27", unit: 'KGS', singleUnit: 'KG', name: "5 X 5 KG", packing: "5 X 5 KG", weight: "25", fillWeight: '5' },
    { id: "28", unit: 'KGS', singleUnit: 'KG', name: "3 X 5 KG", packing: "3 X 5 KG", weight: "15", fillWeight: '5' },
    { id: "29", unit: 'KGS', singleUnit: 'KG', name: "10 X 1.8 KG", packing: "10 X 1.8 KG", weight: "18", fillWeight: '1.8' },
    { id: "30", unit: 'KGS', singleUnit: 'KG', name: "5 X 3.5 KG", packing: "5 X 3.5 KG", weight: "17.5", fillWeight: '3.5' },
    { id: "31", unit: 'KGS', singleUnit: 'KG', name: "1 X 5 KG", packing: "1 X 5 KG", weight: "5", fillWeight: '5' },
    { id: "32", unit: 'KGS', singleUnit: 'KG', name: "1 X 8 KG", packing: "1 X 8 KG", weight: "8", fillWeight: '8' },
    { id: "33", unit: 'KGS', singleUnit: 'KG', name: "1 X 9 KG", packing: "1 X 9 KG", weight: "9", fillWeight: '9' },
    { id: "34", unit: 'KGS', singleUnit: 'KG', name: "1 X 10 KG", packing: "1 X 10 KG", weight: "10", fillWeight: '10' },
    { id: "35", unit: 'KGS', singleUnit: 'KG', name: "1 X 11 KG", packing: "1 X 11 KG", weight: "11", fillWeight: '11' },
    { id: "36", unit: 'KGS', singleUnit: 'KG', name: "1 X 12 KG", packing: "1 X 12 KG", weight: "12", fillWeight: '12' },
    { id: "37", unit: 'KGS', singleUnit: 'KG', name: "1 X 13 KG", packing: "1 X 13 KG", weight: "13", fillWeight: '13' },
    { id: "38", unit: 'KGS', singleUnit: 'KG', name: "1 X 15 KG", packing: "1 X 15 KG", weight: "15", fillWeight: '15' },
    { id: "39", unit: 'KGS', singleUnit: 'KG', name: "1 X 20 KG", packing: "1 X 20 KG", weight: "20", fillWeight: '20' },
    { id: "40", unit: 'KGS', singleUnit: 'KG', name: "12 X 1 KG", packing: "12 X 1 KG", weight: "12", fillWeight: '1' },
    { id: "41", unit: 'GMS', singleUnit: 'GM', name: "14 X 700 GM", packing: "14 X 700 GM", weight: "9.8", fillWeight: '700 GM' },
    { id: "42", unit: 'LBS', singleUnit: 'LBS', name: "10 X 2 LBS", packing: "10 X 2 LBS", weight: "9.07185", fillWeight: '2 LBS' },
    { id: "43", unit: 'LBS', singleUnit: 'LBS', name: "20 X 1 LBS", packing: "20 X 1 LBS", weight: "9.07184", fillWeight: '1 LBS' },
    { id: "44", unit: 'LBS', singleUnit: 'LBS', name: "1 X 20 LBS", packing: "1 X 20 LBS", weight: "9.07185", fillWeight: '20 LBS' },
    { id: "45", unit: 'LBS', singleUnit: 'LBS', name: "1 X 22 LBS", packing: "1 X 22 LBS", weight: "9.97903", fillWeight: '22 LBS' },
    { id: "46", unit: 'LBS', singleUnit: 'LBS', name: "22 X 1 LBS", packing: "22 X 1 LBS", weight: "9.979024", fillWeight: '1 LBS' },
    { id: "48", unit: 'KGS', singleUnit: 'KG', name: "1 X 1 KG", packing: "1 X 1 KG", weight: "1", fillWeight: '1' },
    { id: "49", unit: 'KGS', singleUnit: 'KG', name: "1 X 2 KG", packing: "1 X 2 KG", weight: "2", fillWeight: '2' },
    { id: "50", unit: 'KGS', singleUnit: 'KG', name: "1 X 3 KG", packing: "1 X 3 KG", weight: "3", fillWeight: '3' },
    { id: "51", unit: 'KGS', singleUnit: 'KG', name: "1 X 4 KG", packing: "1 X 4 KG", weight: "4", fillWeight: '4' },
    { id: "52", unit: 'KGS', singleUnit: 'KG', name: "1 X 6 KG", packing: "1 X 6 KG", weight: "6", fillWeight: '6' },
    { id: "53", unit: 'KGS', singleUnit: 'KG', name: "1 X 7 KG", packing: "1 X 7 KG", weight: "7", fillWeight: '7' },
    { id: "54", unit: 'KGS', singleUnit: 'KG', name: "1 X 8 KG", packing: "1 X 8 KG", weight: "8", fillWeight: '8' },
    { id: "55", unit: 'KGS', singleUnit: 'KG', name: "1 X 9 KG", packing: "1 X 9 KG", weight: "9", fillWeight: '9' },
    { id: "56", unit: 'KGS', singleUnit: 'KG', name: "1 X 14 KG", packing: "1 X 14 KG", weight: "14", fillWeight: '14' },
    { id: "57", unit: 'KGS', singleUnit: 'KG', name: "1 X 16 KG", packing: "1 X 16 KG", weight: "16", fillWeight: '16' },
    { id: "58", unit: 'KGS', singleUnit: 'KG', name: "1 X 17 KG", packing: "1 X 17 KG", weight: "17", fillWeight: '17' },
    { id: "59", unit: 'KGS', singleUnit: 'KG', name: "1 X 18 KG", packing: "1 X 18 KG", weight: "18", fillWeight: '18' },
    { id: "60", unit: 'KGS', singleUnit: 'KG', name: "1 X 19 KG", packing: "1 X 19 KG", weight: "19", fillWeight: '19' },
    { id: "61", unit: 'KGS', singleUnit: 'KG', name: "6 X 1 KG", packing: "6 X 1 KG", weight: "6", fillWeight: '1' },
    { id: "62", unit: 'KGS', singleUnit: 'KG', name: "2 X 4 KG", packing: "2 X 4 KG", weight: "8", fillWeight: '4' },
    { id: "63", unit: 'KGS', singleUnit: 'KG', name: "9 X 2 KG", packing: "9 X 2 KG", weight: "18", fillWeight: '2' },
    { id: "64", unit: 'KGS', singleUnit: 'KG', name: "1 X 1.5 KG", packing: "1 X 1.5 KG", weight: "1.5", fillWeight: '1.5' },
    { id: "65", unit: 'KGS', singleUnit: 'KG', name: "5 X 2 KG", packing: "5 X 2 KG", weight: "10", fillWeight: '2' },
    { id: "67", unit: 'GMS', singleUnit: 'GM', name: "30 X 350 GM", packing: "30 X 350 GM", weight: "10.5", fillWeight: '350' },
    { id: "68", unit: 'GMS', singleUnit: 'GM', name: "10 X 300 GM", packing: "10 X 300 GM", weight: "3", fillWeight: '300' },
    { id: "69", unit: 'KGS', singleUnit: 'KG', name: "4 X 2 KG", packing: "4 X 2 KG", weight: "8", fillWeight: '2' },
    { id: "70", unit: 'KGS', singleUnit: 'KG', name: "7 X 2 KG", packing: "7 X 2 KG", weight: "14", fillWeight: '2' },
    { id: "71", unit: 'KGS', singleUnit: 'KG', name: "2 X 2 KG", packing: "2 X 2 KG", weight: "4", fillWeight: '2' },
    { id: "72", unit: 'KGS', singleUnit: 'KG', name: "2 X 4 KG", packing: "2 X 4 KG", weight: "8", fillWeight: '4' },
    { id: "73", unit: 'KGS', singleUnit: 'KG', name: "3 X 2 KG", packing: "3 X 2 KG", weight: "6", fillWeight: '2' },
    { id: "74", unit: 'GMS', singleUnit: 'GM', name: "30 X 400 GM", packing: "30 X 400 GM", weight: "12", fillWeight: '400' },
    { id: "75", unit: 'KGS', singleUnit: 'KG', name: "6 X 1.8 KG", packing: "6 X 1.8 KG", weight: "10.8", fillWeight: '1.8' },
    { id: "76", unit: 'KGS', singleUnit: 'KG', name: "10 X 1.8 KG", packing: "10 X 1.8 KG", weight: "18", fillWeight: '1.8' },
    { id: "77", unit: 'KGS', singleUnit: 'KG', name: "14 X 1 KG", packing: "14 X 1 KG", weight: "14", fillWeight: '1' },
    { id: "78", unit: 'GMS', singleUnit: 'GM', name: "40 X 250 GM", packing: "40 X 250 GM", weight: "10", fillWeight: '250' },
    { id: "79", unit: 'KGS', singleUnit: 'KG', name: "5 X 3 KG", packing: "5 X 3 KG", weight: "15", fillWeight: '3' },
    { id: "80", unit: 'KGS', singleUnit: 'KG', name: "2 X 7 KG", packing: "2 X 7 KG", weight: "14", fillWeight: '7' },
    { id: "81", unit: 'KGS', singleUnit: 'KG', name: "2 X 5 KG", packing: "2 X 5 KG", weight: "10", fillWeight: '5' },
    { id: "82", unit: 'GMS', singleUnit: 'GM', name: "20 X 400 GM", packing: "20 X 400 GM", weight: "8", fillWeight: '400 GM' },
    { id: "83", unit: 'GMS', singleUnit: 'GM', name: "12 X 400 GM", packing: "12 X 400 GM", weight: "4.8", fillWeight: '400 GM' },
    { id: "84", unit: 'GMS', singleUnit: 'GM', name: "20 X 300 GM", packing: "20 X 300 GM", weight: "6.0ng ", fillWeight: '300 GM' },
    { id: "85", unit: 'KGS', singleUnit: 'KG', name: "4 X 2.5 KG", packing: "4 X 2.5 KG", weight: "10", fillWeight: '2.5' },
    { id: "86", unit: 'KGS', singleUnit: 'KG', name: "3 X 7 KG", packing: "3 X 7 KG", weight: "21", fillWeight: '7' },
    { id: "87", unit: 'LBS', singleUnit: 'LBS', name: "10 X 2.2 LBS", packing: "10 X 2.2 LBS", weight: "9.97903", fillWeight: '22 LBS' },
    { id: "88", unit: 'KGS', singleUnit: 'KG', name: "5 X 1 KG", packing: "5 X 1 KG", weight: "5", fillWeight: '1' },


];

export const paymentShortTerm = [
    { id: "TT", name: "TT" },
    { id: "LC", name: "LC" },
    // { id: "DP", name: "DP" },
    // { id: "DA", name: "DA" },
    // { id: "CAD", name: "CAD" },
];

export const paymentTerm = [
    { id: "1", shortTermId: "TT", name: "100% TT PAYMENT 30 DAYS AFTER ARRIVAL OF CONTAINER (FIXED DAYS OF PAYMENT 10TH AND 25TH )" },
    { id: "2", shortTermId: "TT", name: "10 % ADVANCE BY TT AND 90 % BEFORE ARRIVAL OF CONTAINER" },
    { id: "3", shortTermId: "TT", name: "100% TT 1 MONTH AFTER CUSTOM CLEARANCE" },
    { id: "4", shortTermId: "TT", name: "T/T BEFORE 7 DAYS FROM ETA" },
    { id: "5", shortTermId: "TT", name: "TT 100% BEFORE 3 DAYS OF ETA DATE" },
    { id: "6", shortTermId: "TT", name: "T/T BEFORE 7 DAYS FROM ETA" },
    { id: "7", shortTermId: "TT", name: "TT PAYMENT AFTER 15 DAYS FROM ETA" },
    { id: "8", shortTermId: "TT", name: "TT PAYMENT 6250 USD IN ADVANCE REMAINING 63750 USD BEFORE 2 DAYS OF SHIPMENT REACHING DESTINATION PORT" },
    { id: "9", shortTermId: "TT", name: "TT 30% ADVANCE & BALANCE 70% PAYMENT AFTER SENDING COPY OF DOCS BY EMAIL" },
    { id: "10", shortTermId: "TT", name: "100% TT PAYMENT" },
    { id: "11", shortTermId: "TT", name: "TT PAYMENT 20% ADVANCE & 80% ONE WEEK BEFORE ETA" },
    { id: "12", shortTermId: "TT", name: "PAYMENT BY 10000 USD ADVANCE TT AND BALANCE 1 WEEKBEFORE ETA" },
    { id: "13", shortTermId: "TT", name: "TT 20% ADVANCE & BALANCE 80% 1 WEEK BEFORE ETA" },
    { id: "14", shortTermId: "TT", name: "TT PAYMENT 120 DAYS FROM BL DATE" },
    { id: "15", shortTermId: "TT", name: "TT PAYMENT 60 DAYS FROM BL DATE" },
    { id: "16", shortTermId: "TT", name: "DA AT 90 DAYS FROM BL DATE" },
    { id: "17", shortTermId: "TT", name: "TT PAYMENT AFTER 120 DAYS FROM BL DATE" },
    { id: "18", shortTermId: "TT", name: "TT PAYMENT AFTER 60 DAYS FROM BL DATE" },
    { id: "19", shortTermId: "TT", name: "TT PAYMENT USD 20000/- ADVANCE BALANCE AFTER SHIPMENT BEFORE ONTAINER REACHES TO DESTINATION" },
    { id: "20", shortTermId: "TT", name: "TT PAYMENT USD 15000/- ADVANCE BALANCE AFTER SHIPMENT BEFORE CONTAINER REACHES TO DESTINATION" },
    { id: "21", shortTermId: "TT", name: "TT PAYMENT USD 10000/- ADVANCE BALANCE AFTER SHIPMENT BEFORE CONTAINER REACHES TO DESTINATION" },
    { id: "22", shortTermId: "TT", name: "TT PAYMENT USD 5000/- ADVANCE BALANCE AFTER SHIPMENT" },
    { id: "23", shortTermId: "TT", name: "TT PAYMENT 20% ADVANCE 80% AFTER SHIPMENT" },
    { id: "24", shortTermId: "TT", name: "TT PAYMENT 15% ADVANCE 85% AFTER SHIPMENT" },
    { id: "25", shortTermId: "TT", name: "TT PAYMENT 10% ADVANCE 90% AFTER SHIPMENT" },
    { id: "26", shortTermId: "TT", name: "TT PAYMENT 5% ADVANCE 95% AFTER SHIPMENT" },
    { id: "27", shortTermId: "LC", name: "DRAFTS AT 100 DAYS AFTER SIGHT" },
    { id: "28", shortTermId: "LC", name: "DRAFTS AT 120 DAYS AFTER SIGHT BANKER'S USANCE" },
    { id: "29", shortTermId: "LC", name: "DRAFTS AT SIGHT FOR FULL INVOICE VALUE" },
    { id: "30", shortTermId: "LC", name: "DRAFTS AT SIGHT" },
    { id: "31", shortTermId: "LC", name: "DRAFTS AT 90 DAYS AFTER SIGHT" },
    { id: "32", shortTermId: "LC", name: "100 DAYS DRAFTS AFTER SIGHT" },
    { id: "33", shortTermId: "LC", name: "DRAFTS AT 120 DAYS AFTER SIGHT" },
    { id: "34", shortTermId: "LC", name: "LC AT SIGHT" },
    { id: "35", shortTermId: "LC", name: "BY LC AT 60 DAY AFTER B/L DATE" },
    { id: "36", shortTermId: "LC", name: "LC AT 60 DAYS AFTER BILL OF LADING DATE FREE OF INTEREST" },
    { id: "37", shortTermId: "LC", name: "LC AT 60 DAYS AFTER SHIPMENT DATE" },
    { id: "38", shortTermId: "LC", name: "LC AT 90 DAYS AFTER BILL OF LADING" },
    { id: "39", shortTermId: "LC", name: "BY LC AT 60 DAYS AFTER B/L ON BOARD DATE" },
    { id: "40", shortTermId: "LC", name: "LC AT 60 DAYS" },
    { id: "41", shortTermId: "LC", name: "LC AT 45 DAYS AFTER BILL OF LADING DATE FOR 100 PCT OF INVOICE VALUE" },
    { id: "42", shortTermId: "LC", name: "LC AT SIGHT FOR 100 PCT INVOICE VALUE" },
    { id: "43", shortTermId: "LC", name: "LC AT 60 DAYS AFTER BL DATE" },
    { id: "44", shortTermId: "LC", name: "LC AT 120 DAYS FROM BL DATE" },
    { id: "45", shortTermId: "LC", name: "LC AT 90 DAYS FROM BL DATE" },
    { id: "46", shortTermId: "LC", name: "LC AT 30 DAYS FROM BL DATE" },
    { id: "47", shortTermId: "LC", name: "LC AT 15 DAYS FROM BL DATE" },
    { id: "48", shortTermId: "DP", name: "BY 100% DP AT SIGHT" },
    { id: "49", shortTermId: "DP", name: "100% PAYMENT BY DP" },
    { id: "50", shortTermId: "DP", name: "DP AT 120 DAYS FROM BL DATE" },
    { id: "51", shortTermId: "DP", name: "DP AT 90 DAYS FROM BL DATE" },
    { id: "52", shortTermId: "DP", name: "DP AT 60 DAYS FROM BL DATE" },
    { id: "53", shortTermId: "DP", name: "DP AT 30 DAYS FROM BL DATE" },
    { id: "54", shortTermId: "DP", name: "DP AT SIGHT" },
    { id: "55", shortTermId: "DA", name: "DA AT 120 DAYS FROM BL DATE" },
    { id: "56", shortTermId: "DA", name: "DA AT 90 DAYS FROM BL DATE" },
    { id: "57", shortTermId: "DA", name: "DA AT 60 DAYS FROM BL DATE" },
    { id: "58", shortTermId: "DA", name: "DA AT 30 DAYS FROM BL DATE" },
    { id: "59", shortTermId: "DA", name: "DA AT SIGHT" },
    { id: "60", shortTermId: "DA", name: "100% PAYMENT BY DA" },
    { id: "61", shortTermId: "CAD", name: "5% ADVANCE BY TT AND REMAINING BY CAD" },
    { id: "62", shortTermId: "CAD", name: "BY CAD PAYMENT" },
    { id: "63", shortTermId: "CAD", name: "CAD AT 120 DAYS FROM BL DATE" },
    { id: "64", shortTermId: "CAD", name: "CAD AT 90 DAYS FROM BL DATE" },
    { id: "65", shortTermId: "CAD", name: "CAD AT 60 DAYS FROM BL DATE" },
    { id: "66", shortTermId: "CAD", name: "CAD AT 30 DAYS FROM BL DATE" },
    { id: "67", shortTermId: "CAD", name: "CAD AT SIGHT" },
    { id: "68", shortTermId: "CAD", name: "100% CAD PAYMENT" },
    { id: "69", shortTermId: "CAD", name: "ADVANCE 20% AND BALANCE THROUGHT CAD" },
    { id: "70", shortTermId: "CAD", name: "ADVANCE 15% AND BALANCE THROUGHT CAD" },
    { id: "71", shortTermId: "CAD", name: "ADVANCE 10% AND BALANCE THROUGHT CAD" },
    { id: "72", shortTermId: "CAD", name: "48860 USD ADVANCE BY TT AND REMAINING BY CAD" }

];

export const deliveryTerm = [
    { id: "FOB", term: "FOB", name: "FREE ON BOARD" },
    { id: "CIF", term: "CIF", name: "COST INSURANCE AND FREIGHT" },
    { id: "CFR", term: "CFR", name: "COST AND FRIEGHT" },


];

export const whiteness = [
    { id: "1", name: "90/UP" },
    { id: "2", name: "89/UP" },
    { id: "3", name: "88/UP" },
    { id: "4", name: "87/UP" },
    { id: "5", name: "86/UP" },
    { id: "6", name: "85/UP" },
    { id: "7", name: "84/UP" },
    { id: "8", name: "83/UP" },
    { id: "9", name: "82/UP" },
    { id: "10", name: "81/UP" },
    { id: "11", name: "80/UP" },
    { id: "12", name: "79/UP" },
    { id: "13", name: "79/UP" },
    { id: "14", name: "78/UP" },
    { id: "15", name: "77/UP" },
    { id: "16", name: "76/UP" },
    { id: "17", name: "76/UP" },
    { id: "18", name: "75/UP" },
    { id: "19", name: "74/UP" },
    { id: "20", name: "73/UP" },
    { id: "21", name: "72/UP" },
    { id: "22", name: "71/UP" },
    { id: "23", name: "70/UP" },
    { id: "24", name: "69/UP" },
    { id: "25", name: "68/UP" },
    { id: "26", name: "67/UP" },
    { id: "27", name: "66/UP" },
    { id: "28", name: "65/UP" },
    { id: "30", name: "64/UP" },
    { id: "31", name: "63/UP" },
    { id: "32", name: "62/UP" },
    { id: "33", name: "61/UP" },
    { id: "34", name: "60/UP" },
    { id: "35", name: "55/UP" },
    { id: "36", name: "54/UP" },
    { id: "37", name: "53/UP" },
    { id: "38", name: "52/UP" },
    { id: "39", name: "51/UP" },
    { id: "40", name: "50/UP" },
    { id: "41", name: "49/UP" },
    { id: "42", name: "48/UP" },
    { id: "43", name: "47/UP" },
    { id: "44", name: "46/UP" },
    { id: "45", name: "45/UP" },
    { id: "46", name: "44/UP" },
    { id: "47", name: "43/UP" },
    { id: "48", name: "42/UP" },
    { id: "49", name: "41/UP" },
    { id: "50", name: "40/UP" },
    { id: "51", name: "39/UP" },
    { id: "52", name: "38/UP" },
    { id: "53", name: "37/UP" },
    { id: "54", name: "36/UP" },

];

export const whitenessMethod = [
    { id: "1", name: "JAPANESE METHOD" },
    { id: "2", name: "EUROPE METHOD" },
];

export const testingLaboratory = [
    { id: "1", name: "Vimta Labs" },
    { id: "2", name: "TUV SUD" },
    // { id: "3", name: "TUV INDIA" },
    { id: "4", name: "SMS Labs" },
    // { id: "5", name: "SGS India Pvt. Ltd" },
    { id: "6", name: "SGS INDIA" },
    { id: "7", name: "Sea Lab" },
    { id: "8", name: "Reliable Analytical" },
    { id: "9", name: "Punjab Biotechnology" },
    { id: "10", name: "PTH" },
    { id: "11", name: "National Collateral Management" },
    { id: "12", name: "MPEDA QC Lab" },
    // { id: "13", name: "MPEDA" },
    { id: "14", name: "MicroChem Silliker" },
    { id: "15", name: "Intertek India" },
    { id: "16", name: "GEO-CHEM" },
    { id: "17", name: "Export Inspection" },
    { id: "18", name: "Eurofins Analytical" },
    { id: "19", name: "Envirocare Labs" },
    { id: "20", name: "Edward Food Research" },
    { id: "21", name: "Delhi Test House" },
    { id: "22", name: "Chennai Mettex" },
    { id: "23", name: "CEG Test House" },
    { id: "24", name: "CALF, NDDB" },
    { id: "25", name: "Arbro Pharmaceutical" },
    { id: "26", name: "AES Laboratories" },
];

export const fishTestingTypes = [
    { id: "1", name: "Heavy Metal" },
];

export const surimiTestingTypes = [
    { id: "1", name: "Testing not required" },
    { id: "2", name: "PCB" },
    { id: "3", name: "Heavy metals" },
    { id: "4", name: "Dyes" },
    { id: "5", name: "Chemical testing" },
    { id: "6", name: "Antibiotics" },
];

export const surimiTestingParameter = [
    { id: "1", name: "VIBRIO VULNIFIOUS" },
    { id: "2", name: "VIBRIO PARAHACMOLYTICUS" },
    { id: "3", name: "VIBRIO CHLORAE" },
    { id: "4", name: "VIBRIO ALGINOLYTICUS" },
    { id: "5", name: "Total phosphours (as P2O5)" },
    { id: "6", name: "Tetracyclines" },
    { id: "7", name: "SULFONAMIDE" },
    { id: "8", name: "Quinolones" },
    { id: "9", name: "PCB 52" },
    { id: "10", name: "PCB 28" },
    { id: "11", name: "PCB 180" },
    { id: "12", name: "PCB 153" },
    { id: "13", name: "PCB 138" },
    { id: "14", name: "PCB 118" },
    { id: "15", name: "PCB 101" },
    { id: "16", name: "PCB" },
    { id: "17", name: "PAH" },
    { id: "18", name: "Oxytetracycline" },
    { id: "19", name: "NITROFURAZONE" },
    { id: "20", name: "NITROFURANTION" },
    { id: "21", name: "Nitrofuran Metabolites" },
    { id: "22", name: "NA" },
    { id: "23", name: "Methyl Mercury" },
    { id: "24", name: "Mercury" },
    { id: "25", name: "Malachite green & leuco malachite green" },
    { id: "26", name: "MALACHITE GREEN" },
    { id: "27", name: "Lysozyme" },
    { id: "28", name: "LEUCO MALACHITE GREEN" },
    { id: "29", name: "Leuco crystal Violet" },
    { id: "30", name: "Lead" },
    { id: "31", name: "Hitrofurans" },
    { id: "32", name: "Histamine" },
    { id: "33", name: "Gal d3 - Ovotransferrin" },
    { id: "34", name: "Gal d2 - Ovalbumin" },
    { id: "35", name: "GAL d1-Ovomucoid" },
    { id: "36", name: "Furazolidone (AOZ)" },
    { id: "37", name: "FURAZOLIDONE" },
    { id: "38", name: "Crystal Violet & Leuco crystal Violet" },
    { id: "39", name: "Crystal Violet" },
    { id: "40", name: "Chromium" },
    { id: "41", name: "Chlorteracycline" },
    { id: "42", name: "Chloramphenicol" },
    { id: "43", name: "Cadmium" },
    { id: "44", name: "Arsenic" },
];

export const shippingLine = [
    { id: "ZIM LINE", name: "ZIM LINE", shippinglinename: "ZIM LINE", bookinglink: "https://www.ship.inttra.com/bkact/bkCreate#/bkCreate/1533019078969", shippinginstructionlink: "https://www.ship.inttra.com/siact/create#/create/1533019132186" },
    { id: "YANG MING", name: "YANG MING", shippinglinename: "YANG MING", bookinglink: "http://www.yml.in/web_OLagent/Information/s_Menu.asp?D_FRAME=Memberlogin", shippinginstructionlink: "http://www.yml.in/web_OLagent/Information/s_Menu.asp?D_FRAME=Memberlogin" },
    { id: "WAN HAI", name: "WAN HAI", shippinglinename: "WAN HAI", bookinglink: "", shippinginstructionlink: "" },
    { id: "TS LINE", name: "TS LINE", shippinglinename: "TS LINE", bookinglink: "https://www.track-trace.com", shippinginstructionlink: "https://portal.tslineindia.com/tslineindia/shippingInstruction" },
    { id: "PIL", name: "PIL", shippinglinename: "PIL", bookinglink: "", shippinginstructionlink: "" },
    { id: "OOCL LINE", name: "OOCL LINE", shippinglinename: "OOCL LINE", bookinglink: "https://moc.oocl.com/exportdomain/bookingrequest/booking_request_new_real.jsf?ENTRY=MCC&ENTRY_TYPE=OOCL&OPERATOR_USER_ID=ulkamumbai&PREFER_LANGUAGE=en-US&USER_TOKEN=LRjHFqgqFlkjWSSvXKWVMCCOOCL&domainName=EXPORT_DOMAIN&COOKIE_USER_SESSION_SIZE=1&DOMAIN_BEHAVIOR=CHANGE_DOMAIN&USER_BEHAVIOR=INPAGE_FUNCTION", shippinginstructionlink: "https://moc.oocl.com/shippinginstruction/shippinginstruction/si_creation.jsf?ENTRY=MCC&ENTRY_TYPE=OOCL&OPERATOR_USER_ID=ulkamumbai&PREFER_LANGUAGE=en-US&USER_TOKEN=LRjHFqgqFlkjWSSvXKWVMCCOOCL&domainName=SHIPPING_INSTRUCTION_DOMAIN&COOKIE_USER_SESSION_SIZE=1&DOMAIN_BEHAVIOR=CHANGE_DOMAIN&USER_BEHAVIOR=INPAGE_FUNCTION" },
    { id: "ONE LINE", name: "ONE LINE", shippinglinename: "ONE LINE", bookinglink: "http://ecomm.one-line.com/ecom/CUP_HOM_3201.do?redir=Y&sessLocale=en", shippinginstructionlink: "http://ecomm.one-line.com/ecom/CUP_HOM_3211.do?redir=Y&sessLocale=en" },
    { id: "MSC LINE", name: "MSC LINE", shippinglinename: "MSC LINE", bookinglink: "https://www.ship.inttra.com/bkact/bkCreate#/bkCreate/1533019078969", shippinginstructionlink: "https://www.ship.inttra.com/siact/create#/create/1533019132186" },
    { id: "MAERSK LINE", name: "MAERSK LINE", shippinglinename: "MAERSK LINE", bookinglink: "https://www.maersk.com", shippinginstructionlink: "https://www.maersk.com" },
    { id: "KMTC", name: "KMTC", shippinglinename: "KMTC", bookinglink: "http://www.ekmtc.com/", shippinginstructionlink: "http://www.ekmtc.com/" },
    { id: "HYUNDAI LINE", name: "HYUNDAI LINE", shippinglinename: "HYUNDAI LINE", bookinglink: "https://www.hmm21.com/cms/business/india/export/booking/index.jsp?type=1&userNation=india&number=&BookingNo=&BookingNo1=&blNo=&bookingNo=&bookingNumber=&bookingBlNo=&BLNumber=&BNO=&cno=&MBNO=&BKN=&mode1=dateBL&kind=BNO&tab=surcharge&is_quick=Y&quick_params=&command=INSERT&isFirstAccess=&directShow=Y", shippinginstructionlink: "https://www.hmm21.com/cms/business/india/export/instructionBL/index.jsp" },
    { id: "HAPAG LLOYD", name: "HAPAG LLOYD", shippinglinename: "HAPAG LLOYD", bookinglink: "https://www.ship.inttra.com/bkact/bkCreate#/bkCreate/1533019078969", shippinginstructionlink: "https://www.ship.inttra.com/siact/create#/create/1533019132186" },
    { id: "HAMBURG SUD", name: "HAMBURG SUD", shippinglinename: "HAMBURG SUD", bookinglink: "", shippinginstructionlink: "" },
    { id: "EVERGREEN", name: "EVERGREEN", shippinglinename: "EVERGREEN", bookinglink: "https://www.shipmentlink.com/servlet/TUF1_ControllerServlet.do?action=allInOne&lang=en#top", shippinginstructionlink: "https://www.shipmentlink.com/servlet/TUF1_ControllerServlet.do?action=allInOne&lang=en#" },
    { id: "CMA CGM", name: "CMA CGM LINE", shippinglinename: "CMA CGM LINE", bookinglink: "https://www.cma-cgm.com/ebusiness/booking/request", shippinginstructionlink: "https://www.cma-cgm.com/ebusiness/si/request/step1" },
    { id: "BALAJI(AVANA) LINE", name: "BALAJI(AVANA) LINE", shippinglinename: "BALAJI(AVANA) LINE", bookinglink: "https://app.avanalogistek.com/vessel-schedule", shippinginstructionlink: "https://app.avanalogistek.com/booking-list" },
    { id: "APL LINE", name: "APL LINE", shippinglinename: "APL LINE", bookinglink: "https://www.track-trace.com", shippinginstructionlink: "https://lift-preview.apl.com/ebusiness/account/terms-and-conditions/confirm" },
    { id: "COSCO LINE", name: "COSCO LINE", shippinglinename: "COSCO LINE", bookinglink: "", shippinginstructionlink: "" },
    { id: "HMM LINE", name: "HMM LINE", shippinglinename: "HMM LINE", bookinglink: "", shippinginstructionlink: "" },
    { id: "GIL SHIPPING LINE", name: "GIL SHIPPING LINE", shippinglinename: "GIL SHIPPING LINE", bookinglink: "", shippinginstructionlink: "" },
    { id: "SINOKOR LINE", name: "SINOKOR LINE", shippinglinename: "SINOKOR LINE", bookinglink: "", shippinginstructionlink: "" },


];
export const shippingLineFreightInvoice = [
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Additional BL N/N copies",
        "fieldName": "field1",
        "TS LINE": "Yes",
        "MSC LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Aden Gulf Surcharge /ADEN GULF CROSSING",
        "fieldName": "field2",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "CARGO DATA DECLARATION",
        "fieldName": "field3",
        "MSC LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "ADVANCE FILING SURC",
        "fieldName": "field4",
        "KMTC": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Amendment Post Filing prior ETD /ACD/ENS",
        "fieldName": "field5",
        "CMA CGM": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "AMS fee (Advance Manifest Submission - China )",
        "fieldName": "field6",
        "OOCL LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "B/L (DOC) FEE / Documentation Charges / Export documentation fee",
        "fieldName": "field7",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "B/L Amendment before DTX",
        "fieldName": "field8",
        "MSC LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Bill of Lading Re-Issuance /DBRF",
        "fieldName": "field9",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Billing Party/Invoice Amendment Charge (Per Document",
        "fieldName": "field10",
        "APL LINE": "Yes",
        "TS LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "BL Amendment Charges",
        "fieldName": "field11",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "BL Release against Bank Guarantee/Indemnity Bond",
        "fieldName": "field12",
        "APL LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "BL release at location other than Booking office /BL Release in 3rd Party Country /Co-ordination Charges (to release BL from other Offices in India)",
        "fieldName": "field13",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "MSC LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Booking Cancellation Charges (against cntr offloading)",
        "fieldName": "field14",
        "CMA CGM": "Yes",
        "APL LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Broker Code Update / Insertion Fee",
        "fieldName": "field15",
        "APL LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Cargo release against bank guarantee per BL",
        "fieldName": "field16",
        "ONE LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Certificate Issuance Charge /SHIPPING (VESSEL) CERTIFICATE /Shipping certificate charges – per certificate",
        "fieldName": "field17",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "MSC LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Cheque Bounce Fee",
        "fieldName": "field18",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "COD / port change /Administration Fee for Change of Destination/SHIFTING / POD CHANGE CHARGES",
        "fieldName": "field19",
        "ZIM LINE": "Yes",
        "APL LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Container Insurance Surcharge",
        "fieldName": "field20",
        "CMA CGM": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "CONTAINER RELEASE FACILITATION CHARGES",
        "fieldName": "field21",
        "TS LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Cross Stuffing Fee",
        "fieldName": "field22",
        "CMA CGM": "Yes",
        "ONE LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "DBCF ( Combine B/L Charges )",
        "fieldName": "field23",
        "WAN HAI": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "DBSF ( Split B/L Charges )",
        "fieldName": "field24",
        "WAN HAI": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Diversion Fee",
        "fieldName": "field25",
        "APL LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "DMAF ( Correction BL Fee )",
        "fieldName": "field26",
        "WAN HAI": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "E B/L Fee",
        "fieldName": "field27",
        "MSC LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "LANDING CERTIFICATE / ISSUE OF SPL. CERTIFICATES LIKE VSL CERTIFICATE/ FREIGHT TERM CERTIFICATE AND ALL OTHER CERTIFICATES (EXCL. FREE TIME & SOB) (CER)",
        "fieldName": "field28",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "OOCL LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "LATE B/L RELEASE CHARGES (AFTER 7 DAYS OF SAILING)/LBLC ( Late Issue B/L after 7 days of sailing )",
        "fieldName": "field29",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "LATE SHIPPING BILL CHARGE/BL 6 HRS BEFORE VESSEL ARRIVAL (DC3)/ LATE DOC SUBMISSION CHARGES",
        "fieldName": "field30",
        "ZIM LINE": "Yes",
        "TS LINE": "Yes",
        "MSC LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "LATE SI CHARGES",
        "fieldName": "field31",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "HYUNDAI LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "M/C CHARGES / SECURITY MANIFEST CHARGE",
        "fieldName": "field32",
        "ZIM LINE": "Yes",
        "TS LINE": "Yes",
        "MSC LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "MANDATORY USER CHARGS",
        "fieldName": "field33",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "WAN HAI": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "HYUNDAI LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Manifest Amendment Chgs (ENS) prior vsl sailing - Europe",
        "fieldName": "field34",
        "APL LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "MTF (Manifets charges for SHANGHAI shipments)",
        "fieldName": "field35",
        "WAN HAI": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "MTF (Manifets charges for US, JAPAN, MEXICO shipments)",
        "fieldName": "field36",
        "WAN HAI": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "OBL BL amendment after Issuance",
        "fieldName": "field37",
        "OOCL LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Original / Seaway BL release by NON-EBL",
        "fieldName": "field38",
        "APL LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Out port BL Issuance Fee",
        "fieldName": "field39",
        "OOCL LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "OUT-STATION B/L RELEASE (RLC )",
        "fieldName": "field40",
        "ZIM LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Reefer Documentation Charges",
        "fieldName": "field41",
        "MSC LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Return / Re-export Shipment Admin Fee",
        "fieldName": "field42",
        "APL LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "RVC",
        "fieldName": "field43",
        "WAN HAI": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Seal Charge / Sealing charges & associated svc export, Carrier",
        "fieldName": "field44",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Security Charges",
        "fieldName": "field45",
        "ZIM LINE": "Yes",
        "OOCL LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Shipping Instruction Amendment Fee",
        "fieldName": "field46",
        "OOCL LINE": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "Switch Bill of Lading / DMTF (Bl Amednment at Third Place ( Switch bl)",
        "fieldName": "field47",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "TEMPERATURE VARIANCE/Refrigerated Container Facilitation Fee /Port Miscellaneous Expense ( Temprature charges ) /Reefer temperature variance charges",
        "fieldName": "field48",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "B/L Surrender(Telex release charges / TLX/TLXT)/BL Surrender (Telex Release) Fee / 3rd Party Fine Export /BSF",
        "fieldName": "field49",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "DOCUMENT CHARGES",
        "chargesType": "VALUE ADDED SERVICE/ VIA CHARGES/Special Service Request / SPECAIL SHIPMENT / SERVICE REQUEST",
        "fieldName": "field50",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "FREIGHT CHRAGES",
        "chargesType": "BASIC FREIGHT",
        "fieldName": "field51",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "MAERSK LINE": "Yes",
        "ONE LINE": "Yes"
    },
    {
        "heading": "FREIGHT CHRAGES",
        "chargesType": "Bunker surcharge NOS",
        "fieldName": "field52",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "HYUNDAI LINE": "Yes"
    },
    {
        "heading": "FREIGHT CHRAGES",
        "chargesType": "CURRENCY ADJUSTMENT FACTOR",
        "fieldName": "field53",
        "ZIM LINE": "Yes"
    },
    {
        "heading": "FREIGHT CHRAGES",
        "chargesType": "EMERGENCY BUNKER CHARGE",
        "fieldName": "field54",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "TS LINE": "Yes",
        "MAERSK LINE": "Yes",
        "OOCL LINE": "Yes"
    },
    {
        "heading": "FREIGHT CHRAGES",
        "chargesType": "Emergency Risk Surcharge",
        "fieldName": "field55",
        "MAERSK LINE": "Yes"
    },
    {
        "heading": "FREIGHT CHRAGES",
        "chargesType": "EXAMINATION DELIVERY CHARGES ( EXA )",
        "fieldName": "field56",
        "ZIM LINE": "Yes"
    },
    {
        "heading": "FREIGHT CHRAGES",
        "chargesType": "Export Declaration Surcharge",
        "fieldName": "field57",
        "CMA CGM": "Yes",
        "APL LINE": "Yes"
    },
    {
        "heading": "FREIGHT CHRAGES",
        "chargesType": "FACILITY CHARGE",
        "fieldName": "field58",
        "ZIM LINE": "Yes",
        "TS LINE": "Yes"
    },
    {
        "heading": "FREIGHT CHRAGES",
        "chargesType": "Fuel Adjustment Factor",
        "fieldName": "field59",
        "TS LINE": "Yes"
    },
    {
        "heading": "FREIGHT CHRAGES",
        "chargesType": "Ocean Carrier-Intl Ship & port Facility Security",
        "fieldName": "field60",
        "CMA CGM": "Yes"
    },
    {
        "heading": "FREIGHT CHRAGES",
        "chargesType": "OCEAN FREIGHT",
        "fieldName": "field61",
        "ZIM LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "FREIGHT CHRAGES",
        "chargesType": "Peak Season Surcharge",
        "fieldName": "field62",
        "MAERSK LINE": "Yes"
    },
    {
        "heading": "FREIGHT CHRAGES",
        "chargesType": "Surcharge",
        "fieldName": "field63",
        "ZIM LINE": "Yes",
        "TS LINE": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "Canal charges, Suez, Bosphorus",
        "fieldName": "field64",
        "CMA CGM": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "CESE ( TERMINAL INFRASTRUCTURE DEVELOPMENT SURCHARGE (REEFER) )",
        "fieldName": "field65",
        "KMTC": "Yes",
        "WAN HAI": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "Congestion Fee Destination",
        "fieldName": "field66",
        "MAERSK LINE": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "Detention charges",
        "fieldName": "field67",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "DIRECT PORT DELIVERY ( DID)",
        "fieldName": "field68",
        "ZIM LINE": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "EFSC ( E-FORM 13 Service charge )",
        "fieldName": "field69",
        "WAN HAI": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "Gate charge",
        "fieldName": "field70",
        "OOCL LINE": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "General Administrative Fee ( VIA Admin chgs ) /VIA Application Charges",
        "fieldName": "field71",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "MSC LINE": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "GROUND RENT ( GRR ) /Storage carrier, export ( VIA Ground Rent chgs )",
        "fieldName": "field72",
        "ZIM LINE": "Yes",
        "APL LINE": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "HSS",
        "fieldName": "field73",
        "WAN HAI": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "PORT INFRASTRUCTURE CHARGES",
        "fieldName": "field74"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "PRS",
        "fieldName": "field75",
        "WAN HAI": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "Reefer Consumption Surcharge",
        "fieldName": "field76",
        "CMA CGM": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "Reefer Power Supply and Monitoring",
        "fieldName": "field77",
        "OOCL LINE": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "SHUT-OUT CHARGES ( SHO )",
        "fieldName": "field78",
        "ZIM LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "THC Nhava Sheva (BMCT)",
        "fieldName": "field79",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "THC NHAVA SHEVA (GTI)",
        "fieldName": "field80",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "THC NHAVA SHEVA (JNP)",
        "fieldName": "field81",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "THC NHAVA SHEVA (NSICT)",
        "fieldName": "field82",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "THC NHAVA SHEVA (NSIGT)",
        "fieldName": "field83",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "Toll Charges",
        "fieldName": "field84",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "VGM Charges per Container",
        "fieldName": "field85",
        "ONE LINE": "Yes"
    },
    {
        "heading": "PORT CHARGES",
        "chargesType": "Weighment Charges",
        "fieldName": "field86",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes"
    },
    {
        "heading": "TAX DETAILS",
        "chargesType": "CGST",
        "fieldName": "field87",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "TAX DETAILS",
        "chargesType": "SGST",
        "fieldName": "field88",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "TAX DETAILS",
        "chargesType": "IGST",
        "fieldName": "field89",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "SUMMARY",
        "chargesType": "TOTAL DOCUMENT CHARGES",
        "fieldName": "documentCharges",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "SUMMARY",
        "chargesType": "TOTAL FREIGHT CHARGES",
        "fieldName": "freightCharges",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "SUMMARY",
        "chargesType": "TOTAL PORT CHARGES",
        "fieldName": "portCharges",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "SUMMARY",
        "chargesType": "TOTAL TAX AMOUNT",
        "fieldName": "taxAmount",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    },
    {
        "heading": "SUMMARY",
        "chargesType": "TOTAL INVOICE AMOUNT",
        "fieldName": "invoiceAmount",
        "ZIM LINE": "Yes",
        "CMA CGM": "Yes",
        "APL LINE": "Yes",
        "TS LINE": "Yes",
        "KMTC": "Yes",
        "MSC LINE": "Yes",
        "MAERSK LINE": "Yes",
        "WAN HAI": "Yes",
        "OOCL LINE": "Yes",
        "HAPAG LLOYD": "Yes",
        "BALAJI(AVANA) LINE": "Yes",
        "ONE LINE": "Yes",
        "HYUNDAI LINE": "Yes",
        "EVERGREEN": "Yes"
    }
]

export const shipmentInstruction = [
    { id: "1", name: "URGENT SHIPMENT" },
    { id: "2", name: "THAPPIWISE LOADING PHOTOS & LOT WISE LOADING SHEET" },
    { id: "3", name: "REQUIRE DATA LOGGER" },
    { id: "4", name: "INSPECTION REPORT AND PHOTOS REQUIRED" },
    { id: "5", name: "FINAL PRODUCT PACKING PHOTOS" },
    { id: "6", name: "BUYER SHIPMENT DATE" },
    { id: "7", name: "BUYER ETA DATE" }
];

export const customerSource = [
    { id: "1", name: "VISIT" },
    { id: "2", name: "REGISTERS" },
    { id: "3", name: "PORTAL" },
    { id: "4", name: "EXPORT DATA" },
    { id: "5", name: "EXHIBITION" },
    { id: "6", name: "EMAIL" },
];

export const port = [
    { id: "1", name: "india-Visakhapatnam", country: "India", portName: "Visakhapatnam", transitWeight: "" },
    { id: "2", name: "india-Tuticorin", country: "India", portName: "Tuticorin", transitWeight: "" },
    { id: "3", name: "india-Paradip", country: "India", portName: "Paradip", transitWeight: "" },
    { id: "4", name: "india-NHAVA SHEVA PORT", country: "India", portName: "NHAVA SHEVA PORT", transitWeight: "" },
    { id: "5", name: "india-Mumbai", country: "India", portName: "Mumbai", transitWeight: "" },
    { id: "6", name: "india-Mormugao", country: "India", portName: "Mormugao", transitWeight: "" },
    { id: "7", name: "india-Mangalore", country: "India", portName: "Mangalore", transitWeight: "" },
    { id: "8", name: "india-Krishnapatnam Port", country: "India", portName: "Krishnapatnam Port", transitWeight: "" },
    { id: "9", name: "india-Kolkata", country: "India", portName: "Kolkata", transitWeight: "" },
    { id: "10", name: "india-Kochi", country: "India", portName: "Kochi", transitWeight: "" },
    { id: "11", name: "india-Chennai", country: "India", portName: "Chennai", transitWeight: "" },
    { id: "12", name: "india-Kandla", country: "India", portName: "Kandla", transitWeight: "" },
    { id: "13", name: "india-Kamarajar Port", country: "India", portName: "Kamarajar Port", transitWeight: "" },
    { id: "14", name: "Malaysia-WEST PORT KLANG", country: "Malaysia", portName: "WEST PORT KLANG", transitWeight: "" },
    { id: "15", name: "Malaysia-TANJUNG PELEPAS", country: "Malaysia", portName: "TANJUNG PELEPAS", transitWeight: "" },
    { id: "16", name: "Malaysia-PORT KLANG", country: "Malaysia", portName: "PORT KLANG", transitWeight: "" },
    { id: "17", name: "Malaysia-NORTH PORT KLANG", country: "Malaysia", portName: "NORTH PORT KLANG", transitWeight: "" },
    { id: "18", name: "Malaysia-PENANG", country: "Malaysia", portName: "PENANG", transitWeight: "" },
    { id: "19", name: "Malaysia-PASIR GUDANG", country: "Malaysia", portName: "PASIR GUDANG", transitWeight: "" },
    { id: "20", name: "Greece-THESSALONIKI", country: "Greece", portName: "THESSALONIKI", transitWeight: "26000" },
    { id: "21", name: "Greece-PIRAEUS", country: "Greece", portName: "PIRAEUS", transitWeight: "26000" },
    { id: "22", name: "Italy-NAPOLI", country: "Italy", portName: "NAPOLI", transitWeight: "" },
    { id: "23", name: "Italy-LIVORNO", country: "Italy", portName: "LIVORNO", transitWeight: "" },
    { id: "24", name: "Italy-ANCONA PORT", country: "Italy", portName: "ANCONA PORT", transitWeight: "" },
    { id: "25", name: "Taiwan-KEELUNG PORT", country: "Taiwan", portName: "KEELUNG PORT", transitWeight: "" },
    { id: "26", name: "Taiwan-KAOHSIUNG PORT", country: "Taiwan", portName: "KAOHSIUNG PORT", transitWeight: "" },
    { id: "27", name: "Taiwan-TAICHUNG PORT", country: "Taiwan", portName: "TAICHUNG PORT", transitWeight: "" },
    { id: "28", name: "Japan-MOJI", country: "Japan", portName: "MOJI", transitWeight: "" },
    { id: "29", name: "Japan-OSAKA", country: "Japan", portName: "OSAKA", transitWeight: "" },
    { id: "30", name: "Thailand-LAT KRABANG", country: "Thailand", portName: "LAT KRABANG", transitWeight: "" },
    { id: "31", name: "Thailand-LAEM CHABANG", country: "Thailand", portName: "LAEM CHABANG", transitWeight: "" },
    { id: "32", name: "Thailand-BANGKOK", country: "Thailand", portName: "BANGKOK", transitWeight: "" },
    { id: "33", name: "Portugal-LISBON PORT", country: "Portugal", portName: "LISBON PORT", transitWeight: "" },
    { id: "34", name: "Portugal-LEIXOES", country: "Portugal", portName: "LEIXOES", transitWeight: "" },
    { id: "35", name: "China-XIAMEN", country: "China", portName: "XIAMEN", transitWeight: "" },
    { id: "36", name: "China-SHIDAO PORT", country: "China", portName: "SHIDAO PORT", transitWeight: "" },
    { id: "37", name: "China-QINGDAO", country: "China", portName: "QINGDAO", transitWeight: "" },
    { id: "38", name: "China-MAWEI FUZHOU", country: "China", portName: "MAWEI FUZHOU", transitWeight: "" },
    { id: "39", name: "China-MAWEI", country: "China", portName: "MAWEI", transitWeight: "" },
    { id: "40", name: "China-HUANGPU", country: "China", portName: "HUANGPU", transitWeight: "" },
    { id: "41", name: "Vietnam-VICT PORT", country: "Vietnam", portName: "VICT PORT", transitWeight: "" },
    { id: "42", name: "Vietnam-CAT LAI PORT", country: "Vietnam", portName: "CAT LAI PORT", transitWeight: "" },
    { id: "43", name: "Vietnam-HAIPHONG", country: "Vietnam", portName: "HAIPHONG", transitWeight: "" },
    { id: "44", name: "United Arab Emirates-SHARJAH", country: "United Arab Emirates", portName: "SHARJAH", transitWeight: "" },
    { id: "45", name: "Netherlands-ROTTERDAM", country: "Netherlands", portName: "ROTTERDAM", transitWeight: "" },
    { id: "46", name: "Poland-SZCZECIN", country: "Poland", portName: "SZCZECIN", transitWeight: "" },
    { id: "47", name: "Singapore-SINGAPORE", country: "Singapore", portName: "SINGAPORE", transitWeight: "" },
    { id: "48", name: "Réunion-POINTE DES GALETS", country: "Réunion", portName: "POINTE DES GALETS", transitWeight: "" },
    { id: "49", name: "Algeria-ORAN PORT", country: "Algeria", portName: "ORAN PORT", transitWeight: "" },
    { id: "50", name: "Mayotte-LONGONI", country: "Mayotte", portName: "LONGONI", transitWeight: "" },
    { id: "51", name: "Cyprus-LIMASSOL PORT", country: "Cyprus", portName: "LIMASSOL PORT", transitWeight: "" },
    { id: "52", name: "France-LE HAVRE PORT", country: "France", portName: "LE HAVRE PORT", transitWeight: "" },
    { id: "53", name: "Lithuania-KLAIPEDA PORT", country: "Lithuania", portName: "KLAIPEDA PORT", transitWeight: "" },
    { id: "54", name: "Russia-KALININGRAD", country: "Russia", portName: "KALININGRAD", transitWeight: "" },
    { id: "55", name: "Turkey-ISTANBUL", country: "Turkey", portName: "ISTANBUL", transitWeight: "" },
    { id: "56", name: "Martinique-FORT DE FRANCE", country: "Martinique", portName: "FORT DE FRANCE", transitWeight: "" },
    { id: "57", name: "South Africa-DURBAN", country: "South Africa", portName: "DURBAN", transitWeight: "" },
    { id: "58", name: "Dominican Republic-CAUCEDO", country: "Dominican Republic", portName: "CAUCEDO", transitWeight: "" },
    { id: "59", name: "South Korea-BUSAN PORT", country: "South Korea", portName: "BUSAN PORT", transitWeight: "" },
    { id: "60", name: "Spain-BILBOA", country: "Spain", portName: "BILBOA", transitWeight: "" },
    { id: "61", name: "China-SHANTOU", country: "China", portName: "SHANTOU", transitWeight: "" },
    { id: "62", name: "China-YANTIAN", country: "China", portName: "YANTIAN", transitWeight: "" },


];

export const fclSize = [
    { id: "50 Feet", name: "50 Feet" },
    { id: "40 Feet", name: "40 Feet" },
    { id: "20 Feet", name: "20 Feet" },

];

export const jetty = [
    { id: "1", name: "SASOON DOCK" },
    { id: "2", name: "MANGALORE" },

];

export const currency = [
    // { id: "1", name: "YEN" },
    { id: "2", name: "USD" },
    // { id: "3", name: "INR" },
    // { id: "4", name: "EURO" },

];

export const courierCompany = [
    { id: "UPS", name: "UPS", trackingLink: "" },
    { id: "FedEX", name: "FedEX", trackingLink: "" },
    { id: "DHL", name: "DHL", trackingLink: "" },
    { id: "ARAMEX", name: "ARAMEX", trackingLink: "" }
];

export const ingredient = [
    { id: "1", name: "TSPP", ingredientName: "TSPP", range: "0.1% to 0.5%" },
    { id: "2", name: "Sugar", ingredientName: "Sugar", range: "1% to 6%" },
    { id: "3", name: "STPP", ingredientName: "STPP", range: "0.1% to 0.4%" },
    { id: "4", name: "Sorbitol", ingredientName: "Sorbitol", range: "1% to 6%" }
];

export const gelStrength = [
    { id: "NO GEL", name: "NO GEL" },
    { id: "50/UP", name: "50/UP" },
    { id: "50/100", name: "50/100" },
    { id: "100/UP", name: "100/UP" },
    { id: "100/200", name: "100/200" },
    { id: "100/300", name: "100/300" },
    { id: "200/UP", name: "200/UP" },
    { id: "250/UP", name: "250/UP" },
    { id: "200/300", name: "200/300" },
    { id: "200/400", name: "200/400" },
    { id: "300/UP", name: "300/UP" },
    { id: "300/400", name: "300/400" },
    { id: "300/500", name: "300/500" },
    { id: "400/UP", name: "400/UP" },
    { id: "400/500", name: "400/500" },
    { id: "400/600", name: "400/600" },
    { id: "500/UP", name: "500/UP" },
    { id: "500/600", name: "500/600" },
    { id: "500/700", name: "500/700" },
    { id: "600/UP", name: "600/UP" },
    { id: "650/UP", name: "650/UP" },
    { id: "600/700", name: "600/700" },
    { id: "600/800", name: "600/800" },
    { id: "700/UP", name: "700/UP" },
    { id: "700/800", name: "700/800" },
    { id: "700/900", name: "700/900" },
    { id: "800/UP", name: "800/UP" },
    { id: "800/900", name: "800/900" },
    { id: "800/1000", name: "800/1000" },
    { id: "900/UP", name: "900/UP" },
    { id: "900/1000", name: "900/1000" },
    { id: "900/1100", name: "900/1100" },
    { id: "1000/UP", name: "1000/UP" },
    { id: "1000/1100", name: "1000/1100" },
    { id: "1000/1200", name: "1000/1200" },
    { id: "1100/UP", name: "1100/UP" },
    { id: "1100/1200", name: "1100/1200" },
    { id: "1100/1300", name: "1100/1300" },
    { id: "1200/UP", name: "1200/UP" },
    { id: "1200/1300", name: "1200/1300" },
    { id: "1200/1400", name: "1200/1400" },
    { id: "1300/UP", name: "1300/UP" },
    { id: "MIXED 600/800", name: "MIXED 600/800" },
    { id: "350/UP", name: "350/UP" },
    { id: "150/UP", name: "150/UP" },
    { id: "150/300", name: "150/300" },
];

export const actualGrade = [
    { id: "A", name: "A" },
    { id: "AA", name: "AA" },
    { id: "IA", name: "IA" },
    { id: "IAA", name: "IAA" },
    { id: "IKA", name: "IKA" },
    { id: "ISA", name: "ISA" },
    { id: "ISSA", name: "ISSA" },
    { id: "ISSSA", name: "ISSSA" },
    { id: "SA", name: "SA" },
    { id: "SSA", name: "SSA" },
    { id: "SSSA", name: "SSSA" },
    { id: "MA", name: "MA" },
    { id: "MAA", name: "MAA" },
    { id: "MKA", name: "MKA" },
    { id: "MSA", name: "MSA" },
    { id: "MSSA", name: "MSSA" },
    { id: "MSSSA", name: "MSSSA" },
    { id: "NG", name: "NG" },
    { id: "KA", name: "KA" },
    { id: "C", name: "C" },
    { id: "TA", name: "TA" },
    { id: "TAA", name: "TAA" },
    { id: "TKA", name: "TKA" },
    { id: "TSA", name: "TSA" },
    { id: "TSSA", name: "TSSA" },
    { id: "TSSSA", name: "TSSSA" },
    { id: "KAT", name: "KAT" },
    { id: "AT", name: "AT" },
    { id: "AAT", name: "AAT" },
    { id: "SAT", name: "SAT" },
    { id: "SSAT", name: "SSAT" },
    { id: "SSSAT", name: "SSSAT" },
    { id: "SSSSAT", name: "SSSSAT" },
    { id: "SSSSSAT", name: "SSSSSAT" },
    { id: "SSSSSSAT", name: "SSSSSSAT" },
    { id: "MIXED 600/800", name: "MIXED 600/800" },
    { id: "CNP", name: "CNP" },
];

export const declaredGrade = [
    { id: "A", name: "A" },
    { id: "AA", name: "AA" },
    { id: "SA", name: "SA" },
    { id: "SSA", name: "SSA" },
    { id: "SSSA", name: "SSSA" },
    { id: "KA", name: "KA" },
    { id: "KAA", name: "KAA" },
    { id: "KSA", name: "KSA" },
    { id: "KSSA", name: "KSSA" },
    { id: "KSSSA", name: "KSSSA" },
    { id: "IA", name: "IA" },
    { id: "IAA", name: "IAA" },
    { id: "ISA", name: "ISA" },
    { id: "ISSA", name: "ISSA" },
    { id: "ISSSA", name: "ISSSA" },
    { id: "MA", name: "MA" },
    { id: "MAA", name: "MAA" },
    { id: "MSA", name: "MSA" },
    { id: "MSSA", name: "MSSA" },
    { id: "MSSSA", name: "MSSSA" },
    { id: "KKA", name: "KKA" },
    { id: "MKA", name: "MKA" },
    { id: "IKA", name: "IKA" },
    { id: "SGKA", name: "SGKA" },
    { id: "C", name: "C" },
    { id: "CT", name: "CT" },
    { id: "IC", name: "IC" },
    { id: "EC", name: "EC" },
    { id: "TC", name: "TC" },
    { id: "MC", name: "MC" },
    { id: "KC", name: "KC" },
    { id: "SGC", name: "SGC" },
    { id: "NG", name: "NG" },
    { id: "ING", name: "ING" },
    { id: "ENG", name: "ENG" },
    { id: "TNG", name: "TNG" },
    { id: "MNG", name: "MNG" },
    { id: "KNG", name: "KNG" },
    { id: "SGNG", name: "SGNG" },
    { id: "TA", name: "TA" },
    { id: "TAA", name: "TAA" },
    { id: "TKA", name: "TKA" },
    { id: "TSA", name: "TSA" },
    { id: "TSSA", name: "TSSA" },
    { id: "TSSSA", name: "TSSSA" },
    { id: "KAT", name: "KAT" },
    { id: "AT", name: "AT" },
    { id: "AAT", name: "AAT" },
    { id: "SAT", name: "SAT" },
    { id: "SSAT", name: "SSAT" },
    { id: "SSSAT", name: "SSSAT" },
    { id: "SSSSAT", name: "SSSSAT" },
    { id: "SSSSSAT", name: "SSSSSAT" },
    { id: "SSSSSSAT", name: "SSSSSSAT" },
    { id: "MIXED 600/800", name: "MIXED 600/800" },
    { id: "B", name: "B" },
    { id: "CNP", name: "CNP" },
    { id: "FA", name: "FA" },
];


export const hsCodesData = [
    {
        "descriptionOfGoodsForCustom": "BLOCK FROZEN RAW HEADLESS SHELL ON VANNAMEI SHRIMPS",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HEAD ON SHELL ON SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HEAD ON SHELL ON SHRIMPS",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HEAD ON SHRIMPS",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HEAD ON SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW HOSO SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW PDTON VANNAMEI SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW HLSO EZ PEELED VANNAMEI SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW PEELED DEVEINED TAIL ON VANNAMEI SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW HEADLESS SHELL ON VANNAMEI SHRIMPS",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW HEADLESS SHELL ON SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW HEADLESS SHELL ON SHRIMPS",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HLSO SHRIMPS BLOCK",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW CUT SQUID SKIN ON IQF",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030401B",
        "dbkRate": "4.50",
        "cap": "29.50"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW SQUID TENTACLES IQF",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030401B",
        "dbkRate": "4.50",
        "cap": "29.50"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW HLSO EZ PEELED WHITE SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HEADLESS EZ PEELED SHRIMPS BLANCHED IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HEADLESS EZ PEELED SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW HLSO EZ PEELED SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW HLSO VANNAMEI SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW PD VANNAMEI SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW HLSO SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PUD SHRIMPS BLANCHED IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PD SHRIMPS BLANCHED IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID WHOLE CHEMICAL FREE",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },

    {
        "descriptionOfGoodsForCustom": "FROZEN HLSO EZ PEELED VANNAMEI SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HLSO EZ PEELED SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HEADLESS SHRIMPS BLOCK",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW HEADLESS SHELL ON SHRIMPS",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HEADLESS SHRIMPS BLANCHED IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HEADLESS SHRIMPS COOKED IQF",
        "hsCodeCustom": "16054000",
        "hsCodeTMA": "16054000",
        "hsCodeMIES": "16054000",
        "hsCodeDBK": "160501",
        "dbkRate": "3.00",
        "cap": "23.60"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HEADLESS SHRIMPS COOKED IQF",
        "hsCodeCustom": "16054000",
        "hsCodeTMA": "16054000",
        "hsCodeMIES": "16054000",
        "hsCodeDBK": "160501",
        "dbkRate": "3.00",
        "cap": "23.60"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PUDTON SHRIMPS BLANCHED IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PDTON SHRIMPS BLANCHED IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW PDTON SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    
    {
        "descriptionOfGoodsForCustom": "FROZEN PDTO COOKED VANNAMEI SHRIMPS IQF",
        "hsCodeCustom": "16054000",
        "hsCodeTMA": "16054000",
        "hsCodeMIES": "16054000",
        "hsCodeDBK": "160501",
        "dbkRate": "3.00",
        "cap": "23.60"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PDTO COOKED VANNAMEI SHRIMPS IQF",
        "hsCodeCustom": "16054000",
        "hsCodeTMA": "16054000",
        "hsCodeMIES": "16054000",
        "hsCodeDBK": "160501",
        "dbkRate": "3.00",
        "cap": "23.60"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN COOKED SHRIMPS PDTO",
        "hsCodeCustom": "16054000",
        "hsCodeTMA": "16054000",
        "hsCodeMIES": "16054000",
        "hsCodeDBK": "160501",
        "dbkRate": "3.00",
        "cap": "23.60"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PDTO COOKED SHRIMPS IQF",
        "hsCodeCustom": "16054000",
        "hsCodeTMA": "16054000",
        "hsCodeMIES": "16054000",
        "hsCodeDBK": "160501",
        "dbkRate": "3.00",
        "cap": "23.60"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN COOKED HEADON SHRIMPS",
        "hsCodeCustom": "16054000",
        "hsCodeTMA": "16054000",
        "hsCodeMIES": "16054000",
        "hsCodeDBK": "160501",
        "dbkRate": "3.00",
        "cap": "23.60"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PUD SHRIMPS",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PD SHRIMPS",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PDTON SHRIMPS",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN COOKED PUD SHRIMPS IQF",
        "hsCodeCustom": "16054000",
        "hsCodeTMA": "16054000",
        "hsCodeMIES": "16054000",
        "hsCodeDBK": "160501",
        "dbkRate": "3.00",
        "cap": "23.60"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN COOKED PUD SHRIMPS IQF",
        "hsCodeCustom": "16054000",
        "hsCodeTMA": "16054000",
        "hsCodeMIES": "16054000",
        "hsCodeDBK": "160501",
        "dbkRate": "3.00",
        "cap": "23.60"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN COOKED PD SHRIMPS IQF",
        "hsCodeCustom": "16054000",
        "hsCodeTMA": "16054000",
        "hsCodeMIES": "16054000",
        "hsCodeDBK": "160501",
        "dbkRate": "3.00",
        "cap": "23.60"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN COOKED PD SHRIMPS IQF",
        "hsCodeCustom": "16054000",
        "hsCodeTMA": "16054000",
        "hsCodeMIES": "16054000",
        "hsCodeDBK": "160501",
        "dbkRate": "3.00",
        "cap": "23.60"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PUD COOKED VANNAMEI SHRIMPS IQF",
        "hsCodeCustom": "16054000",
        "hsCodeTMA": "16054000",
        "hsCodeMIES": "16054000",
        "hsCodeDBK": "160501",
        "dbkRate": "3.00",
        "cap": "23.60"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PUD  SHRIMPS BLANCHED  IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PD  SHRIMPS BLANCHED  IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PDTON SHRIMPS BLANCHED  IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PRE COOKED PUD SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PRE COOKED HOSO SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PUD SHRIMPS BLOCK",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PRE COOKED PDTON SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"

    },
      {
        "descriptionOfGoodsForCustom": "FROZEN RAW PD SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN PEELED DEVEINED TAIL ON COOKED VANNAMEI SHRIMPS IQF",
        "hsCodeCustom": "16054000",
        "hsCodeTMA": "16054000",
        "hsCodeMIES": "16054000",
        "hsCodeDBK": "160501",
        "dbkRate": "3.00",
        "cap": "23.60"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW PEELED DEVEINED VANNAMEI SHRIMPS IQF",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HLSO VANNAMEI SHRIMPS BLOCK",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"

    },
    {
        "descriptionOfGoodsForCustom": "FROZEN LOBSTER TAIL IQF",
        "hsCodeCustom": "03061100",
        "hsCodeTMA": "03061100",
        "hsCodeMIES": "03061100",
        "hsCodeDBK": "030603B",
        "dbkRate": "4.40",
        "cap": "74.60"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN LOBSTER WHOLE",
        "hsCodeCustom": "03061100",
        "hsCodeTMA": "03061100",
        "hsCodeMIES": "03061100",
        "hsCodeDBK": "030603B",
        "dbkRate": "4.40",
        "cap": "74.60"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW SLIPPER LOBSTER TAIL IQF",
        "hsCodeCustom": "03061100",
        "hsCodeTMA": "03061100",
        "hsCodeMIES": "03061100",
        "hsCodeDBK": "030603B",
        "dbkRate": "4.40",
        "cap": "74.60"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID RINGS BLANCHED IQF",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID TENTACLES BLANCHED IQF",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID TENTACLES AND RINGS SKIN ON IQF",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID WHOLE BLOCK",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN CLEANED SQUID RINGS BLOCK",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID WHOLE",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID WHOLE CLEANED IQF",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID TUBE IQF",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID WHOLE ROUND IQF",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID WHOLE IQF",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID WHOLE TRAY PACK",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID WHOLE CLEANED TRAY PACK",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID CUTTED AND GUTTED IQF",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN WHOLE SQUID CUTTED SKIN ON IQF",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID WHOLE CLEANED BLOCK",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    
    {
        "descriptionOfGoodsForCustom": "FROZEN CUT SKIN ON SQUID IQF",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID WHOLE CHEMICAL FREE",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW CUT SQUID SKIN ON IQF",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RAW SQUID TENTACLES IQF",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "SQUID WHOLE ROUND BLOCK",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SQUID WHOLE SEMI NEEDLE BLOCK",
        "hsCodeCustom": "03074320",
        "hsCodeTMA": "03074320",
        "hsCodeMIES": "03074320",
        "hsCodeDBK": "030701",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN CUTTLEFISH WHOLE CLEANED IQF",
        "hsCodeCustom": "03074310",
        "hsCodeTMA": "03074310",
        "hsCodeMIES": "03074310",
        "hsCodeDBK": "030701B",
        "dbkRate": "4.50",
        "cap": "29.50"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN CUTTLEFISH WHOLE IQF",
         "hsCodeCustom": "03074310",
        "hsCodeTMA": "03074310",
        "hsCodeMIES": "03074310",
        "hsCodeDBK": "030701B",
        "dbkRate": "4.50",
        "cap": "29.50"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN CUTTLEFISH WHOLE BLOCK",
         "hsCodeCustom": "03074310",
        "hsCodeTMA": "03074310",
        "hsCodeMIES": "03074310",
        "hsCodeDBK": "030701B",
        "dbkRate": "4.50",
        "cap": "29.50"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN CUTTLEFISH",
         "hsCodeCustom": "03074310",
        "hsCodeTMA": "03074310",
        "hsCodeMIES": "03074310",
        "hsCodeDBK": "030701B",
        "dbkRate": "4.50",
        "cap": "29.50"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN CUTTLEFISH WHOLE CLEANED BLOCK",
         "hsCodeCustom": "03074310",
        "hsCodeTMA": "03074310",
        "hsCodeMIES": "03074310",
        "hsCodeDBK": "030701B",
        "dbkRate": "4.50",
        "cap": "29.50"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN INDIAN MACKEREL",
        "hsCodeCustom": "03035910",
        "hsCodeTMA": "03035910",
        "hsCodeMIES": "03035910",
        "hsCodeDBK": "303B",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HORSE MACKEREL",
        "hsCodeCustom": "03035500",
        "hsCodeTMA": "03035500",
        "hsCodeMIES": "03035500",
        "hsCodeDBK": "303",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN CROAKER WHOLE",
        "hsCodeCustom": "03038980",
        "hsCodeTMA": "03038980",
        "hsCodeMIES": "03038980",
        "hsCodeDBK": "0303",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RIBBONFISH WHOLE",
        "hsCodeCustom": "03038930",
        "hsCodeTMA": "03038930",
        "hsCodeMIES": "03038930",
        "hsCodeDBK": "303",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN REEFCOD",
        "hsCodeCustom": "03038980",
        "hsCodeTMA": "03038980",
        "hsCodeMIES": "03038980",
        "hsCodeDBK": "303",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN WHOLE ROUND POMFRET FISH",
        "hsCodeCustom": "03038950",
        "hsCodeTMA": "03038950",
        "hsCodeMIES": "03038950",
        "hsCodeDBK": "303",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN POMFRET WHOLE",
        "hsCodeCustom": "03038950",
        "hsCodeTMA": "03038950",
        "hsCodeMIES": "03038950",
        "hsCodeDBK": "303",
        "dbkRate": "4.00",
        "cap": "24.20"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SURIMI FISH PASTE",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN KIGUCHI SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HATTA SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN ITOYORI SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN ITOYORI SURIMI(THREADFIN BREAM)",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN ITOYORI SURIMI (MINCED)",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SURIMI (FROZEN MINCED FISH MEAT)",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SURIMI ( FROZEN MINCED FISH MEAT) FROZEN ITOYORI SURIMI AA",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN MINCED FISH MEAT ITOYORI SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN ITOYORI MIX SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN ITOYORI SURIMI (MINCED)",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN ITOYOTI MIX MINCHED MEAT",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN TACHIUO SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RIBBONFISH MEAT",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN KINTOKIDAI SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RED BIGEYE SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HIMEJI SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SHIROGUCHI SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN ESO SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN MINCED FISH MEAT ESO SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN RIBBON FISH SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "LEATHER JACKET SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "LIZARD FISH SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN CROAKER SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN MIX SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN ITOYORI MIX SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN MIX SURIMI",
        "hsCodeCustom": "03049900",
        "hsCodeTMA": "03049900",
        "hsCodeMIES": "03049900",
        "hsCodeDBK": "030402B",
        "dbkRate": "4.00",
        "cap": "10.80"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SHRIMPS",
        "hsCodeCustom": "03061900",
        "hsCodeTMA": "03061900",
        "hsCodeMIES": "03061900",
        "hsCodeDBK": "030601",
        "dbkRate": "4.00",
        "cap": "10.60"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN SEAFOOD MIX",
        "hsCodeCustom": "03061900",
        "hsCodeTMA": "03061900",
        "hsCodeMIES": "00000",
        "hsCodeDBK": "0000",
        "dbkRate": "4.00",
        "cap": "10.60"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HEADON TAIL ON PEELED SHRIMPS",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "COOKED SHRIMPS BULK",
        "hsCodeCustom": "16052100",
        "hsCodeTMA": "16052100",
        "hsCodeMIES": "16052100",
        "hsCodeDBK": "16050599B",
        "dbkRate": "4.30",
        "cap": "53.60"
    },
    {
        "descriptionOfGoodsForCustom": "FISH MEAL POWDER",
        "hsCodeCustom": "23012011",
        "hsCodeTMA": "23012011",
        "hsCodeMIES": "23012011",
        "hsCodeDBK": "230101B",
        "dbkRate": "2.90",
        "cap": "3.7"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN HLSO SHRIMPS",
        "hsCodeCustom": "03063690",
        "hsCodeTMA": "03063690",
        "hsCodeMIES": "03063690",
        "hsCodeDBK": "030605B",
        "dbkRate": "4.40",
        "cap": "46.30"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN TUNA CUBES",
        "hsCodeCustom": "03034900",
        "hsCodeTMA": "03034900",
        "hsCodeMIES": "03034900",
        "hsCodeDBK": "03034900",
        "dbkRate": "4",
        "cap": "24.2"
    },
    {
        "descriptionOfGoodsForCustom": "FROZEN TUNA STEAKS",
        "hsCodeCustom": "03034900",
        "hsCodeTMA": "03034900",
        "hsCodeMIES": "03034900",
        "hsCodeDBK": "03034900",
        "dbkRate": "4",
        "cap": "24.2"
    },
]


export const surimiProduct = [
    {
        id: "KIGUCHI SURIMI", name: "KIGUCHI SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "JOHNIUS SPP", name: "JOHNIUS SPP" }
        ],
        descriptionOfGoods: [
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            { id: "FROZEN KIGUCHI SURIMI", name: "FROZEN KIGUCHI SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            // { id: "FROZEN KIGUCHI SURIMI", name: "FROZEN KIGUCHI SURIMI" }
        ]
    },
    {
        id: "MAMAKARI SURIMI", name: "MAMAKARI SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "SARDINELLA SPP.", name: "SARDINELLA SPP." }
        ],
        descriptionOfGoods: [
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
        ]
    },
    {
        id: "HATTA SURIMI", name: "HATTA SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "EPINEPHELUS SPP.", name: "EPINEPHELUS SPP." },
            { id: "EPINEPHELUS DIACANTHUS", name: "EPINEPHELUS DIACANTHUS" },
        ],
        descriptionOfGoods: [
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            { id: "FROZEN HATTA SURIMI", name: "FROZEN HATTA SURIMI" },
        ]
    },
    {
        id: "ITOYORI SURIMI", name: "ITOYORI SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "NEMIPTERUS SPP.", name: "NEMIPTERUS SPP." },
            { id: "NEMIPTERUS JAPONICUS", name: "NEMIPTERUS JAPONICUS" },
            { id: "Nemipterus japonicus / Nemipterus spp.", name: "Nemipterus japonicus / Nemipterus spp." },
        ],
        descriptionOfGoods: [
            { id: "FROZEN ITOYORI SURIMI", name: "FROZEN ITOYORI SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            { id: "FROZEN ITOYORI SURIMI(THREADFIN BREAM)", name: "FROZEN ITOYORI SURIMI(THREADFIN BREAM)" },
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN ITOYORI SURIMI (MINCED)", name: "FROZEN ITOYORI SURIMI (MINCED)" },
            { id: "FROZEN SURIMI (FROZEN MINCED FISH MEAT)", name: "FROZEN SURIMI (FROZEN MINCED FISH MEAT)" },
            { id: "FROZEN SURIMI ( FROZEN MINCED FISH MEAT) FROZEN ITOYORI SURIMI AA", name: "FROZEN SURIMI ( FROZEN MINCED FISH MEAT) FROZEN ITOYORI SURIMI AA" },
            { id: "FROZEN MINCED FISH MEAT ITOYORI SURIMI", name: "FROZEN MINCED FISH MEAT ITOYORI SURIMI" },
            { id: "FROZEN MINCED FISH MEAT- ITO 800/1000", name: "FROZEN MINCED FISH MEAT- ITO 800/1000" },
            { id: "FROZEN SURIMI (FROZEN MINCED FISH MEAT) FROZEN ITOYORI SURIMI", name: "FROZEN SURIMI (FROZEN MINCED FISH MEAT) FROZEN ITOYORI SURIMI" },
            { id: "FROZEN SURIMI ( FROZEN MINCED FISH MEAT) FROZEN ITOYORI SURIMI AA (WO)", name: "FROZEN SURIMI ( FROZEN MINCED FISH MEAT) FROZEN ITOYORI SURIMI AA (WO)" },
            { id: "FROZEN MINCED FISH MEAT SURIMI (Pure Itoyori)", name: "FROZEN MINCED FISH MEAT SURIMI (Pure Itoyori)" },
        ]
    },
    {
        id: "ITOYORI MIX SURIMI", name: "ITOYORI MIX SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "NA", name: "NA" },
            { id: "Nemipterus Japonicus+Saurida spp.+Priacanthus Spp.+Johnius Spp.", name: "Nemipterus Japonicus+Saurida spp.+Priacanthus Spp.+Johnius Spp." },
            { id: "Nemipterus Japonicus+Johnius Spp.+Saurida spp.", name: "Nemipterus Japonicus+Johnius Spp.+Saurida spp." },
            { id: "Nemipterus japonicus+ Aluterus monoceros+ Saurida spp.+ Priacanthus spp.+ Johnius spp.+ Epinephelus diacanthus", name: "Nemipterus japonicus+ Aluterus monoceros+ Saurida spp.+ Priacanthus spp.+ Johnius spp.+ Epinephelus diacanthus" },
            { id: "Nemipterus japonicus + Epinephelus diacanthus+ Priacanthus spp.+ Johnius spp.+ Saurida spp.+ Trichiurus spp.+ Aluterus Monoceros", name: "Nemipterus japonicus + Epinephelus diacanthus+ Priacanthus spp.+ Johnius spp.+ Saurida spp.+ Trichiurus spp.+ Aluterus Monoceros" },
            { id: "Nemipterus spp + Saurida spp + Pennahia argentata + Epinephelus septemfasciatus", name: "Nemipterus spp + Saurida spp + Pennahia argentata + Epinephelus septemfasciatus" },

            
        ],
        descriptionOfGoods: [
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            { id: "FROZEN ITOYORI MIX SURIMI", name: "FROZEN ITOYORI MIX SURIMI" },
            { id: "FROZEN ITOYOTI MIX MINCHED MEAT", name: "FROZEN ITOYOTI MIX MINCHED MEAT" }
        ]
    },
    {
        id: "FROZEN ITOYORI SURIMI (MINCED)", name: "FROZEN ITOYORI SURIMI (MINCED)", hsCode: "03049900",
        scientificNames: [
            { id: "NA", name: "NA" },
        ],
        descriptionOfGoods: [
            { id: "FROZEN ITOYORI SURIMI (MINCED)", name: "FROZEN ITOYORI SURIMI (MINCED)" },
        ]
    },
    {
        id: "TACHIUO SURIMI", name: "TACHIUO SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "TRICHIURUS SPP.", name: "TRICHIURUS SPP." },
            { id: "TRICHIURUS SAVALA", name: "TRICHIURUS SAVALA" },
            { id: "Trichiurus lepturus", name: "Trichiurus lepturus" },
            { id: "TRICHIURUS LEPTURUS", name: "TRICHIURUS LEPTURUS" },
            { id: "LEPTURACANTHUS SPP.", name: "LEPTURACANTHUS SPP." },
            { id: "LEPTURACANTHUS SAVALA", name: "LEPTURACANTHUS SAVALA" },
            { id: "Nemipterus Japonicus + Priacanthus macracanthus + Trichiurus lepturus", name: "Nemipterus Japonicus + Priacanthus macracanthus + Trichiurus lepturus" },

        ],
        descriptionOfGoods: [
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            { id: "FROZEN TACHIUO SURIMI", name: "FROZEN TACHIUO SURIMI" },
            { id: "FROZEN RIBBONFISH MEAT", name: "FROZEN RIBBONFISH MEAT" },
        ]
    },
    {
        id: "KINTOKIDAI SURIMI", name: "KINTOKIDAI SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "PRIACANTHUS SPP.", name: "PRIACANTHUS SPP." },
            { id: "PRIACANTHUS SPP / PRIACANTHUS HAMRUR / PRIACANTHUS MACRACANTHUS", name: "PRIACANTHUS SPP / PRIACANTHUS HAMRUR / PRIACANTHUS MACRACANTHUS" },
            { id: "PRIACANTHUS MACRACANTHUS", name: "PRIACANTHUS MACRACANTHUS" },
            { id: "PRIACANTHUS HAMRUR", name: "PRIACANTHUS HAMRUR" },
        ],
        descriptionOfGoods: [
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            { id: "FROZEN KINTOKIDAI SURIMI", name: "FROZEN KINTOKIDAI SURIMI" },
            { id: "FROZEN RED BIGEYE SURIMI", name: "FROZEN RED BIGEYE SURIMI" },
            { id: "FROZEN SURIMI (FROZEN MINICED FISH MEAT) FROZEN KINTOKIDAI SURIMI", name: "FROZEN SURIMI (FROZEN MINICED FISH MEAT) FROZEN KINTOKIDAI SURIMI" },
        ]
    },
    {
        id: "HIMEJI SURIMI", name: "HIMEJI SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "UPENEUS SULPHUREUS.", name: "UPENEUS SULPHUREUS." },
        ],
        descriptionOfGoods: [
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            { id: "FROZEN HIMEJI SURIMI", name: "FROZEN HIMEJI SURIMI" },
        ]
    },
    {
        id: "SHIROGUCHI SURIMI", name: "SHIROGUCHI SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "JOHNIUS DUSSUMIERI.", name: "JOHNIUS DUSSUMIERI." },
            { id: "JOHNIUS SPP.", name: "JOHNIUS SPP." },
            { id: "PENNAHIA ARGENTATA", name: "PENNAHIA ARGENTATA" },
        ],
        descriptionOfGoods: [
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            { id: "FROZEN SHIROGUCHI SURIMI", name: "FROZEN SHIROGUCHI SURIMI" },
            { id: "FROZEN MINCED MEAT : SHIROGUCHI", name: "FROZEN MINCED MEAT : SHIROGUCHI" },
        ]
    },
    {
        id: "ESO SURIMI", name: "ESO SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "SAURIDA UNDOSQUAMIS", name: "SAURIDA UNDOSQUAMIS" },
            { id: "SAURIDA TUMBIL", name: "SAURIDA TUMBIL" },
            { id: "SAURIDA SPP.", name: "SAURIDA SPP." },
        ],
        descriptionOfGoods: [
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            { id: "FROZEN ESO SURIMI", name: "FROZEN ESO SURIMI" },
            { id: "FROZEN MINCED FISH MEAT ESO SURIMI", name: "FROZEN MINCED FISH MEAT ESO SURIMI" },
            { id: "FISHES MIXED MINCED FROZEN", name: "FISHES MIXED MINCED FROZEN" },
        ]
    },
    {
        id: "RIBBON FISH SURIMI", name: "RIBBON FISH SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "TRICHIURUS SPP.", name: "TRICHIURUS SPP." },
            { id: "LEPTURACANTHUS SPP.", name: "LEPTURACANTHUS SPP." },
            { id: "TRICHIURUS LEPTURUS", name: "TRICHIURUS LEPTURUS" },



        ],
        descriptionOfGoods: [
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            { id: "FROZEN RIBBON FISH SURIMI", name: "FROZEN RIBBON FISH SURIMI" },
            { id: "FROZEN MINCED FISH MEAT RIBBON SURIMI", name: "FROZEN MINCED FISH MEAT RIBBON SURIMI" },
        ]
    },
    {
        id: "LEATHER JACKET SURIMI", name: "LEATHER JACKET SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "Aluterus monoceros", name: "Aluterus monoceros" },

        ],
        descriptionOfGoods: [
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            { id: "LEATHER JACKET SURIMI", name: "LEATHER JACKET SURIMI" },
        ]
    },
    {
        id: "LIZARD SURIMI", name: "LIZARD SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "NA", name: "NA" },

        ],
        descriptionOfGoods: [
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            { id: "LIZARD FISH SURIMI", name: "LIZARD FISH SURIMI" },
        ]
    },
    {
        id: "CROAKER SURIMI", name: "CROAKER SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "JOHNIUS SPP", name: "JOHNIUS SPP" },
            { id: "JOHNIUS DUSSUMIERI", name: "JOHNIUS DUSSUMIERI" },

        ],
        descriptionOfGoods: [
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            { id: "FROZEN CROAKER SURIMI", name: "FROZEN CROAKER SURIMI" },
            { id: "FROZEN SILVER CROAKER WHOLE", name: "FROZEN SILVER CROAKER WHOLE" },
        ]
    },
    // {
    //     id: "KIGUCHI SURIMI", name: "KIGUCHI SURIMI", hsCode: "03049900",
    //     scientificNames: [
    //         { id: "JOHNIUS DUSSUMIERI", name: "JOHNIUS DUSSUMIERI" },

    //     ],
    //     descriptionOfGoods: [
    //         { id: "", name: "" },
    //     ]
    // },
    {
        id: "EEL SURIMI", name: "EEL SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "Anguilla spp.", name: "Anguilla spp." },
        ],
        descriptionOfGoods: [
            { id: "FROZEN EEL SURIMI", name: "FROZEN EEL SURIMI" },
        ]
    },
    {
        id: "FROZEN SURIMI", name: "FROZEN SURIMI", hsCode: "03049900",
        scientificNames: [
            { id: "NEMIPTERUS SPP. + SAURIDA SPP. + JOHNIUS SPP. + EPINEPHELUS DICANTHUS", name: "NEMIPTERUS SPP. + SAURIDA SPP. + JOHNIUS SPP. + EPINEPHELUS DICANTHUS" },
            { id: "Nemipterus spp.+Saurida spp.+Johnius spp.+Epinephelus spp.", name: "Nemipterus spp.+Saurida spp.+Johnius spp.+Epinephelus spp." },
            { id: "NA", name: "NA" },
            { id: "Nemipterus japonicus, Saurida spp., Johnius spp., Trichiurus spp., Priacanthus spp., Epinephelus diacanthus", name: "Nemipterus japonicus, Saurida spp., Johnius spp., Trichiurus spp., Priacanthus spp., Epinephelus diacanthus" },
            { id: "Nemipterus spp.+Saurida spp.+Johnius spp.+Epinephelus Dicanthus", name: "Nemipterus spp.+Saurida spp.+Johnius spp.+Epinephelus Dicanthus" },
            { id: "Nemipterus japonicus + Saurida spp. + Johnius spp. + Priacanthus spp.", name: "Nemipterus japonicus + Saurida spp. + Johnius spp. + Priacanthus spp." },
            { id: "Nemipterus japonicus+Epinephelus Dicanthus+Priacanthus spp.+Sardinella spp.+Trichiurus spp.+Saurida spp.+Johnius spp.", name: "Nemipterus japonicus+Epinephelus Dicanthus+Priacanthus spp.+Sardinella spp.+Trichiurus spp.+Saurida spp.+Johnius spp." },

        ],
        descriptionOfGoods: [
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            { id: "FROZEN MIX SURIMI", name: "FROZEN MIX SURIMI" },
            { id: "FROZEN ITOYORI MIX SURIMI", name: "FROZEN ITOYORI MIX SURIMI" },
            { id: "FROZEN MINCED FISH MEAT MIX SURIMI", name: "FROZEN MINCED FISH MEAT MIX SURIMI" },
        ]
    },
    {
        id: "MIX SURIMI", name: "MIX SURIMI", hsCode: "030499",
        scientificNames: [
            { id: "NA", name: "NA" },
            { id: "Nemipterus japonicus+Saurida spp+Priacanthus spp+Johnius Spp+Trichiurus spp+Epinephelus Dicanthus", name: "Nemipterus japonicus+Saurida spp+Priacanthus spp+Johnius Spp+Trichiurus spp+Epinephelus Dicanthus" },
            { id: "Nemipterus spp.+Saurida spp.+Johnius spp.+Epinephelus spp.", name: "Nemipterus spp.+Saurida spp.+Johnius spp.+Epinephelus spp." },
            { id: "Nemipterus japonicus, Saurida spp., Johnius spp., Trichiurus spp., Priacanthus spp., Epinephelus diacanthus", name: "Nemipterus japonicus, Saurida spp., Johnius spp., Trichiurus spp., Priacanthus spp., Epinephelus diacanthus" },
            { id: "Nemipterus spp.+Saurida spp.+Johnius spp.+Epinephelus Dicanthus", name: "Nemipterus spp.+Saurida spp.+Johnius spp.+Epinephelus Dicanthus" },
            { id: "Nemipterus japonicus + Saurida spp. + Johnius sp. + Priacanthus spp.", name: "Nemipterus japonicus + Saurida spp. + Johnius spp. + Priacanthus spp." },
            { id: "Nemipterus japonicus+Epinephelus Dicanthus+Priacanthus spp.+Sardinella spp.+Trichiurus spp.+Saurida spp.+Johnius spp.", name: "Nemipterus japonicus+Epinephelus Dicanthus+Priacanthus spp.+Sardinella spp.+Trichiurus spp.+Saurida spp.+Johnius spp." },
            { id: "Nemipterus japonicus + Saurida spp. + johnius spp.", name: "Nemipterus japonicus + Saurida spp. + johnius spp." },
            { id: "Nemipterus japonicus, Saurida tumbil, Johnius dussumieri, Lepturacanthus savala, priacanthus hamrur, Epinephelus diacanthus.", name: "Nemipterus japonicus, Saurida tumbil, Johnius dussumieri, Lepturacanthus savala, priacanthus hamrur, Epinephelus diacanthus." },
            { id: "Nemipterus japonicus + Epinephelus diacanthus +  priacanthus spp. + Johnius spp. + Saurida spp. + Trichiurus spp. + Sardinella spp. + Aluterus Monoceros+Odonus niger", name: "Nemipterus japonicus + Epinephelus diacanthus +  priacanthus spp. + Johnius spp. + Saurida spp. + Trichiurus spp. + Sardinella spp. + Aluterus Monoceros+Odonus niger" },
            { id: "Nemipterus japonicus, + Epinephelus diacanthus + priacanthus spp. + Johnius spp. + Saurida spp.+ Trichiurus spp.+ Aluterus Monoceros ", name: "Nemipterus japonicus, + Epinephelus diacanthus + priacanthus spp. + Johnius spp. + Saurida spp.+ Trichiurus spp.+ Aluterus Monoceros " },
            { id: "PRIACANTHUS SPP.+ JOHNIUS SPP.+ SAURIDA SPP.+ LEPTURACANTHUS SPP.+ NEMIPTERUS SPP.+ ALUTERUS MONOCEROS", name: "PRIACANTHUS SPP.+ JOHNIUS SPP.+ SAURIDA SPP.+ LEPTURACANTHUS SPP.+ NEMIPTERUS SPP.+ ALUTERUS MONOCEROS" },
            { id: "LEPTURACANTHUS SPP.+ PRIACANTHUS SPP.+JOHNIUS SPP.", name: "LEPTURACANTHUS SPP.+ PRIACANTHUS SPP.+JOHNIUS SPP." },
            { id: "NEMIPTERUS JAPONICUS+ SAURIDA SPP.+ JOHNIUS SPP.+ PRIACANTHUS SPP.+ TRICHIURUS SPP.", name: "NEMIPTERUS JAPONICUS+ SAURIDA SPP.+ JOHNIUS SPP.+ PRIACANTHUS SPP.+ TRICHIURUS SPP." },
            { id: "Saurida spp.+ Johnius spp.+ Trichiurus spp.", name: "Saurida spp.+ Johnius spp.+ Trichiurus spp." },
            { id:"NEMIPTERUS JAPONICUS + EPINEPHELUS DIACANTHUS + PRIACANTHUS SPP + JOHNIUS SPP. + SAURIDA SPP. + TRICHIURUS SPP. + SARDINELLA SPP. + ALUTERUS MONOCEROS" , name:"NEMIPTERUS JAPONICUS + EPINEPHELUS DIACANTHUS + PRIACANTHUS SPP + JOHNIUS SPP. + SAURIDA SPP. + TRICHIURUS SPP. + SARDINELLA SPP. + ALUTERUS MONOCEROS"},
            { id: "NEMIPTERUS JAPONICUS+ SAURIDA SPP.+ JOHNIUS SPP.+ PRIACANTHUS SPP.+ TRICHIURUS SPP. + ALUTERUS MONOCEROS", name: "NEMIPTERUS JAPONICUS+ SAURIDA SPP.+ JOHNIUS SPP.+ PRIACANTHUS SPP.+ TRICHIURUS SPP. + ALUTERUS MONOCEROS" },
            { id: "Priacanthus macracanthus+ Johnius dussumieri+ Nemipterus spp.+ Trichiurus lepturus", name: "Priacanthus macracanthus+ Johnius dussumieri+ Nemipterus spp.+ Trichiurus lepturus" },
            { id: "Nemipterus spp + Saurida spp + Pennahia argentata + Epinephelus septemfasciatus", name: "Nemipterus spp + Saurida spp + Pennahia argentata + Epinephelus septemfasciatus" },



        ],
      
        descriptionOfGoods: [
            { id: "FROZEN SURIMI", name: "FROZEN SURIMI" },
            { id: "FROZEN SURIMI FISH PASTE", name: "FROZEN SURIMI FISH PASTE" },
            { id: "FROZEN MIX SURIMI", name: "FROZEN MIX SURIMI" },
            { id: "FROZEN ITOYORI MIX SURIMI", name: "FROZEN ITOYORI MIX SURIMI" },
            { id: "FROZEN MINCEN FISH MEAT MIX", name: "FROZEN MINCEN FISH MEAT MIX" },
            { id: "FROZEN TACHIUO MIX SURIMI", name: "FROZEN TACHIUO MIX SURIMI" },
            { id: "FISHES MIXED MINCED FROZEN", name: "FISHES MIXED MINCED FROZEN" },
        ]
    },
];


export const fishProduct = [
    {
        id: 'SHRIMPS',
        name: 'SHRIMPS',
        descriptionOfGoods: [
            {
                id: 'BLOCK FROZEN RAW HEADLESS SHELL ON VANNAMEI SHRIMPS',
                name: 'BLOCK FROZEN RAW HEADLESS SHELL ON VANNAMEI SHRIMPS',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW HLSO VANNAMEI SHRIMPS IQF',
                name: 'FROZEN RAW HLSO VANNAMEI SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW VANNAMEI SHRIMPS',
                name: 'FROZEN RAW VANNAMEI SHRIMPS',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW PD VANNAMEI SHRIMPS IQF',
                name: 'FROZEN RAW PD VANNAMEI SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW HEADLESS SHELL ON SHRIMPS IQF',
                name: 'FROZEN RAW HEADLESS SHELL ON SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HEAD ON SHELL ON SHRIMPS IQF',
                name: 'FROZEN HEAD ON SHELL ON SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HEAD ON SHELL ON SHRIMPS',
                name: 'FROZEN HEAD ON SHELL ON SHRIMPS',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HEAD ON SHRIMPS',
                name: 'FROZEN HEAD ON SHRIMPS',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HEAD ON SHRIMPS IQF',
                name: 'FROZEN HEAD ON SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW HOSO SHRIMPS IQF',
                name: 'FROZEN RAW HOSO SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW PDTON VANNAMEI SHRIMPS IQF',
                name: 'FROZEN RAW PDTON VANNAMEI SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN PDTON SHRIPS BLANCHED IQF',
                name: 'FROZEN PDTON SHRIPS BLANCHED IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW HLSO EZ PEELED VANNAMEI SHRIMPS IQF',
                name: 'FROZEN RAW HLSO EZ PEELED VANNAMEI SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW PEELED DEVEINED TAIL ON VANNAMEI SHRIMPS IQF',
                name: 'FROZEN RAW PEELED DEVEINED TAIL ON VANNAMEI SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW HEADLESS SHELL ON VANNAMEI SHRIMPS',
                name: 'FROZEN RAW HEADLESS SHELL ON VANNAMEI SHRIMPS',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HLSO SHRIMPS BLOCK',
                name: 'FROZEN HLSO SHRIMPS BLOCK',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW HLSO SHRIMPS IQF',
                name: 'FROZEN RAW HLSO SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW HLSO EZ PEELED WHITE SHRIMPS IQF',
                name: 'FROZEN RAW HLSO EZ PEELED WHITE SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HEADLESS EZ PEELED SHRIMPS BLANCHED IQF',
                name: 'FROZEN HEADLESS EZ PEELED SHRIMPS BLANCHED IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HEADLESS EZ PEELED SHRIMPS IQF',
                name: 'FROZEN HEADLESS EZ PEELED SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW HLSO EZ PEELED SHRIMPS IQF',
                name: 'FROZEN RAW HLSO EZ PEELED SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HLSO EZ PEELED VANNAMEI SHRIMPS IQF',
                name: 'FROZEN HLSO EZ PEELED VANNAMEI SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HLSO EZ PEELED SHRIMPS IQF',
                name: 'FROZEN HLSO EZ PEELED SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HEADLESS SHRIMPS BLOCK',
                name: 'FROZEN HEADLESS SHRIMPS BLOCK',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW HEADLESS SHELL ON SHRIMPS',
                name: 'FROZEN RAW HEADLESS SHELL ON SHRIMPS',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HEAD LESS SHELL ON SHRIMPS BLOCK',
                name: 'FROZEN HEAD LESS SHELL ON SHRIMPS BLOCK',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HEADLESS SHRIMPS BLANCHED IQF',
                name: 'FROZEN HEADLESS SHRIMPS BLANCHED IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HEADON SHRIMPS BLANCHED IQF',
                name: 'FROZEN HEADON SHRIMPS BLANCHED IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HEADLESS SHRIMPS COOKED IQF',
                name: 'FROZEN HEADLESS SHRIMPS COOKED IQF',
                hsCode: '16052900'
            },
            
            {
                id: 'FROZEN HEADLESS SHRIMPS COOKED IQF',
                name: 'FROZEN HEADLESS SHRIMPS COOKED IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN PDTON SHRIMPS BLANCHED IQF',
                name: 'FROZEN PDTON SHRIMPS BLANCHED IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW PDTON SHRIMPS IQF',
                name: 'FROZEN RAW PDTON SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN PUDTON SHRIMPS BLANCHED IQF',
                name: 'FROZEN PUDTON SHRIMPS BLANCHED IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN PDTO COOKED VANNAMEI SHRIMPS IQF',
                name: 'FROZEN PDTO COOKED VANNAMEI SHRIMPS IQF',
                hsCode: '16052100'
            },
            {
                id: 'FROZEN PDTO COOKED VANNAMEI SHRIMPS IQF',
                name: 'FROZEN PDTO COOKED VANNAMEI SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN COOKED SHRIMPS PDTO',
                name: 'FROZEN COOKED SHRIMPS PDTO',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN PDTO COOKED SHRIMPS IQF',
                name: 'FROZEN PDTO COOKED SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN PUD SHRIMPS',
                name: 'FROZEN PUD SHRIMPS',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN PD SHRIMPS',
                name: 'FROZEN PD SHRIMPS',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN PDTON SHRIMPS',
                name: 'FROZEN PDTON SHRIMPS',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN COOKED PUD SHRIMPS IQF',
                name: 'FROZEN COOKED PUD SHRIMPS IQF',
                hsCode: '16052900'
            },
            {
                id: 'FROZEN COOKED PUD SHRIMPS IQF',
                name: 'FROZEN COOKED PUD SHRIMPS IQF',
                hsCode: '16052100'
            },
            {
                id: 'FROZEN COOKED PD SHRIMPS IQF',
                name: 'FROZEN COOKED PD SHRIMPS IQF',
                hsCode: '16052100'
            },
            {
                id: 'FROZEN COOKED PD SHRIMPS IQF',
                name: 'FROZEN COOKED PD SHRIMPS IQF',
                hsCode: '16052900'
            },
            {
                id: 'FROZEN PUD COOKED VANNAMEI SHRIMPS IQF',
                name: 'FROZEN PUD COOKED VANNAMEI SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN PUD SHRIMPS BLANCHED IQF',
                name: 'FROZEN PUD SHRIMPS BLANCHED IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN PD SHRIMPS BLANCHED IQF',
                name: 'FROZEN PD SHRIMPS BLANCHED IQF',
                hsCode: '3061719'
            },

            {
                id: 'FROZEN PRE COOKED PUD SHRIMPS IQF',
                name: 'FROZEN PRE COOKED PUD SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN PRE COOKED HOSO SHRIMPS IQF',
                name: 'FROZEN PRE COOKED HOSO SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN PUD SHRIMPS BLOCK',
                name: 'FROZEN PUD SHRIMPS BLOCK',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN PRE COOKED PDTON SHRIMPS IQF',
                name: 'FROZEN PRE COOKED PDTON SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW PD SHRIMPS IQF',
                name: 'FROZEN RAW PD SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN PEELED DEVEINED TAIL ON COOKED VANNAMEI SHRIMPS IQF',
                name: 'FROZEN PEELED DEVEINED TAIL ON COOKED VANNAMEI SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN RAW PEELED DEVEINED VANNAMEI SHRIMPS IQF',
                name: 'FROZEN RAW PEELED DEVEINED VANNAMEI SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HLSO VANNAMEI SHRIMPS BLOCK',
                name: 'FROZEN HLSO VANNAMEI SHRIMPS BLOCK',
                hsCode: '3061719'
            },
            {
                id: 'RAW BLOCK FROZEN HLSO VANNAMEI SHRIMP',
                name: 'RAW BLOCK FROZEN HLSO VANNAMEI SHRIMP',
                hsCode: '03061900'
            },
            {
                id: 'Frozen Headon Shell On Sea White Shrimp',
                name: 'Frozen Headon Shell On Sea White Shrimp',
                hsCode: '3061719'
            },
            {
                id: 'COOKED HOSO SHRIMPS IQF',
                name: 'COOKED HOSO SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN COOKED HEADON SHRIMPS',
                name: 'FROZEN COOKED HEADON SHRIMPS',
                hsCode: '16052100'
            },
            {
                id: 'FROZEN SHRIMPS',
                name: 'FROZEN SHRIMPS',
                hsCode: '03061900'
            },
            {
                id: 'FROZEN HEADON TAIL ON PEELED SHRIMPS',
                name: 'FROZEN HEADON TAIL ON PEELED SHRIMPS',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN COOKED PUDTON SHRIMPS IQF',
                name: 'FROZEN COOKED PUDTON SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'PRE COOKED PD SHRIMPS IQF',
                name: 'PRE COOKED PD SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN PRE COOKED PD SHRIMPS IQF',
                name: 'FROZEN PRE COOKED PD SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN COOKED HOSO SHRIMPS IQF',
                name: 'FROZEN COOKED HOSO SHRIMPS IQF',
                hsCode: '3061719'
            },
            {
                id: 'FROZEN HLSO SHRIMPS',
                name: 'FROZEN HLSO SHRIMPS',
                hsCode: '3061719'
            },
        ],
        scientificNames: [
            { id: 'Fenneropenaeus Indicus', name: 'Fenneropenaeus Indicus' },
            { id: 'Penaeus semisulcatus', name: 'Penaeus semisulcatus' },
            { id: 'Penaeus monodon', name: 'Penaeus monodon' },
            {
                id: 'Parapenaeopsis stylifera',
                name: 'Parapenaeopsis stylifera'
            },
            {
                id: 'Solenocera Crassicornis',
                name: 'Solenocera Crassicornis'
            },
            { id: 'Metapenaeus monoceros', name: 'Metapenaeus monoceros' },
            { id: 'Metapenaeus affinis', name: 'Metapenaeus affinis' },
            { id: 'Metapaneus dobsoni', name: 'Metapaneus dobsoni' },
            {
                id: 'Solenocera Crassicornis',
                name: 'Solenocera Crassicornis'
            },
            { id: 'LITOPENAEUS VANNAMEI', name: 'LITOPENAEUS VANNAMEI' },
            { id: 'Metapenaeus Spp', name: 'Metapenaeus Spp' }
        ]
    },
    {
        id: 'LOBSTER',
        name: 'LOBSTER',
        descriptionOfGoods: [
            {
                id: 'FROZEN LOBSTER TAIL IQF',
                name: 'FROZEN LOBSTER TAIL IQF',
                hsCode: '3061100'
            },
            {
                id: 'FROZEN LOBSTER WHOLE',
                name: 'FROZEN LOBSTER WHOLE',
                hsCode: '3061100'
            },
            {
                id: 'FROZEN RAW SLIPPER LOBSTER TAIL IQF',
                name: 'FROZEN RAW SLIPPER LOBSTER TAIL IQF',
                hsCode: '3061100'
            }
        ],
        scientificNames: [
            { id: 'Panulirus spp', name: 'Panulirus spp' },
            { id: 'Panulirus homarus', name: 'Panulirus homarus' },
            { id: 'Thenus Orientalis', name: 'Thenus Orientalis' }
        ]
    },
    {
        id: 'SQUID',
        name: 'SQUID',
        descriptionOfGoods: [
            {
                id: 'FROZEN SQUID WHOLE CHEMICAL FREE',
                name: 'FROZEN SQUID WHOLE CHEMICAL FREE',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN SQUID RINGS BLANCHED IQF',
                name: 'FROZEN SQUID RINGS BLANCHED IQF',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN SQUID TENTACLES BLANCHED IQF',
                name: 'FROZEN SQUID TENTACLES BLANCHED IQF',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN SQUID TENTACLES AND RINGS SKIN ON IQF',
                name: 'FROZEN SQUID TENTACLES AND RINGS SKIN ON IQF',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN SQUID WHOLE BLOCK',
                name: 'FROZEN SQUID WHOLE BLOCK',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN CLEANED SQUID RINGS BLOCK',
                name: 'FROZEN CLEANED SQUID RINGS BLOCK',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN SQUID WHOLE',
                name: 'FROZEN SQUID WHOLE',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN SQUID WHOLE CLEANED IQF',
                name: 'FROZEN SQUID WHOLE CLEANED IQF',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN SQUID TUBE IQF',
                name: 'FROZEN SQUID TUBE IQF',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN SQUID WHOLE ROUND IQF',
                name: 'FROZEN SQUID WHOLE ROUND IQF',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN SQUID WHOLE IQF',
                name: 'FROZEN SQUID WHOLE IQF',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN SQUID WHOLE TRAY PACK',
                name: 'FROZEN SQUID WHOLE TRAY PACK',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN SQUID WHOLE CLEANED TRAY PACK',
                name: 'FROZEN SQUID WHOLE CLEANED TRAY PACK',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN SQUID CUTTED AND GUTTED IQF',
                name: 'FROZEN SQUID CUTTED AND GUTTED IQF',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN WHOLE SQUID CUTTED SKIN ON IQF',
                name: 'FROZEN WHOLE SQUID CUTTED SKIN ON IQF',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN SQUID WHOLE CLEANED BLOCK',
                name: 'FROZEN SQUID WHOLE CLEANED BLOCK',
                hsCode: '3074320'
            },
            {
                id: 'FROZEN RAW CUT SQUID SKIN ON IQF',
                name: 'FROZEN RAW CUT SQUID SKIN ON IQF',
                hsCode: '03074320',
               
            },
            {
                id: 'FROZEN RAW SQUID TENTACLES IQF',
                name: 'FROZEN RAW SQUID TENTACLES IQF',
                hsCode: '03074320',
               
            },
            {
                id: 'FROZEN SQUID',
                name: 'FROZEN SQUID',
                hsCode: '3074320',
               
            },
            {
                id: 'SQUID WHOLE ROUND BLOCK',
                name: 'SQUID WHOLE ROUND BLOCK',
                hsCode: '03074320',
               
            },
            {
                id: 'FROZEN SQUID WHOLE SEMI NEEDLE BLOCK',
                name: 'FROZEN SQUID WHOLE SEMI NEEDLE BLOCK',
                hsCode: '03074320',
               
            },
            {
                id: 'FROZEN CUT SKIN ON SQUID IQF',
                name: 'FROZEN CUT SKIN ON SQUID IQF',
                hsCode: '03074320',
               
            }
        ],
        scientificNames: [
            { id: 'Loligo duvauceli', name: 'Loligo duvauceli' },
            { id: 'Uroteuthis chinensis', name: 'Uroteuthis chinensis' },
            { id: 'Uroteuthis duvaucelii', name: 'Uroteuthis duvaucelii' },
            { id: 'loligo spp', name: 'loligo spp' },
            { id: 'Loligo singhalensis', name: 'Loligo singhalensis' },
            { id: 'Doryteuthis sibogae', name: 'Doryteuthis sibogae' },
            { id: 'Doryteuthis singhalensis', name: 'Doryteuthis singhalensis' },
            { id: 'Uroteuthis duvauceli', name: 'Uroteuthis duvauceli' },
        ]
    },
    {
        id: 'CUTTLEFISH',
        name: 'CUTTLEFISH',
        descriptionOfGoods: [
            {
                id: 'FROZEN CUTTLEFISH WHOLE CLEANED IQF',
                name: 'FROZEN CUTTLEFISH WHOLE CLEANED IQF',
                hsCode: '3074310'
            },
            {
                id: 'FROZEN CUTTLEFISH WHOLE IQF',
                name: 'FROZEN CUTTLEFISH WHOLE IQF',
                hsCode: '3074310'
            },
            {
                id: 'FROZEN CUTTLEFISH WHOLE BLOCK',
                name: 'FROZEN CUTTLEFISH WHOLE BLOCK',
                hsCode: '3074310'
            },
            {
                id: 'FROZEN CUTTLEFISH',
                name: 'FROZEN CUTTLEFISH',
                hsCode: '3074310'
            },
            {
                id: 'FROZEN CUTTLEFISH WHOLE CLEANED BLOCK',
                name: 'FROZEN CUTTLEFISH WHOLE CLEANED BLOCK',
                hsCode: '3074310'
            }
        ],
        scientificNames: [
            { id: 'Sepia pharaonis', name: 'Sepia pharaonis' },
            { id: 'Sepia officinalis', name: 'Sepia officinalis' }
        ]
    },
    {
        id: 'INDIAN MACKEREL',
        name: 'INDIAN MACKEREL',
        descriptionOfGoods: [
            {
                id: 'FROZEN INDIAN MACKEREL',
                name: 'FROZEN INDIAN MACKEREL',
                hsCode: '03035910'
            }
        ],
        scientificNames: [
            { id: 'Rastrelliger kanagurta', name: 'Rastrelliger kanagurta' }
        ]
    },
    {
        id: 'HORSE MACKEREL',
        name: 'HORSE MACKEREL',
        descriptionOfGoods: [
            {
                id: 'FROZEN HORSE MACKEREL',
                name: 'FROZEN HORSE MACKEREL',
                hsCode: '3035500'
            }
        ],
        scientificNames: [{ id: 'Megalaspis cordyla', name: 'Megalaspis cordyla' }]
    },
    {
        id: 'CROACKER',
        name: 'CROACKER',
        descriptionOfGoods: [
            {
                id: 'FROZEN CROAKER WHOLE',
                name: 'FROZEN CROAKER WHOLE',
                hsCode: '3038980'
            },
            {
                id: 'FROZEN SILVER CROAKER WHOLE',
                name: 'FROZEN SILVER CROAKER WHOLE',
                hsCode: '03049900'
            },
            {
                id: 'FROZEN YELLOW CROAKER WHOLE',
                name: 'FROZEN YELLOW CROAKER WHOLE',
                hsCode: '3038980'
            }
        ],
        scientificNames: [
            { id: 'Johnius spp', name: 'Johnius spp' },
            { id: 'Johnius dussumieri', name: 'Johnius dussumieri' },
    ]
    },
    {
        id: 'RIBBONFISH',
        name: 'RIBBONFISH',
        descriptionOfGoods: [
            {
                id: 'FROZEN RIBBONFISH WHOLE',
                name: 'FROZEN RIBBONFISH WHOLE',
                hsCode: '3038930'
            }
        ],
        scientificNames: [
            { id: 'Lepturacanthus savala', name: 'Lepturacanthus savala' },
            { id: 'Trichiurus spp', name: 'Trichiurus spp' }
        ]
    },
    {
        id: 'REEFCOD',
        name: 'REEFCOD',
        descriptionOfGoods: [
            {
                id: 'FROZEN REEFCOD',
                name: 'FROZEN REEFCOD',
                hsCode: '3038980'
            }
        ],
        scientificNames: [
            {
                id: 'Epiniphelus diachanthus',
                name: 'Epiniphelus diachanthus'
            },
            { id: 'Epinephelus spp.', name: 'Epinephelus spp.' }
        ]
    },
    {
        id: 'POMFRET FISH',
        name: 'POMFRET FISH',
        descriptionOfGoods: [
            {
                id: 'FROZEN WHOLE ROUND POMFRET FISH',
                name: 'FROZEN WHOLE ROUND POMFRET FISH',
                hsCode: '3038950'
            },
            {
                id: 'FROZEN POMFRET WHOLE',
                name: 'FROZEN POMFRET WHOLE',
                hsCode: '3038950'
            }
        ],
        scientificNames: [
            { id: 'Parastomateus niger', name: 'Parastomateus niger' },
            { id: 'Pampus chinensis', name: 'Pampus chinensis' },
            { id: 'Pampus argentius', name: 'Pampus argentius' }
        ]
    },
    {
        id: "SEAFOOD MIX", name: "SEAFOOD MIX", hsCode: "03061900",
        descriptionOfGoods: [
            { id: "FROZEN SEAFOOD MIX", name: "FROZEN SEAFOOD MIX" ,hsCode: '03061900'},
            { id: "FROZEN SEAFOOD MIX", name: "FROZEN SEAFOOD MIX" ,hsCode: '03074320'},
            { id: "FROZEN SEAFOOD MIX", name: "FROZEN SEAFOOD MIX" ,hsCode: '03074310'},
        ],
        scientificNames: [
            { id: "NA", name: "NA" },
            { id: "PARAPENAEOPSIS STYLIFERA, SEPIA PHARAONIS, LOLIGO DUVAUCELI", name: "PARAPENAEOPSIS STYLIFERA, SEPIA PHARAONIS, LOLIGO DUVAUCELI" },
            { id: "PARAPENAEOPSIS STYLIFERA, SEPIA PHARAONIS, LOLIGO DUVAUCELI, OCTOPUS VULGARIS", name: "PARAPENAEOPSIS STYLIFERA, SEPIA PHARAONIS, LOLIGO DUVAUCELI, OCTOPUS VULGARIS" },
            { id: "LITOPENAEUS VANNAMEI, SEPIA PHARAONIS, LOLIGO DUVAUCELI, OCTOPUS VULGARIS, JOHNIUS DUSSUMIERI", name: "LITOPENAEUS VANNAMEI, SEPIA PHARAONIS, LOLIGO DUVAUCELI, OCTOPUS VULGARIS, JOHNIUS DUSSUMIERI"},
        ],
    },
    {
        id: "OCTOPUS", name: "OCTOPUS", hsCode: "03075200",
        descriptionOfGoods: [
            { id: "FROZEN OCTOPUS WHOLE CLEANED IQF", name: "FROZEN OCTOPUS WHOLE CLEANED IQF" ,hsCode: '03075200'},
        ],
        scientificNames: [
            { id: "NA", name: "NA" },
            { id: "OCTOPUS VULGARIS", name: "OCTOPUS VULGARIS" },
            { id: "PARAPENAEOPSIS STYLIFERA", name: "PARAPENAEOPSIS STYLIFERA" },
            { id: "SEPIA PHARONIS", name: "SEPIA PHARONIS" },
            { id: "LOLIGO DUVAUCELI", name: "LOLIGO DUVAUCELI" },
            { id: "OCTOPUS VULGARIS", name: "OCTOPUS VULGARIS" },
        ],
    },
    {
                id: "OCTOPUS", name: "FROZEN RAW SLIPPER LOBSTER TAIL", hsCode: "03075200",
                descriptionOfGoods: [
                    { id: "FROZEN OCTOPUS WHOLE CLEANED IQF", name: "FROZEN OCTOPUS WHOLE CLEANED IQF" ,hsCode: '03075200'},
                ],
                scientificNames: [
                    { id: "NA", name: "NA" },
                    { id: "THENUS ORIENTALIS", name: "THENUS ORIENTALIS" },
                ],
            },
            {
                id: 'TUNA',
                name: 'TUNA',
                descriptionOfGoods: [
                    {
                        id: 'FROZEN TUNA CUBES',
                        name: 'FROZEN TUNA CUBES',
                        hsCode: '03034900'
                    },
                    {
                        id: 'FROZEN TUNA STEAKS',
                        name: 'FROZEN TUNA STEAKS',
                        hsCode: '03034900'
                    }
                ],
                scientificNames: [
                    { id: 'Thunnus albacares', name: 'Thunnus albacares' },
                  
            ]
            },
]

export const vesselDetails = [
    { id: "1", name: "AAI TULJAI", vesselUid: "", vesselName: "AAI TULJAI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-296", registrationDate: "05-06-2016", expiryDate: "05-05-2019", exporterUniquedId: "MA1/PP/024/13" },
    { id: "2", name: "AAICHI KRUPA", vesselUid: "", vesselName: "AAICHI KRUPA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-693", registrationDate: "12-03-2016", expiryDate: "12-02-2019", exporterUniquedId: "MA1/PP/024/13" },
    { id: "3", name: "BAL GANESH", vesselUid: "", vesselName: "BAL GANESH", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1171", registrationDate: "07-03-2017", expiryDate: "07-02-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "4", name: "CHAITANYA SHIVGIRI", vesselUid: "", vesselName: "CHAITANYA SHIVGIRI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-4367", registrationDate: "08-06-2016", expiryDate: "03-05-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "5", name: "DEVI KULSWAMINI", vesselUid: "", vesselName: "DEVI KULSWAMINI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-665", registrationDate: "10/20/2016", expiryDate: "10/27/2019", exporterUniquedId: "MA1/PP/024/13" },
    { id: "6", name: "GANESH TITWALA PRASAD", vesselUid: "", vesselName: "GANESH TITWALA PRASAD", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-92", registrationDate: "03-01-2017", expiryDate: "2/28/2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "7", name: "HERAMB SHIVLING", vesselUid: "", vesselName: "HERAMB SHIVLING", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-319", registrationDate: "05-12-2016", expiryDate: "05-11-2019", exporterUniquedId: "MA1/PP/024/13" },
    { id: "8", name: "HERAMB SHRI RAM", vesselUid: "", vesselName: "HERAMB SHRI RAM", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-822", registrationDate: "2/14/2017", expiryDate: "2/13/2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "9", name: "JAY KHANDERAYA PRASANNA", vesselUid: "", vesselName: "JAY KHANDERAYA PRASANNA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-4540", registrationDate: "3/23/2017", expiryDate: "3/22/2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "10", name: "JAY SUVARNA GANESH", vesselUid: "", vesselName: "JAY SUVARNA GANESH", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-689", registrationDate: "11/19/2016", expiryDate: "11/18/2019", exporterUniquedId: "MA1/PP/024/13" },
    { id: "11", name: "OM SHREE MAHALAXMI BALAJI", vesselUid: "MHMA115510", vesselName: "OM SHREE MAHALAXMI BALAJI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-798", registrationDate: "01-06-2017", expiryDate: "01-05-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "12", name: "OM SHREE VISHW JANANI JIVDANI", vesselUid: "", vesselName: "OM SHREE VISHW JANANI JIVDANI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-793", registrationDate: "01-06-2017", expiryDate: "01-05-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "13", name: "PADMANABH MAULI", vesselUid: "", vesselName: "PADMANABH MAULI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-357", registrationDate: "06-10-2016", expiryDate: "06-09-2019", exporterUniquedId: "MA1/PP/024/13" },
    { id: "14", name: "SAI RATH", vesselUid: "", vesselName: "SAI RATH", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-3075", registrationDate: "03-01-2017", expiryDate: "2/28/2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "5", name: "SHIV DATTA MAULI", vesselUid: "", vesselName: "SHIV DATTA MAULI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1047", registrationDate: "07-06-2017", expiryDate: "07-05-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "16", name: "RADHIKA", vesselUid: "", vesselName: "RADHIKA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1089", registrationDate: "02-06-2017", expiryDate: "02-05-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "17", name: "KRUPA DEVI", vesselUid: "", vesselName: "KRUPA DEVI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1093", registrationDate: "07-05-2017", expiryDate: "07-04-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "18", name: "MAHALAXMI", vesselUid: "MHMA115508", vesselName: "MAHALAXMI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1306", registrationDate: "7/17/2020", expiryDate: "7/20/2019", exporterUniquedId: "MA1/PP/024/13" },
    { id: "19", name: "NANDINI", vesselUid: "", vesselName: "NANDINI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1614", registrationDate: "10-06-2017", expiryDate: "10-05-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "20", name: "GAJLAXMI", vesselUid: "", vesselName: "GAJLAXMI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1705", registrationDate: "10/18/2017", expiryDate: "10/17/2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "21", name: "ASHAPURA", vesselUid: "", vesselName: "ASHAPURA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1706", registrationDate: "07-04-2017", expiryDate: "07-03-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "22", name: "YAMUNA MAIYA", vesselUid: "", vesselName: "YAMUNA MAIYA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1613", registrationDate: "07-04-2017", expiryDate: "07-03-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "23", name: "ARUNA", vesselUid: "", vesselName: "ARUNA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1654", registrationDate: "10/16/2017", expiryDate: "10/15/2020", exporterUniquedId: "" },
    { id: "24", name: "BHAGYALAXMI", vesselUid: "", vesselName: "BHAGYALAXMI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1663", registrationDate: "10/16/2017", expiryDate: "10/15/2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "25", name: "DEEPMALA", vesselUid: "", vesselName: "DEEPMALA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1646", registrationDate: "07-04-2017", expiryDate: "07-03-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "26", name: "URVASHI", vesselUid: "", vesselName: "HARI PRIYA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1659", registrationDate: "10/16/2017", expiryDate: "10/15/2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "27", name: "HARI PRIYA", vesselUid: "", vesselName: "SHREE JARI MARI TISAI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1644", registrationDate: "07-04-2017", expiryDate: "07-03-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "28", name: "SHREE JARI MARI TISAI", vesselUid: "", vesselName: "SHREE OM SAI MAULI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1055", registrationDate: "07-06-2017", expiryDate: "07-05-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "29", name: "SHREE OM SAI MAULI", vesselUid: "MHMA115514", vesselName: "SHREE VIGHNARAJAY NAMAH", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-220", registrationDate: "07-12-2016", expiryDate: "07-11-2019", exporterUniquedId: "MA1/PP/024/13" },
    { id: "30", name: "SHREE VIGHNARAJAY NAMAH", vesselUid: "", vesselName: "VAIBHAV LAXMI GANESH", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1046", registrationDate: "3/22/2017", expiryDate: "3/21/2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "31", name: "VAIBHAV LAXMI GANESH", vesselUid: "", vesselName: "VANDAN GANESHA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-841", registrationDate: "2/22/2017", expiryDate: "2/21/2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "32", name: "VANDAN GANESHA", vesselUid: "", vesselName: "DEVI MAHALAXMI SARASWATI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-414", registrationDate: "7/30/2016", expiryDate: "7/29/2019", exporterUniquedId: "MA1/PP/024/13" },
    { id: "33", name: "DEVI MAHALAXMI SARASWATI", vesselUid: "", vesselName: "HERAMB LAXMI GANESH", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1508", registrationDate: "09-08-2017", expiryDate: "09-07-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "34", name: "HERAMB LAXMI GANESH", vesselUid: "", vesselName: "KAMLAKAR KRUPA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1178", registrationDate: "07-03-2017", expiryDate: "07-02-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "35", name: "KAMLAKAR KRUPA", vesselUid: "", vesselName: "KULSWAMINI EKVIRA MAULI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-2061", registrationDate: "03-07-2018", expiryDate: "03-06-2021", exporterUniquedId: "MA1/PP/024/13" },
    { id: "36", name: "KULSWAMINI EKVIRA MAULI", vesselUid: "", vesselName: "MANGALMURTI MORYA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-3661", registrationDate: "03-03-2018", expiryDate: "03-02-2021", exporterUniquedId: "MA1/PP/024/13" },
    { id: "37", name: "MANGALMURTI MORYA", vesselUid: "", vesselName: "PRATHMESH MAULI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-2028", registrationDate: "03-03-2018", expiryDate: "03-02-2021", exporterUniquedId: "MA1/PP/024/13" },
    { id: "38", name: "PRATHMESH MAULI", vesselUid: "", vesselName: "SWAYAMBHU VAKRATUND", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1276", registrationDate: "07-07-2017", expiryDate: "07-06-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "39", name: "SWAYAMBHU VAKRATUND", vesselUid: "", vesselName: "TULAJAAI MAULI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1627", registrationDate: "10-06-2017", expiryDate: "10-05-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "40", name: "TULAJAAI MAULI", vesselUid: "", vesselName: "VAIBHAV LAXMI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-2059", registrationDate: "03-07-2018", expiryDate: "03-06-2021", exporterUniquedId: "MA1/PP/024/13" },
    { id: "41", name: "VAIBHAV LAXMI", vesselUid: "", vesselName: "DEVI BHAVANI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1411", registrationDate: "08-03-2017", expiryDate: "08-02-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "42", name: "DEVI BHAVANI", vesselUid: "", vesselName: "MAHAVISHWA GANRAJ", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-294", registrationDate: "10-07-2014", expiryDate: "10-06-2017", exporterUniquedId: "MA1/PP/024/13" },
    { id: "43", name: "MAHAVISHWA GANRAJ", vesselUid: "", vesselName: "GURU MAULI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-2440", registrationDate: "3/27/2015", expiryDate: "3/26/2018", exporterUniquedId: "MA1/PP/024/13" },
    { id: "44", name: "GURU MAULI", vesselUid: "", vesselName: "DARYA SAI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-359", registrationDate: "6/14/2016", expiryDate: "6/13/2019", exporterUniquedId: "MA1/PP/024/13" },
    { id: "45", name: "DARYA SAI", vesselUid: "MHMA116709", vesselName: "DEVACHI ALANDI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1183", registrationDate: "07-03-2017", expiryDate: "07-02-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "46", name: "DEVACHI ALANDI", vesselUid: "MHMA116711", vesselName: "LABHDA TISAI MAULI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-704", registrationDate: "11/19/2016", expiryDate: "11/18/2019", exporterUniquedId: "MA1/PP/024/13" },
    { id: "47", name: "LABHDA TISAI MAULI", vesselUid: "", vesselName: "MAYA DATTA GURUCHI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-602", registrationDate: "10-07-2016", expiryDate: "10-06-2019", exporterUniquedId: "MA1/PP/024/13" },
    { id: "48", name: "MAYA DATTA GURUCHI", vesselUid: "", vesselName: "NARAYANI PRASANNA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-4308", registrationDate: "12-03-2014", expiryDate: "12-02-2017", exporterUniquedId: "MA1/PP/024/13" },
    { id: "49", name: "NARAYANI PRASANNA", vesselUid: "", vesselName: "SAI SAMARTH", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-6337", registrationDate: "03-01-2016", expiryDate: "2/28/2019", exporterUniquedId: "MA1/PP/024/13" },
    { id: "50", name: "SAI SAMARTH", vesselUid: "", vesselName: "SHREE GANESH PRASAD", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-234", registrationDate: "1/21/2015", expiryDate: "1/20/2018", exporterUniquedId: "MA1/PP/024/13" },
    { id: "51", name: "SHREE GANESH PRASAD", vesselUid: "MHMA116712", vesselName: "MORESHWAR SAI SHAKTI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-7739", registrationDate: "11/20/2015", expiryDate: "11/19/2018", exporterUniquedId: "MA1/PP/024/13" },
    { id: "52", name: "MORESHWAR SAI SHAKTI", vesselUid: "", vesselName: "MORYA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1182", registrationDate: "07-03-2017", expiryDate: "07-02-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "53", name: "MORYA", vesselUid: "", vesselName: "SAI SHRADDHA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-338", registrationDate: "08-05-2016", expiryDate: "08-04-2019", exporterUniquedId: "MA1/PP/024/13" },
    { id: "54", name: "SAI SHRADDHA", vesselUid: "", vesselName: "SHREE SAI LABH PRASAD", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1195", registrationDate: "07-03-2017", expiryDate: "07-02-2020", exporterUniquedId: "MA1/PP/024/13" },
    { id: "55", name: "SHREE SAI LABH PRASAD", vesselUid: "", vesselName: "AARADHANA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-705", registrationDate: "11/19/2016", expiryDate: "11/18/2019", exporterUniquedId: "MA1/PP/024/13" },
    { id: "56", name: "AARADHANA", vesselUid: "", vesselName: "HIRA AAI KRUPA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-1744", registrationDate: "9/30/2015", expiryDate: "9/29/2018", exporterUniquedId: "MA1/PP/024/13" },
    { id: "57", name: "HIRA AAI KRUPA", vesselUid: "", vesselName: "SAINATH", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-32", registrationDate: "10/16/2015", expiryDate: "10/15/2018", exporterUniquedId: "MA1/PP/024/13" },
    { id: "58", name: "SAINATH", vesselUid: "MHMA116094", vesselName: "JAI EKVIRA MAULI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-4909", registrationDate: "9/28/2015", expiryDate: "9/27/2018", exporterUniquedId: "MA1/PP/024/13" },
    { id: "59", name: "JAI EKVIRA MAULI", vesselUid: "MHMA116089", vesselName: "NAMASKAR SIDDHIVINAYAK", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-4576", registrationDate: "8/16/2016", expiryDate: "8/15/2018", exporterUniquedId: "MA1/PP/024/13" },
    { id: "60", name: "NAMASKAR SIDDHIVINAYAK", vesselUid: "MHMA116091", vesselName: "PURSHOTTAM SHAKTI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-7412", registrationDate: "3/14/2014", expiryDate: "3/13/2017", exporterUniquedId: "MA1/PP/024/13" },
    { id: "61", name: "PURSHOTTAM SHAKTI", vesselUid: "", vesselName: "RATNA MAULI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-4899", registrationDate: "12-11-2014", expiryDate: "12-10-2017", exporterUniquedId: "MA1/PP/024/13" },
    { id: "62", name: "RATNA MAULI", vesselUid: "", vesselName: "PAVANPUTRA HANUMAN", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-4080", registrationDate: "7/28/2015", expiryDate: "7/27/2018", exporterUniquedId: "MA1/PP/024/13" },
    { id: "63", name: "PAVANPUTRA HANUMAN", vesselUid: "", vesselName: "SAINATH", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-237", registrationDate: "8/14/2012", expiryDate: "8/13/2015", exporterUniquedId: "MA1/PP/024/13" },
    { id: "64", name: "SAINATH", vesselUid: "", vesselName: "VAIBHAV LAXMI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-4909", registrationDate: "9/28/2015", expiryDate: "9/17/2018", exporterUniquedId: "MA1/PP/016/10" },
    { id: "65", name: "VAIBHAV LAXMI", vesselUid: "", vesselName: "VAISHNAVI MATA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-248", registrationDate: "3/22/2016", expiryDate: "3/21/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "66", name: "VAISHNAVI MATA", vesselUid: "", vesselName: "VANDAN SHREE SIDHIVINAYAK", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-266", registrationDate: "3/31/2016", expiryDate: "3/30/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "67", name: "VANDAN SHREE SIDHIVINAYAK", vesselUid: "", vesselName: "VARAD BALLAL", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-127", registrationDate: "08-11-2015", expiryDate: "08-10-2018", exporterUniquedId: "MA1/PP/016/10" },
    { id: "68", name: "VARAD BALLAL", vesselUid: "", vesselName: "VEER GANESHA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-287", registrationDate: "4/29/2016", expiryDate: "4/24/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "69", name: "VEER GANESHA", vesselUid: "", vesselName: "SHREE BALLALESHWAR PRASAD", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-128", registrationDate: "08-11-2015", expiryDate: "08-10-2018", exporterUniquedId: "MA1/PP/016/10" },
    { id: "70", name: "SHREE BALLALESHWAR PRASAD", vesselUid: "", vesselName: "OM NAMAH SHIVAY", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-2378", registrationDate: "3/28/2016", expiryDate: "3/27/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "71", name: "OM NAMAH SHIVAY", vesselUid: "", vesselName: "KRUPALU OM RAJ SAI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-470", registrationDate: "8/16/2016", expiryDate: "8/15/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "72", name: "KRUPALU OM RAJ SAI", vesselUid: "", vesselName: "MAJHAR SAI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-539", registrationDate: "07-08-2016", expiryDate: "07-07-2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "73", name: "MAJHAR SAI", vesselUid: "", vesselName: "HERAMB SHRI RAM", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-290", registrationDate: "4/29/2016", expiryDate: "4/28/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "74", name: "HERAMB SHRI RAM", vesselUid: "", vesselName: "CHINTAN LAXMI NARAYAN", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-822", registrationDate: "2/14/2017", expiryDate: "2/13/2020", exporterUniquedId: "MA1/PP/016/10" },
    { id: "75", name: "CHINTAN LAXMI NARAYAN", vesselUid: "", vesselName: "OM SHIVLING", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-574", registrationDate: "9/27/2016", expiryDate: "9/26/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "76", name: "OM SHIVLING", vesselUid: "", vesselName: "NAMO GANRAYA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-533", registrationDate: "09-06-2016", expiryDate: "09-05-2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "77", name: "NAMO GANRAYA", vesselUid: "", vesselName: "RAM DATTA GAGANGIRI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-1688", registrationDate: "2/29/2016", expiryDate: "2/28/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "78", name: "RAM DATTA GAGANGIRI", vesselUid: "", vesselName: "JARI MARICHI SHRADHA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-2441", registrationDate: "07-01-2015", expiryDate: "6/30/2018", exporterUniquedId: "MA1/PP/016/10" },
    { id: "79", name: "JARI MARICHI SHRADHA", vesselUid: "", vesselName: "SHREE BALLALESHWAR PRASAD", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-199", registrationDate: "03-10-2016", expiryDate: "03-09-2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "80", name: "SHREE BALLALESHWAR PRASAD", vesselUid: "", vesselName: "KRUSHNA SAI PRASAD", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1993", registrationDate: "2/26/2018", expiryDate: "2/25/2021", exporterUniquedId: "MA1/PP/016/10" },
    { id: "81", name: "KRUSHNA SAI PRASAD", vesselUid: "", vesselName: "VANDAN GANRAYA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1167", registrationDate: "07-03-2017", expiryDate: "07-02-2020", exporterUniquedId: "MA1/PP/016/10" },
    { id: "82", name: "VANDAN GANRAYA", vesselUid: "", vesselName: "SHREE MHALSAKANT PRASAD", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1172", registrationDate: "07-03-2017", expiryDate: "07-02-2020", exporterUniquedId: "MA1/PP/016/10" },
    { id: "83", name: "SHREE MHALSAKANT PRASAD", vesselUid: "", vesselName: "JARI MARI KHANDOBA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-1227", registrationDate: "06-02-2016", expiryDate: "06-01-2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "84", name: "JARI MARI KHANDOBA", vesselUid: "", vesselName: "AYODHYA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-548", registrationDate: "3/30/2016", expiryDate: "3/29/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "85", name: "AYODHYA", vesselUid: "", vesselName: "OMKAR GANESH", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-476", registrationDate: "09-01-2016", expiryDate: "8/31/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "86", name: "OMKAR GANESH", vesselUid: "", vesselName: "DATTA GAGANGIRI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-690", registrationDate: "11/17/2016", expiryDate: "11/16/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "87", name: "DATTA GAGANGIRI", vesselUid: "", vesselName: "MAHARUDRA DATTA MARLESHWAR", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-516", registrationDate: "8/31/2017", expiryDate: "8/30/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "88", name: "MAHARUDRA DATTA MARLESHWAR", vesselUid: "", vesselName: "JAI SHREE GAJANAN", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-515", registrationDate: "8/31/2017", expiryDate: "8/30/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "89", name: "JAI SHREE GAJANAN", vesselUid: "", vesselName: "DATTA GAGAN GIRI MAULI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-1522", registrationDate: "03-03-2016", expiryDate: "03-02-2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "90", name: "DATTA GAGAN GIRI MAULI", vesselUid: "", vesselName: "JARI MARI SHRADHA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-292", registrationDate: "4/29/2016", expiryDate: "4/28/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "91", name: "JARI MARI SHRADHA", vesselUid: "", vesselName: "PARVATI NANDAN", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-199", registrationDate: "03-10-2016", expiryDate: "03-09-2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "92", name: "PARVATI NANDAN", vesselUid: "", vesselName: "MAHALAXMI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-295", registrationDate: "05-06-2016", expiryDate: "05-05-2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "93", name: "MAHALAXMI", vesselUid: "", vesselName: "LAXMI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-514", registrationDate: "8/31/2017", expiryDate: "8/30/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "94", name: "LAXMI", vesselUid: "", vesselName: "VAIBHAV LAXMI GANESH", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-507", registrationDate: "8/31/2017", expiryDate: "8/30/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "95", name: "VAIBHAV LAXMI GANESH", vesselUid: "", vesselName: "SHREE OM SAI MAULI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-841", registrationDate: "2/22/2017", expiryDate: "2/21/2020", exporterUniquedId: "MA1/PP/016/10" },
    { id: "96", name: "SHREE OM SAI MAULI", vesselUid: "", vesselName: "DEVI KULSWAMINI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-220", registrationDate: "07-12-2016", expiryDate: "07-11-2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "97", name: "DEVI KULSWAMINI", vesselUid: "", vesselName: "JAY SUVARNA GANESH", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-665", registrationDate: "10/28/2016", expiryDate: "10/27/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "98", name: "JAY SUVARNA GANESH", vesselUid: "", vesselName: "AAI EKVIRECHI CHHAYA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-689", registrationDate: "11/19/2016", expiryDate: "11/18/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "99", name: "AAI EKVIRECHI CHHAYA", vesselName: "AAI EKVIRECHI CHHAYA", jetty: "SASOON DOCK", registrationNumber: "IND-MH-7-MM-550", registrationDate: "09-09-2016", expiryDate: "09-08-2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "100", name: "JAY SAI MALHAR", vesselName: "JAY SAI MALHAR", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-3657", registrationDate: "2/27/2018", expiryDate: "2/26/2019", exporterUniquedId: "MA1/PP/016/10" },
    { id: "101", name: "JAI SANTOSHI", vesselName: "JAI SANTOSHI", jetty: "SASOON DOCK", registrationNumber: "IND-MH-1-MM-7696", registrationDate: "7/16/2014", expiryDate: "7/15/2017", exporterUniquedId: "MA1/PP/016/10" },
    {
        id: "102",
        name: "CRINSHI",
        vesselName: "CRINSHI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-7164",
        registrationDate: "8/26/2014",
        expiryDate: "6/25/2017",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "103",
        name: "DHAN LAXMI",
        vesselName: "DHAN LAXMI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-5192",
        registrationDate: "42708",
        expiryDate: "43773",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "104",
        name: "M.F.B GAJANAND",
        vesselName: "M.F.B GAJANAND",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-2306",
        registrationDate: "3/14/2017",
        expiryDate: "3/13/2020",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "105",
        name: "M.F.B JARI MARI DARSHAN",
        vesselName: "M.F.B JARI MARI DARSHAN",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-7601",
        registrationDate: "42774",
        expiryDate: "43838",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "106",
        name: "VISHWAMBAHRI",
        vesselName: "VISHWAMBAHRI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1439",
        registrationDate: "43016",
        expiryDate: "44082",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "107",
        name: "LAXMI SAGAR",
        vesselName: "LAXMI SAGAR",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1948",
        registrationDate: "43345",
        expiryDate: "44410",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "108",
        name: "RAMESHWARI",
        vesselName: "RAMESHWARI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1438",
        registrationDate: "43016",
        expiryDate: "44082",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "109",
        name: "M.F.B. YOGIRAJ PRASAD",
        vesselName: "M.F.B. YOGIRAJ PRASAD",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-8167",
        registrationDate: "41761",
        expiryDate: "42827",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "110",
        name: "M.F.B. SHREE DATTA GURU",
        vesselName: "M.F.B. SHREE DATTA GURU",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-8175",
        registrationDate: "41672",
        expiryDate: "42827",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "111",
        name: "JAY DEV",
        vesselName: "JAY DEV",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1440",
        registrationDate: "43016",
        expiryDate: "44082",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "112",
        name: "M.F.B. JAY LAXMI",
        vesselName: "M.F.B. JAY LAXMI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-350",
        registrationDate: "43016",
        expiryDate: "44082",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "113",
        name: "JAY JALARAM",
        vesselName: "JAY JALARAM",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-2275",
        registrationDate: "6/19/2018",
        expiryDate: "6/18/2021",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "114",
        name: "M.F.B. OM SHREE MAULI",
        vesselName: "M.F.B. OM SHREE MAULI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-196",
        registrationDate: "42493",
        expiryDate: "43558",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "115",
        name: "GANGOTRI",
        vesselName: "GANGOTRI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1904",
        registrationDate: "7/21/2016",
        expiryDate: "7/20/2019",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "116",
        name: "M.F.B. GAURI PRASAD",
        vesselName: "M.F.B. GAURI PRASAD",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-3592",
        registrationDate: "42738",
        expiryDate: "2/28/2020",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "117",
        name: "RATNESHEWARI",
        vesselName: "RATNESHEWARI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-94",
        registrationDate: "9/17/2018",
        expiryDate: "9/16/2021",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "118",
        name: "MAA KRUPA",
        vesselName: "MAA KRUPA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-488",
        registrationDate: "8/16/2016",
        expiryDate: "8/15/2019",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "119",
        name: "GANESH LILA",
        vesselName: "GANESH LILA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-2269",
        registrationDate: "6/13/2018",
        expiryDate: "44536",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "120",
        name: "LOKESHWARI",
        vesselName: "LOKESHWARI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1949",
        registrationDate: "42890",
        expiryDate: "43955",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "121",
        name: "LAXMI NARAYAN",
        vesselName: "LAXMI NARAYAN",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1939",
        registrationDate: "43345",
        expiryDate: "44410",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "122",
        name: "SANGITA",
        vesselName: "SANGITA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-2046",
        registrationDate: "43254",
        expiryDate: "44319",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "123",
        name: "M.F.B. TEJASVINI",
        vesselName: "M.F.B. TEJASVINI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-8657",
        registrationDate: "1/30/2017",
        expiryDate: "1/29/2020",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "124",
        name: "DATTA SWAMI",
        vesselName: "DATTA SWAMI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-88",
        registrationDate: "8/20/2018",
        expiryDate: "8/19/2021",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "125",
        name: "KULDEVATA",
        vesselName: "KULDEVATA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-2180",
        registrationDate: "42981",
        expiryDate: "44046",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "126",
        name: "SHRI SHINYA MAHADEV",
        vesselName: "SHRI SHINYA MAHADEV",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-77",
        registrationDate: "12/29/2015",
        expiryDate: "12/20/2016",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "127",
        name: "JAI LAXMI",
        vesselName: "JAI LAXMI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1790",
        registrationDate: "42986",
        expiryDate: "44051",
        exporterUniquedId: "MA1/PP/016/10"
    },
    {
        id: "128",
        name: "BHUMIKA",
        vesselName: "BHUMIKA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1940",
        registrationDate: "43314",
        expiryDate: "44379",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "129",
        name: "GANGA SHREE",
        vesselName: "GANGA SHREE",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1960",
        registrationDate: "43345",
        expiryDate: "44410",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "130",
        name: "MAHALAXMI",
        vesselName: "MAHALAXMI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-33",
        registrationDate: "43410",
        expiryDate: "44475",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "131",
        name: "LAXMI NARAYAN",
        vesselName: "LAXMI NARAYAN",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-2019",
        registrationDate: "43223",
        expiryDate: "44289",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "132",
        name: "SAGAR KANYA",
        vesselName: "SAGAR KANYA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-2045",
        registrationDate: "43223",
        expiryDate: "44289",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "133",
        name: "TANISHQA",
        vesselName: "TANISHQA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1937",
        registrationDate: "43314",
        expiryDate: "44379",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "134",
        name: "JAI JAGDAMBA",
        vesselName: "JAI JAGDAMBA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-2305",
        registrationDate: "3/20/2017",
        expiryDate: "3/19/2020",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "135",
        name: "GOTREJ MATA",
        vesselName: "GOTREJ MATA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-2500",
        registrationDate: "3/14/2017",
        expiryDate: "3/13/2020",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "136",
        name: "SAGAR DHAN",
        vesselName: "SAGAR DHAN",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1602",
        registrationDate: "9/28/2017",
        expiryDate: "9/27/2020",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "137",
        name: "YASHODA",
        vesselName: "YASHODA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-8194",
        registrationDate: "1/24/2017",
        expiryDate: "1/23/2020",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "138",
        name: "LABHSAVAI",
        vesselName: "LABHSAVAI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1936",
        registrationDate: "43314",
        expiryDate: "44379",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "139",
        name: "JAI MATAJI",
        vesselName: "JAI MATAJI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1319",
        registrationDate: "7/18/2017",
        expiryDate: "7/17/2020",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "140",
        name: "MAYURESH GANESH",
        vesselName: "MAYURESH GANESH",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1783",
        registrationDate: "2/26/2018",
        expiryDate: "2/25/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "141",
        name: "BHAVANI",
        vesselName: "BHAVANI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1132",
        registrationDate: "2/25/2018",
        expiryDate: "2/26/2020",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "142",
        name: "VISHWA MALHAR",
        vesselName: "VISHWA MALHAR",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1129",
        registrationDate: "5/25/2018",
        expiryDate: "2/26/2020",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "143",
        name: "JAI GANESH",
        vesselName: "JAI GANESH",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1128",
        registrationDate: "8/26/2012",
        expiryDate: "2/26/2015",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "144",
        name: "PREM SAGAR",
        vesselName: "PREM SAGAR",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1087",
        registrationDate: "2/26/2012",
        expiryDate: "2/26/2015",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "145",
        name: "TIRTH SAI",
        vesselName: "TIRTH SAI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1086",
        registrationDate: "2/26/2012",
        expiryDate: "2/26/2015",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "146",
        name: "GEETA MAIYA",
        vesselName: "GEETA MAIYA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1072",
        registrationDate: "2/26/2012",
        expiryDate: "2/26/2015",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "147",
        name: "JAY BAJRANG",
        vesselName: "JAY BAJRANG",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1069",
        registrationDate: "2/26/2012",
        expiryDate: "2/26/2015",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "148",
        name: "KANKESHWARI",
        vesselName: "KANKESHWARI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1067",
        registrationDate: "2/26/2012",
        expiryDate: "2/26/2015",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "149",
        name: "PARVATI SHIV NANDAN",
        vesselName: "PARVATI SHIV NANDAN",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1066",
        registrationDate: "2/26/2012",
        expiryDate: "2/26/2015",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "150",
        name: "JAL DHARA",
        vesselName: "JAL DHARA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1062",
        registrationDate: "2/26/2012",
        expiryDate: "2/26/2015",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "151",
        name: "LAMBODAR KRUPA",
        vesselName: "LAMBODAR KRUPA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1059",
        registrationDate: "2/26/2012",
        expiryDate: "2/26/2015",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "152",
        name: "GAURI NANDAN",
        vesselName: "GAURI NANDAN",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-2367",
        registrationDate: "43620",
        expiryDate: "43622",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "153",
        name: "DATTA SAI",
        vesselName: "DATTA SAI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-4572",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "154",
        name: "DATTA DIGAMBAR KRIPA",
        vesselName: "DATTA DIGAMBAR KRIPA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-88",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "155",
        name: "DATTA SAI KRUPA",
        vesselName: "DATTA SAI KRUPA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-3812",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "156",
        name: "ATHARVA",
        vesselName: "ATHARVA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-913",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "157",
        name: "AAI LAXMI",
        vesselName: "AAI LAXMI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-2604",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "158",
        name: "NAMASKAR SAI KRUPA",
        vesselName: "NAMASKAR SAI KRUPA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-2377",
        registrationDate: "43620",
        expiryDate: "43623",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "159",
        "name": "DATTA PRASAD",
        vesselName: "DATTA PRASAD",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-3099",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "160",
        name: "NARAYAN PRASAD",
        vesselName: "NARAYAN PRASAD",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-3108",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "161",
        name: "SHREE SADGURU SMARAN",
        vesselName: "SHREE SADGURU SMARAN",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-9154",
        registrationDate: "43620",
        expiryDate: "43622",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "162",
        name: "VANDAN PANDURANG MAULI",
        vesselName: "VANDAN PANDURANG MAULI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-138",
        registrationDate: "43620",
        expiryDate: "43621",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "163",
        name: "GAYATRI DEVI",
        vesselName: "GAYATRI DEVI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1693",
        registrationDate: "43620",
        expiryDate: "43622",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "164",
        name: "JAY MALHAR",
        vesselName: "JAY MALHAR",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-177",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "165",
        name: "RAM KRISHNA HARI",
        vesselName: "RAM KRISHNA HARI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-67",
        registrationDate: "43621",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "166",
        name: "JEJURI DARSHAN",
        vesselName: "JEJURI DARSHAN",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-658",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "167",
        name: "EKVIRA AAI",
        vesselName: "EKVIRA AAI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-112",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "168",
        name: "VARAD MURTI TISAI",
        vesselName: "VARAD MURTI TISAI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-598",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "169",
        name: "VALLABH SAI",
        vesselName: "VALLABH SAI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-375",
        registrationDate: "43624",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "170",
        name: "SHREE SHIV AMRUT PRASAD",
        vesselName: "SHREE SHIV AMRUT PRASAD",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-4444",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "171",
        name: "SHREE OM SAI GANESH",
        vesselName: "SHREE OM SAI GANESH",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-8293",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "172",
        name: "SHREE EKVIRA MAULI",
        vesselName: "SHREE EKVIRA MAULI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-27",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "173",
        name: "SHREE EKVIRA AAI",
        vesselName: "SHREE EKVIRA AAI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-4445",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "174",
        name: "NAND KISHOR",
        vesselName: "NAND KISHOR",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-7564",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "175",
        name: "MOT MAULI",
        vesselName: "MOT MAULI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-64",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "176",
        name: "MAHALAXMI",
        vesselName: "MAHALAXMI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-2488",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "177",
        name: "BALLARESHWAR PRASAD",
        vesselName: "BALLARESHWAR PRASAD",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-233",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "178",
        name: "AGNNI POOJAN",
        vesselName: "AGNNI POOJAN",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-2499",
        registrationDate: "43620",
        expiryDate: "43620",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "179",
        name: "HERAMBA GANAPATI PRASAD",
        vesselName: "HERAMBA GANAPATI PRASAD",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1127",
        registrationDate: "43681",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "180",
        name: "JAY SHREE GANESH SAI",
        vesselName: "JAY SHREE GANESH SAI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1123",
        registrationDate: "43681",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "181",
        name: "SHREE SAMARTH KRUPA",
        vesselName: "SHREE SAMARTH KRUPA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1122",
        registrationDate: "43681",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "182",
        name: "NAND YASHODA",
        vesselName: "NAND YASHODA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1121",
        registrationDate: "43681",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "183",
        name: "VAISHALI",
        vesselName: "VAISHALI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1102",
        registrationDate: "43681",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "184",
        name: "PREM SAGAR",
        vesselName: "PREM SAGAR",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1087",
        registrationDate: "43681",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "185",
        name: "JAY BAJARANG",
        vesselName: "JAY BAJARANG",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1069",
        registrationDate: "43681",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "186",
        name: "JALDHARA",
        vesselName: "JALDHARA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1062",
        registrationDate: "43681",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "187",
        name: "RATNESHWARI MAULI",
        vesselName: "RATNESHWARI MAULI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1041",
        registrationDate: "43683",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "188",
        name: "DEVI EKVIRA AAI",
        vesselName: "DEVI EKVIRA AAI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1042",
        registrationDate: "43681",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "189",
        name: "SADGURU KRUPA",
        vesselName: "SADGURU KRUPA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1031",
        registrationDate: "43681",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "190",
        name: "SAI RATNA",
        vesselName: "SAI RATNA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1030",
        registrationDate: "43681",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "191",
        name: "DHAN LAXMI",
        vesselName: "DHAN LAXMI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1027",
        registrationDate: "43681",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "192",
        name: "DATTA SAI DIGAMBAR",
        vesselName: "DATTA SAI DIGAMBAR",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1021",
        registrationDate: "43681",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "193",
        name: "GANARAJ",
        vesselName: "GANARAJ",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-823",
        registrationDate: "43681",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "194",
        name: "HAR SIDDHI",
        vesselName: "HAR SIDDHI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1049",
        registrationDate: "43681",
        expiryDate: "43681",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "195",
        name: "KRUPALU SWAYAMBHU EKADASHI",
        vesselName: "KRUPALU SWAYAMBHU EKADASHI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-2061",
        registrationDate: "5/15/2018",
        expiryDate: "5/14/2020",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "196",
        name: "TULJA BHAVANI",
        vesselName: "TULJA BHAVANI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-829",
        registrationDate: "6/24/2019",
        expiryDate: "5/23/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "197",
        name: "MAHALAXMI",
        vesselName: "MAHALAXMI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1231",
        registrationDate: "5/24/2019",
        expiryDate: "5/23/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "198",
        name: "CHANDRA MANI",
        vesselName: "CHANDRA MANI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1019",
        registrationDate: "5/24/2019",
        expiryDate: "5/23/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "199",
        name: "RAMESHWARI",
        vesselName: "RAMESHWARI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1018",
        registrationDate: "5/24/2019",
        expiryDate: "5/23/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "200",
        name: "JAGRUT KHANDOBA",
        vesselName: "JAGRUT KHANDOBA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1014",
        registrationDate: "5/24/2019",
        expiryDate: "5/23/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "201",
        name: "SAI LAXMI",
        vesselName: "SAI LAXMI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-310",
        registrationDate: "5/24/2019",
        expiryDate: "5/23/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "202",
        name: "MAHALAXMI SAI",
        vesselName: "MAHALAXMI SAI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-660",
        registrationDate: "5/24/2019",
        expiryDate: "5/23/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "203",
        name: "SHRI SADGURU PRASAD",
        vesselName: "SHRI SADGURU PRASAD",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-117",
        registrationDate: "5/24/2019",
        expiryDate: "5/23/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "204",
        name: "HARICHI ALANDI",
        vesselName: "HARICHI ALANDI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-924",
        registrationDate: "5/24/2019",
        expiryDate: "5/23/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "205",
        name: "MAULI VAISHNO",
        vesselName: "MAULI VAISHNO",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1787",
        registrationDate: "5/28/2019",
        expiryDate: "5/27/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "206",
        name: "JAI SAI GANESH",
        vesselName: "JAI SAI GANESH",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1188",
        registrationDate: "5/28/2019",
        expiryDate: "5/27/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "207",
        name: "HARI OM LAXMI",
        vesselName: "HARI OM LAXMI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1343",
        registrationDate: "5/28/2019",
        expiryDate: "5/27/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "208",
        name: "JAI MALHAR",
        vesselName: "JAI MALHAR",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-2107",
        registrationDate: "5/28/2019",
        expiryDate: "5/27/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "207",
        name: "VAKRATUND SAI MAULI",
        vesselName: "VAKRATUND SAI MAULI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1766",
        registrationDate: "5/28/2019",
        expiryDate: "5/27/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "208",
        name: "RENUKA MATA",
        vesselName: "RENUKA MATA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-3-MM-782",
        registrationDate: "5/28/2019",
        expiryDate: "5/27/2019",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "209",
        name: "MAHARUDRA",
        vesselName: "MAHARUDRA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1370",
        registrationDate: "5/28/2019",
        expiryDate: "5/27/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "210",
        name: "NAMO VISHWA VYANKTESH",
        vesselName: "NAMO VISHWA VYANKTESH",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-2347",
        registrationDate: "5/29/2019",
        expiryDate: "5/28/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "211",
        name: "RAM KRUSHNA HARI",
        vesselName: "RAM KRUSHNA HARI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-2228",
        registrationDate: "5/29/2019",
        expiryDate: "5/28/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "212",
        name: "SHREE SAI BHOLE",
        vesselName: "SHREE SAI BHOLE",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-585",
        registrationDate: "5/29/2019",
        expiryDate: "5/28/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "213",
        name: "SHRI HARI LAXMI MATA",
        vesselName: "SHRI HARI LAXMI MATA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1588",
        registrationDate: "5/29/2019",
        expiryDate: "5/28/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "214",
        name: "F.B. VARAD VINAYAK SAI",
        vesselName: "F.B. VARAD VINAYAK SAI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-228",
        registrationDate: "5/29/2019",
        expiryDate: "5/28/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "215",
        name: "SHREE SIDDHIVINAYAK KRUPA",
        vesselName: "SHREE SIDDHIVINAYAK KRUPA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-2406",
        registrationDate: "5/29/2019",
        expiryDate: "5/28/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "216",
        name: "GAJLAXMI",
        vesselName: "GAJLAXMI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-1705",
        registrationDate: "10/18/2018",
        expiryDate: "10/17/2020",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "217",
        name: "DATTARAJ MAULI",
        vesselName: "DATTARAJ MAULI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-98",
        registrationDate: "6/21/2019",
        expiryDate: "6/20/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "218",
        name: "AAI AMBIKA",
        vesselName: "AAI AMBIKA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-4923",
        registrationDate: "6/21/2019",
        expiryDate: "6/20/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "219",
        name: "EKADASHI",
        vesselName: "EKADASHI",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-1404",
        registrationDate: "6/21/2019",
        expiryDate: "6/20/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "220",
        name: "EKVIRA PRASAD",
        vesselName: "EKVIRA PRASAD",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-1116",
        registrationDate: "6/26/2019",
        expiryDate: "6/25/2019",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "221",
        name: "SHREE RAM SAMARTH",
        vesselName: "SHREE RAM SAMARTH",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-1084",
        registrationDate: "6/26/2019",
        expiryDate: "6/25/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "222",
        name: "HARI OM SAI GANESH",
        vesselName: "HARI OM SAI GANESH",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-7-MM-69",
        registrationDate: "6/27/2019",
        expiryDate: "6/26/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "223",
        name: "MAHARUDRA",
        vesselName: "MAHARUDRA",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-1047",
        registrationDate: "6/27/2019",
        expiryDate: "6/26/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "224",
        name: "GANESH TITWALA PRASAD",
        vesselName: "GANESH TITWALA PRASAD",
        jetty: "SASOON DOCK",
        registrationNumber: "IND-MH-1-MM-92",
        registrationDate: "6/27/2019",
        expiryDate: "6/26/2021",
        exporterUniquedId: "MA1/PP/024/13"
    },
    {
        id: "225",
        name: "RADHE KRISHNA",
        vesselName: "RADHE KRISHNA",
        jetty: "MANGALORE",
        registrationNumber: "IND-MH-7-MM-283",
        registrationDate: "1/17/2020",
        expiryDate: "12/16/2021"
    },
    {
        id: "226",
        name: "SHREE RAM SAMARTH",
        vesselName: "KANYAKUMARI",
        jetty: "MANGALORE",
        registrationNumber: "IND-MH-7-MM-34",
        registrationDate: "9/17/2019",
        expiryDate: "10/16/2021"
    },
    {
        id: "227",
        name: "BAPUJI DEV PRASAD",
        vesselName: "SHREE RAM SAMARTH",
        jetty: "MANGALORE",
        registrationNumber: "IND-MH-7-MM-985",
        registrationDate: "9/17/2019",
        expiryDate: "9/16/2021"
    },
    {
        id: "228",
        name: "JAY ALIMKHANDI MAULI",
        vesselName: "BAPUJI DEV PRASAD",
        jetty: "MANGALORE",
        registrationNumber: "IND-MH-7-MM-961",
        registrationDate: "9/17/2019",
        expiryDate: "10/21/2021"
    },
    {
        id: "229",
        name: "GAURI SHANKAR",
        vesselName: "JAY ALIMKHANDI MAULI",
        jetty: "MANGALORE",
        registrationNumber: "IND-MH-7-MM-818",
        registrationDate: "1/17/2020",
        expiryDate: "9/16/2021"
    },
    {
        id: "230",
        name: "MALHARI GANESH",
        vesselName: "GAURI SHANKAR",
        jetty: "MANGALORE",
        registrationNumber: "IND-MH-7-MM-525",
        registrationDate: "9/17/2019",
        expiryDate: "9/16/2021"
    },
    {
        id: "231",
        name: "VAISHNAVI MATA",
        vesselName: "MALHARI GANESH",
        jetty: "MANGALORE",
        registrationNumber: "IND-MH-7-MM-277",
        registrationDate: "11/17/2019",
        expiryDate: "9/17/2021"
    },
    {
        id: "232",
        name: "NAMO VISHWA VYANKTESH",
        vesselName: "VAISHNAVI MATA",
        jetty: "MANGALORE",
        registrationNumber: "IND-MH-7-MM-2710",
        registrationDate: "9/17/2019",
        expiryDate: "9/16/2021"
    },
    {
        id: "233",
        name: "MAULI KRUPA",
        vesselName: "NAMO VISHWA VYANKTESH",
        jetty: "MANGALORE",
        registrationNumber: "IND-MH-7-MM-2347",
        registrationDate: "9/17/2019",
        expiryDate: "9/16/2021"
    },
    {
        id: "234",
        name: "SHRI OM SAI GANESH",
        vesselName: "MAULI KRUPA",
        jetty: "MANGALORE",
        registrationNumber: "IND-MH-7-MM-1515",
        registrationDate: "9/17/2019",
        expiryDate: "9/16/2021"
    },
];

// export const portOfDischarge = [
//     { id: "1", name: "WEST PORT KLANG", country: "Malaysia" },
//     { id: "2", name: "TANJUNG PELEPAS", country: "Malaysia" },
//     { id: "3", name: "PORT KLANG", country: "Malaysia" },
//     { id: "4", name: "NORTH PORT KLANG", country: "Malaysia" },
//     { id: "5", name: "PENANG", country: "Malaysia" },
//     { id: "6", name: "PASIR GUDANG", country: "Malaysia" },
//     { id: "7", name: "THESSALONIKI", country: "Greece" },
//     { id: "8", name: "PIRAEUS", country: "Greece" },
//     { id: "9", name: "NAPOLI", country: "Italy" },
//     { id: "10", name: "LIVORNO", country: "Italy" },
//     { id: "11", name: "ANCONA PORT", country: "Italy" },
//     { id: "12", name: "KEELUNG PORT", country: "Taiwan" },
//     { id: "13", name: "KAOHSIUNG PORT", country: "Taiwan" },
//     { id: "14", name: "TAICHUNG PORT", country: "Taiwan" },
//     { id: "15", name: "MOJI", country: "Japan" },
//     { id: "16", name: "OSAKA", country: "Japan" },
//     { id: "17", name: "LAT KRABANG", country: "Thailand" },
//     { id: "18", name: "LAEM CHABANG", country: "Thailand" },
//     { id: "19", name: "BANGKOK", country: "Thailand" },
//     { id: "20", name: "LISBON PORT", country: "Portugal" },
//     { id: "21", name: "LEIXOES", country: "Portugal" },
//     { id: "22", name: "XIAMEN", country: "China" },
//     { id: "23", name: "SHIDAO PORT", country: "China" },
//     { id: "24", name: "QINGDAO", country: "China" },
//     { id: "25", name: "MAWEI FUZHOU", country: "China" },
//     { id: "26", name: "MAWEI", country: "China" },
//     { id: "27", name: "HUANGPU", country: "China" },
//     { id: "28", name: "VICT PORT", country: "Vietnam" },
//     { id: "29", name: "CAT LAI PORT", country: "Vietnam" },
//     { id: "30", name: "HAIPHONG", country: "Vietnam" },
//     { id: "31", name: "SHARJAH", country: "United Arab Emirates" },
//     { id: "32", name: "ROTTERDAM", country: "Netherlands" },
//     { id: "33", name: "SZCZECIN", country: "Poland" },
//     { id: "34", name: "SINGAPORE", country: "Singapore" },
//     { id: "35", name: "POINTE DES GALETS", country: "Réunion" },
//     { id: "36", name: "ORAN PORT", country: "Algeria" },
//     { id: "37", name: "LONGONI", country: "Mayotte" },
//     { id: "38", name: "LIMASSOL PORT", country: "Cyprus" },
//     { id: "39", name: "LE HAVRE PORT", country: "France" },
//     { id: "40", name: "KLAIPEDA PORT", country: "Lithuania" },
//     { id: "41", name: "KALININGRAD", country: "Russia" },
//     { id: "42", name: "ISTANBUL", country: "Turkey" },
//     { id: "43", name: "FORT DE FRANCE", country: "Martinique" },
//     { id: "44", name: "DURBAN", country: "South Africa" },
//     { id: "45", name: "CAUCEDO", country: "Dominican Republic" },
//     { id: "46", name: "BUSAN PORT", country: "South Korea" },
//     { id: "47", name: "BILBOA", country: "Spain" },
// ]
export const portOfDischarge = [
    {
        "id": "1",
        "name": "DJEN DJEN, ALGERIA"
    },
    {
        "id": "2",
        "name": "KHALIFA BIN SALMAN PORT, BAHRAIN"
    },
    {
        "id": "3",
        "name": "DALIAN,CHINA"
    },
    {
        "id": "4",
        "name": "HUANGPU, CHINA"
    },
    {
        "id": "5",
        "name": "LIANYUNGANG, CHINA"
    },
    {
        "id": "6",
        "name": "MAWEI FUZHOU, CHINA"
    },
    {
        "id": "7",
        "name": "MAWEI, CHINA"
    },
    {
        "id": "8",
        "name": "SHIDAO, CHINA"
    },
    {
        "id": "9",
        "name": "TIANJIN PORT, CHINA"
    },
    {
        "id": "10",
        "name": "XIAMEN, CHINA"
    },
    {
        "id": "11",
        "name": "ZHANJIANG, CHINA"
    },
    {
        "id": "12",
        "name": "FUZHOU, CHINA"
    },
    {
        "id": "13",
        "name": "QINGDAO, CHINA"
    },
    {
        "id": "14",
        "name": "RIZHAO, CHINA"
    },
    {
        "id": "15",
        "name": "SHANGHAI, CHINA"
    },
    {
        "id": "16",
        "name": "LIMASSOL, CYPRUS"
    },
    {
        "id": "17",
        "name": "CAUCEDO, DOMINICAN REPUBLIC"
    },
    {
        "id": "18",
        "name": "LE HAVRE, FRANCE"
    },
    {
        "id": "19",
        "name": "POTI PORT, GEORGIA"
    },
    {
        "id": "20",
        "name": "THESSALONIKI, GREECE"
    },
    {
        "id": "21",
        "name": "PIRAEUS, GREECE"
    },
    {
        "id": "22",
        "name": "POINTE A PITRE, GUADELOUPE ISLAND"
    },
    {
        "id": "23",
        "name": "HONGKONG,HONGKONG"
    },
    {
        "id": "24",
        "name": "ANCONA, ITALY"
    },
    {
        "id": "25",
        "name": "BARI,ITALY"
    },
    {
        "id": "26",
        "name": "GENOVA, ITALY"
    },
    {
        "id": "27",
        "name": "LIVORNO, ITALY"
    },
    {
        "id": "28",
        "name": "NAPLES, ITALY"
    },
    {
        "id": "29",
        "name": "NAPOLI, ITALY"
    },
    {
        "id": "30",
        "name": "VENICE PORT, ITALY"
    },
    {
        "id": "31",
        "name": "HAKATA PORT, JAPAN"
    },
    {
        "id": "32",
        "name": "KOBE PORT, JAPAN"
    },
    {
        "id": "33",
        "name": "OSAKA, JAPAN"
    },
    {
        "id": "34",
        "name": "MOJI, JAPAN"
    },
    {
        "id": "35",
        "name": "SHUWAIKH SEAPORT, KUWAIT"
    },
    {
        "id": "36",
        "name": "KLAIPEDA, LITHUANIA"
    },
    {
        "id": "37",
        "name": "PASIR GUDANG, MALAYSIA"
    },
    {
        "id": "38",
        "name": "PENANG, MALAYSIA"
    },
    {
        "id": "39",
        "name": "PORT KLANG (NORTH), MALAYSIA"
    },
    {
        "id": "40",
        "name": "PORT KLANG (WEST), MALAYSIA"
    },
    {
        "id": "41",
        "name": "TANJUNG PELEPAS, MALAYSIA"
    },
    {
        "id": "42",
        "name": "FORT DE FRANCE, MARTINIQUE"
    },
    {
        "id": "43",
        "name": "PORT LOUIS, MAURITIUS"
    },
    {
        "id": "44",
        "name": "SZCZECIN, POLAND"
    },
    {
        "id": "45",
        "name": "LEIXOES, PORTUGAL"
    },
    {
        "id": "46",
        "name": "LISBON, PORTUGAL"
    },
    {
        "id": "47",
        "name": "POINTE DES GALETS, REUNION ISLAND"
    },
    {
        "id": "48",
        "name": "KALININGRAD, RUSSIA"
    },
    {
        "id": "49",
        "name": "SINGAPORE,SINGAPORE"
    },
    {
        "id": "50",
        "name": "DURBAN, SOUTH AFRICA"
    },
    {
        "id": "51",
        "name": "BUSAN, SOUTH KOREA"
    },
    {
        "id": "52",
        "name": "GWANGYANG,SOUTH KOREA"
    },
    {
        "id": "53",
        "name": "BILBOA, SPAIN"
    },
    {
        "id": "54",
        "name": "LAS PALMAS, SPAIN"
    },
    {
        "id": "55",
        "name": "SANTA CRUZ DE TENERIFE, SPAIN"
    },
    {
        "id": "56",
        "name": "KAOHSIUNG, TAIWAN"
    },
    {
        "id": "57",
        "name": "KEELUNG, TAIWAN"
    },
    {
        "id": "58",
        "name": "TAICHUNG, TAIWAN"
    },
    {
        "id": "59",
        "name": "LAT KRABANG, THAILAND"
    },
    {
        "id": "60",
        "name": "SONGKHLA, THAILAND"
    },
    {
        "id": "61",
        "name": "BANGKOK, THAILAND"
    },
    {
        "id": "62",
        "name": "LAEM CHABANG, THAILAND"
    },
    {
        "id": "63",
        "name": "SHANTHAI, THAILAND"
    },
    {
        "id": "64",
        "name": "ISTANBUL, TURKEY"
    },
    {
        "id": "65",
        "name": "SHARJAH, UAE"
    },
    {
        "id": "66",
        "name": "ODESSA PORT, UKRAINE"
    },
    {
        "id": "67",
        "name": "CAT LAI, VIETNAM"
    },
    {
        "id": "68",
        "name": "HAIPHONG, VIETNAM"
    },
    {
        "id": "69",
        "name": "PIVDENNYI PORT , UKRAINE"
    },
    {
        "id": "70",
        "name": "JIUJIANG, GUANGDONG"
    },
    {
        "id": "71",
        "name": "KWANGYANG, SOUTH KOREA"
    },
    {
        "id": "72",
        "name": "INCHEON, SOUTH KOREA"
    },
    {
        "id": "73",
        "name": "QINZHAOU"
    },
    {
        "id": "74",
        "name": "XINGANG, CHINA"
    },
    {
        "id": "75",
        "name": "NANSHA, CHINA"
    },
    {
        "id": "76",
        "name": "VALENCIA, SPAIN"
    },
    {
        "id": "77",
        "name": "BARCELONA, SPAIN"
    },
    {
        "id": "78",
        "name": "SHANSHAN , CHINA"
    },
    {
        "id": "79",
        "name": "SHIMIZU , JAPAN"
    },
    {
        "id": "80",
        "name": "OSAKA , JAPAN"
    },
    {
        "id": "81",
        "name": "HAKATA , JAPAN"
    },
    {
        "id": "82",
        "name": "BILBAO, SPAIN"
    },
    {
        "id": "83",
        "name": "Constanta, Romania"
    },
    {
        "id": "84",
        "name": "ST.PETERSBURG , RUSSIA"
    },
    {
        "id": "85",
        "name": "CONSTANTA-SOUTH"
    },
    {
        "id": "86",
        "name": "AQABA,JORDAN"
    },
    {
        "id": "87",
        "name": "JORDAN"
    },
    {
        "id": "88",
        "name": "DURRES,ALBANIA"
    },
    {
        "id": "89",
        "name": "BEIRUT PORT,LEBANON"
    },
    {
        "id": "90",
        "name": "SANSHAN, CHINA"
    },
    {
        "id": "91",
        "name": "TOYAMA, JAPAN"
    },
    {
        "id":"92",
        "name":"ORAN PORT , ALGERIA"
    },
    {
        "id":"93",
        "name":"Riga, Latvia"
    },
    {
        "id":"94",
        "name":"DUNKERQUE PORT, FRANCE"
    } ,
    {
        "id":"95",
        "name":"GDANSK, POLAND"
    } ,
    {
        "id":"96",
        "name":"Semarang, Indonesia"
    },
    {
        "id":"97",
        "name":"ASHDOD PORT, ISRAEL"
    } ,
    {
        "id":"98",
        "name":"Vigo, Spain"
    } ,
    {
        "id":"99",
        "name":"MANILA NORTH PORT, PHILIPPINES"
    } ,
    {
        "id":"100",
        "name":"Algeciras, Spain"
    },
    {
        "id":"101",
        "name":"RENI PORT , UKRAINE"
    },   
    {
        "id":"102",
        "name":"ANTWERP, BELGIUM"
    },    
    {
        "id":"103",
        "name":"CAPE TOWN, SOUTH AFRICA"
    },
    {
        "id":"104",
        "name":"RIJEKA, CROATIA"
    },
    {
        "id":"105",
        "name":"SHANTOU, CHINA"
    },
    {
        "id":"106",
        "name":"YANTIAN, CHINA"
    }
]

export const portOfShipment = [
    // { id: "1", name: "Visakhapatnam" },
    // { id: "2", name: "Tuticorin" },
    // { id: "3", name: "Paradip" },
    { id: "NHAVA SHEVA PORT, INDIA", name: "NHAVA SHEVA PORT, INDIA" },
    // { id: "5", name: "Mumbai" },
    // { id: "6", name: "Mormugao" },
    { id: "MANGALORE, INDIA", name: "MANGALORE, INDIA" },
    { id: "MARMAGAO, INDIA", name: "MARMAGAO, INDIA" },
    // { id: "8", name: "Krishnapatnam Port" },
    // { id: "9", name: "Kolkata" },
    // { id: "10", name: "Kochi" },
    // { id: "11", name: "Chennai" },
    // { id: "12", name: "Kandla" },
    // { id: "13", name: "Kamarajar Port" },
]

export const otherDescriptionOfGoods = [
    { id: "1", name: "NA" },
    { id: "2", name: "PROCESSED, PRESERVED FROZEN FISH & FISH PRODUCT (SURIMI)" },
]

export const heavyMetals = [
    { id: "Heavy Metal - Arsenic", name: "Heavy Metal - Arsenic" },
    { id: "Heavy Metal - Cadmium", name: "Heavy Metal - Cadmium" },
    { id: "Heavy Metal - Chromium", name: "Heavy Metal - Chromium" },
    { id: "Heavy Metal - Lead", name: "Heavy Metal - Lead" },
    { id: "Heavy Metal - Mercury", name: "Heavy Metal - Mercury" },
]

export const dyes = [
    { id: "Dyes - Crystal Violet", name: "Dyes - Crystal Violet" },
    { id: "Dyes - Leuco Crystal Violet", name: "Dyes - Leuco Crystal Violet" },
    { id: "Dyes - MALACHITE GREEN", name: "Dyes - MALACHITE GREEN" },
    { id: "Dyes - LEUCO MALACHITE GREEN", name: "Dyes - LEUCO MALACHITE GREEN" },
]

export const antibiotics = [
    { id: "Antibiotics - Chloramphenicol", name: "Antibiotics - Chloramphenicol" },
    { id: "Antibiotics - Chlorteracycline", name: "Antibiotics - Chlorteracycline" },
    { id: "Antibiotics - Hitrofurans", name: "Antibiotics - Hitrofurans" },
    { id: "Antibiotics - NITROFURAZONE", name: "Antibiotics - NITROFURAZONE" },
    { id: "Antibiotics - Oxytetracycline", name: "Antibiotics - Oxytetracycline" },
    { id: "Antibiotics - Tetracyclines", name: "Antibiotics - Tetracyclines" },
    { id: "Antibiotics - FURAZOLIDONE", name: "Antibiotics - FURAZOLIDONE" },
]

export const chemicalTesting = [
    { id: "Chemical Testing - GAL d1-Ovomucoid", name: "Chemical Testing - GAL d1-Ovomucoid" },
    { id: "Chemical Testing - Gal d2 - Ovalbumin", name: "Chemical Testing - Gal d2 - Ovalbumin" },
    { id: "Chemical Testing - Gal d3 - Ovotransferrin", name: "Chemical Testing - Gal d3 - Ovotransferrin" },
    { id: "Chemical Testing - Histamine", name: "Chemical Testing - Histamine" },
    { id: "Chemical Testing - Lysozyme", name: "Chemical Testing - Lysozyme" },
    { id: "Chemical Testing - Total phosphours (as P2O5)", name: "Chemical Testing - Total phosphours (as P2O5)" },
]

export const pcb = [
    { id: "PCB 101", name: "PCB 101" },
    { id: "PCB 118", name: "PCB 118" },
    { id: "PCB 138", name: "PCB 138" },
    { id: "PCB 153", name: "PCB 153" },
    { id: "PCB 180", name: "PCB 180" },
    { id: "PCB 28", name: "PCB 28" },
    { id: "PCB 52", name: "PCB 52" },
]

// export const testingRequirements = [...heavyMetals, ...antibiotics, ...chemicalTesting, ...pcb];


export const testingRequirements = ['Heavy Metal', 'Microbiological', 'Antibiotic', 'Dye Testing', 'Histamine', 'PCB', 'P2O5', 'Egg White', 'Country Specific']

export const species = [
    { id: "UROCONGER LEPTURUS", name: "UROCONGER LEPTURUS" },
    { id: "UPENEUS MOLCCENSIS", name: "UPENEUS MOLCCENSIS" },
    { id: "TRICHIURUS LEPTURUS", name: "TRICHIURUS LEPTURUS" },
    { id: "SAURIDA TUMBIL", name: "SAURIDA TUMBIL" },
    { id: "SAURIDA SPP", name: "SAURIDA SPP" },
    { id: "SARDINELLA SPP", name: "SARDINELLA SPP" },
    { id: "SARDINELLA LONGICEPS", name: "SARDINELLA LONGICEPS" },
    { id: "PRICANTHUS TAYENUS", name: "PRICANTHUS TAYENUS" },
    { id: "PRICANTHUS HAMRUR", name: "PRICANTHUS HAMRUR" },
    { id: "PRIACANTHUS SPP", name: "PRIACANTHUS SPP" },
    { id: "PARUPENEUS INDICUS", name: "PARUPENEUS INDICUS" },
    { id: "PARUPENEUS BARBERINUS", name: "PARUPENEUS BARBERINUS" },
    { id: "PARAUPENEUS SPP", name: "PARAUPENEUS SPP" },
    { id: "NEMIPTERUS SPP", name: "NEMIPTERUS SPP" },
    { id: "NEMIPTERUS MESOPRION", name: "NEMIPTERUS MESOPRION" },
    { id: "NEMIPTERUS JAPONICUS", name: "NEMIPTERUS JAPONICUS" },
    { id: "MULLOIDES VANICOLENSIS", name: "MULLOIDES VANICOLENSIS" },
    { id: "MULLOIDES FLAVOLINEATUS", name: "MULLOIDES FLAVOLINEATUS" },
    { id: "LEPTURACANTHUS SAVALA", name: "LEPTURACANTHUS SAVALA" },
    { id: "JOHNIUS SPP", name: "JOHNIUS SPP" },
    { id: "JOHNIUS DUSSUMIERI", name: "JOHNIUS DUSSUMIERI" },
    { id: "HETEROPRIACANTHUS CRUENTATUS", name: "HETEROPRIACANTHUS CRUENTATUS" },
    { id: "EPINEPHELUS SPP", name: "EPINEPHELUS SPP" },
    { id: "EPINEPHELUS DIACANTHUS", name: "EPINEPHELUS DIACANTHUS" },
    { id: "CONGER CINEREUS", name: "CONGER CINEREUS" }
]

export const documentRequirements = [
    { id: 'TRACEABLITY REPORT', type: 'both' },
    { id: 'TEST REPORT', type: 'both' },
    { id: 'SURIMI PROCESS FLOW CHART', type: 'both' },
    { id: 'SPECIFICATION', type: 'both' },
    { id: 'SHIPPING BILL', type: 'both' },
    { id: 'QUALITY (ANALYSIS REPORT)', type: 'both' },
    { id: 'LABELS', type: 'both' },
    { id: 'INSURANCE POLICY', type: 'both' },
    { id: 'EXTRA SET OF DOCUMENTS COPY', type: 'both' },
    { id: 'CERTIFICATE OF PRODUCTION DATE', type: 'both' },
    { id: 'COMMERCIAL INVOICE', type: 'both' },
    { id: 'SHIPPING ADVISE', type: 'both' },
    { id: 'IMC CERTIFICATE', type: 'both' },
    { id: 'GSP CERTIFICATE', type: 'both' },
    { id: 'IKCEPA CERTIFICATE', type: 'both' },
    { id: 'APTA CERTIFICATE', type: 'both' },
    { id: 'AIFTA CERTIFICATE', type: 'both' },
    { id: 'INGREDIENT LIST', type: 'both' },
    { id: 'PACKING LIST', type: 'both' },
    { id: 'LC', type: 'both' },
    { id: 'BUYER COVERING LETTER', type: 'buyer' },
    { id: 'BANK COVERING LETTER', type: 'bank' },
    { id: 'BENEFICIARY CERTIFICATE', type: 'both' },
    { id: 'BILL OF EXCHANGE', type: 'both' },
    { id: 'BILL OF LADING', type: 'both' },
    { id: 'Q CERTIFICATE', type: 'both' },
    { id: 'HEALTH CERTIFICATE', type: 'both' },
    { id: 'CATCH CERTIFICATE', type: 'both' },
    { id: 'CATCH STATEMENT', type: 'both' },
    { id: 'ADDITIVES', type: 'both' },
    { id: 'BL SURRENDER', type: 'both' },
]

export const documents = [
    { id: 'BILL OF EXCHANGE', type: 'both' },
    { id: 'COMMERCIAL INVOICE', type: 'both' },
    { id: 'PACKING LIST', type: 'both' },
    { id: 'IMC CERTIFICATE', type: 'both' },
    { id: 'GSP CERTIFICATE', type: 'both' },
    { id: 'IKCEPA CERTIFICATE', type: 'both' },
    { id: 'APTA CERTIFICATE', type: 'both' },
    { id: 'AIFTA CERTIFICATE', type: 'both' },
    { id: 'BILL OF LADING', type: 'both' },
    { id: 'HEALTH CERTIFICATE', type: 'both' },
    { id: 'VETERINARY CERTIFICATE', type: 'both' },
    { id: 'SHIPPING BILL', type: 'both' },
    { id: 'TRACEABLITY REPORT', type: 'both' },
    { id: 'TEST REPORT', type: 'both' },
    { id: 'SURIMI PROCESS FLOW CHART', type: 'both' },
    { id: 'SPECIFICATION', type: 'both' },
    { id: 'QUALITY (ANALYSIS REPORT)', type: 'both' },
    { id: 'LABELS', type: 'both' },
    { id: 'INSURANCE POLICY', type: 'both' },
    { id: 'EXTRA SET OF DOCUMENTS COPY', type: 'both' },
    { id: 'CERTIFICATE OF PRODUCTION DATE', type: 'both' },
    { id: 'SHIPPING ADVISE', type: 'both' },
    { id: 'INGREDIENT LIST', type: 'both' },
    { id: 'LC', type: 'both' },
    { id: 'BUYER COVERING LETTER', type: 'buyer' },
    { id: 'BANK COVERING LETTER', type: 'bank' },
    { id: 'BENEFICIARY CERTIFICATE', type: 'both' },
    { id: 'Q CERTIFICATE', type: 'both' },
    { id: 'CATCH CERTIFICATE', type: 'both' },
    { id: 'CATCH STATEMENT', type: 'both' },
    { id: 'ADDITIVES', type: 'both' },
    { id: 'BL SURRENDER', type: 'both' },
    { id: 'ENGAGEMENT LETTER', type: 'both' },
    { id: 'SURVEY REPORT', type: 'both' },
    { id: 'BACTERIOLOGICAL / MICROBIOLOGICAL CERTIFICATE', type: 'both' },
    { id: "BENEFICIARY'S ATTESTATION", type: 'both' },
    { id: 'ANALYTICAL CERTIFICATE', type: 'both' },
    { id: 'LOADING PLAN', type: 'both' },
    { id: 'BENEFICIARY ON PREFERNTIAL', type: 'both' },
    { id: 'EXPORTER REGISTRY FORM', type: 'both' },
    { id: 'SHIPPING LINE CERTIFICATE ', type: 'both' },
    { id: 'BENEFICIARY TELEFAX', type: 'both' },
    { id: 'BENEFICIARY STATEMENT', type: 'both' },
    { id: 'COMPENSATION LETTER', type: 'both' },
    { id: 'INSPECTION REPORT', type: 'both' },
    { id: 'NO WOODEN CERTIFICATE', type: 'both' },
    { id: 'ASSESSMENT CERTIFICATE', type: 'both' },
    { id: 'INSURANCE', type: 'both' },
    { id: 'SPECIFICATION', type: 'both' },
    { id: 'PRICE LIST', type: 'both' },
    { id: 'BRAND', type: 'buyer' },
    { id: 'CERTIFICATE OF ANALYSIS', type: 'buyer' },
    { id: 'CERTIFICATE OF QUALITY', type: 'buyer' },
    { id: 'COMMERCIAL OFFER', type: 'buyer' },
    { id: 'NON-GMO CERTIFICATE', type: 'buyer' },
    { id: 'PRICE LIST', type: 'buyer' },
    { id: 'PRODUCER DECLARATION', type: 'buyer' },
    { id: 'TECHNOLOGY CHART', type: 'buyer' },
    { id: 'STUFFING REPORT', type: 'both' },
    { id: 'NET WEIGHT CERTIFICATE', type: 'both' },
    { id: 'SELLER GUARENTEE', type: 'both' },
]


export const banks = [
    {
        approvalNumber: 1192,
        paymentTerms: {
            LC: {
                fish: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	ULKA SEA FOODS PVT. LTD.
                    <br>BENEFICIARY ACCOUNT NUMBER : 19080210005217
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY ADVICING BANK NAME : UCO BANK
                    <br>BENEFICIARY ADVICING BANK ADDRESS :	FLAGSHIP CORPORATE BRANCH,  NARIMAN POINT, MUMBAI – 400 021,INDIA
                    <br>BENEFICIARY ADVICING BANK SWIFT CODE : UCBAINBB002
                    `,
                    accountNumber: '19080210005217',
                    bankName: 'UCO BANK, FLAGSHIP CORPORATE BRANCH',
                    branch: 'NARIMAN POINT, MUMBAI 400 021, INDIA',
                    swift: 'UCBAINBB002'
                },
                surimi: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	ULKA SEA FOODS PVT. LTD.
                    <br>BENEFICIARY ACCOUNT NUMBER : 19080210005224
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY ADVICING BANK NAME : UCO BANK
                    <br>BENEFICIARY ADVICING BANK ADDRESS :	FLAGSHIP CORPORATE BRANCH,  NARIMAN POINT, MUMBAI – 400 021,INDIA
                    <br>BENEFICIARY ADVICING BANK SWIFT CODE : UCBAINBB002
                    `,
                    accountNumber: '19080210005224',
                    bankName: 'UCO BANK, FLAGSHIP CORPORATE BRANCH',
                    branch: 'NARIMAN POINT, MUMBAI 400 021, INDIA',
                    swift: 'UCBAINBB002'
                }
            },
            TT: {
                fish: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	ULKA SEA FOODS PVT. LTD.
                    <br>BENEFICIARY ACCOUNT NUMBER : 19080210005217
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY BANK NAME :	UCO BANK
                    <br>BENEFICIARY BANK ADDRESS : FLAGSHIP CORPORATE BRANCH,  NARIMAN POINT, MUMBAI – 400 021,INDIA
                    <br>BENEFICIARY BANK SWIFT CODE : UCBAINBB002
                    <br>INTERMEDIARY BANK NAME : WELL FARGO BANK, NEW YORK
                    <br>INTERMEDIARY BANK ADDRESS :	NEW YORK
                    <br>INTERMEDIARY BANK SWIFT CODE : PNBPUS3NNYC
                    <br>INTERMEDIARY BANK ACCOUNT NO : 2000191060245
                    `,
                    accountNumber: '19080210005217',
                    bankName: 'UCO BANK, FLAGSHIP CORPORATE BRANCH',
                    branch: 'NARIMAN POINT, MUMBAI 400 021, INDIA',
                    swift: 'UCBAINBB002'
                },
                surimi: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	ULKA SEA FOODS PVT. LTD.
                    <br>BENEFICIARY ACCOUNT NUMBER : 19080210005224
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY BANK NAME :	UCO BANK
                    <br>BENEFICIARY BANK ADDRESS : FLAGSHIP CORPORATE BRANCH,  NARIMAN POINT, MUMBAI – 400 021,INDIA
                    <br>BENEFICIARY BANK SWIFT CODE : UCBAINBB002
                    <br>INTERMEDIARY BANK NAME : WELL FARGO BANK, NEW YORK
                    <br>INTERMEDIARY BANK ADDRESS :	NEW YORK
                    <br>INTERMEDIARY BANK SWIFT CODE : PNBPUS3NNYC
                    <br>INTERMEDIARY BANK ACCOUNT NO : 2000191060245
                    `,
                    accountNumber: '19080210005224',
                    bankName: 'UCO BANK, FLAGSHIP CORPORATE BRANCH',
                    branch: 'NARIMAN POINT, MUMBAI 400 021, INDIA',
                    swift: 'UCBAINBB002'
                }
            }
        }
    },
    {
        approvalNumber: 1161,
        paymentTerms: {
            LC: {
                fish: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	ULKA SEA FOODS PVT. LTD.
                    <br>BENEFICIARY ACCOUNT NUMBER : 19080210005217
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY ADVICING BANK NAME : UCO BANK
                    <br>BENEFICIARY ADVICING BANK ADDRESS :	FLAGSHIP CORPORATE BRANCH,  NARIMAN POINT, MUMBAI – 400 021,INDIA
                    <br>BENEFICIARY ADVICING BANK SWIFT CODE : UCBAINBB002
                    `,
                    accountNumber: '19080210005217',
                    bankName: 'UCO BANK, FLAGSHIP CORPORATE BRANCH',
                    branch: 'NARIMAN POINT, MUMBAI 400 021, INDIA',
                    swift: 'UCBAINBB002'
                },
                surimi: ''
            },
            TT: {
                fish: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	ULKA SEA FOODS PVT. LTD.
                    <br>BENEFICIARY ACCOUNT NUMBER : 19080210005217
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY BANK NAME :	UCO BANK
                    <br>BENEFICIARY BANK ADDRESS : FLAGSHIP CORPORATE BRANCH,  NARIMAN POINT, MUMBAI – 400 021,INDIA
                    <br>BENEFICIARY BANK SWIFT CODE : UCBAINBB002
                    <br>INTERMEDIARY BANK NAME : WELL FARGO BANK, NEW YORK
                    <br>INTERMEDIARY BANK ADDRESS :	NEW YORK
                    <br>INTERMEDIARY BANK SWIFT CODE : PNBPUS3NNYC
                    <br>INTERMEDIARY BANK ACCOUNT NO : 2000191060245
                    `,
                    accountNumber: '19080210005217',
                    bankName: 'UCO BANK, FLAGSHIP CORPORATE BRANCH',
                    branch: 'NARIMAN POINT, MUMBAI 400 021, INDIA',
                    swift: 'UCBAINBB002'
                },
                surimi: ''
            }
        }
    },
    {
        approvalNumber: 1215,
        paymentTerms: {
            LC: {
                 fish: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	ULKA SEA FOODS PVT. LTD.
                    <br>BENEFICIARY ACCOUNT NUMBER : 19080210005217
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY ADVICING BANK NAME : UCO BANK
                    <br>BENEFICIARY ADVICING BANK ADDRESS :	FLAGSHIP CORPORATE BRANCH,  NARIMAN POINT, MUMBAI – 400 021,INDIA
                    <br>BENEFICIARY ADVICING BANK SWIFT CODE : UCBAINBB002
                    <br>INTERMEDIARY BANK NAME : WELL FARGO BANK, NEW YORK
                    <br>INTERMEDIARY BANK ADDRESS :	NEW YORK
                    <br>INTERMEDIARY BANK SWIFT CODE : PNBPUS3NNYC
                    <br>INTERMEDIARY BANK ACCOUNT NO : 2000191060245
                    `,
                    accountNumber: '19080210005217',
                    bankName: 'UCO BANK, FLAGSHIP CORPORATE BRANCH',
                    branch: 'NARIMAN POINT, MUMBAI 400 021, INDIA',
                    swift: 'UCBAINBB002'
                },
                surimi: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	ULKA SEA FOODS PVT. LTD.
                    <br>BENEFICIARY ACCOUNT NUMBER : 19080210005224
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY ADVICING BANK NAME : UCO BANK
                    <br>BENEFICIARY ADVICING BANK ADDRESS :	FLAGSHIP CORPORATE BRANCH,  NARIMAN POINT, MUMBAI – 400 021,INDIA
                    <br>BENEFICIARY ADVICING BANK SWIFT CODE : UCBAINBB002
                    `,
                    accountNumber: '19080210005224',
                    bankName: 'UCO BANK, FLAGSHIP CORPORATE BRANCH',
                    branch: 'NARIMAN POINT, MUMBAI 400 021, INDIA',
                    swift: 'UCBAINBB002'
                }
            },
            TT: {
                 fish: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	ULKA SEA FOODS PVT. LTD.
                    <br>BENEFICIARY ACCOUNT NUMBER : 19080210005217
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY ADVICING BANK NAME : UCO BANK
                    <br>BENEFICIARY ADVICING BANK ADDRESS :	FLAGSHIP CORPORATE BRANCH,  NARIMAN POINT, MUMBAI – 400 021,INDIA
                    <br>BENEFICIARY ADVICING BANK SWIFT CODE : UCBAINBB002
                    <br>INTERMEDIARY BANK NAME : WELL FARGO BANK, NEW YORK
                    <br>INTERMEDIARY BANK ADDRESS :	NEW YORK
                    <br>INTERMEDIARY BANK SWIFT CODE : PNBPUS3NNYC
                    <br>INTERMEDIARY BANK ACCOUNT NO : 2000191060245
                    `,
                    accountNumber: '19080210005217',
                    bankName: 'UCO BANK, FLAGSHIP CORPORATE BRANCH',
                    branch: 'NARIMAN POINT, MUMBAI 400 021, INDIA',
                    swift: 'UCBAINBB002'
                },
                surimi: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	ULKA SEA FOODS PVT. LTD.
                    <br>BENEFICIARY ACCOUNT NUMBER : 19080210005224
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY BANK NAME :	UCO BANK
                    <br>BENEFICIARY BANK ADDRESS : FLAGSHIP CORPORATE BRANCH,  NARIMAN POINT, MUMBAI – 400 021,INDIA
                    <br>BENEFICIARY BANK SWIFT CODE : UCBAINBB002
                    <br>INTERMEDIARY BANK NAME : WELL FARGO BANK, NEW YORK
                    <br>INTERMEDIARY BANK ADDRESS :	NEW YORK
                    <br>INTERMEDIARY BANK SWIFT CODE : PNBPUS3NNYC
                    <br>INTERMEDIARY BANK ACCOUNT NO : 2000191060245
                    `,
                    accountNumber: '19080210005224',
                    bankName: 'UCO BANK, FLAGSHIP CORPORATE BRANCH',
                    branch: 'NARIMAN POINT, MUMBAI 400 021, INDIA',
                    swift: 'UCBAINBB002'
                }
            }
        }
    },
    {
        approvalNumber: 1584,
        paymentTerms: {
            LC: {
                fish: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	SHREE ULKA LLP
                    <br>BENEFICIARY ACCOUNT NUMBER : 922020033033874
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY ADVICING BANK NAME : AXIS BANK
                    <br>BENEFICIARY ADVICING BANK ADDRESS :	ELLORA, COMMERCIAL UNIT 1-4, PLOT NO.27, SECTOR 11,CBD BELAPUR, NAVI MUMBAI-400614, MAHARASHTRA, INDIA
                    <br>BENEFICIARY ADVICING BANK SWIFT CODE : AXISINBBA32
                    `,
                    accountNumber: '922020033033874',
                    bankName: 'AXIS BANK',
                    branch: 'CBD BELAPUR, NAVI MUMBAI-400614, MAHARASHTRA, INDIA',
                    swift: 'AXISINBBA32'
                },
                surimi: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	SHREE ULKA LLP
                    <br>BENEFICIARY ACCOUNT NUMBER : 922020033033874
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY ADVICING BANK NAME : AXIS BANK
                    <br>BENEFICIARY ADVICING BANK ADDRESS :	ELLORA, COMMERCIAL UNIT 1-4, PLOT NO.27, SECTOR 11,CBD BELAPUR, NAVI , MUMBAI-400614, MAHARASHTRA, INDIA
                    <br>BENEFICIARY ADVICING BANK SWIFT CODE : AXISINBBA32
                    `,
                    accountNumber: '922020033033874',
                    bankName: 'AXIS BANK',
                    branch: 'CBD BELAPUR, NAVI MUMBAI-400614, MAHARASHTRA, INDIA',
                    swift: 'AXISINBBA32'
                }
            },
            TT: {
                fish: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	SHREE ULKA LLP
                    <br>BENEFICIARY ACCOUNT NUMBER : 922020033033874
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY BANK NAME :	AXIS BANK
                    <br>BENEFICIARY BANK ADDRESS : ELLORA, COMMERCIAL UNIT 1-4, PLOT NO.27, SECTOR 11,CBD BELAPUR, NAVI , MUMBAI-400614, MAHARASHTRA, INDIA
                    <br>BENEFICIARY BANK SWIFT CODE : AXISINBBA32
                    <br>INTERMEDIARY BANK NAME : AXIS BANK
                    <br>INTERMEDIARY BANK ADDRESS :CBD BELAPUR, NAVI , MUMBAI-400614, MAHARASHTRA, INDIA
                    <br>INTERMEDIARY BANK SWIFT CODE : AXISINBBA32
                    <br>INTERMEDIARY BANK ACCOUNT NO : 922020033033874
                    `,
                    accountNumber: '922020033033874',
                    bankName: 'AXIS BANK',
                    branch: ',CBD BELAPUR, NAVI , MUMBAI-400614, MAHARASHTRA, INDIA',
                    swift: 'AXISINBBA32'
                },
                surimi: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	SHREE ULKA LLP
                    <br>BENEFICIARY ACCOUNT NUMBER : 922020033033874
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY BANK NAME :	AXIS BANK
                    <br>BENEFICIARY BANK ADDRESS : ELLORA, COMMERCIAL UNIT 1-4, PLOT NO.27, SECTOR 11,CBD BELAPUR, NAVI , MUMBAI-400614, MAHARASHTRA, INDIA
                    <br>BENEFICIARY BANK SWIFT CODE : AXISINBBA32
                    <br>INTERMEDIARY BANK NAME : AXIS BANK
                    <br>INTERMEDIARY BANK ADDRESS :CBD BELAPUR, NAVI , MUMBAI-400614, MAHARASHTRA, INDIA
                    <br>INTERMEDIARY BANK SWIFT CODE : AXISINBBA32
                    <br>INTERMEDIARY BANK ACCOUNT NO : 922020033033874
                    `,
                    accountNumber: '922020033033874',
                    bankName: 'AXIS BANK',
                    branch: ',CBD BELAPUR, NAVI , MUMBAI-400614, MAHARASHTRA, INDIA',
                    swift: 'AXISINBBA32'
                }
            }
        }
    },
    {
        approvalNumber: 222,
        paymentTerms: {
            LC: {
                fish: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	ULKA SEA FOODS PVT. LTD.
                    <br>BENEFICIARY ACCOUNT NUMBER : 19080210005231
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY ADVICING BANK NAME : UCO BANK
                    <br>BENEFICIARY ADVICING BANK ADDRESS :	FLAGSHIP CORPORATE BRANCH,  NARIMAN POINT, MUMBAI – 400 021,INDIA
                    <br>BENEFICIARY ADVICING BANK SWIFT CODE : UCBAINBB002
                    `,
                    accountNumber: '19080210005231',
                    bankName: 'UCO BANK, FLAGSHIP CORPORATE BRANCH',
                    branch: 'NARIMAN POINT, MUMBAI 400 021, INDIA',
                    swift: 'UCBAINBB002'
                },
                surimi: ''
            },
            TT: {
                fish: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	ULKA SEA FOODS PVT. LTD.
                    <br>BENEFICIARY ACCOUNT NUMBER : 19080210005231
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY BANK NAME :	UCO BANK
                    <br>BENEFICIARY BANK ADDRESS : FLAGSHIP CORPORATE BRANCH,  NARIMAN POINT, MUMBAI – 400 021,INDIA
                    <br>BENEFICIARY BANK SWIFT CODE : UCBAINBB002
                    <br>INTERMEDIARY BANK NAME : WELL FARGO BANK, NEW YORK
                    <br>INTERMEDIARY BANK ADDRESS :	NEW YORK
                    <br>INTERMEDIARY BANK SWIFT CODE : PNBPUS3NNYC
                    <br>INTERMEDIARY BANK ACCOUNT NO : 2000191060245
                    `,
                    accountNumber: '19080210005231',
                    bankName: 'UCO BANK, FLAGSHIP CORPORATE BRANCH',
                    branch: 'NARIMAN POINT, MUMBAI 400 021, INDIA',
                    swift: 'UCBAINBB002'
                },
                surimi: ''
            }
        }
    },
    {
        approvalNumber: "1584_INR",
        paymentTerms: {
            LC: {
                fish: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	SHREE ULKA LLP
                    <br>BENEFICIARY ACCOUNT NUMBER : 922020033033874
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY ADVICING BANK NAME : UCO BANK
                    <br>BENEFICIARY ADVICING BANK ADDRESS :	ELLORA, COMMERCIAL UNIT 1-4, PLOT NO.27, SECTOR 11,CBD BELAPUR, NAVI MUMBAI-400614, MAHARASHTRA, INDIA
                    <br>BENEFICIARY ADVICING BANK SWIFT CODE : AXISINBBA32
                    `,
                    accountNumber: '922020033033874',
                    bankName: 'UCO BANK',
                    branch: 'CBD BELAPUR, NAVI MUMBAI-400614, MAHARASHTRA, INDIA',
                    swift: 'AXISINBBA32'
                },
                surimi: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	SHREE ULKA LLP
                    <br>BENEFICIARY ACCOUNT NUMBER : 922020033033874
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY ADVICING BANK NAME : UCO BANK
                    <br>BENEFICIARY ADVICING BANK ADDRESS :	ELLORA, COMMERCIAL UNIT 1-4, PLOT NO.27, SECTOR 11,CBD BELAPUR, NAVI , MUMBAI-400614, MAHARASHTRA, INDIA
                    <br>BENEFICIARY ADVICING BANK SWIFT CODE : AXISINBBA32
                    `,
                    accountNumber: '922020033033874',
                    bankName: 'UCO BANK',
                    branch: 'CBD BELAPUR, NAVI MUMBAI-400614, MAHARASHTRA, INDIA',
                    swift: 'AXISINBBA32'
                }
            },
            TT: {
                fish: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	SHREE ULKA LLP
                    <br>BENEFICIARY ACCOUNT NUMBER : 05290210002674
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY BANK NAME :	UCO BANK
                    <br>BENEFICIARY BANK ADDRESS : GROUND FLOOR, YWCA BUILDING,NH-18, MADAMECAME RD, NARIMAN POINT ,MUMBAI,MAHARASHTRA 400039
                    <br>BENEFICIARY BANK SWIFT CODE : UCBAINBB002
                    <br>INTERMEDIARY BANK NAME : GAZPROMBANK, RUCCIA
                    <br>INTERMEDIARY BANK ADDRESS :
                    <br>INTERMEDIARY BANK SWIFT CODE : GAZPRUMM666
                    <br>INTERMEDIARY BANK ACCOUNT NO :
                    `,
                    accountNumber: '05290210002674',
                    bankName: 'UCO BANK',
                    branch: 'NARIMAN POINT ,MUMBAI,MAHARASHTRA 400039',
                    swift: 'UCBAINBB002'
                },
                surimi: {
                    details: `BANK DETAILS :<br>BENEFICIARY NAME :	SHREE ULKA LLP
                    <br>BENEFICIARY ACCOUNT NUMBER : 05290210002674
                    <br>BENEFICIARY ADDRESS : MUMBAI, INDIA
                    <br>BENEFICIARY BANK NAME :	UCO BANK
                    <br>BENEFICIARY BANK ADDRESS : GROUND FLOOR, YWCA BUILDING,NH-18, MADAMECAME RD, NARIMAN POINT ,MUMBAI,MAHARASHTRA 400039
                    <br>BENEFICIARY BANK SWIFT CODE : UCBAINBB002
                    <br>INTERMEDIARY BANK NAME : GAZPROMBANK, RUCCIA
                    <br>INTERMEDIARY BANK ADDRESS :
                    <br>INTERMEDIARY BANK SWIFT CODE : GAZPRUMM666
                    <br>INTERMEDIARY BANK ACCOUNT NO :
                    `,
                    accountNumber: '05290210002674',
                    bankName: 'UCO BANK',
                    branch: 'NARIMAN POINT ,MUMBAI,MAHARASHTRA 400039',
                    swift: 'UCBAINBB002'
                }
            }
        }
    },
   
]

export const commissionBeneficiaries = [
    {
        id: 'Akash',
        name: 'Akash',
        address: 'Address',
    }
]
