import { Component } from '@angular/core';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { HttpProviderService } from '../../services/http-provider.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent {

    searchVisible: boolean = false;
    quickViewVisible: boolean = false;
    isFolded: boolean;
    isExpand: boolean;
    public user: any
    loading = false
    constructor(private themeService: ThemeConstantService, public hps: HttpProviderService, public router: Router, public gs: GlobalService) {

    }

    ngOnInit(): void {
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.user = this.gs.currentUser


    }
    refresh() {
        this.loading=true
        this.gs.getMasters('refresh')
        this.loading=false
    }

    async signOut() {
        try {
            await this.hps.post('idm', 'user', 'sign-out', {});
            localStorage.clear()
            this.router.navigateByUrl('/full/login');
        } catch (error) {
            localStorage.clear()
            this.router.navigateByUrl('/full/login');
            console.log(error);
        }
    }

    toggleFold() {
        this.isFolded = !this.isFolded;
        this.themeService.toggleFold(this.isFolded);
    }

    toggleExpand() {
        this.isFolded = false;
        this.isExpand = !this.isExpand;
        this.themeService.toggleExpand(this.isExpand);
        this.themeService.toggleFold(this.isFolded);
    }

    searchToggle(): void {
        this.searchVisible = !this.searchVisible;
    }

    quickViewToggle(): void {
        this.quickViewVisible = !this.quickViewVisible;
    }

    notificationList = [
        {
            title: 'You received a new message',
            time: '8 min',
            icon: 'mail',
            color: 'ant-avatar-' + 'blue'
        },
        {
            title: 'New user registered',
            time: '7 hours',
            icon: 'user-add',
            color: 'ant-avatar-' + 'cyan'
        },
        {
            title: 'System Alert',
            time: '8 hours',
            icon: 'warning',
            color: 'ant-avatar-' + 'red'
        },
        {
            title: 'You have a new update',
            time: '2 days',
            icon: 'sync',
            color: 'ant-avatar-' + 'gold'
        }
    ];
}
