import { Component, OnInit, forwardRef, Input, Output, EventEmitter, Optional, Host, Self, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, ControlContainer, FormControl, AbstractControl, NgControl } from '@angular/forms';

const noop = () => {
};

@Component({
  selector: 'm-ngselect',
  templateUrl: './m-ngselect.component.html',
  styleUrls: ['./m-ngselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MNgselectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: MNgselectComponent,
      multi: true
    }
  ]
})
export class MNgselectComponent implements OnInit, ControlValueAccessor {

  @Input() name: string;
  @Input() val: string;

  @Input() label;
  @Input() labelSmall;

  @Input() bindLabel;
  @Input() bindValue;

  @Input() errorMsg;
  @Input() clearable = true;

  @Input() placeholder = "";

  @Input() searchable = false;

  @Input() multiple = false;

  @Input() hideSelected = false;

  @Input()
  items = [];

  inputType = "text";
  private _type;
  get type(): any {
    return this._type;

  }
  @Input() set type(val: any) {
    this._type = val;
    // this.inputType 
  }

  // Both onChange and onTouched are functions
  public onValChange: (_: any) => void = () => noop;
  public onValTouched: () => void = () => noop;

  @Output()
  onBlur: EventEmitter<any> = new EventEmitter();

  @Output()
  onChange: EventEmitter<any> = new EventEmitter();

  @Output()
  onTouched: EventEmitter<any> = new EventEmitter();

  @Input() formControlName: string;
  public control: AbstractControl;

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange.emit(val);
    this.onValChange(val);
    this.onValTouched();
  }

  constructor(private injector: Injector) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      setTimeout(() => {
        this.control = ngControl.control as FormControl;
        this.addValidators();
      });
    } else {
      // Component is missing form control binding
        console.warn('Missing FormControlName directive from host element of the component');
    }
  }

  addValidators() {
    this.control.updateValueAndValidity();
  }

  validate({ value }: FormControl) {
    return null;
  }

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onValChange = fn;
  }
  // We implement this method to keep a reference to the onTouched
  //callback function passed by the forms API
  registerOnTouched(fn) {
    this.onValTouched = fn;
  }
  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

}

