import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceStr'
})
export class ReplaceStrPipe implements PipeTransform {

  transform(value: string, strToReplace: string, replacementStr: string): any {
    let regex = new RegExp(strToReplace, 'g');
    return value.toString().replace(regex, replacementStr);


  }

}
