import * as moment from "moment";
import { ValidatorFn, FormGroup, FormControl, AbstractControl } from "@angular/forms";

export function validateMinDate(): ValidatorFn {
  return (c: AbstractControl) => {
    if (c) {
      let isValid = c.get('to').value >= c.get('from').value;
      if (isValid) {
        return null;
      } else {
        return { validateMinDate: true };
      }
    }
  };
}

export const divisbleBy10 = (control: any) => {
  if (!control.value) {
    return null;
  }
  let value: any = control.value;
  // if (value.indexOf('@mesbro.com') > 0) {
  //   let parts = value.split('@mesbro.com');
  //   value = parts[0];
  // }
  return ((value) % 10 == 0) ? null : {
    divisibleBy10: true
  };
}

export const validUsername = (control: any) => {
  if (!control.value) {
    return null;
  }
  let value: string = control.value;
  // if (value.indexOf('@mesbro.com') > 0) {
  //   let parts = value.split('@mesbro.com');
  //   value = parts[0];
  // }
  return (value && (value.match(/^(?!\.)(?!\_)(?!.*\.$)(?!.*\_$)(?!.*?\.\.)(?!.*?[_]{2})[a-zA-Z0-9_.]+$/) && !(value.split('.').length > 2) && !(value.split('_').length > 2))) ? null : {
    invalidUsername: true
  };
}

export const validateCIN = (control: any) => {
  if (!control.value) {
    return null;
  }
  if (!control.value || !control.value.match(/^[a-zA-Z0-9]*$/)) {
    return {
      invalidCIN: true
    };
  }
}

export const validateAddress = (control: any) => {
  if (!control.value) {
    return null;
  }
  if (!control.value || !control.value.match(/^[a-zA-Z0-9, /.-]*$/)) {
    return {
      invalidAddress: true
    };
  }
}

export const minLength = (length: number): ValidatorFn => {
  return (control: FormControl) => {
    if (!control.value || typeof control.value === 'string' && control.value.trim().length < length) {
      return {
        minlength: true
      };
    }

    return null;
  };
}

export const maxLength = (length: number): ValidatorFn => {
  return (control: FormControl) => {
    if (control.value && typeof control.value === 'string' && control.value.trim().length > length) {
      return {
        maxlength: true
      };
    }

    return null;
  };
}

export const trimValidation = (control: any) => {
  if (!control.value) {
    return null;
  }

  return (control.value.trim() !== '') ? null : {
    invalidTrimmedString: true
  };
}

// public onCityAdding(tag: TagModel): Observable<TagModel> {
//   // const confirm = window.confirm('Do you really want to add this tag?');
//   // console.log(tag);
//   return Observable
//     .of(tag)
//     .filter((item) => item && item['city'] && item['city'].match(/^[a-zA-Z ]*$/));
// }

export const validateChipString = (control: FormControl) => {
  // console.log(control.value, control.value.match(/^[a-zA-Z ]*$/));

  return new Promise(resolve => {
    if (!control.value || !control.value.match(/^[a-zA-Z ]*$/)) {
      resolve({
        'invalidString': true
      });
    } else {
      resolve(null);
    }
  })



  // return null;
}
export const validStringWithNumber = (control: any) => {

  if (!control.value) {
    return null;
  }

  return (control.value && control.value.match(/^[a-zA-Z0-9 ]*$/)) ? null : {
    invalidStringWithNumber: true
  };
}

export const validStringWithPeriod = (control: any) => {

  if (!control.value) {
    return null;
  }

  return (control.value && control.value.match(/^[a-zA-Z. ]*$/)) ? null : {
    invalidString: true
  };
}

export const checkSpaces = (control: any) => {
  let noMoreSpaces = true
  if (!control.value) {
    return null;
  }
  if (control.value && control.value.split(' ').length > 2) {
    noMoreSpaces = false;
  } else {
    noMoreSpaces = true;
  }
  return (control.value && noMoreSpaces) ? null : {
    invalidcheckSpaces: true
  };
}

export const validStringWithNumberPeriodAndHifen = (control: any) => {

  if (!control.value) {
    return null;
  }

  return (control.value && control.value.match(/^[a-zA-Z0-9-. ]*$/)) ? null : {
    invalidStringWithNumberPeriodAndHifen: true
  };
}

export const validStringWithHifen = (control: any) => {

  if (!control.value) {
    return null;
  }

  return (control.value && control.value.match(/^[a-zA-Z-. ]*$/)) ? null : {
    invalidStringWithHifen: true
  };
}

export const validString = (control: any) => {

  if (!control.value) {
    return null;
  }

  return (control.value && control.value.match(/^[a-zA-Z ]*$/)) ? null : {
    invalidString: true
  };
}

export const noConsecucutiveSpaces = (control: any) => {
  if (!control.value) {
    return null;
  }

  return (control.value && !control.value.match(/\s\s/)) ? null : {
    invalidnoConsecucutiveSpaces: true
  };
}

export const confirmPassword = (c: FormControl): any => {
  if (c.get('password').value !== c.get('confirm_password').value) {
    return { invalid: true };
  }
  return null;
}

export const validNumberWithSpaces = (control: any) => {
  if (!control.value || typeof control.value != 'string') {
    return null;
  }
  let exp = control.value && control.value.match(/^[0-9 ]+$/);
  return exp ? null : {
    invalidNumberWithSpaces: true
  };
}

// export const validCallingPin= (control: any) => {
//   if (!control.value || typeof control.value != 'string') {
//     return null;
//   }
//     let exp = control.value && control.value.match(/^[0-9]{4,4}$/);
//     return exp ? null : {
//       validCallingPin: {
//         invalid: true
//       }
//     }
// }


export const validCallingPin = (control: any) => {
  // return true;
  console.log(control.value);
  if (!control.value || typeof control.value != 'string') {
    return null;
  }
  let exp = control.value && control.value.match(/^[0-9]{4}$/);
  return exp ? null : {
    invalidCallingPin: true
  };
}

export const validDunsNumber = (control: any) => {
  // return true;
  console.log(control.value);
  if (!control.value || typeof control.value != 'string') {
    return null;
  }
  let exp = control.value && control.value.match(/^[0-9]{9}$/);
  return exp ? null : {
    invalidDunsNumber: true
  };
}

export const validMobile = (control: any) => {
  if (!control.value || typeof control.value != 'string') {
    return null;
  }
  let exp = control.value && control.value.match(/^[0-9]{4,13}$/);
  return exp ? null : {
    invalidMobile: true
  };
}

export const validTagsWithNoSpecialChar = (control: any) => {
  if (!control.value) {
    return null;
  }
  let exp = control.value.filter((val, i) => {
    return val.match(/[^A-Za-z0-9]+/);
  }).length;
  return !exp ? null : {
    invalidTagsWithNoSpecialChar: true
  };
}

export const validOtp = (control: any) => {
  if (!control.value || typeof control.value != 'string') {
    return null;
  }
  let exp = control.value && control.value.match(/^[0-9]{6,6}$/);
  return exp ? null : {
    invalidOtp: true
  };
}

export const validPinCode = (control: any) => {
  if (!control.value || typeof control.value != 'string') {
    return null;
  }
  let exp = control.value && control.value.match(/^[a-zA-Z0-9 -]{5,12}$/);
  return exp ? null : {
    invalidPinCode: true
  };
}

export const validLatitudeLongitude = (control: any) => {
  if (!control.value || typeof control.value != 'string') {
    return null;
  }
  let exp = control.value && control.value.match(/^-?[1-9]\d*(\.\d+)?$/);
  return exp ? null : {
    invalidLatitudeLongitude: true
  };
}

export const numberWithDecimal = (control: any) => {
  /^\d*\.?\d*$/
  if (!control.value || typeof control.value != 'string') {
    return null;
  }
  let exp = control.value && control.value.match(/^\d*\.?\d*$/);
  return exp ? null : {
    invalidNumberWithDecimal: true
  };
}

export const validGSTNumber = (control: any) => {
  if (!control.value || typeof control.value != 'string') {
    return null;
  }
  let exp = control.value && control.value.match(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);
  return exp ? null : {
    invalidGSTNumber: true
  };
}

export const validBarCode = (control: any) => {
  if (!control.value || typeof control.value != "string") {
    return null;
  }
  let exp = control.value && control.value.match(/^[a-zA-Z0-9]{18}$/);
  return exp ? null : {
    invalidBarCode: true
  }
}


export const validateLandline = () => {

}

export const validateDateRange = (value: any, min, max) => {
  if (!value) {
    return false;
  }

  if (min && max) {
    return moment(value).isBetween(min, max);
  } else if (min) {
    return moment(value).isSameOrAfter(min);
  } else if (max) {
    return moment(value).isSameOrAfter(max);
  }
}

export const validateDOB = (control: any) => {
  if (!control.value) {
    return null;
  }
  // console.log(control.value, moment(control.value).isValid(), !isNaN(control.value),  moment(control.value).isAfter(moment().subtract(18, 'years')))
  return (control.value && moment(control.value).isValid() && !isNaN(control.value) && !moment(control.value).isAfter(moment().subtract(18, 'years'))) ? null : {
    invalidateDOB: true
  };
}

export const validDate = (control: any) => {
  if (!control.value) {
    return null;
  }

  return (control.value && moment(control.value).isValid() && !isNaN(control.value)) ? null : {
    invalidDate: true
  };


  // console.log(control.value instanceof Date && !isNaN(control.value));

  // if(typeof control.value == 'object'){

  // }
  // return control.value.match(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/) ? null : {
  //   validateDate: {
  //     invalid: true
  //   }
  // };
  return null
}

export const validateEmail = (control: any) => {
  if (control.value && control.value.address) {

    return control.value.address.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? null : {
      invalidateEmail: true
    };
  } else if (control.value && control.value.match) {
    return control.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? null : {
      invalidateEmail: true
    };
  } else {
    return null;
  }
}

export const validateEmailString = (str: any) => {
  return str.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

// Old Validator Function

// validateWebsite= (control: any) => {
//   if (!control.value) {
//     return null;
//   }
//   return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(control.value) ? null : {
//     validateWebsite: {
//       invalid: true
//     }
//   };

// }

// New Validator Function

export const validateWebsite = (control: any) => {
  if (!control.value) {
    return null;
  }
  return /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/i.test(control.value) ? null : {
    invalidateWebsite: true
  };

}

export const validTollFreeNumber = (control: any) => {
  if (!control.value || typeof control.value != "string") {
    return null;
  }
  let exp = control.value && control.value.match(/^[0-9- ]*$/);
  return exp ? null : {
    invalidTollFreeNumber: true
  }
}

export const validSKU = (control: any) => {
  if (!control.value || typeof control.value != "string") {
    return null;
  }
  let exp = control.value && control.value.match(/^[a-zA-Z0-9]{8,12}$/);
  return exp ? null : {
    invalidTollFreeNumber: true
  }
}

export const validInteger = (control: any) => {
  if (!control.value || typeof control.value != "string") {
    return null;
  }
  let exp = control.value && control.value.match(/^\-?[0-9]*$/);
  return exp ? null : {
    invalidInteger: true
  }
}

export const validFloat = (control: any) => {
  if (!control.value || typeof control.value != "string") {
    return null;
  }
  let exp = control.value && control.value.match(/^\-?[0-9]*\.?[0-9]*$/);
  return exp ? null : {
    invalidFloat: true
  }
}

export const validStringWithSpecialCharacters = (control: any) => {
  if (!control.value || typeof control.value != "string") {
    return null;
  }
  let exp = control.value && control.value.match(/^[a-zA-Z0-9 !@#£€¥\$\?%\^\&*\)\(\.\]\[,:;°_-]+$/);
  return exp ? null : {
    invalidStringWithSpecialCharacters: true
  }
}

export const onlyAlphabets = (control: any) => {
  if (!control.value || typeof control.value != "string") {
    return null;
  }
  let exp = control.value && control.value.match(/^[a-zA-Z ]*$/);
  return exp ? null : {
    invalidonlyAlphabets: true
  }
}




export const validTitle = (control: any) => {
  if (!control.value || typeof control.value != "string") {
    return null;
  }
  let exp = control.value && control.value.match("^[^{}_<>|#\\]\\[]+$");
  return exp ? null : {
    invalidTitle: true
  }
}

export const validCity = (control: any) => {
  if (!control.value || typeof control.value != "string") {
    return null;
  }
  let exp = control.value && control.value.match(/^[a-zA-Z0-9 \\-\\.\\'\\,]$/);
  return exp ? null : {
    invalidCity: true
  }
}

export const checkIfEndDateAfterStartDate = (start: string = 'from', end: string = 'to'): ValidatorFn => {

  return (control: FormGroup) => {
    if (!control.value[start] || !control.value[end]) {
      return null;
    }
    let exp = new Date(control.value[start]).setHours(0, 0, 0, 0) >= new Date(control.value[end]).setHours(0, 0, 0, 0);
    if (exp) {
      return {
        invalidcheckIfEndDateAfterStartDate: true
      }
    }
  };
}

