import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../services/global.service';

@Pipe({
  name: 'checkFormArrayLength'
})
export class FormArrayLengthPipe implements PipeTransform {

  constructor(public gs: GlobalService) {

  }

  transform(form: any, controls: string[]): any {

    if (!form || !controls) {
      return false;
    }

    else {
      let currentValue = this.gs.getControlValue(form, controls);
      
      if (currentValue.length > 1) {
        return true;
      } else {
        return false;
      }
    }
  }

}
