import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'printFromArray'
})
export class PrintFromArrayPipe implements PipeTransform {

  transform(array: any[], keys: any[], fetchKey: any): any {
    //Example array : ['containers'] : 'containerNumber' 
    //Handled only for first level and one key at the moment

    let returnString = '';

    if(!array){
      return returnString 
    }

    array.forEach((element,arrayIndex) => {

      if (keys.length) {
        keys.forEach((key, index) => {
          let element2 = element[key];
          if (index == keys.length - 1) {
            if(element2[fetchKey]){
              returnString +=  element2[fetchKey];
              if(index != keys.length-1){
                returnString += `<br>` 
              }
            }
   
          }
        })
      } else {
        //Only this part works as of now.

        if(element[fetchKey]){
          returnString +=  element[fetchKey];
          if(arrayIndex != array.length-1){
            returnString += `<br>` 
          }
        }
      }


    })

    return returnString;
  }

}
