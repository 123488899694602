import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSelectorComponent } from './file-selector/file-selector.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FileTransferComponent } from './file-transfer/file-transfer.component';
import { Route } from '@angular/compiler/src/core';
import { Routes, RouterModule } from '@angular/router';
import { FileManagerComponent } from './file-manager.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MesbroAttachmentComponent } from 'src/app/modules/shared/components/mesbro-attachment/mesbro-attachment.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSmartModalModule } from 'ngx-smart-modal';
// import { BrowserModule } from '@angular/platform-browser';
import { NgxUploaderModule } from 'ngx-uploader';


export const ROUTES: Routes = [

  {
    path: '',
    component: FileManagerComponent,
    data: { title: 'File Manager' },
    children: [
      // {
      //   path: '',
      //   redirectTo: 'file-transfer'
      // },
      {
        path: 'file-transfer',
        component: FileTransferComponent
      },
      {
        path: 'file-transfer/:id',
        component: FileTransferComponent
      }
    ]
  }
]


@NgModule({
  declarations: [FileManagerComponent, FileTransferComponent, FileSelectorComponent, MesbroAttachmentComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ImageCropperModule,
    ReactiveFormsModule,
    // RouterModule.forChild(ROUTES),
    NgSelectModule,
    NgxSmartModalModule,
    NgxUploaderModule
    // SharedModule
  ],
  exports: [
    FileSelectorComponent
  ]
})
export class FileManagerModule { }
