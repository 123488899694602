import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ContentChildren, QueryList, ViewChild, ElementRef, ContentChild, Renderer, HostListener, RendererFactory2, Renderer2 } from '@angular/core';
import { UploaderOptions, UploadFile, UploadInput, humanizeBytes, UploadOutput, UploadStatus } from 'ngx-uploader';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { FileManagerService } from '../file-manager.service';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/modules/shared/services/global.service';


@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileSelectorComponent implements OnInit {

  private renderer: Renderer2;

  @ContentChild('target', { static: true }) targetButton: ElementRef;
  @ViewChild('fileManagerModal', { static: true }) fileManagerModal: NgxSmartModalComponent;
  @ViewChild('nativeImageSelect', { static: true }) nativeImageSelect: HTMLInputElement;
  @ViewChild('uploadInputElement', { static: true }) uploadInputElement: HTMLInputElement;

  fileSelections = {};
  public singleFileEvent;
  public singleFileCropPositionChanged: boolean = false;

  filesToUpload = {};

  env = environment;

  @Input()
  cropAspectRatio = 1 / 1;

  @Input()
  crop: boolean = false;

  @Output()
  onFilesSelected = new EventEmitter();

  @Input()
  multiple: boolean = false;

  @Input()
  selectedFiles: Array<string> = [];

  @Input()
  selectedAttachmentFiles: Array<any> = [];

  uploadedFiles: Array<any> = [];

  @Input()
  returnType: 'url' | 'attachment' | 'file-signature';

  @Input()
  fileType: 'video' | 'image' | 'pdf' | 'doc' | 'general' | '' = '';

  serverFileTypes = {
    "": "*/*, .*",
    "image": "image/png,image/jpeg,image/jpg",
    "video": "video/mp4,video/x-m4v,video/x-flv,video/x-msvideo,video/3gpp2,video/3gpp,video/*",
    "pdf": "application/pdf",
    "doc": "application/pdf,application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,",
    "general": "*/*, .*"
  }

  imageChangedEvent: any = '';
  base64Image: any = '';
  croppedImage: any = '';

  options: UploaderOptions;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  activeTab = 'all';

  fileTokens = {

  };

  fileUrls = {

  };

  latestFileName = "";

  searchString;
  searchByMEString;
  searchWithYouString;

  fileInterval;

  @HostListener('click') onClick(btn) {

  }

  constructor(public gs: GlobalService, public fs: FileManagerService, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.files = []; // local uploading files array
    this.options = { concurrency: 1 };
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      console.log(this.serverFileTypes[this.fileType].split(","));
      if (this.fileType != "" && this.fileType != "general") {
        this.options = { concurrency: 2, allowedContentTypes: this.serverFileTypes[this.fileType].split(",") };
      } else {
        this.options = { concurrency: 2 };
      }
    });
  }


  isProcessing: boolean = false;

  ngOnInit() {

    // console.log(this.crop, this.returnType, this.targetButton);

    if (this.targetButton) {

      this.renderer.listen(this.targetButton.nativeElement, 'click', (event) => {
        this.fileManagerModal.open();
        // this.activeTab = this.fileType;
        this.activeTab = "all";
        this.getFiles();

        if (this.nativeImageSelect) {
          this.nativeImageSelect.value = null;
          this.clearSelection();
        }
      });
    } else {
      console.error("Please add #target reference to child, which should be clicked to open selector");
    }
  }

  ngOnDestroy() {
    if (this.fileInterval) {
      clearInterval(this.fileInterval);
    }
  }

  getFiles() {
    console.log("type is", this.fileType);
    this.fs.getAllFiles(null, this.fileType);
    if (this.fileType != 'video') {
      // this.fs.getAllFilesSharedByMe(null, this.fileType);
      // this.fs.getAllFilesSharedWithMe(null, this.fileType);
    }
  }

  getSelectionSize() {
    return Object.keys(this.fileSelections).filter((key) => this.fileSelections[key]).length;
  }

  changeImage(imageInput: HTMLInputElement) {
    imageInput.value = null;
    imageInput.click();
  }

  clearGlitches() {
    if (this.uploadInputElement) {
      this.uploadInputElement.value = null;
    }
    setTimeout(() => {
      document.querySelector('body').classList.remove('dialog-open');
    });
  }

  selectFile(file) {
    // this.fileSelections[file.basic._id] = !this.fileSelections[file.basic._id];
    console.log(this.multiple, this.crop, file)
    if (this.multiple) {
      this.fileSelections[file.basic._id] = !this.fileSelections[file.basic._id];
      console.log(this.fileSelections)
    } else {

      if (this.crop) {
        this.isProcessing = true;
        this.fileSelections = {
          [file.basic._id]: !this.fileSelections[file.basic._id]
        }

        if (this.fileSelections[file.basic._id]) {
          this.singleFileEvent = null;
          fetch(this.env.IMAGE_URL + file.basic.accessUrl).then(r => r.blob()).then(blob => {
            var reader = new FileReader();
            reader.onload = () => {
              this.singleFileEvent = null;
              // console.log(reader.result);
              var b64 = (<any>reader.result).replace(/^data:.+;base64,/, '');
              // console.log(b64);
              // console.log(`${b64.slice(0,20)}...${b64.slice(-20)}`);
              this.base64Image = "data:image/png;base64," + b64;
              this.activeTab = 'upload';
              this.isProcessing = false;
            };
            reader.readAsDataURL(blob);
          }).catch((err) => {
            this.singleFileEvent = null;
            this.isProcessing = false;
          });

          // let image: any = document.getElementById("b64-placeholder");
          // // let file = this.fs.allFiles.concat(this.fs.sharedByMeFiles).concat(this.fs.sharedWithMeFiles).find((f) => f.id == file.basic._id);

          // // image.crossOrigin = 'anonymous';
          // image.src = this.env.STORAGE_URL+file.accessUrl;

          // image.onload = (event) => {
          //   this.base64Image = this.getBase64Image(image);
          //   this.activeTab = 'upload';
          // };

          // this.fs.fileManagerRequests.http.get(this.env.STORAGE_URL_SECURED+file.accessUrl, {
          //   responseType: 'arraybuffer'
          // }).toPromise()
          // .then((response) => {
          //   var arr = new Uint8Array(response);
          //   var raw = String.fromCharCode.apply(null,arr);
          //   var b64 = btoa(raw);
          //   // var dataURL="data:image/png;base64," + b64;
          //   this.base64Image = b64;
          //   this.activeTab = 'upload';
          // })
          // .catch((error) => {
          //   console.log(error);
          // });

          // var xmlHTTP = new XMLHttpRequest();
          // xmlHTTP.open('GET', this.env.STORAGE_URL+file.accessUrl, true);
          // xmlHTTP.responseType = 'arraybuffer';
          // xmlHTTP.onload = (e) => {
          //   var arr = new Uint8Array(xmlHTTP.response);
          //   var raw = String.fromCharCode.apply(null,arr);
          //   var b64 = btoa(raw);
          //   // var dataURL="data:image/png;base64," + b64;
          //   this.base64Image = b64;
          //   this.activeTab = 'upload';
          // };
          // xmlHTTP.send();
        } else {
          this.isProcessing = false;
        }


      } else {
        this.fileSelections = {
          [file.basic._id]: !this.fileSelections[file.basic._id]
        }
        this.isProcessing = false;
      }
    }
  }

  selectUploadFiles(index) {

  }

  getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  onVideoUploadResponse(videoResponse) {
    this.fileManagerModal.close();
    this.onFilesSelected.emit(videoResponse);
  }

  onSumbitList() {
    if((this.files && this.files.length) && (!this.crop || this.multiple || this.fileType=='general')) {
      this.uploadAll();
    } else {
      this.onSubmit(this.fileManagerModal);
    }
  }

  onSubmit(modal: NgxSmartModalComponent) {
    console.log("on Submit");
    modal.close();



    if (this.uploadInputElement) {
      this.uploadInputElement.value = null;
    }
    // document.body.className = document.body.className.replace("dialog-open","");
    // setTimeout(() => {
    //   document.querySelector('body').classList.remove('dialog-open');
    // });
    if (this.returnType == 'url') {
      let files = this.fs.allFiles.concat(this.fs.sharedByMeFiles).concat(this.fs.sharedWithMeFiles);
      // console.log(files, this.uploadedFiles, this.fileUrls)
      // console.log('-----------------------------------------------------------------------------')
      // console.log(files)
      this.selectedFiles = files.filter((f) => this.fileSelections[f.basic._id]).concat(this.uploadedFiles).map((file) => (file.basic? file.basic.accessUrl : file.accessUrl));
      this.onFilesSelected.emit(this.selectedFiles);
      this.clearSelection();
    } else if (this.returnType == 'attachment') {
      let files = this.fs.allFiles.concat(this.fs.sharedByMeFiles).concat(this.fs.sharedWithMeFiles);
      // console.log(files);
      this.selectedAttachmentFiles = files.filter((f) => this.fileSelections[f.basic._id]).concat(this.uploadedFiles).map((file) => {

        return {
          fileName: file.basic.fileName,
          path: environment.STORAGE_URL + (file.basic? file.basic.accessUrl : file.accessUrl),
          contentDisposition: "attachment",
          contentType: file.basic.fileType,
          fileSize: file.basic.fileSize || undefined
        };
      });
      // console.log(this.selectedAttachmentFiles)
      this.onFilesSelected.emit(this.selectedAttachmentFiles);
      this.clearSelection();
    } else {
      // alert('Hello');
      console.log(this.fs.allFiles, this.fs.sharedByMeFiles, this.fs.sharedWithMeFiles)
      let files = this.fs.allFiles.concat(this.fs.sharedByMeFiles).concat(this.fs.sharedWithMeFiles);
      console.log(this.fileSelections, this.fileUrls, this.fileTokens, this.uploadedFiles)

      let tempFiles = files.filter((f) => this.fileSelections[f.basic._id]).concat(this.uploadedFiles);
      console.log(tempFiles)
      this.onFilesSelected.emit(tempFiles);
      this.clearSelection();
    }
  }

  async onUploadOutput(output: UploadOutput, uploadInputElement?: HTMLInputElement) {
    console.log(output);
    switch (output.type) {
      case 'allAddedToQueue':
        // console.log('output: ', output);
        this.activeTab = 'upload';
        break;
      case 'addedToQueue':
        // console.log('output: ', output);
        if (typeof output.file !== 'undefined') {
          this.files.push(output.file);
          this.fileTokens[output.file.id] = null;
        }

        if (uploadInputElement) {
          uploadInputElement.value = null;
        }

        if (this.crop && !this.multiple && this.fileType == 'image') {
          this.fileChanged({
            target: {
              files: [output.file.nativeFile]
            }
          })


          this.files = [];
          this.fileTokens = {

          };
        }

        // this.startUpload(this.files[this.files.length-1]);
        break;
      case 'uploading':
        // console.log(output);
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          // const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          // // console.log(index);

          // this.files[index] = output.file;

          // if (output.file.progress.status == 1 && output.file.progress.data.percentage == 100) {
          //   await this.confirmUpload(output.file);
          //   // console.log("updated files", this.files)
          //   if (this.files.filter(file => (file.progress.status !== UploadStatus.Done && file.progress.status !== UploadStatus.Uploading)).length) {
          //     this.uploadAll();
          //   } else {
          //     setTimeout(() => {
          //       this.isProcessing = false;
          //       this.onSubmit(this.fileManagerModal);
          //       // this.activeTab = 'all';
          //       // this.fs.getAllFiles(null, this.fileType);
          //     }, 3000);
          //   }
          // }
        }
        break;
      case 'removed':
        // remove file from array when removed
        // this.attachmentSelect.value = null;
        console.log("removing")
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        // this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        // this.dragOver = false;
        break;
      case 'done':
        if (output.file.responseStatus == 200 && output.file.progress.data.percentage == 100) {
          await this.confirmUpload(output.file);
          if (this.files.filter(file => file.progress.status !== UploadStatus.Done).length) {
            this.uploadAll();
          } else {
            if((this.files && this.files.length) && (!this.crop || this.multiple || this.fileType=='general')) {


              this.onSubmit(this.fileManagerModal);
            } else {
              this.isProcessing = false;
              this.activeTab = 'all';
              this.fs.getAllFiles(null, this.fileType);
            }
          }
        }
        break;
    }
    this.isProcessing = false;
  }

  async startUpload(file, isLast?) {

    if (file && this.fileTokens[file.id] && this.fileUrls[file.id]) {
      if (isLast) {
        this.isProcessing = false;
      }
      return;
    } else
      if (file && this.fileTokens[file.id] && !this.fileUrls[file.id]) {
        this.confirmUpload(file);
        if (isLast) {
          this.isProcessing = false;
        }
        return;
      }

    try {

      let type = 'general';

      if (file.type && file.type.includes("image")) {
        type = 'image';
      } else if (file.type && file.type.includes("video")) {
        type = 'video';
      } else if (file.type && file.type.includes("pdf") && this.fileType != "doc" && this.fileType != "pdf") {
        type = 'pdf';
      } else if (file.type && (file.type.includes("excel") || file.type.includes("word") || file.type.includes("doc") || file.type.includes("pdf")) || file.name.includes(".pdf") || file.name.includes(".docx") || file.name.includes(".pptx") || file.name.includes(".ppt")) {
        type = 'doc';
      }


      console.log(type, this.fileType, file.type, file);
      

      let useFileName = "";

      if (this.returnType == "attachment") {
        useFileName = "&useFileName=true";
      }

      let fileObj = {
        basic: {
          type: type,
          fileName: file.name,
          fileType: file.type
        },

        type: type,
        fileName: file.name,
        fileType: file.type

      };

      let getSignedresponse: HttpResponse<any> = await this.fs.http.post(environment.BUSINESS_ADMIN + "api/business/file-manager/get-signed-url", fileObj, { observe: 'response' }).toPromise();
      console.log(getSignedresponse);
      if (getSignedresponse.status == 200) {
        let signedUrl = getSignedresponse.body.details.signedUrl;
        this.fileTokens[file.id] = getSignedresponse.body.details.uploadToken;
        const event: UploadInput = {
          type: 'uploadFile',
          url: signedUrl,
          method: 'PUT',
          file: file,
          includeWebKitFormBoundary: false
        };
        console.log(event);

        this.uploadInput.emit(event);
      }

      if (isLast) {
        this.isProcessing = false;
      }
    } catch (err) {
      this.isProcessing = false;
      console.log(err);
    }
  }

  async uploadCroppedPicture(imageInput: HTMLInputElement) {
    this.isProcessing = true;
    console.log(this.activeTab);
    console.log(this.crop);

    // if (!this.croppedImage || !this.singleFileCropPositionChanged) {
    if (!this.croppedImage || !this.singleFileCropPositionChanged) {
      this.isProcessing = false;
      this.onSubmit(this.fileManagerModal);
      return;
    }
    else {
      this.singleFileCropPositionChanged = false;
    }

    try {

      // encodeURIComponent(this.croppedImage.name)
      let fileObj = {
        basic: {
          type: "image",
          fileName: this.croppedImage.name,
          fileType: this.croppedImage.type
        },

        type: "image",
        fileName: this.croppedImage.name,
        fileType: this.croppedImage.type

      };

      let getSignedresponse: HttpResponse<any> = await this.fs.http.post(environment.BUSINESS_ADMIN + "api/business/file-manager/get-signed-url", fileObj, { observe: 'response' }).toPromise();

      if (getSignedresponse.status == 200) {
        let signedUrl = getSignedresponse.body.details.signedUrl;
        let uploadToken = getSignedresponse.body.details.uploadToken;

        if (this.croppedImage) {
          const httpOptions = {
            headers: new HttpHeaders({}),
          };
          const url = signedUrl;

          this.fs.http.put(
            url,
            this.croppedImage,
            { observe: 'response', ...httpOptions }
          ).toPromise().then((response) => {
            if (response && response.status == 200) {
              // this.isProcessing = false;
              let token =  { uploadToken: uploadToken }
              this.fs.http.post(environment.BUSINESS_ADMIN + 'api/business/file-manager/confirm-upload', token, {
                observe: 'response'
              }).toPromise()
                .then((response: any) => {
                  if (response && response.status == 200) {
                    this.isProcessing = false;
                    // this.selectedFiles = [response.body.content.accessUrl];
                    // this.onFilesSelected.emit(this.selectedFiles);
                    // this.fileManagerModal.close();
                    this.latestFileName = "";
                    if (imageInput) {
                      imageInput.value = null;
                    }


                    if (this.returnType == 'url') {
                      this.onFilesSelected.emit([response.body["basic"].accessUrl])
                    } else if (this.returnType == 'attachment') {
                      this.onFilesSelected.emit([{
                        fileName: this.croppedImage.name,
                        path: environment.STORAGE_URL + response.body["basic"].accessUrl,
                        contentDisposition: "attachment",
                        contentType: this.croppedImage.type,
                        fileSize: response.body["basic"].fileSize || undefined
                      }]);
                    } else {
                      this.onFilesSelected.emit([{
                        fileName: this.croppedImage.name,
                        path: response.body["basic"].accessUrl,
                        contentType: this.croppedImage.type,
                        fileSize: response.body["basic"].fileSize || undefined
                      }]);
                    }
                    this.fileManagerModal.close();
                    this.clearSelection();

                    // this.activeTab = 'all';
                    // this.fs.getAllFiles(null, this.fileType);
                  }
                }).catch(err => {
                  this.isProcessing = false;
                  console.log(err);
                });
            }
          });
        }
      }
      // this.isProcessing = false;
    } catch (err) {
      // console.log(e);
      this.isProcessing = false;
      console.log(err);
      if (imageInput) {
        imageInput.value = null;
      }
      this.clearSelection();
      // this.modalRef.hide();
    }
  }



  uploadAll() {
    this.isProcessing = true;
    // this.files.forEach((file, index) => {
    //   if(index == (this.files.length-1)){
    //     this.startUpload(file, true);
    //   } else{
    //     this.startUpload(file);
    //   }
    // });

    let fileToUpload = this.files.find(file => (file.progress.status !== UploadStatus.Done && file.progress.status !== UploadStatus.Uploading));
    console.log("fileToUpload", fileToUpload);
    this.startUpload(fileToUpload);
  }

  async confirmUpload(file) {
    let token =  { uploadToken: this.fileTokens[file.id] }
    return this.fs.http.post(environment.BUSINESS_ADMIN + 'api/business/file-manager/confirm-upload', token, {
      observe: 'response'
    }).toPromise()
      .then((response) => {
        this.isProcessing = false;
        if (response && response.status == 200) {
          this.fileUrls[file.id] = response.body["basic"]["accessUrl"];
          // if(this.multiple) {
            this.uploadedFiles.push(response.body);
          // }
          console.log("confirmed", this.fileUrls)

          return Promise.resolve(true);

          // this.fileInterval = setInterval(() => {
          //   this.fs.getAllFiles(null, this.fileType);
          // }, 1000);


          // setTimeout(() => {
          //   clearInterval(this.fileInterval);
          // }, 10000);

          // if (this.returnType == 'url') {
          //   this.selectedFiles.push(response.body["content"].accessUrl);
          // } else {
          //   this.selectedAttachmentFiles.push({
          //     fileName: file.name,
          //     path: environment.STORAGE_URL + response.body["content"].accessUrl,
          //     contentDisposition: "attachment",
          //     contentType: file.type
          //   });
          // }
        }
      }).catch((err: any) => {
        console.log(err)
        this.isProcessing = false;
        return Promise.resolve(true);
      });
  }

  clearSelection(cropperInput?: HTMLInputElement) {
    this.files = [];
    this.selectedFiles = [];
    this.imageChangedEvent = '';
    this.latestFileName = "";

    this.searchString = null;
    this.searchByMEString = null;
    this.searchWithYouString = null;
    this.base64Image = '';
    this.croppedImage = '';
    this.selectedAttachmentFiles = [];
    this.uploadedFiles = [];
    this.fileTokens = {

    };

    this.fileUrls = {

    };

    this.fileSelections = {};
    if (this.uploadInputElement) {
      this.uploadInputElement.value = null;
    }
    if (cropperInput) {
      cropperInput.value = null;
    }
    // this.fileManagerModal.close();
    // // document.body.className = document.body.className.replace("dialog-open","");
    // setTimeout(() => {
    //   document.querySelector('body').classList.remove('dialog-open');
    // });
  }

  fileChanged(event) {
    console.log('file change event: ', event);
    this.singleFileEvent = true;
    this.latestFileName = event.target.files[0].name;
    if (event.target.files.length) {
      this.imageChangedEvent = event;
    }
  }


  imageCropped(event: ImageCroppedEvent) {
    console.log(event, this.singleFileEvent);

    if (!this.singleFileEvent) {
      this.singleFileEvent = event;
      return;
    }


    // && (event.imagePosition.x2 - event.cropperPosition.x2  <= 0.15615615615615752)
    // && (event.imagePosition.y2 - event.cropperPosition.y2 <= 0.15615615615615752)

    if (event && event.cropperPosition && this.singleFileEvent && this.singleFileEvent.cropperPosition
      && event.cropperPosition.x1 == this.singleFileEvent.cropperPosition.x1
      && event.cropperPosition.x2 == this.singleFileEvent.cropperPosition.x2
      && event.cropperPosition.y1 == this.singleFileEvent.cropperPosition.y1
      && event.cropperPosition.y2 == this.singleFileEvent.cropperPosition.y2
      && event.imagePosition.x1 == this.singleFileEvent.imagePosition.x1
      && event.imagePosition.x2 == this.singleFileEvent.imagePosition.x2
      && event.imagePosition.y1 == this.singleFileEvent.imagePosition.y1
      && event.imagePosition.y2 == this.singleFileEvent.imagePosition.y2
      && event.width == this.singleFileEvent.width
      && event.height == this.singleFileEvent.height
      && (event.imagePosition.x1 <= 0.15615615615615752)
      && (event.imagePosition.y1 <= 0.15615615615615752)) {
      this.singleFileCropPositionChanged = false;
      console.log("Same Image");
    }
    else {
      this.singleFileCropPositionChanged = true;
    }

    console.log("Cropper");

    let fileBlob = event.file;

    let fileName = this.latestFileName ? 'cropped_' + this.latestFileName : 'mesbro_upload_' + (new Date().getTime());

    if (!fileBlob) {
      return;
    }
    this.croppedImage = new File([fileBlob], fileName, {
      type: fileBlob.type
    });
  }

  removeFileFromSelection(id) {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  imageLoaded(event) {
    // show cropper
    // console.log("image loaded", event);
  }
  cropperReady(event) {
    // console.log("image cropper ready", event);
    // cropper ready
  }
  loadImageFailed(event) {
    // console.log("image faled", event);
    // show message
  }

  onScroll(ev, id: string) {
    // console.log(ev, id)
    var elem = document.getElementById(id);
    // console.log(elem.scrollHeight, elem.scrollTop + elem.offsetHeight + 100);
    // console.log(elem.scrollHeight - elem.scrollTop  );
    if (elem.scrollTop + elem.offsetHeight == elem.scrollHeight) {
      if (id.toLowerCase() === "allfiles") {
        this.fs.getAllFiles(this.searchString, this.fileType, (this.fs.allFiles && this.fs.allFiles.length)? Math.ceil((this.fs.allFiles.length/20)):0);
      }
      // else if (id.toLowerCase() === "withyou") {
      //   this.fs.getAllFilesSharedWithMe(this.searchWithYouString, this.fileType, this.fs.sharedWithMeFiles[this.fs.sharedWithMeFiles.length - 1].id);
      // }
      // else if (id.toLowerCase() === "byme") {
      //   this.fs.getAllFilesSharedByMe(this.searchByMEString, this.fileType, this.fs.sharedByMeFiles[this.fs.sharedByMeFiles.length - 1].id)
      // }
    }
  }

}
