import { Routes } from '@angular/router';
import { DashbaordComponent } from 'src/app/components/dashbaord/dashbaord.component';
import { DummyComponent } from 'src/app/components/dummy/dummy.component';

export const CommonLayout_ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashbaordComponent
  },
  {
    path: 'dummy',
    component: DummyComponent
  },
  {
    path: 'master',
    loadChildren: () => import('../../master/master.module').then(m => m.MasterModule)
  },
  {
    path: 'attendance',
    loadChildren: () => import('../../attendance/attendance.module').then(m => m.AttendanceModule)
  },
  {
    path: 'idm',
    loadChildren: () => import('../../authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'waste-management',
    loadChildren: () => import('../../waste-management/waste-management.module').then(m => m.WasteManagementModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('../../reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'purchase',
    loadChildren: () => import('../../purchase/purchase.module').then(m => m.PurchaseModule)
  },
  {
    path: 'purchase-report',
    loadChildren: () => import('../../purchase-report/purchase-report.module').then(m => m.PurchaseReportModule)
  },
  {
    path: 'quality',
    loadChildren: () => import('../../quality/quality.module').then(m => m.QualityModule)
  },
  {
    path: 'stock',
    loadChildren: () => import('../../stock/stock.module').then(m => m.StockModule)
  },
  {
    path: 'fish-stock',
    loadChildren: () => import('../../fish-stock/fish-stock.module').then(m => m.FishStockModule)
  },
  {
    path: 'fish-stock-report',
    loadChildren: () => import('../../fish-stock/fish-stock-report/fish-stock-report.module').then(m => m.FishStockReportModule)
  },
  {
    path: 'sales',
    loadChildren: () => import('../../sales/sales.module').then(m => m.SalesModule)
  },
  {
    path: 'peeling',
    loadChildren: () => import('../../peeling/peeling.module').then(m => m.PeelingModule)
  },
  {
    path: 'peeling-report',
    loadChildren: () => import('../../peeling-report/peeling-report.module').then(m => m.PeelingReportModule)
  },
  {
    path: 'sales-documents',
    loadChildren: () => import('../../sales-documents/sales-documents.module').then(m => m.SalesDocumentsModule)
  },
  
  {
    path: 'payment',
    loadChildren: () => import('../../payment/payment.module').then(m => m.PaymentModule)
  },
];
