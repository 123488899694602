// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  envName: 'localhost',
  // BASE_URL: "http://localhost:4000/",
  // BASE_URL: "https://ulkabe.herokuapp.com/",
  // BASE_URL: "https://ulkabe2.herokuapp.com/",
  // BASE_URL: "http://1e60868c.ngrok.io/"
  // BASE_URL: "https://ec2-3-129-149-38.us-east-2.compute.amazonaws.com/",
  BASE_URL: "https://ulka-api.ulkaone.com/",
  // BASE_URL:"https://13.232.142.36/",
  // BASE_URL: "https://13.232.142.36:4000",
  FILE_MANAGER_API: 'https://file-manager.simplifying.world/apis/v1.0.1/',
  FILE_MANAGER_URL: 'https://file-manager.simplifying.world/apis/v1.0.1/',
  VIDEO_API: 'http://api.mesbro.uk/api/video/',
  STORAGE_URL: "https://s3.ap-south-1.amazonaws.com/files.mesbro.com/",
  STORAGE_URL_SECURED: 'https://s3.ap-south-1.amazonaws.com/files.simplifying.world/',
  IMAGE_URL: "https://s3.ap-south-1.amazonaws.com/files.mesbro.com/",
  BUSINESS_ADMIN: 'https://api.mesbro.com/',
  MESBRO_TOKEN: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZWMyMzQ2YTNkYzM2ZTAwMzRiNjczMDgiLCJmaXJzdE5hbWUiOiJVbGthIiwibGFzdE5hbWUiOiJVbGthIiwibW9iaWxlIjoiNzczODE2NjY2MSIsImRhdGVPZkJpcnRoIjotMTU3MjQ1NDM3MjY5OCwiZ2VuZGVyIjoibWFsZSIsImNvdW50cnkiOnsibmFtZSI6IkluZGlhIiwiY291bnRyeUNvZGUiOiI5MSIsImNvZGUiOiJJTiJ9LCJwcm9maWxlUGljdHVyZSI6Im1hbGUtYXZhdGFyLnBuZyIsImRvbWFpbiI6Im1lc2Jyby5jb20iLCJlbWFpbCI6InVsa2FAbWVzYnJvLmNvbSIsInVzZXJuYW1lIjoidWxrYSIsInNpZ251cFRva2VuIjoiNjA3MTdiMGVlZjE3ZTA3NzkzNjM3ZGVlIiwiaWF0IjoxNjE4MDQ5ODA2fQ.ktS-h0dG44ROQ4HDTGMpP53H0C9yMNMo5ZOnkWC3kXg'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
