import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertWeight'
})
export class ConvertWeightPipe implements PipeTransform {

  transform(value: any, key: string, keyValue:string): any {
    let weight = 0
    if (key=='LBS') {
      // weight = (value[keyValue] * 0.00220462);
      weight = (value[keyValue] * 2.20462);
  
      return weight
    }
  else{
    weight = (value[keyValue]);
    return weight
   }
    
    
  }

}
