import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'objectFromArray'
})
export class ObjectFromArrayPipe implements PipeTransform {

  transform(array: any, id: string, key?: string): any {
    if (Array.isArray(array)) {
      if (!key) {
        let object = array.filter(element => {
          if (element.id == id) {
            return true;
          } else {
            return false;
          }
        })
        if (object) {
          return object[0];
        } else {
          return null
        }
      }

      // if (key) {
      //   console.log(array, id, key)
      // }

      if (key) {
        let object = array.filter(element => {

          if (_.get(element, key) == id) {
            return true;
          } else {
            return false;
          }
        })
        if (object) {
          return object[0];
        } else {
          return null
        }
      }




    } else {
      return null
    }
  }

}
