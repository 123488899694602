import { FormControl, ValidatorFn, FormGroup } from "@angular/forms";
import * as moment from 'moment';
import { filter, map, combineAll } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import { createCheckers } from 'ts-interface-checker';
import { invalid } from "@angular/compiler/src/render3/view/util";

export class validator {

  constructor() {

  }

  validUsername(control: any) {
    if (!control.value) {
      return null;
    }
    let value: string = control.value;
    // if (value.indexOf('@mesbro.com') > 0) {
    //   let parts = value.split('@mesbro.com');
    //   value = parts[0];
    // }
    return (value && (value.match(/^(?!\.)(?!\_)(?!.*\.$)(?!.*\_$)(?!.*?\.\.)(?!.*?[_]{2})[a-zA-Z0-9_.]+$/) && !(value.split('.').length > 2) && !(value.split('_').length > 2))) ? null : {
      validUsername: {
        invalid: true
      }
    };
  }

  validateCIN(control: any) {
    if (!control.value) {
      return null;
    }
    if (!control.value || !control.value.match(/^[a-zA-Z0-9]*$/)) {
      return {
        validateCIN: true
      };
    }
  }

  validateAddress(control: any) {
    if (!control.value) {
      return null;
    }
    if (!control.value || !control.value.match(/^[a-zA-Z0-9, /.-]*$/)) {
      return {
        validateAddress: true
      };
    }
  }

  minLength(length: number): ValidatorFn {
    return (control: FormControl) => {
      if (!control.value || typeof control.value === 'string' && control.value.trim().length < length) {
        return {
          minlength: true
        };
      }

      return null;
    };
  }

  maxLength(length: number): ValidatorFn {
    return (control: FormControl) => {
      if (control.value && typeof control.value === 'string' && control.value.trim().length > length) {
        return {
          maxlength: true
        };
      }

      return null;
    };
  }

  trimValidation(control: any) {
    if (!control.value) {
      return null;
    }

    return (control.value.trim() !== '') ? null : {
      validTrimmedString: {
        invalid: true
      }
    };
  }

  // public onCityAdding(tag: TagModel): Observable<TagModel> {
  //   // const confirm = window.confirm('Do you really want to add this tag?');
  //   // console.log(tag);
  //   return Observable
  //     .of(tag)
  //     .filter((item) => item && item['city'] && item['city'].match(/^[a-zA-Z ]*$/));
  // }

  validateChipString(control: FormControl) {
    // console.log(control.value, control.value.match(/^[a-zA-Z ]*$/));

    return new Promise(resolve => {
      if (!control.value || !control.value.match(/^[a-zA-Z ]*$/)) {
        resolve({
          'invalidString': true
        });
      } else {
        resolve(null);
      }
    })



    // return null;
  }
  validStringWithNumber(control: any) {

    if (!control.value) {
      return null;
    }

    return (control.value && control.value.match(/^[a-zA-Z0-9 ]*$/)) ? null : {
      validStringWithNumber: {
        invalid: true
      }
    };
  }

  validStringNumber(control: any) {

    if (!control.value) {
      return null;
    }

    return (control.value && control.value.match(/^[0-9]*$/)) ? null : {
      validStringNumber: {
        invalid: true
      }
    };
  }

  validStringWithPeriod(control: any) {

    if (!control.value) {
      return null;
    }

    return (control.value && control.value.match(/^[a-zA-Z. ]*$/)) ? null : {
      validString: {
        invalid: true
      }
    };
  }

  checkSpaces(control: any) {
    let noMoreSpaces = true
    if (!control.value) {
      return null;
    }
    if (control.value && control.value.split(' ').length > 2) {
      noMoreSpaces = false;
    } else {
      noMoreSpaces = true;
    }
    return (control.value && noMoreSpaces) ? null : {
      checkSpaces: {
        invalid: true
      }
    };
  }

  validStringWithNumberPeriodAndHifen(control: any) {

    if (!control.value) {
      return null;
    }

    return (control.value && control.value.match(/^[a-zA-Z0-9-. ]*$/)) ? null : {
      validStringWithNumberPeriodAndHifen: {
        invalid: true
      }
    };
  }

  validStringWithHifen(control: any) {

    if (!control.value) {
      return null;
    }

    return (control.value && control.value.match(/^[a-zA-Z-. ]*$/)) ? null : {
      validString: {
        invalid: true
      }
    };
  }

  validString(control: any) {

    if (!control.value) {
      return null;
    }

    return (control.value && control.value.match(/^[a-zA-Z ]*$/)) ? null : {
      validString: {
        invalid: true
      }
    };
  }

  noConsecucutiveSpaces(control: any) {
    if (!control.value) {
      return null;
    }

    return (control.value && !control.value.match(/\s\s/)) ? null : {
      noConsecucutiveSpaces: {
        invalid: true
      }
    };
  }

  confirmPassword(c: FormControl): any {
    if (c.get('password').value !== c.get('confirm_password').value) {
      return { invalid: true };
    }
    return null;
  }

  validNumberWithSpaces(control: any) {
    if (!control.value || typeof control.value != 'string') {
      return null;
    }
    let exp = control.value && control.value.match(/^[0-9 ]+$/);
    return exp ? null : {
      validNumberWithSpaces: {
        invalid: true
      }
    };
  }

  // validCallingPin(control: any) {
  //   if (!control.value || typeof control.value != 'string') {
  //     return null;
  //   }
  //     let exp = control.value && control.value.match(/^[0-9]{4,4}$/);
  //     return exp ? null : {
  //       validCallingPin: {
  //         invalid: true
  //       }
  //     }
  // }


  validCallingPin(control: any) {
    // return true;
    console.log(control.value);
    if (!control.value || typeof control.value != 'string') {
      return null;
    }
    let exp = control.value && control.value.match(/^[0-9]{4}$/);
    return exp ? null : {
      validCallingPin: {
        invalid: true
      }
    };
  }

  validDunsNumber(control: any) {
    // return true;
    console.log(control.value);
    if (!control.value || typeof control.value != 'string') {
      return null;
    }
    let exp = control.value && control.value.match(/^[0-9]{9}$/);
    return exp ? null : {
      validDunsNumber: {
        invalid: true
      }
    };
  }

  validMobile(control: any) {
    if (!control.value || typeof control.value != 'string') {
      return null;
    }
    let exp = control.value && control.value.match(/^[0-9]{4,13}$/);
    return exp ? null : {
      validMobile: {
        invalid: true
      }
    };
  }

  validTagsWithNoSpecialChar(control: any) {
    if (!control.value) {
      return null;
    }
    console.log(control.value)
    let exp = control.value.filter((val, i)=>{
        if(typeof val == "string") {
          return val.match(/[^A-Za-z0-9]+/) ;
        } else {
          return true;
        }
      }).length;
    return !exp ? null : {
      validTagsWithNoSpecialChar: {
        invalid: true
      }
    };
  }

  validOtp(control: any) {
    if (!control.value || typeof control.value != 'string') {
      return null;
    }
    let exp = control.value && control.value.match(/^[0-9]{6,6}$/);
    return exp ? null : {
      validOtp: {
        invalid: true
      }
    };
  }

  validTagLength(control: any) {
    console.log(control.value)
    if (!control.value) {
      return null;
    }
    let length = control.value.length - 1;
    if (control.value && control.value.length) {
      for (let i = 0; i <= control.value.length - 1; i++) {
        if (control.value[i].length <= 30 && length < 10) {
          if ((length == i)) {
            return null;
          }
        } else {
          return {
            validTagLength: {
              invalid: true
            }
          }
        }
      }
    }

  }

  validPinCode(control: any) {
    if (!control.value || typeof control.value != 'string') {
      return null;
    }
    let exp = control.value && control.value.match(/^[a-zA-Z0-9 -]{5,12}$/);
    return exp ? null : {
      validPinCode: {
        invalid: true
      }
    };
  }
  validPassword(control: any) {
    if (!control.value) {
      return null;
    }
    let value: string = control.value;

    return (value && value.match(/^[^-\s][a-zA-Z0-9!@#$&()\\-`.+,/\ "]*$/)) ? null : {
      validPassword: {
        invalid: true
      }
    };
  }

  validLatitudeLongitude(control: any) {
    if (!control.value || typeof control.value != 'string') {
      return null;
    }
    let exp = control.value && control.value.match(/^-?[0-9]\d*(\.\d+)?$/);
    // (/^-?[0-9]\d*(\.\d+)?$/);
    return exp ? null : {
      validLatitudeLongitude: {
        invalid: true
      }
    };
  }

  numberWithDecimal(control: any) {
    // /^\d*\.?\d*$/
    if (!control.value || typeof control.value != 'string') {
      return null;
    }
    let exp = control.value && control.value.match(/^\d*\.?\d*$/);
    return exp ? null : {
      numberWithDecimal: {
        invalid: true
      }
    };
  }

  validGSTNumber(control: any) {
    if (!control.value || typeof control.value != 'string') {
      return null;
    }
    let exp = control.value && control.value.match(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);
    return exp ? null : {
      validGSTNumber: {
        invalid: true
      }
    };
  }

  validBarCode(control: any) {
    if (!control.value || typeof control.value != "string") {
      return null;
    }
    let exp = control.value && control.value.match(/^[a-zA-Z0-9+-]{5,50}$/);
    return exp ? null : {
      validBarCode: {
        invalid: true
      }
    }
  }


  validateLandline() {

  }

  validateDateRange(value: any, min, max) {
    if (!value) {
      return false;
    }

    if (min && max) {
      return moment(value).isBetween(min, max);
    } else if (min) {
      return moment(value).isSameOrAfter(min);
    } else if (max) {
      return moment(value).isSameOrAfter(max);
    }
  }

  validateDOB(control: any) {
    if (!control.value) {
      return null;
    }
    // console.log(control.value, moment(control.value).isValid(), !isNaN(control.value),  moment(control.value).isAfter(moment().subtract(18, 'years')))
    return (control.value && moment(control.value).isValid() && !isNaN(control.value) && !moment(control.value).isAfter(moment().subtract(18, 'years'))) ? null : {
      validateDOB: {
        invalid: true
      }
    };
  }

  validDate(control: any) {
    if (!control.value) {
      return null;
    }

    return (control.value && moment(control.value).isValid() && !isNaN(control.value)) ? null : {
      validDate: {
        invalid: true
      }
    };


    // console.log(control.value instanceof Date && !isNaN(control.value));

    // if(typeof control.value == 'object'){

    // }
    // return control.value.match(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/) ? null : {
    //   validateDate: {
    //     invalid: true
    //   }
    // };
    return null
  }

  validateEmail(control: any) {
    if (control.value && control.value.address) {

      return control.value.address.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? null : {
        validateEmail: {
          invalid: true
        }
      };
    } else if (control.value && control.value.match) {
      return control.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? null : {
        validateEmail: {
          invalid: true
        }
      };
    } else {
      return null;
    }
  }

  validateEmailString(str: any) {
    return str.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  }

  // Old Validator Function

  // validateWebsite(control: any) {
  //   if (!control.value) {
  //     return null;
  //   }
  //   return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(control.value) ? null : {
  //     validateWebsite: {
  //       invalid: true
  //     }
  //   };

  // }

  // New Validator Function

  validateWebsite(control: any) {
    if (!control.value) {
      return null;
    }
    return /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/i.test(control.value) ? null : {
      validateWebsite: {
        invalid: true
      }
    };

  }

  validTollFreeNumber(control: any) {
    if (!control.value || typeof control.value != "string") {
      return null;
    }
    let exp = control.value && control.value.match(/^[0-9- ]*$/);
    return exp ? null : {
      validTollFreeNumber: {
        invalid: true
      }
    }
  }

  validSKU(control: any) {
    if (!control.value || typeof control.value != "string") {
      return null;
    }
    let exp = control.value && control.value.match(/^[a-zA-Z0-9]{8,12}$/);
    return exp ? null : {
      validTollFreeNumber: {
        invalid: true
      }
    }
  }

  validInteger(control: any) {
    if (!control.value || typeof control.value != "string") {
      return null;
    }
    let exp = control.value && control.value.match(/^\-?[0-9]*$/);
    return exp ? null : {
      validInteger: {
        invalid: true
      }
    }
  }

  validFloat(control: any) {
    if (!control.value || typeof control.value != "string") {
      return null;
    }
    let exp = control.value && control.value.match(/^\-?[0-9]*\.?[0-9]*$/);
    return exp ? null : {
      validFloat: {
        invalid: true
      }
    }
  }

  validStringWithSpecialCharacters(control: any) {
    if (!control.value || typeof control.value != "string") {
      return null;
    }
    let exp = control.value && control.value.match(/^[a-zA-Z0-9 !@#£€¥\$\?%\^\&*\)\(\.\]\[,:;°_-]+$/);
    return exp ? null : {
      validStringWithSpecialCharacters: {
        invalid: true
      }
    }
  }

  onlyAlphabets(control: any) {
    if (!control.value || typeof control.value != "string") {
      return null;
    }
    let exp = control.value && control.value.match(/^[a-zA-Z ]*$/);
    return exp ? null : {
      onlyAlphabets: {
        invalid: true
      }
    }
  }




  validTitle(control: any) {
    if (!control.value || typeof control.value != "string") {
      return null;
    }
    let exp = control.value && control.value.match("^[^{}_<>|#\\]\\[]+$");
    return exp ? null : {
      validTitle: {
        invalid: true
      }
    }
  }

  validCity(control: any) {
    if (!control.value || typeof control.value != "string") {
      return null;
    }
    let exp = control.value && control.value.match(/^[a-zA-Z0-9 \\-\\.\\'\\,]$/);
    return exp ? null : {
      validCity: {
        invalid: true
      }
    }
  }

  checkIfEndDateAfterStartDate(start: string = 'from', end: string = 'to'): ValidatorFn {

    return (control: FormGroup) => {
      if (!control.value[start] || !control.value[end]) {
        return null;
      }
      let exp = new Date(control.value[start]).setHours(0, 0, 0, 0) >= new Date(control.value[end]).setHours(0, 0, 0, 0);
      if (exp) {
        return {
          checkIfEndDateAfterStartDate: true
        }
      }
    };
  }

  validRequest(arg, interfaceToCheck, interfaceModel) {
    let Checker = createCheckers(interfaceModel)[interfaceToCheck];
    if (Checker.strictValidate(arg) != null) {
      delete arg[Checker.strictValidate(arg).path.split(".")[1]];
      this.validRequest(arg, interfaceToCheck, interfaceModel);
    } else {
      return arg;
    }
  }

  validateRequest(arg, interfaceToCheck, interfaceModel) {
    let Checker = createCheckers(interfaceModel)[interfaceToCheck];
    if (Checker.strictValidate(arg) != null) {
      console.error(Checker.strictValidate(arg));
      throw Checker.strictValidate(arg);
    }
  }
}
