import { Pipe, PipeTransform } from '@angular/core';
import { func } from 'ts-interface-checker';

var words = new Array();

words[0] = '';
words[1] = 'One';
words[2] = 'Two';
words[3] = 'Three';
words[4] = 'Four';
words[5] = 'Five';
words[6] = 'Six';
words[7] = 'Seven';
words[8] = 'Eight';
words[9] = 'Nine';
words[10] = 'Ten';
words[11] = 'Eleven';
words[12] = 'Twelve';
words[13] = 'Thirteen';
words[14] = 'Fourteen';
words[15] = 'Fifteen';
words[16] = 'Sixteen';
words[17] = 'Seventeen';
words[18] = 'Eighteen';
words[19] = 'Nineteen';
words[20] = 'Twenty';
words[30] = 'Thirty';
words[40] = 'Forty';
words[50] = 'Fifty';
words[60] = 'Sixty';
words[70] = 'Seventy';
words[80] = 'Eighty';
words[90] = 'Ninety';

@Pipe({
  name: 'amountInWords'
})

export class AmountInWordsPipe implements PipeTransform {

  transform(amount: any, type?, unit?): any {

    if (!amount) {
      return 'Zero';
    }

    amount = (typeof amount == 'string')? parseFloat(amount.replace(',','')) : amount

    if (type == 'USD') {
      const num2text = {
        ones: ['', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT', 'NINE', 'TEN', 'ELEVEN', 'TWELVE', 'THIRTEEN', 'FOURTEEN', 'FIFTEEN', 'SIXTEEN', 'SEVENTEEN', 'EIGHTEEN', 'NINETEEN'],
        tens: ['', '', 'TWENTY', 'THIRTY', 'FORTY', 'FIFTY', 'SIXTY', 'SEVENTY', 'EIGHTY', 'NINETY'],
        sep: ['', ' THOUSAND ', ' MILLION ', ' BILLION ', ' TRILLION ', ' QUADRILLION ', ' QUINTILLION ', ' SEXTILLION ']
      };
      // const convert = function(amount) {
      if (amount.length === 0) {
        return '';
      }

      // amount = amount.replace(/,/g, '');
      if (isNaN(amount)) {
        return 'Invalid input.';
      }


      let [val1, val2] = amount.toString().split(".")
      let str2 = "";
      if (val2 != null && val2 != '') {
        //convert the decimals here
        var digits = (val2 + "0").slice(0, 2).split("");
       
        if(unit=="LBS"){
          // str2 = num2text.tens[+digits[0]] + " " + num2text.ones[+digits[1]]
          str2 = num2text.ones[+digits[0]] + " " + num2text.ones[+digits[1]]
        }
          else{
            str2 = num2text.tens[+digits[0]] + " " + num2text.ones[+digits[1]]
          }
      
      }

      let arr = [];
      while (val1) {
        arr.push(val1 % 1000);
        val1 = parseInt((val1 / 1000) + '', 10);
      }
      // console.log(arr)
      let i = 0;
      let str = "";
      while (arr.length) {
        str = (function (a) {
          var x = Math.floor(a / 100),
            y = Math.floor(a / 10) % 10,
            z = a % 10;
          // console.log(x)
          // if(x){
          return (x > 0 ? num2text.ones[x] + ' HUNDRED ' : '') +
            (y >= 2 ? num2text.tens[y] + ' ' + num2text.ones[z] : num2text.ones[10 * y + z]);
          // }

        })
          (arr.shift()) + num2text.sep[i++] + str;
      }
      if (str) {
        return str +
          ' DOLLARS ' +
          (str2 ? (' AND ' + str2 + ' CENTS') : '');
          // ((!str2 && str2 != '' && str != null) ? ' AND ' + str2 + ' CENTS' : '');
      }

      // };
    }
    else {
      amount = amount.toString();
      amount = amount.toString();
      amount = parseFloat(amount).toFixed(2)
      var atemp = amount.split(".");
      let words_string = decimalCount(atemp[0])
      let decimal = decimalCount(atemp[1])
      console.log(type)
      return words_string + ((atemp[1] && parseFloat(atemp[1].replace(',',''))) ? ('AND ' + (decimal + (type=='dollar'? 'CENTS ' : 'PAISA '))) : '');
    }

  }



}

function decimalCount(originalNumber: any) {
  var number = originalNumber.split(",").join("");
  var n_length = number.length;
  var words_string = "";

  if (n_length <= 9) {
    let n_array: any = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
    let received_n_array: any = new Array();
    for (var i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1);
    }
    for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j];
    }
    for (var i = 0, j = 1; i < 9; i++, j++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        if (n_array[i] == 1) {
          n_array[j] = 10 + parseInt(n_array[j]);
          n_array[i] = 0;
        }
      }
    }
    let value: any = "";
    for (var i = 0; i < 9; i++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        value = n_array[i] * 10;
      } else {
        value = n_array[i];
      }
      if (value != 0) {
        words_string += words[value] + " ";
      }
      if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
        words_string += "Crores ";
      }
      if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
        words_string += "Lakhs ";
      }
      if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
        words_string += "Thousand ";
      }
      if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
        words_string += "Hundred ";
      } else if (i == 6 && value != 0) {
        words_string += "Hundred ";
      }
    }
    words_string = words_string.split("  ").join(" ");
  }
  return words_string
}