import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthInWords'
})
export class MonthInWordsPipe implements PipeTransform {

  transform(value: any): any {

   
      
//       let month = value.split('-',1)
//       let year = value.split('-',2)
//     console.log(month,year);
    
//     var monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June',
//     'July', 'August', 'September', 'October', 'November', 'December' ];
// return monthNames[year[0] - 1] + '-'+year[1];

   


      let month = value.split('-',1)
      let year = value.split('-',2)
    console.log(month,year);
    
    var monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December' ];
return monthNames[year[1] - 1] + '-'+year[0];
  }

}
