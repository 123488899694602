import { Pipe, PipeTransform } from '@angular/core';
import * as changeCase from 'change-case'

@Pipe({
  name: 'case'
})
export class CasePipe implements PipeTransform {

  transform(value: any, key: any): any {
    if(!value) return value;

    if(key=='commas'){
      if(Array.isArray){
        let returnString = '';
        value.forEach((e,index) => {
          returnString += ((index!=0? ', ' : '')+e)
        });
        return returnString
      }
    }

    if(key=='json'){
      return JSON.stringify(value)
    }
    return changeCase[key + 'Case'](value);
  }

}
