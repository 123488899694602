import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitByColon'
})
export class SplitByColonPipe implements PipeTransform {

  transform(value:string, separator, arrayLength): any {
    
      let fullArray = [];
      fullArray = value.split(':');
      return fullArray[arrayLength];
  }

}
