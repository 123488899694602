import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'

@Pipe({
  name: 'arnNo'
})

export class ArnNoPipe implements PipeTransform {
  public arnNumbers =
    {
      'ulka': [
        // { startDate: '2020-04-01T00:00:00.000Z', endDate: '2021-03-31T23:59:59.000Z', arnNo: 'AD270420004189E', date:'07/04/2020' },
        // { startDate: '2021-04-01T00:00:00.000Z', endDate: '2022-03-31T23:59:59.000Z', arnNo: 'AD270321080110R',date:'31/03/2021' },
        // { startDate: '2022-04-01T00:00:00.000Z', endDate: '2023-03-31T23:59:59.000Z', arnNo: 'AD270322093359U',date:'31/03/2022' },
        // { startDate: '2023-04-01T00:00:00.000Z', endDate: '2024-03-31T23:59:59.000Z', arnNo: 'AD2703230811376',date:'31/03/2023' },
        { startDate: '2024-04-01T00:00:00.000Z', endDate: '2025-03-31T23:59:59.000Z', arnNo: 'AD270324216074F',date:'31/03/2024' }

      ],
      'shree-ulka': [
        { startDate: '2020-04-01T00:00:00.000Z', endDate: '2021-03-31T23:59:59.000Z', arnNo: 'AD290719000919I' },
        { startDate: '2021-04-01T00:00:00.000Z', endDate: '2022-03-31T23:59:59.000Z', arnNo: 'AD290321029362D' },
        { startDate: '2022-04-01T00:00:00.000Z', endDate: '2023-03-31T23:59:59.000Z', arnNo: 'AD2903220374356' },
        { startDate: '2023-04-01T00:00:00.000Z', endDate: '2024-03-31T23:59:59.000Z', arnNo: 'AD290423000202S' },
        { startDate: '2024-04-01T00:00:00.000Z', endDate: '2025-03-31T23:59:59.000Z', arnNo: 'AD2903240693041' },
      ],
    }


  // transform(value: any, company): any {
  // console.log(this.arnNumbers[company]);

  //   return this.arnNumbers[company].find(a=>{
  //     return moment(value).isSameOrBefore(a.endDate) &&  moment(value).isSameOrAfter(a.startDate)
  //   })


  // }
  transform(value: string, company: string): any {
    const foundArn = this.arnNumbers[company].find(a => {
      const startDate = moment.utc(a.startDate);
      const endDate = moment.utc(a.endDate);
      const inputDate = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ'); // use the same format as in the ARN object
      // compare the dates using `isSameOrAfter()` and `isSameOrBefore()`
       inputDate.isSameOrAfter(startDate, 'minute') && inputDate.isSameOrBefore(endDate, 'minute');
       return a.arnNo;
      
    });
  return  foundArn;
  }
}


