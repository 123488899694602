import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../services/global.service';
import { environment } from 'src/environments/environment';
// import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    public env = environment;

    constructor(public gs: GlobalService) {
        // console.log(this.gs.currentUser.token)
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let isMesbro = request.url.includes('mesbro.com');

        let token = undefined;

        if(this.gs.currentUser){
            token = this.gs.currentUser.token;
        }
        if (isMesbro) {
            token = this.env.MESBRO_TOKEN;
            // console.log('999999999999999999999999999999999999999999999999999',token)

        }
        if (!isMesbro && (request.url.includes('filemanager') || request.url.includes('file-manager'))) {
            token = undefined;
        }

        // console.log(request,request.url, isMesbro, token);

        if (!request.url.includes('sign-in') && !request.url.includes('s3.ap-south-1.amazonaws.com') && !request.url.includes('sign-up') && !request.url.includes('otp') && !request.url.includes('forgot-password') && token) {
            var requestedToken = request.clone({
                setHeaders: {
                    Authorization: token
                }
            });
        }
        else {
            requestedToken = request.clone();
        }
        return next.handle(requestedToken);
    }


}