import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByKeyAndId'
})
export class FilterByKeyAndIdPipe implements PipeTransform {

  transform(array: any, key: string, keyValue: string): any {

    // console.log(array, key, keyValue);

    if (!array) {
      return [];
    }

    // console.log(array,key,keyValue)

    
      let returnArray = [];

      for (let i = 0; i < array.length; i++) {
        if (array[i].basic && (array[i].basic[key] == keyValue || array[i].basic[key] == 'both')) {
          returnArray.push(array[i]);
        }
        else if (array[i] && (array[i][key] == keyValue || array[i][key] == 'both' )) {
          returnArray.push(array[i]);
        }
      }
      // console.log(returnArray);

      return returnArray;
   

  }

}
