import { Component, OnInit, forwardRef, Input, Output, EventEmitter, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, AbstractControl, NgControl, FormControl, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/modules/shared/services/global.service';
import { FileManagerService } from 'src/app/modules/file-manager/file-manager.service';
import { UploadFile } from 'ng-zorro-antd';
import { combineAll } from 'rxjs/operators';
import { environment } from 'src/environments/environment'
import { ImageCroppedEvent } from 'ngx-image-cropper';
// import {base64ToFile} from './image-cropper/utils/blob.utils';
import { HttpResponse, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'm-image-picker',
  templateUrl: './m-image-picker.component.html',
  styleUrls: ['./m-image-picker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MImagePickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: MImagePickerComponent,
      multi: true
    }
  ]
})
export class MImagePickerComponent implements OnInit, ControlValueAccessor {

  @Input() name: string;
  @Input() val: Array<any>;

  @Input() label;
  @Input() labelSmall;

  @Input() errorMsg = "Invalid Input";
  @Input() errorHint;
  @Input() clearable = true;

  previewImage

  @Input()
  returnType: 'url' | 'file-signature' | 'attachment' = 'url';

  @Input()
  imageGlanceAbsolute = false;

  @Input()
  cropper = false;

  @Input()
  cropAspectRatio = 1 / 1;

  @Input() multiple = false;

  @Input() placeholder = "";

  @Input() min = null;
  @Input() max = null;

  @Input() source = null;

  @Input() fileSelector = true;

  @Input() imagePicker = true;
  @Input() imageUrl;

  @Input() fileType;

  public env = environment;
  public fileList = [];
  public cropperVisible = false;
  public file;
  public isProcessing = false;
  public latestFileName = "";
  public imageChangedEvent: any = '';
  public croppedImage: any = '';

  @Input() view = false;



  inputType = "text";
  private _type;
  get type(): any {
    return this._type;

  }
  @Input() set type(val: any) {
    this._type = val;
    // this.inputType 
  }

  // Both onChange and onTouched are functions
  onValChange: any = () => { };
  onValTouched: any = () => { };

  @Output()
  onBlur: EventEmitter<any> = new EventEmitter();

  @Output()
  onChange: EventEmitter<any> = new EventEmitter();

  @Output()
  onTouched: EventEmitter<any> = new EventEmitter();

  @Input() formControlName: string;
  public control: AbstractControl;

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange.emit(val);
    this.onValChange(val);
    this.onValTouched();
  }

  constructor(private injector: Injector, public gs: GlobalService, public fs: FileManagerService) {
  }

  ngOnInit() {
    // setTimeout(() => {
    //   // console.log(this.fileType)
    // }, 8000);
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      setTimeout(() => {
        this.control = ngControl.control as FormControl;
        this.addValidators();
      });
    } else {
      // Component is missing form control binding
      // console.warn('Missing FormControlName directive from host element of the component');
    }
  }

  addValidators() {
    this.control.setValidators([this.control.validator]);
    this.control.updateValueAndValidity();
  }

  onFileSelect($event) {
    console.log('A')
    console.log($event, '-------------------------------------------------------------------------');
    if (this.multiple) {
      this.value = (this.value || []).concat($event);
    } else {
      this.value = $event;
    }
  }

  removeImage($event, imageGlance) {
    console.log($event, this.value)
    if (this.multiple && this.value) {
      this.value.splice($event, 1);
      this.control.patchValue(this.value);
    } else {
      this.control.patchValue([]);
    }
    imageGlance.images = this.value;
  }

  validate({ value }: FormControl) {
    return null;
  }

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onValChange = fn;
  }
  // We implement this method to keep a reference to the onTouched
  //callback function passed by the forms API
  registerOnTouched(fn) {
    this.onValTouched = fn;
  }
  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  onFileClick(event) {
    console.log(event, '---------------------------------------------------')
    if (event.target.files.length) {
      console.log(event.target.files);

      this.fs.uploadFile(event.target.files[0]).then((file) => {

        if (this.multiple) {
          this.value = (this.value || []).concat(file);
        } else {
          this.value = [file];
        }
      }).catch((err) => {
        console.log(err);
        this.value = null;
      });
    }
    console.log(this.value)
    this.fileList = this.value;
  }

  deleteImage(image) {
    let currentValue = this.control.value;
    let index = currentValue.indexOf(image)
    currentValue.splice(index, 1);
    this.control.patchValue(currentValue);
  }

  isVisible = false;
  imagePreview(image): void {
    this.previewImage = image;
    this.isVisible = true;
  }

  handleOk(): void {
    console.log('clicked ok');
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  downloadPdf(image) {
    console.log(image);
    window.open(image);
    // window.open(this.env.STORAGE_URL + image.accessUrl)
  }

  videoPreview(image) {
    console.log(image);
    window.open(image)
  }


  fileChangeEvent(event) {
    console.log('file change event: ', event);
    // this.singleFileEvent = true;
    this.latestFileName = event.target.files[0].name;
    if (event.target.files.length) {
      this.imageChangedEvent = event;
      this.showModal();
      this.isProcessing = true;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log("inside cropper.....", event);
    var image = new Image();
    image.src = event.base64;
    // console.log(image)
    // let fileBlob = event.file;

    let fileName = this.latestFileName ? 'cropped_' + this.latestFileName : 'mesbro_upload_' + (new Date().getTime());

    this.file = this.dataURLtoFile(event.base64, fileName);
    // console.log(this.file);
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  showModal() {
    this.cropperVisible = true;
  }

  handleOkCropper() {
    console.log('Button ok clicked!');
    this.cropperVisible = false;
    this.fs.uploadFile(this.file, this.returnType).then((file) => {
      console.log(file);
      console.log(this.returnType);

      if (this.multiple) {
        this.value = (this.value || []).concat(file);
      } else {
        this.value = [file];
      }
      console.log(this.value);
      this.isProcessing = false;
    }).catch((err) => {
      console.log(err);
      this.value = null;
    });
  }

  handleCancelCropper() {
    console.log('Button cancel clicked!');
    this.cropperVisible = false;
    this.isProcessing = false;
  }

}
