import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Injector, forwardRef } from '@angular/core';
import { AbstractControl, NgControl, FormControl, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor } from '@angular/forms';
import { CKEditorComponent } from 'ngx-ckeditor';

@Component({
  selector: 'm-editor',
  templateUrl: './m-editor.component.html',
  styleUrls: ['./m-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MEditorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: MEditorComponent,
      multi: true
    }
  ]
})
export class MEditorComponent implements OnInit, ControlValueAccessor {

  @Input() name: string;
  @Input() val: string;

  @Input() label;
  @Input() labelSmall;

  @Input() errorMsg = "Invalid Input";
  @Input() errorHint;
  @Input() clearable = false;

  @Input() placeholder = "";

  @Input() minLength = null;
  @Input() maxLength = null;

  onValChange: any = () => { };
  onValTouched: any = () => { };

  @Output()
  onBlur: EventEmitter<any> = new EventEmitter();

  @Output()
  onChange: EventEmitter<any> = new EventEmitter();

  @Output()
  onTouched: EventEmitter<any> = new EventEmitter();

  @Input() formControlName: string;
  public control: AbstractControl;

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange.emit(val);
    this.onValChange(val);
    this.onValTouched();
  }

  @ViewChild('ckEditor', { static: false }) ckEditor: CKEditorComponent;


  @Input()
  editorConfig = {
    removeButtons: 'Save',
    // height :500  
  };

  constructor(private injector: Injector) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      setTimeout(() => {
        this.control = ngControl.control as FormControl;
        // console.warn('Missing FormControlName directive from host element of the component');
        this.addValidators();
      });
    } else {
      // Component is missing form control binding
        // console.warn('Missing FormControlName directive from host element of the component');
    }
  }

  addValidators() {
    if(this.minLength) {
      this.control.setValidators([this.control.validator, Validators.minLength(this.minLength)]);
    }
    if(this.maxLength) {
      this.control.setValidators([this.control.validator, Validators.maxLength(this.maxLength)]);
    }
    this.control.updateValueAndValidity();
    
  }

  validate({ value }: FormControl) {
    return null;
  }

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onValChange = fn;
  }
  // We implement this method to keep a reference to the onTouched
  //callback function passed by the forms API
  registerOnTouched(fn) {
    this.onValTouched = fn;
  }
  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

}
