import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'calculateRows'
})
export class CalculateRowsPipe implements PipeTransform {

  transform(value: any, application: string, type: any, key?: string): any {

    if (application == 'purchase-new-sez') {


      let groupedList = value;
      let tempList = Object.entries(groupedList);
      let fishVariety = type[0];
      let fishType = type[1];
      // if (fishVariety!="RANI" || fishType!="HEAD LESS") {
      //   return '' 
      // }
      let result = [[]];
      let consideredRate = {}
      let rateAndQuantityPosition = {}
      let maxLength 
      let pushNthPosition = (array, position, value) => {
        if (array.length - 1 >= position) {
          array[position] = value;
        } else {
          for (let i = array.length; i <= position; i++) {
            array[i] = undefined;
          }
          array[position] = value;
        }
      }
      tempList.forEach((fish: any) => {
        let fishInfo = fish[1].fishInfo;
        
        if (fishInfo.varietyId == fishVariety && fishInfo.typeId == fishType) {
          let indexToPush = consideredRate[fishInfo.whileReceipt.sezRate] || 0;
          result[indexToPush] = result[indexToPush] || [];
          if (!indexToPush) {
            result[0].push(fishInfo)
            rateAndQuantityPosition[fishInfo.whileReceipt.sezRate] = result[0].length-1;
            consideredRate[fishInfo.whileReceipt.sezRate] = 1;
          } else {
            // result[indexToPush].push(fishInfo)
            pushNthPosition(result[indexToPush],rateAndQuantityPosition[fishInfo.whileReceipt.sezRate],fishInfo)
            consideredRate[fishInfo.whileReceipt.sezRate]++;
          }
        }
        
      });

     
      
      
      return [...result, result[0].map(r=>r.whileReceipt.sezRate)];
    }
    

    if (application == 'purchase') {
      let fishTypes = ['goodMaterial', 'blackSpot', 'peeling', 'peelingType', 'soft', 'handMeat', 'dagdi', 'pilledAndDeveined'];
      let tempObject = _.get(value, key)
      let returnValue = 0;

      if (type == 'material') {
        fishTypes.forEach(fishType => {
          if (tempObject[fishType]) { returnValue++ }
        })
      }
      if (type == 'entries') {
        tempObject.fishes.forEach(fish => {
          fishTypes.forEach(fishType => {
            if (fish.whileReceipt.weight[fishType]) { returnValue++ }
          })
        })
      }

      return returnValue;
    }

    if (application == 'sales') {
      // let tempObject = _.get(object, key)
      let returnValue = 0;
      if (type == 'entries') {
        value.body.piDetails.pi.forEach(pi => {
          pi.containers.forEach(container => {
            container.products.forEach(product => {
              returnValue++
            })
          })
        })
       
      }

      if (type == 'buyer') {
        console.log(value);
        let consigneeArray = []
        Object.keys(value).forEach((key, index) => {
          console.log(value);
          let consignee = value.value.contractData.consigneeId;
          console.log(consignee);
          
          if (index==0) {
            
            consigneeArray.push(consignee)
          }
          else{
            if (consignee[index] == consignee[index-1]) {
              consigneeArray.push(consignee)
            }
            else{
              return
              
            }
          }
          console.log(consigneeArray);
          // console.log(consigneeArray);
          
        });
      console.log(value);
      
       
      }

      return returnValue;
    }


    // if (application == 'waste') {
    //   let tempObject = _.get(object, key)
    //   console.log(tempObject);

    //   let returnValue = 0;

    //   if (type == 'vehicle') {

    //       if (tempObject) { returnValue++ }
    //   }
    //   if (type == 'entries') {
    //     if (tempObject) {
    //       returnValue++
    //     }
    //   }
    //   return returnValue;
    // }
    return 1
  }

}
