import { SideNavInterface } from '../../interfaces/side-nav.type';
import * as changeCase from 'change-case';


const alias = {
  'customer-waste-material': 'waste-buyer',
  'waste-permit': 'boe-details',
}

const crudNav = (application, component) => {
  return {
    path: '/' + application + '/' + component + '',
    title: changeCase.capitalCase(alias[component] || component),
    iconType: 'nzIcon' as const,
    icon: 'plus' as const,
    iconTheme: 'outline' as const,
    application: application,
    component: component,
    process: component,
    submenu: [
      {
        path: '/' + application + '/' + component + '/add/0',
        title: 'Add',
        iconType: 'nzIcon' as const,
        iconTheme: 'outline' as const,
        icon: 'plus' as const,
        application: application,
        component: component,
        submenu: []
      },
      {
        path: '/' + application + '/' + component + '/list',
        title: 'List',
        iconType: 'nzIcon' as const,
        iconTheme: 'outline' as const,
        icon: 'bars' as const,
        application: application,
        component: component,
        submenu: []
      },
    ]
  }
}


export const ROUTES: SideNavInterface[] = [
  {
    path: '',
    title: 'Dashboard',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'dashboard',
    submenu: []
  },
  {
    path: '/master',
    title: 'Master',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore',
    application: 'master',
    submenu: [
      crudNav('master', 'customer-waste-material'),
      crudNav('master', 'contractor'),
      crudNav('master', 'person'),
      // crudNav('master', 'process-rate'),
      // crudNav('master', 'variety'),
      // crudNav('master', 'type'),
      // crudNav('master', 'species'),
      crudNav('master', 'consignee'),
      // crudNav('master', 'waste-permit'),
      // crudNav('master', 'employee'),
    ]
  },



  {
    path: '/attendance',
    title: 'Attendance',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore',
    application: 'attendance',
    process: "true",
    submenu: [
      // crudNav('master', 'employee'),
      {
        path: '/master/employee',
        title: 'Employee',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'peeling',
        process: "employee",
        submenu: [
          {
            path: '/master/employee/add/0/attendance',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          {
            path: '/master/employee/list/attendance',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      // {
      //   path: '/attendance/add',
      //   title: 'Add',
      //   iconType: 'nzIcon',
      //   iconTheme: 'outline',
      //   icon: 'user-add',
      //   process: "attendance",
      //   submenu: []
      // },
      {
        path: '/attendance/list',
        title: 'Attendance',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'bars',
        process: "attendance",
        submenu: []
      },
      {
        path: '/attendance/attendance-report/attendance',
        title: 'Report',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'bars',
        process: "attendance-report",
        submenu: []
      },
    ]
  },
  {
    path: '/idm/user',
    title: 'Users',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore',
    application: 'idm',
    process: "true",
    submenu: [
      {
        path: '/idm/user/add/0',
        title: 'Add',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'idm',
        process: "user",
        submenu: []
      },
      {
        path: '/idm/user/list',
        title: 'List',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'bars',
        application: 'idm',
        process: "user",
        submenu: []
      },
    ]
  },
  {
    path: '/purchase/purchase',
    title: 'Purchase',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore',
    application: 'purchase',
    process: "true",
    submenu: [

      {
        path: '/purchase/exchange-rate',
        title: 'Exchange Rate',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        application: 'purchase',
        process: "exchange",
        submenu: [
          {
            path: '/purchase/exchange-rate/add',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'appstore',
            application: 'purchase',
            process: "exchange",
            submenu: []
          },
          {
            path: '/purchase/exchange-rate/list',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'appstore',
            application: 'purchase',
            process: "exchange",
            submenu: []
          },
        ]
      },
      {
        path: '/purchase/purchase',
        title: 'Loading Details',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        application: 'purchase',
        process: "loading",
        submenu: [
          {
            path: '/purchase/purchase/add/0/loading',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'appstore',
            submenu: []
          },
          {
            path: '/purchase/purchase/loading/list/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },

      {
        path: '/purchase/purchase',
        title: 'Weighment',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        application: 'purchase',
        process: "weighment",
        submenu: [
          {
            path: '/purchase/purchase/add/0/weighment',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'appstore',
            submenu: []
          },
          {
            path: '/purchase/purchase/weighment/list/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/purchase/purchase',
        title: 'production',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        application: 'purchase',
        process: "production",
        submenu: [
          {
            path: '/purchase/purchase/production/list/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/purchase/purchase',
        title: 'Pending Receipt',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        application: 'purchase',
        process: "unloading",
        submenu: [
          {
            path: '/purchase/purchase/unloading/list/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/purchase/purchase',
        title: 'Receipt',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        application: 'purchase',
        process: "receipt",
        submenu: [
          {
            path: '/purchase/purchase/receipt/list/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/purchase/supplier-invoice',
        title: 'Supplier Invoice',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        application: 'invoice',
        process: "supplier-invoice",
        submenu: []
      },
      {
        path: '/master',
        title: 'BOE Details',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        application: 'purchase',
        process: "sez-boe",
        submenu: [
          {
            path: '/master/sez-master/add/0',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'appstore',
            submenu: []
          },
          {
            path: '/master/sez-master/list/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      // {
      //   path: '/purchase-report/sez-invoice',
      //   title: 'Sez Invoice',
      //   iconType: 'nzIcon',
      //   iconTheme: 'outline',
      //   icon: 'appstore',
      //   application: 'purchase',
      //   process: 'sez-invoice',
      //   submenu: []
      // },
      {
        path: '/payment/payment/1/purchase',
        title: 'Payment',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        application: 'purchase',
        process: "payment",
        submenu: []
      },
      {
        path: '/purchase/advance-remittance',
        title: 'Advance Remmitance',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        application: 'purchase',
        process: "advance-remittance",
        submenu: [
          {
            path: '/purchase/advance-remittance/add/0',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'appstore',
            application: 'purchase',
            process: "advance-remittance",
            submenu: []
          },
          {
            path: '/purchase/advance-remittance/list/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            application: 'purchase',
            process: "advance-remittance",
            submenu: []
          },
        ]
      },
      {
        path: '/purchase/direct-remittance',
        title: 'Direct Remmitance',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        application: 'purchase',
        process: "direct-remittance",
        submenu: [
          {
            path: '/purchase/direct-remittance/add/0',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'appstore',
            application: 'purchase',
            process: "direct-remittance",
            submenu: []
          },
          {
            path: '/purchase/direct-remittance/list/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            application: 'purchase',
            process: "direct-remittance",
            submenu: []
          },
        ]
      },
      {
        path: '/purchase-report/pending-report',
        title: 'Report',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        application: 'purchase',
        process: 'report',
        submenu: []
      },
      {
        path: '/purchase-report/sez-report',
        title: 'Sez Report',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        application: 'purchase',
        process: 'sez-report',
        submenu: []
      },
    ]
  },
  // {
  //   path: '/purchase-report',
  //   title: 'Purchase Report',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'appstore',
  //   application: 'purchase-report',
  //   process: "true",
  //   submenu: [
  //     {
  //       path: '/purchase-report/pending-report',
  //       title: 'Report',
  //       iconType: 'nzIcon',
  //       iconTheme: 'outline',
  //       icon: 'appstore',
  //       application: 'purchase-report',
  //       process: 'pending-report',
  //       submenu: []
  //     },
  //     {
  //       path: '/purchase-report/sez-report',
  //       title: 'Sez Report',
  //       iconType: 'nzIcon',
  //       iconTheme: 'outline',
  //       icon: 'appstore',
  //       application: 'purchase-report',
  //       process: 'sez-report',
  //       submenu: []
  //     },
      
  //   ]
  // },



  {
    path: '/quality/quality',
    title: 'Quality',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore',
    application: 'quality',
    process: "true",
    submenu: [
      {
        path: '/quality/quality/add/0',
        title: 'Add',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        process: "quality",
        submenu: []
      },
      {
        path: '/quality/quality/list',
        title: 'List',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        process: "quality",
        submenu: []
      },
      
    ]
  },
  // {
  //   path: '/waste-management',
  //   title: 'Waste Management',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'appstore',
  //   submenu: [
  //     {
  //       path: '/waste-management/waste-entry/add/0',
  //       title: 'Add',
  //       iconType: 'nzIcon',
  //       iconTheme: 'outline',
  //       icon: 'user-add',
  //       submenu: []
  //     },
  //     {
  //       path: '/waste-management/waste-entry/list',
  //       title: 'List',
  //       iconType: 'nzIcon',
  //       iconTheme: 'outline',
  //       icon: 'bars',
  //       submenu: []
  //     },
  //   ]
  // },



  // {
  //   path: '/master',
  //   title: 'Waste Permit',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'appstore',
  //   application: 'master',
  //   submenu: [
  //     crudNav('master', 'waste-permit'),
  //   ]
  // },
  {
    path: '/waste-management',
    title: 'Waste Management',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore',
    application: 'waste-management',
    process: "true",
    submenu: [
      {
        path: '/waste-management/tr-six',
        title: 'TR 6 / Ice Gate',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'waste-management',
        process: "tr6",
        submenu: [
          {
            path: '/waste-management/tr-six/add/0',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          {
            path: '/waste-management/tr-six/list',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/master/waste-permit',
        title: 'BOE Details',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'waste-management',
        process: "boe-details",
        submenu: [
          {
            path: '/master/waste-permit/add/0',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          {
            path: '/master/waste-permit/list',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      // crudNav('master', 'waste-permit'),

      {
        path: '/waste-management/waste-entry',
        title: 'Vehicle Details',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'waste-management',
        process: "vehicle",
        submenu: [
          {
            path: '/waste-management/waste-entry/add/0/vehicle',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          {
            path: '/waste-management/waste-entry/list/vehicle/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/waste-management/waste-entry',
        title: 'Loading Details',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'waste-management',
        process: "loading",
        submenu: [
          {
            path: '/waste-management/waste-entry/list/loading/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/waste-management/waste-entry',
        title: 'Mundi Details',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'waste-management',
        process: "mundi-details",
        submenu: [
          {
            path: '/waste-management/waste-entry/list/mundi-details/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/waste-management/waste-entry',
        title: 'Invoice Details',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'waste-management',
        process: "invoice",
        submenu: [
          {
            path: '/waste-management/waste-entry/list/invoice/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/payment/payment/1/waste-management',
        title: 'Payment',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'waste-management',
        process: "payment",
        submenu: []
      },
      // {
      //   path: '/waste-management/waste-entry',
      //   title: 'Payment',
      //   iconType: 'nzIcon',
      //   iconTheme: 'outline',
      //   icon: 'user-add',
      //   application: 'waste-management',
      //   process: "actual-payment",
      //   submenu: [
      //     {
      //       path: '/waste-management/waste-entry/list/payment/0',
      //       title: 'List',
      //       iconType: 'nzIcon',
      //       iconTheme: 'outline',
      //       icon: 'bars',
      //       submenu: []
      //     },
      //   ]
      // },
     
      // {
      //   path: '/waste-management/waste-entry',
      //   title: 'Rate Details',
      //   iconType: 'nzIcon',
      //   iconTheme: 'outline',
      //   icon: 'user-add',
      //   application: 'waste-management',
      //   process: "rate",
      //   submenu: [

      //     {
      //       path: '/waste-management/waste-entry/list/rate/0',
      //       title: 'List',
      //       iconType: 'nzIcon',
      //       iconTheme: 'outline',
      //       icon: 'bars',
      //       submenu: []
      //     },
      //   ]
      // },
      // {
      //   path: '/waste-management/waste-entry',
      //   title: 'Payment Details',
      //   iconType: 'nzIcon',
      //   iconTheme: 'outline',
      //   icon: 'user-add',
      //   application: 'waste-management',
      //   process: "payment",
      //   submenu: [

      //     {
      //       path: '/waste-management/waste-entry/list/actual-payment/0',
      //       title: 'Actual Payment',
      //       iconType: 'nzIcon',
      //       iconTheme: 'outline',
      //       icon: 'bars',
      //       submenu: []
      //     },
      //     {
      //       path: '/waste-management/waste-entry/list/declared-payment/0',
      //       title: 'Declared Payment',
      //       iconType: 'nzIcon',
      //       iconTheme: 'outline',
      //       icon: 'bars',
      //       submenu: []
      //     },
      //   ]
      // },
      {
        path: '/waste-management/waste-report',
        title: 'Report',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'waste-management',
        process: "report",
        submenu: [

          {
            path: '/waste-management/waste-report',
            title: 'Report',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      
    ]
  },
  {
    path: 'reports/reports',
    title: 'Reports',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    application: 'reports',
    icon: 'appstore',
    submenu: [
      {
        path: '/reports/reports',
        title: 'Reports',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        process: 'reports',
        icon: 'appstore',
        submenu: []
      },
      // {
      //   path: '/reports/complete-daily-production-report',
      //   title: 'complete-daily-production-report',
      //   iconType: 'nzIcon',
      //   iconTheme: 'outline',
      //   icon: 'appstore',
      //   submenu: []
      // },
    ]
  },
  {
    path: '/stock',
    title: 'Stock',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore',
    application: 'stock',
    process: "true",
    submenu: [
      {
        path: '/stock/stock',
        title: 'Production',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'stock',
        process: "production",
        submenu: [
          {
            path: '/stock/stock/add/0/production',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          {
            path: '/stock/stock/list/production/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/stock/stock',
        title: 'Quality',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'stock',
        process: "quality",
        submenu: [
          // {
          //   path: '/stock/stock/add/0/quality',
          //   title: 'Add',
          //   iconType: 'nzIcon',
          //   iconTheme: 'outline',
          //   icon: 'user-add',
          //   submenu: []
          // },
          {
            path: '/stock/stock/list/quality/0',
            queryParams:{status:'pending'},
            title: 'Quality Pending List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
          {
            path: '/stock/stock/list/quality/0',
            queryParams:{status:'complete'},
            title: 'Quality Complete List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/stock/stock',
        title: 'Thowing',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'stock',
        process: "thowing",
        submenu: [
          {
            path: '/stock/stock/add/0/thowing',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          {
            path: '/stock/stock/list/thowing/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      
      {
        path: '/stock/stock',
        title: 'Repacking',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'stock',
        process: "repacking",
        submenu: [
          {
            path: '/stock/stock/add/0/repacking',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          {
            path: '/stock/stock/list/repacking/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/stock/stock',
        title: 'Reglazing',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'stock',
        process: "reglazing",
        submenu: [
          {
            path: '/stock/stock/add/0/reglazing',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          {
            path: '/stock/stock/list/reglazing/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/stock/stock',
        title: 'Transfer',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'stock',
        process: "transfer",
        submenu: [
          {
            path: '/stock/stock/add/0/transfer',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          {
            path: '/stock/stock/list/transfer/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/stock/stock',
        title: 'Shipment',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'stock',
        process: "shipment",
        submenu: [
          {
            path: '/stock/stock/add/0/shipment',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          {
            path: '/stock/stock/list/shipment/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/stock/stock',
        title: 'Local Sale',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'stock',
        process: "local-sale",
        submenu: [
          {
            path: '/stock/stock/add/0/local-sale',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          {
            path: '/stock/stock/list/local-sale/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
  
      
      {
        path: '/fish-stock/fish-stock-report',
        title: 'Fish Stock Report',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'stock',
        process: "fish-stock-report",
        submenu: [
          {
            path: '/fish-stock/fish-stock-report/fish',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            // process: "stock-report",
            submenu: []
          },
          // {
          //   path: '/fish-stock/fish-stock-report',
          //   title: 'List',
          //   iconType: 'nzIcon',
          //   iconTheme: 'outline',
          //   icon: 'user-add',
          //   process: "surimi-report",
          //   submenu: []
          // },
          
        ]
      },
      {
        path: '/fish-stock/fish-stock-report',
        title: 'Surimi Stock Report',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'stock',
        process: "surimi-stock-report",
        submenu: [
          {
            path: '/fish-stock/fish-stock-report/surimi',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            // process: "stock-report",
            submenu: []
          },
          // {
          //   path: '/fish-stock/fish-stock-report',
          //   title: 'List',
          //   iconType: 'nzIcon',
          //   iconTheme: 'outline',
          //   icon: 'user-add',
          //   process: "surimi-report",
          //   submenu: []
          // },
          
        ]
      },
    ]
  },
  {
    path: '/sales',
    title: 'Sales',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore',
    application: 'sales',
    process: "true",
    submenu: [
      {
        path: '/sales/sales',
        title: 'Contract',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'sales',
        process: "contract",
        submenu: [
          {
            path: '/sales/sales/add/0/contract',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          {
            path: '/sales/sales/list/contract/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/sales/sales',
        title: 'Invoice',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'sales',
        process: "invoice",
        submenu: [
          // {
          //   path: '/sales/sales/add/0/invoice',
          //   title: 'Add',
          //   iconType: 'nzIcon',
          //   iconTheme: 'outline',
          //   icon: 'user-add',
          //   submenu: []
          // },
          // {
          //   path: '/sales/sales/list/invoice/0',
          //   title: 'List',
          //   iconType: 'nzIcon',
          //   iconTheme: 'outline',
          //   icon: 'bars',
          //   submenu: []
          // },
          {
            path: '/sales/sales/list/invoice/0/false',
            title: 'PI List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
          {
            path: '/sales/sales/list/invoice/0/true',
            title: 'Invoice List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/sales/sales',
        title: 'Lab',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'sales',
        process: "lab",
        submenu: [
          // {
          //   path: '/sales/sales/add/0/piDetails',
          //   title: 'Add',
          //   iconType: 'nzIcon',
          //   iconTheme: 'outline',
          //   icon: 'user-add',
          //   submenu: []
          // },
          {
            path: '/sales/sales/list/lab/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/sales/sales',
        title: 'Packing',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'sales',
        process: "packing",
        submenu: [
          // {
          //   path: '/sales/sales/add/0/piDetails',
          //   title: 'Add',
          //   iconType: 'nzIcon',
          //   iconTheme: 'outline',
          //   icon: 'user-add',
          //   submenu: []
          // },
          {
            path: '/sales/sales/list/packing/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/sales/sales',
        title: 'Label',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'sales',
        process: "label",
        submenu: [
          // {
          //   path: '/sales/sales/add/0/piDetails',
          //   title: 'Add',
          //   iconType: 'nzIcon',
          //   iconTheme: 'outline',
          //   icon: 'user-add',
          //   submenu: []
          // },
          {
            path: '/sales/sales/list/label/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/sales/sales',
        title: 'Export',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'sales',
        process: "export",
        submenu: [
          // {
          //   path: '/sales/sales/add/0/piDetails',
          //   title: 'Add',
          //   iconType: 'nzIcon',
          //   iconTheme: 'outline',
          //   icon: 'user-add',
          //   submenu: []
          // },
          {
            path: '/sales/sales/list/export/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/sales/sales',
        title: 'Logistic',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'sales',
        process: "logistic",
        submenu: [
          // {
          //   path: '/sales/sales/add/0/piDetails',
          //   title: 'Add',
          //   iconType: 'nzIcon',
          //   iconTheme: 'outline',
          //   icon: 'user-add',
          //   submenu: []
          // },
          {
            path: '/sales/sales/list/logistic/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },

      {
        path: '/sales/sales',
        title: 'Commission',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'sales',
        process: "commision",
        submenu: [
          // {
          //   path: '/sales/sales/add/0/piDetails',
          //   title: 'Add',
          //   iconType: 'nzIcon',
          //   iconTheme: 'outline',
          //   icon: 'user-add',
          //   submenu: []
          // },
          {
            path: '/sales/sales/list/commission/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
    

      // {
      //   path: '/sales/sales',
      //   title: 'Quality',
      //   iconType: 'nzIcon',
      //   iconTheme: 'outline',
      //   icon: 'user-add',
      //   application: 'sales',
      //   process: "quality",
      //   submenu: [
      //     // {
      //     //   path: '/sales/sales/add/0/payment',
      //     //   title: 'Add',
      //     //   iconType: 'nzIcon',
      //     //   iconTheme: 'outline',
      //     //   icon: 'user-add',
      //     //   submenu: []
      //     // },
      //     {
      //       path: '/sales/sales/list/quality/0',
      //       title: 'List',
      //       iconType: 'nzIcon',
      //       iconTheme: 'outline',
      //       icon: 'bars',
      //       submenu: []
      //     },
      //   ]
      // },
      {
        path: '/sales/sales',
        title: 'Payment',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'sales',
        process: "payment",
        submenu: [
          // {
          //   path: '/sales/sales/add/0/payment',
          //   title: 'Add',
          //   iconType: 'nzIcon',
          //   iconTheme: 'outline',
          //   icon: 'user-add',
          //   submenu: []
          // },
          {
            path: '/sales/sales/list/payment/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/sales/sales',
        title: 'Health & Catch',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'sales',
        process: "healthAndCatch",
        submenu: [
          // {
          //   path: '/sales/sales/add/0/healthAndCatch',
          //   title: 'Add',
          //   iconType: 'nzIcon',
          //   iconTheme: 'outline',
          //   icon: 'user-add',
          //   submenu: []
          // },
          {
            path: '/sales/sales/list/healthAndCatch/0',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: '/sales/shipping-bill',
        title: 'Shipping Bill',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'sales',
        process: "tr6",
        submenu: [
          {
            path: '/sales/shipping-bill/add/0',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          {
            path: '/sales/shipping-bill/list',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },

      {
        path: '/sales/meis-licence',
        title: 'MEIS Licence',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'sales',
        process: "tr6",
        submenu: [
          {
            path: '/sales/meis-licence/add/0',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          {
            path: '/sales/meis-licence/list',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      {
        path: 'reports/reports/sales',
        title: 'Reports',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        application: 'sales',
        process: 'reports',
        icon: 'user-add',
        submenu: [
          {
            path: '/reports/reports/sales',
            title: 'Reports',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          // {
          //   path: '/reports/complete-daily-production-report',
          //   title: 'complete-daily-production-report',
          //   iconType: 'nzIcon',
          //   iconTheme: 'outline',
          //   icon: 'appstore',
          //   submenu: []
          // },
        ]
      },
    ]
  },

  // {
  //   path: '/sales-documents',
  //   title: 'Sales Documents',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'appstore',
  //   application: 'reports',
  //   submenu: [
  //     {
  //       path: '/sales-documents/documents/5eb27214ce917500175f6e7b',
  //       title: 'Documents',
  //       iconType: 'nzIcon',
  //       iconTheme: 'outline',
  //       icon: 'user-add',
  //       process: 'salesDocuments',
  //       submenu: []
  //     },

  //   ]
  // },
  {
    path: '/peeling',
    title: 'Peeling',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore',
    application: 'peeling',
    process: "true",
    submenu: [
      // crudNav('master', 'employee'),
      {
        path: '/master/employee',
        title: 'Employee',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        application: 'peeling',
        process: "peelingEmployee",
        submenu: [
          {
            path: '/master/employee/add/0/peeling',
            title: 'Add',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
          },
          {
            path: '/master/employee/list/peeling',
            title: 'List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bars',
            submenu: []
          },
        ]
      },
      // {
      //   path: '/peeling/peeling/add/0/invoice',
      //   title: 'Add',
      //   iconType: 'nzIcon',
      //   iconTheme: 'outline',
      //   icon: 'user-add',
      //   submenu: []
      // },
      {
        path: '/peeling/peeling/list/0',
        title: 'Peeling',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        application: 'peeling',
        icon: 'bars',
        process: 'peeling',
        submenu: []
      },
      {
        path: '/peeling-report',
        title: 'Report',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'bars',
        process: "attendance-report",
        submenu: []
      },
    ]
  },
  {
    path: '/peeling-report',
    title: 'Peeling Report',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore',
    submenu: [
      {
        path: '/peeling-report/peeling-report',
        title: 'Peeling Report',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        submenu: []
      },
    ]
  },
  {
    path: '/payment',
    title: 'Payment',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore',
    application: 'payment',
    process: "true",
    submenu: [
      {
        path: '/payment/payment/1/sales',
        title: 'Payment',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'bars',
        process: "payment",
        submenu: []
      },
    ]
  },

]
