import { Component, OnInit, Input, forwardRef, Self, Optional, Host, EventEmitter, Output, Injector, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, NgControl, NG_VALIDATORS, ControlContainer, AbstractControl, Validators } from '@angular/forms';
import { validateWebsite, confirmPassword, validateEmail, validOtp, validPinCode, validLatitudeLongitude, validGSTNumber, validateDOB, validInteger, validMobile, validUsername } from '../../../utils/mesbro_validators';
import { NzInputNumberComponent } from 'ng-zorro-antd';
import * as changeCase from 'change-case'

@Component({
  selector: 'm-input',
  templateUrl: './m-input.component.html',
  styleUrls: ['./m-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: MInputComponent,
      multi: true
    }
  ]
})
export class MInputComponent implements OnInit, ControlValueAccessor {

  @Input() showTime = false;

  @ViewChild('nzNumber', { static: false }) nzNumber: NzInputNumberComponent;

  @Input() name: string;
  @Input() val: any;
  @Input() noGrid: boolean = false;
  @Input() columns: number = undefined;

  @Input() label;
  @Input() labelSmall;

  @Input() errorMsg = "Invalid Input";
  @Input() errorHint;
  @Input() clearable = true;

  @Input() placeholder = "";
  @Input() nzFormat;

  @Input() min = null;
  @Input() max = null;
  @Input() step = 1;

  @Input() minLength = null;
  @Input() maxLength = null;

  @Input() minlength = null;
  @Input() maxlength = null;

  @Input() nzMax = undefined;
  @Input() nzMin = undefined;
  @Input() nzStep = 1;

  @Input() nzSuffix;
  @Input() nzAddOnAfter;

  @Input() disable = false;
  @Input() disabledDate;

  @Input() upperCase = false;

  @Input() inputType = "text";

  @Input() view = false;
  @Input() labelColumns = undefined;

  // @Input() size = "small";

  // @Input() type="text";
  private _type = 'text';
  get type(): any {
    return this._type;

  }
  @Input() set type(val: any) {
    this._type = val;
    // this.inputType 
  }

  // Both onChange and onTouched are functions
  onValChange: any = () => { };
  onValTouched: any = () => { };

  @Output()
  onBlur: EventEmitter<any> = new EventEmitter();

  @Output()
  onChange: EventEmitter<any> = new EventEmitter();

  @Output()
  ngModelChange: EventEmitter<any> = new EventEmitter();

  @Output()
  onkeyup: EventEmitter<any> = new EventEmitter();

  @Output()
  onTouched: EventEmitter<any> = new EventEmitter();

  @Output()
  onAdd: EventEmitter<any> = new EventEmitter();

  @Output()
  onRemove: EventEmitter<any> = new EventEmitter();

  @Input() formControlName: string;
  public control: AbstractControl;

  get value() {
    return this.val;
  }

  set value(val) {
    // console.log(val);
    this.val = val;

    // setTimeout(data => {
      if (this.upperCase && val && this.inputType == "text") {
        this.val = val.toUpperCase();
      }
      // console.log(val,this.label);
      this.onChange.emit(val);
      this.onkeyup.emit(val);
      this.onValChange(val);
      this.onValTouched();
    // }, 200)
  }

  constructor(private injector: Injector) {
  }

  ngOnInit() {
  }

  onAdded(value) {
    this.onAdd.emit(value.value)
    console.log(value)

  }

  onRemoved(value) {
    this.onRemove.emit(value.value)
    console.log(value)

  }
  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      setTimeout(() => {
        this.control = ngControl.control as FormControl;
        this.addValidators(this.type);
      });
    } else {
      // Component is missing form control binding
      // console.warn('Missing FormControlName directive from host element of the component');
    }
  }

  addValidators(type) {
    if (type == "website" || type == "url") {
      this.control.setValidators([this.control.validator, validateWebsite]);
    }
    if (type == "email") {
      this.control.setValidators([this.control.validator, validateEmail]);
    }
    if (type == "otp") {
      this.control.setValidators([this.control.validator, validOtp]);
    }
    if (type == "pincode") {
      this.control.setValidators([this.control.validator, validPinCode]);
    }
    if (type == "latlong") {
      this.control.setValidators([this.control.validator, validLatitudeLongitude]);
    }
    if (type == "gst") {
      this.control.setValidators([this.control.validator, validGSTNumber]);
    }
    if (type == "dob") {
      this.control.setValidators([this.control.validator, validateDOB]);
    }
    // if (type == "number") {
    //   this.control.setValidators([this.control.validator, validInteger]);
    // }
    if (type == "mobile") {
      this.control.setValidators([this.control.validator, validMobile]);
    }
    if (type == "username") {
      this.control.setValidators([this.control.validator, validUsername]);
    }

    this.control.updateValueAndValidity();

    if (this.min) {
      this.control.setValidators([this.control.validator, Validators.min(this.min)]);
    }
    if (this.max) {
      this.control.setValidators([this.control.validator, Validators.min(this.max)]);
    }
    if (this.minLength) {
      this.control.setValidators([this.control.validator, Validators.minLength(this.minLength)]);
    }
    if (this.maxLength) {
      this.control.setValidators([this.control.validator, Validators.maxLength(this.maxLength)]);
    }
    this.control.updateValueAndValidity();

  }

  validate({ value }: FormControl) {
    return null;
  }

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onValChange = fn;
  }
  // We implement this method to keep a reference to the onTouched
  //callback function passed by the forms API
  registerOnTouched(fn) {
    this.onValTouched = fn;
  }
  // This is a basic setter that the forms API is going to use
  writeValue(value) {

    if (value) {

      // if(this.upperCase){
      //   value = value.toUpperCase();
      // }

      this.value = value;
    }
  }

}
