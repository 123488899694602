import { Component, OnInit, forwardRef, Injector, Output, Input, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, NgControl, AbstractControl } from '@angular/forms';

const noop = () => {
};

@Component({
  selector: 'm-select',
  templateUrl: './m-select.component.html',
  styleUrls: ['./m-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MSelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: MSelectComponent,
      multi: true
    }
  ]
})
export class MSelectComponent implements OnInit {

  @Input() name: string;
  @Input() val: string;
  @Input() columns: number = undefined;

  @Input() label;
  @Input() labelSmall;

  @Input() nzLabel = 'name';
  @Input() nzValue = 'id';

  @Input() errorMsg;
  @Input() nzMode;
  @Input() items = [];
  @Input() disable = false;
  @Input() view = false;
  @Input() labelColumns = undefined;
  @Input() canBeUndefined = undefined
  inputType = "text";
  private _type;
  get type(): any {
    return this._type;

  }
  @Input() set type(val: any) {
    this._type = val;
  }

  // Both onChange and onTouched are functions
  public onValChange: (_: any) => void = () => noop;
  public onValTouched: () => void = () => noop;

  @Output()
  onBlur: EventEmitter<any> = new EventEmitter();

  @Output()
  onSelect: EventEmitter<any> = new EventEmitter();

  @Output()
  onChange: EventEmitter<any> = new EventEmitter();

  @Output()
  onTouched: EventEmitter<any> = new EventEmitter();

  @Input() formControlName: string;
  public control: AbstractControl;

  get value() {
    return this.val;
  }

  set value(val) {
    // console.log(val)
    this.val = val;
    this.onChange.emit(val);
    this.onValChange(val);
    this.onValTouched();
  }


  constructor(private injector: Injector) {
  }

  ngOnInit() {
  }

  public onSelection(){
    // console.log('Selected')
    this.onSelect.emit(this.val);
  }

  ngAfterViewInit(): void {
    // console.log(this.items);
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      setTimeout(() => {
        this.control = ngControl.control as FormControl;
        this.addValidators();
      });
    } else {
      // Component is missing form control binding
      console.warn('Missing FormControlName directive from host element of the component');
    }
  }

  addValidators() {
    this.control.updateValueAndValidity();
  }

  validate({ value }: FormControl) {
    return null;
  }

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onValChange = fn;
  }
  // We implement this method to keep a reference to the onTouched
  //callback function passed by the forms API
  registerOnTouched(fn) {
    this.onValTouched = fn;
  }
  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

}
