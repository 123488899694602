import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'form-steps',
  templateUrl: './form-steps.component.html',
  styleUrls: ['./form-steps.component.css']
})
export class FormStepsComponent implements OnInit {

  @Input() application;
  @Input() component;
  @Input() action;
  @Input() currentStep;
  @Input() form;
  @Input() currentGroup;
  @Input() currentFormGroup;
  @Input() totalSteps;
  @Input() id;
  @Input() status;
  @Input() extraRoute;


  @Output()
  submit: EventEmitter<any> = new EventEmitter();

  constructor(public router: Router, public gs: GlobalService) { }

  ngOnInit() {
  }


  formStep(stepAction) {
    console.log(this.form, this.currentGroup);
    
    // console.log('Checking Validity for', this.form.get('basic').get(this.currentGroup));
    if (this.form && this.form.get(this.currentGroup) && this.gs.checkFormGroupValidity(this.form.get(this.currentGroup)) || (this.form && this.form.get('basic') && this.form.get('basic').get(this.currentGroup) && this.gs.checkFormGroupValidity(this.form.get('basic').get(this.currentGroup)))) {
      console.log('Error encountered', this.gs.checkFormGroupValidity(this.form.get(this.currentGroup)) || (this.form.get('basic')? this.form.get('basic').get(this.currentGroup) : undefined));
      return
    }
    let navigationArray = [this.application, this.component, this.action];
    if (stepAction == 'back') {
      navigationArray.push(--this.currentStep);
    }
    if (stepAction == 'next') {
      navigationArray.push(++this.currentStep);
    }
    if (this.status) {
      navigationArray.push(this.status);
    }
    if (this.id) {
      navigationArray.push(this.id)
    }
    if(this.extraRoute){
      navigationArray.push(this.extraRoute)
    }
    console.log(navigationArray);
    if (stepAction == 'back') {
      this.router.navigate(navigationArray);
    }
    if (stepAction == 'next') {
      this.router.navigate(navigationArray);
    }
  }

  submitFunction() {
    console.log('Checking Validity for', this.form.get(this.currentGroup),this.form);
    if (((this.form && this.form.get(this.currentGroup) && !this.gs.checkFormGroupValidity(this.form.get(this.currentGroup))) || (this.form && this.form.get('basic') && this.form.get('basic').get(this.currentGroup) && !this.gs.checkFormGroupValidity(this.form.get('basic').get(this.currentGroup))))) {
      this.submit.emit();
    } else {
      this.submit.emit();
    }
  }


}
