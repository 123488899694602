import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subtractArray'
})
export class SubtractArrayPipe implements PipeTransform {

  transform(value: any[], subtractArray: any): any {
    if(subtractArray=='reverse'){
      console.log('Reversing the array')
      return value.reverse()
    }
    return value;
  }

}
