import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { surimiPackings, packings } from '../../sales/sales-dropdowns';

@Pipe({
  name: 'countInnerData'
})
export class CountInnerDataPipe implements PipeTransform {

  transform(value: any, application: string, type: string, key: string, index?: any, control?: any,unit?: string): any {
    
    if (value) {

      if (application == 'sales') {
        if (type == 'remainingGlaze') {
          
          if (value) value = parseInt(value.split('%')[0])
          return 100 - value;
        }
        if (type == 'containers') {
          let sum = 0;
          return value.piDetails.pi.length
        }

        if (type == 'uniquePackaging') {
          let sum = 0;
          let tempKeys = {}
          value.piDetails.pi.forEach(pi => {
            pi.containers.forEach(container => {
              container.products.forEach(product => { 
                if (product.packing != null) {
                  tempKeys[product.packing] = true;
                }

              })
            })
          })
          
          return Object.keys(tempKeys).length;
        }

        if (type == 'advanceCount') {
          let sum = 0;
          
          
          value.piDetails.pi.forEach(pi => {
            sum += (parseFloat(pi[key]) || 0)
          })
          return isNaN(sum) ? 0 : sum;
        }

        if (type == 'productCount') {
          let count = 0;
          if (value.piDetails) {
            value.piDetails.pi.forEach((pi, i) => {
              if (!index || index == i) {
                pi.containers.forEach(container => {
                  container.products.forEach(product => {
                    count++;

                   
                  })
                })
              }

            })

          }
          return isNaN(count) ? 0 : count;
        }

        if (type == 'labSum') {
          let sum = 0;
          if (value.piDetails) {
            value.piDetails.pi.forEach((pi, i) => {
              if (!index || index == i) {
                pi.containers.forEach(container => {
                  container.products.forEach((product, productIndex) => {
                    let packing = packings.find(packing => packing.id == product.packing);
                    product.labDetails.forEach((lab: any, labIndex) => {
                      if (key != 'netWeight') {
                        sum += (parseFloat(lab[key]) || 0);
                      } else {
                        if (packing) {
                          sum += (parseFloat(lab.numberOfCartons) * parseFloat(packing.weight) || 0)
                          
                        }
                      }
                     

                    })

                  
                  })
                })
              }

            })
            

            return isNaN(sum) ? 0 : sum;

          }
        }

        if (type == 'productSum') {
          let sum = 0;
          if (value.piDetails) {
            value.piDetails.pi.forEach((pi, i) => {
              if (!index || index == i) {
                pi.containers.forEach(container => {
                  container.products.forEach(product => {
                    if(unit=="LBS"){
                      sum += (parseFloat(product[key]) * 2.20462);
                    }
                    else{
                      sum += parseFloat(product[key]);
                    }

                  })
                })
              }

            })
          }

       

          return isNaN(sum) ? 0 : parseFloat((unit=="LBS") ? sum.toFixed(0) : sum+'');
        }

        if (type == 'productSumForAverage') {
          let sum = 0;
          if (value) {
            // console.log(value);
            
            value.forEach(data => {
              console.log(data);
              data.body.piDetails.pi.forEach((pi, i) => {
                // console.log(pi);
                if (!index || index == i) {
                  pi.containers.forEach(container => {
                    container.products.forEach(product => {
                      if(unit=="LBS"){
                        sum += (parseFloat(product[key]) * 2.20462);
                      }
                      else{
                        console.log(key);
                        
                        sum += parseFloat(product[key]);
                      }
  
  
                      // if (key == 'grossWeightInKg') {
                      // }
                    })
                  })
                }
  
              })
            });
            
          }

          // if (control) {
          //   control.writeValue(isNaN(sum) ? 0 : sum)
          // }

          return isNaN(sum) ? 0 : parseFloat((unit=="LBS") ? sum.toFixed(0) : sum+'');
        }

        if (type == 'productListSum') {
          let sum = 0;
          if (value) {
            value.forEach((product, i) => {
              sum += parseFloat(product[key]);

            })
          }

          // if (control) {
          //   control.writeValue(isNaN(sum) ? 0 : sum)
          // }

          return isNaN(sum) ? 0 : sum;
        }
        if (type == 'productWiseLabSum') {
          let sum = 0;
          if (value.labDetails) {
            value.labDetails.forEach(lab => {
              sum += parseFloat(lab[key]);

            })
          }


          return isNaN(sum) ? 0 : sum;
        }


        if (type == 'documentSum') {
          let sum = 0;
          if (value.documents) {
            value.documents.ikcepa.forEach((quantity, i) => {
              if (!index || index == i) {
                sum += parseFloat(quantity[key]);
              }

            })
          }

          return isNaN(sum) ? 0 : sum;
        }

        if (type == 'containerSum') {
          let sum = 0;
          value.piDetails.pi.forEach(pi => {
            pi.containers.forEach(container => {
              sum += parseFloat(container[key]);
            })
          })
          return isNaN(sum) ? 0 : sum;
        }

      }


      if (application == 'wasteManagement') {

        if (type == 'vehicleSum') {
          let sum = 0;
          if (value.basic.vehicleDetails) {

            value.basic.vehicleDetails.forEach((vehicle, i) => {
              sum += parseFloat(vehicle[key][index]);

            })
          }

          return isNaN(sum) ? 0 : sum;
        }



        if (type == 'vehicleSum') {
          let sum = 0;
          if (value.basic.vehicleDetails) {

            value.basic.vehicleDetails.forEach((vehicle, i) => {
              sum += parseFloat(vehicle[key][index]);

            })
          }

          return isNaN(sum) ? 0 : sum;
        }

        if (type == 'weightSum') {
          let sum = 0;

          if (value) {

            value.forEach((data, i) => {
              if (data.basic.vehicleDetails) {
                // //console.logg(index)
                data.basic.vehicleDetails.forEach((vehicle, i) => {
                  if (key && vehicle[key][index]) {
                    if (!isNaN(vehicle[key][index]))
                      sum += parseFloat(vehicle[key][index]);
                  } else if (vehicle[index]) {
                    if (!isNaN(vehicle[index]))
                      sum += parseFloat(vehicle[index]);
                  }
                })
              }


            })

          }

          // if (control) {
          //   control.writeValue(isNaN(sum) ? 0 : sum)
          // }

          return isNaN(sum) ? 0 : sum;
        }

        if (type == 'basicSum') {
          let sum = 0;
          if (value) {

            value.forEach((data, i) => {

              if (key && data[key][index]) {
                if (!isNaN(data[key][index]))
                  sum += parseFloat(data[key][index]);
              } else if (data[index]) {
                if (!isNaN(data[index]))
                  sum += parseFloat(data[index]);
              }


            })

          }
          return isNaN(sum) ? 0 : sum;
        }

      }


      if (application == 'purchase') {
        let isArray = Array.isArray(value);
        //console.logg(application,type,key,index);

        if (type == 'totalWeight') {
          ////console.logg(type);

          let sum = 0;
          if (value) {
            ////console.logg(value);

            value.forEach((weight, i) => {
              weight.basic.fishes.forEach(fish => {
                if (fish[key][index]) {
                  sum += parseFloat(fish[key][index]);
                }
              })
              // ////console.logg(weight['basic'][key][index]);



            })
          }
          ////console.logg(sum);

          return isNaN(sum) ? 0 : sum;
        }

        if (type == 'fishTotal') {

          let sum = 0;
          if (value) {
            value.forEach((fishes, i) => {
              sum += parseFloat(fishes[key][index][control]);
              // ////console.logg(weight['basic'][key][index]);

            })
          }
          ////console.logg(sum);

          return isNaN(sum) ? 0 : sum;
        }

        if (type == 'payment') {

          let sum = 0;
          if (value) {
            value.forEach((data, i) => {
              sum += parseFloat(data['basic'][key]);
              // ////console.logg(weight['basic'][key][index]);

            })
          }
          ////console.logg(sum);

          return isNaN(sum) ? 0 : sum;
        }

        if(type=='loadingAmount'){
          let sum = 0;
          if(value){
            
            value.forEach((data,i) => {
              // //console.logg(data.value['whileLoading']['weightInKgs']);
              
              sum +=  parseFloat(data.value['whileLoading']['weightInKgs']) * parseFloat(data.value['whileLoading']['lcRate'])
            });
          }
          return isNaN(sum) ? 0 : sum;
        }

        if (!isArray) {
          let fishes = value;
          let sum = 0;
          for (const fishKey in fishes) {
            if (fishes.hasOwnProperty(fishKey)) {
              const fish = fishes[fishKey];
              // ////console.logg(fish,key,_.get(fish,key))
              if (type == 'sum') {
                sum += parseFloat(_.get(fish, key))
              }

            }
          }
          // if (type == 'fishWeight') {

          //   let sum = 0;


          //   if (value) {
          //     value.forEach((fishes, i) => {
          //       ////console.logg(fishes[key][index]);

          //       sum += parseFloat(fishes[key][index]);
          //       // ////console.logg(weight['basic'][key][index]);

          //     })


          //   }
          //   ////console.logg(sum);

          //   return isNaN(sum) ? 0 : sum;
          // }
          return sum;
        }
        if (isArray) {
          let fishes = value;
          let sum = 0;
          for (let i = 0; i < fishes.legth; i++) {
            if (fishes.hasOwnProperty(i)) {
              const fish = fishes[i];
              // ////console.logg(fish,key,_.get(fish,key))
              if (type == 'sum') {
                sum += parseFloat(_.get(fish, key))
              }

            }
          }

          return sum;
        }
        

      }

      if (application == 'generic') {
        if (!key) {
          let sum = 0
          value.forEach(data => {
            sum += parseFloat(_.get(data, type)) || 0;
          });
          return isNaN(sum) ? 0 : sum;
        } else {
          let sum = 0
          value.forEach(data => {
            let data1 = _.get(data, type);
            data1.forEach(data2 => {
              sum += parseFloat(_.get(data2, key)) || 0;
            })
          });
          return isNaN(sum) ? 0 : sum;
        }
      }
    }
    return value
  }

}
