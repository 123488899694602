import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment'

@Pipe({
  name: 'removeDuplicates'
})
export class RemoveDuplicatesPipe implements PipeTransform {

  transform(value: any[], key?): any {
    let tempObject = {};

    let returnArray = []

    if (!value) {
      return value;
    }
    // console.log(value, key);
    if(Array.isArray(key)){
      value.forEach(entry => { 
        let compareObject =''
        key.forEach(k=>{
          compareObject  += k ? _.get(entry, k) : entry;
        })
        // console.log(compareObject, JSON.stringify(compareObject));
        if (JSON.stringify(compareObject) != '{}') {
          try {
            let temp = moment(new Date(compareObject), 'YYYY-MM-DD', true);
            temp.set('hours', 0)
            temp.set('seconds', 0)
            temp.set("minutes", 0)
            temp.set("millisecond", 0)
            if (!temp.toString().toLowerCase().includes('date')) {
              compareObject = temp.toString();
            }
          } catch (error) {
            console.log(error)
          }
  
          if (!tempObject[compareObject + '']) {
            tempObject[compareObject + ''] = true;
            // console.log(value, tempObject, tempObject[compareObject + ''])
            returnArray.push(entry);
          }
        }
  
      })
    } else{
      value.forEach(entry => {

        let compareObject: any = key ? _.get(entry, key) : entry;
        // console.log(compareObject, JSON.stringify(compareObject));
        if (JSON.stringify(compareObject) != '{}') {
          try {
            let temp = moment(new Date(compareObject), 'YYYY-MM-DD', true);
            temp.set('hours', 0)
            temp.set('seconds', 0)
            temp.set("minutes", 0)
            temp.set("millisecond", 0)
            if (!temp.toString().toLowerCase().includes('date')) {
              compareObject = temp.toString();
            }
          } catch (error) {
            console.log(error)
          }
  
          if (!tempObject[compareObject + '']) {
            tempObject[compareObject + ''] = true;
            // console.log(value, tempObject, tempObject[compareObject + ''])
            returnArray.push(entry);
          }
        }
  
      })
    }
    return returnArray;
 

  }

}
