import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormControlName, Validators, FormGroup } from '@angular/forms';
import { FileManagerService } from '../file-manager.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import * as moment from 'moment';
import { GlobalService } from 'src/app/modules/shared/services/global.service';
import { environment } from 'src/environments/environment';
import { validator } from 'src/app/modules/shared/utils/validator';
import { ShareTransferFileReqObject } from 'src/app/interfaces/file-manager';

@Component({
  selector: 'app-file-transfer',
  templateUrl: './file-transfer.component.html',
  styleUrls: ['./file-transfer.component.scss']
})
export class FileTransferComponent implements OnInit {

  constructor(
    public gs: GlobalService,
    public fs: FileManagerService,
    public ar: ActivatedRoute,
    public httpClient: HttpClient,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) { }

  public files: Array<any> = [];
  public isProcessing: boolean = false;
  public url: string = "";
  public id: string = "";
  public displayCopyLink: boolean = true;
  public currentFile;
  public environment = environment;
  public displayForm: boolean = true;
  public zip: string = null;
  public allPromisesArray: Array<any> = [];
  public fileUploadTrackingArray: Array<any> = [];
  public displayYourEmailField: boolean = false;
  public addTag: boolean = false;
  public numberOfDays: number = 0;

  fileTransferForm: FormGroup;
  validator = new validator();
  receiverEmail = new FormControl([], { validators: [Validators.required, this.validator.validateEmail, this.validator.maxLength(5)] });
  senderEmail = new FormControl('', { validators: [Validators.required, this.validator.validateEmail] });
  text = new FormControl('');
  sendVia = new FormControl('email');
  public arrayOfFiles: Array<any> = [];


  ngOnInit() {

    // if (!this.gs.activeUserData) {
    //   this.gs.navigate("idm/sign-in", true);
    // }

    this.ar.params.subscribe(value => this.id = value.id);

    if (this.gs.currentUser) {
      this.senderEmail = new FormControl(this.gs.currentUser.basic.email ? this.gs.currentUser.basic.email : '', { validators: [Validators.required, this.validator.validateEmail] });
    }

    this.fileTransferForm = new FormGroup({
      receiverEmail: this.receiverEmail,
      senderEmail: this.senderEmail,
      text: this.text,
      sendVia: this.sendVia
    })

    if (this.fileTransferForm.controls.senderEmail.value) {
      this.fileTransferForm.controls.senderEmail.disable();
      this.displayYourEmailField = true;
    }

    if (this.id) {
      this.fs.fileManagerRequests.getTransferredFiles(this.id).then((transferredFilesResponseObject) => {
        this.displayForm = false;
        this.arrayOfFiles = transferredFilesResponseObject.files;
        this.zip = transferredFilesResponseObject.zip;
        var date = new Date();
        let remainingTimeStamp = date.getTime() - transferredFilesResponseObject['addedAt'];
        this.numberOfDays = remainingTimeStamp / (1000 * 3600 * 24);
        this.numberOfDays = Math.floor(this.numberOfDays);
        console.log(this.numberOfDays);
        console.log(this.arrayOfFiles);
        console.log(transferredFilesResponseObject);
      }).catch(err => {
        this.isProcessing = false;
        if (err.error && err.error.content && err.error.content.errorCode) {
          if (err.error.code == '400') {
            // Bad Request To Be Handled
            if (err.error.content.errorCode == "FM-000") {
              // error details
              // this.gs.showToast("error", err.error.content.message, "Error");
            }
            else if (err.error.content.errorCode == "FM-005") {
              // Download link has expired
              this.numberOfDays = 8;
              // this.gs.showToast("error", err.error.content.message, "Error");
            }
            else if (err.error.content.errorCode == "FM-400") {
              // Invalid Link
              // this.gs.showToast("error", err.error.content.message, "Error");
            }
          }
          else {
            // BackEnd Unknown Issue
            console.log(err);
          }
        }
        else {
          // Front end Unknown Issue
          console.log(err);
        }
      })
    }
  }

  sendAs(currentValue: string) {
    const receiverEmailFormControl = this.fileTransferForm.get('receiverEmail');
    const senderEmailFormControl = this.fileTransferForm.get('senderEmail');
    if (currentValue.toLowerCase() === "link") {
      receiverEmailFormControl.clearValidators();
      senderEmailFormControl.clearValidators();
      receiverEmailFormControl.setValidators(this.validator.validateEmail);
      senderEmailFormControl.setValidators([this.validator.validateEmail]);
      // receiverEmailFormControl.reset('');
      // senderEmailFormControl.reset('');
    }
    else {
      receiverEmailFormControl.setValidators([Validators.required, this.validator.validateEmail]);
      senderEmailFormControl.setValidators([Validators.required, this.validator.validateEmail]);
    }
    receiverEmailFormControl.updateValueAndValidity();
    senderEmailFormControl.updateValueAndValidity();
  }

  // public downloadFile(filePath:string, fileName) {
  //   console.log(filePath,fileName);
  //   filePath=filePath.replace(this.gs.storageUrl,'');
  //   console.log(filePath);

  //   if(this.gs.isBrowser){
  //     let query = fileName ? `${filePath}&fileName=${fileName}` : filePath;
  //     window.open(environment.FILE_MANAGER_API+"get-download-url?filePath=" + query+"&au="+this.gs.activeUserData.au);

  //   }
  // }

  pushPromisePerFile(i, event) {
    console.log(i);
    return new Promise((resolve, reject) => {

      console.log(this.allPromisesArray);
      console.log(event.target.files.length);
      this.fs.fileManagerRequests.getSignedUrl('general', event.target.files[i].name, event.target.files[i].type, 'bizDKncdiub8hjks').then(signedUrlResponseObject => {
        this.httpClient.put(signedUrlResponseObject.signedUrl, event.target.files[i], { observe: 'response' }).toPromise().then((response) => {
          if (response && response.status === 200) {
            this.fs.fileManagerRequests.confirmUpload(signedUrlResponseObject.uploadToken).then(uploadConfirmResponseObject => {
              console.log("Upload was Successful");
              console.log(uploadConfirmResponseObject);
              // this.allPromisesArray[i].resolve("index" + i);
              // this.allPromisesArray[i].then(res => {
              //   console.log(res);
              // })
              console.log(this.allPromisesArray[i]);
              console.log(this.currentFile);
              uploadConfirmResponseObject.contentType = event.target.files[i].type;
              console.log(uploadConfirmResponseObject.contentType);
              uploadConfirmResponseObject.fileName = event.target.files[i].name;
              console.log(uploadConfirmResponseObject.fileName);
              console.log(uploadConfirmResponseObject);
              this.files.push(uploadConfirmResponseObject);

              resolve(uploadConfirmResponseObject);

              // t
            })
          }
        }
        )
      })
    })
  }

  fileChanged(event) {
    if (event.target && event.target.files && event.target.files[0]) {
      if (!event.target.files[0].name) return;
    }
    console.log(event.target.files);

    this.isProcessing = true;

    this.allPromisesArray = [];
    // this.fs.fileManagerRequests.getSignedUrl()
    for (let i = 0; i < event.target.files.length; i++) {

      console.log(this.allPromisesArray);
      this.allPromisesArray[i] = this.pushPromisePerFile(i, event);




      // console.log(this.fileUploadTrackingArray);
      // if (i == event.target.files.length - 1) {
      //   console.log(this.fileUploadTrackingArray.every(element => {
      //     return element.status;
      //   }
      //   ));
      // }
    }
    Promise.all(this.allPromisesArray).then(res => {
      console.log(res);
      console.log(this.isProcessing);
      this.isProcessing = false;
    });
    // Promise.all([this.fs.fileManagerRequests.getSignedUrl('general', event.target.files[i].name, event.target.files[i].type, 'bizDKncdiub8hjks')]).then
  }

  shareFiles() {
    if (this.fileTransferForm.invalid) {
      for (let control in this.fileTransferForm.controls) {
        this.fileTransferForm.get(control).markAsDirty();
      }
      this.isProcessing = false;
      return;
    }
    else if (!this.files.length) {
      // this.gs.showToast("error", "Please attach file(s)", "Error");
      this.isProcessing = false;
      return;
    }
    let shareFilesRequestObject;

    shareFilesRequestObject = Object.assign<{}, ShareTransferFileReqObject>({}, this.fileTransferForm.value as ShareTransferFileReqObject);

    shareFilesRequestObject.receiverEmail = [{ address: shareFilesRequestObject.receiverEmail }];

    if (this.fileTransferForm.controls.senderEmail.value) {
      shareFilesRequestObject.senderEmail = this.fileTransferForm.controls.senderEmail.value;
    }

    // shareFilesRequestObject.files.forEach((element, i) => {
    //   element.push(this.files[i]);
    // })

    console.log(shareFilesRequestObject);

    console.log(this.files);

    shareFilesRequestObject.files = [];

    this.files.forEach(element => {
      shareFilesRequestObject.files.push(element);
    })

    console.log(shareFilesRequestObject.files);

    shareFilesRequestObject.files.forEach(element => {
      element.path = element.accessUrl;
      delete element.accessUrl;
    })

    if (environment.envName.toLowerCase() === "localhost") {
      shareFilesRequestObject.key = `http://localhost:4200/files/file-transfer`;
    }
    if (environment.envName.toLowerCase() === "dev") {
      shareFilesRequestObject.key = `https://www.simplifying.world/files/file-transfer`;
    }
    else if (environment.envName.toLowerCase() === "prod") {
      shareFilesRequestObject.key = `https://www.mesbro.com/files/file-transfer`;
    }

    console.log(shareFilesRequestObject);

    for (let i = 1; i < shareFilesRequestObject.receiverEmail[0].address.length; i++) {
      shareFilesRequestObject.receiverEmail.push({ address: shareFilesRequestObject.receiverEmail[0].address[i] });
    }
    shareFilesRequestObject.receiverEmail[0].address = shareFilesRequestObject.receiverEmail[0].address[0];
    console.log(shareFilesRequestObject);

    this.fs.fileManagerRequests.fileTransfer(shareFilesRequestObject).then((shareFilesResponseObject) => {
      this.isProcessing = false;

      this.url = shareFilesResponseObject.url;
      if (shareFilesRequestObject.sendVia.toLowerCase() === "link") {
        // this.gs.showToast("success", "Link Shared Successfully", "Success");
        this.displayForm = false;
      }
      else {
        // this.gs.showToast("success", "Files Sent Successfully", "Success");
        this.fileTransferForm.reset({ sendVia: 'email', senderEmail: this.gs.currentUser.basic.email ? this.gs.currentUser.basic.email : '', text: '', receiverEmail: '' });
        this.files = [];
      }
      console.log(this.url);
      // this.gs.navigate('/files/file-transfer/005c0e10-964a-11e9-b54c-e3acd4d5d4ce', true);
    }).catch(err => {
      this.isProcessing = false;
      if (err.error && err.error.content && err.error.content.errorCode) {
        if (err.error.code == '400') {
          // Bad Request To Be Handled
          if (err.error.content.errorCode == "FM-000") {
            // error details
            // this.gs.showToast("error", "Error", err.error.content.message);
          }
          else if (err.error.content.errorCode == "FM-001") {
            // You do not have this file in your file stock
            // this.gs.showToast("error", "Error", err.error.content.message);
          }
          else if (err.error.content.errorCode == "FM-002") {
            // Upload has expired, cannot perform this operation
            // this.gs.showToast("error", "Error", err.error.content.message);
          }
          else if (err.error.content.errorCode == "FM-003") {
            // You cannot share file with yourself
            // this.gs.showToast("error", "Error", err.error.content.message);
          }
        }
        else {
          // BackEnd Unknown Issue
          console.log(err);
        }
      }
      else {
        // Front end Unknown Issue
        console.log(err);
      }
    })
  }

  
  downloadAllFiles() {
    if (this.gs.isBrowser) {
      console.log(environment.FILE_MANAGER_API + "get-download-url?filePath=" + this.zip);
      window.open(environment.FILE_MANAGER_API + "get-download-url?filePath=" + this.zip);
    }
  }


  copyToClipboard(id) {
    var text = document.getElementById(id).innerText;
    console.log(text);
    var elem = document.createElement("textarea");
    document.body.appendChild(elem);
    elem.value = text;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
    // this.gs.showToast("success", "Success", "Link has been copied");
  }

  addTagPromise = (name: string) => {
    // console.log(t);
    this.addTag = false;

    if (name.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      this.addTag = true;
    } else {
      this.addTag = false;
    }
    return this.addTag ? name : this.addTag;

    // setTimeout(() => {
    //     resolve({ id: 5, name: name, valid: true });
    //  }, 1000);
  };


}

