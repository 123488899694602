import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateTotal'
})
export class CalculateTotalPipe implements PipeTransform {

  transform(value: any, key: string, keyValue: string , weight : string): any {
    console.log(value,key,keyValue,weight)
    let sum = 0
    if(key=="sezAmount"){
      value.forEach(fish => {
        let amount = fish['whileReceipt']['sezWeight'] * fish['whileReceipt']['sezRate']
        console.log(amount)
        sum += (amount || 0);
        console.log(sum)
        
      });
  
      return sum
    }
    if (!weight) {
      value.forEach(fish => {
        sum += (fish[key][keyValue] || 0);
        // console.log(sum)
        
      });
  
      return sum
    }
    else{
      value.forEach(fish => {
        sum += (fish[key][keyValue][weight] || 0);
        // console.log(sum)
      });
  
      return sum
    }
    
  }

}
