import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { FileManager } from 'src/app/requests/file-manager';
import { Videos } from 'src/app/requests/videos';
import { GlobalService } from 'src/app/modules/shared/services/global.service';
// import { ListFilterReq } from 'src/app/interfaces/videos';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {

  public fileManagerRequests: FileManager;
  public videoRequests: Videos;

  public config: any;

  public allFiles = [];
  public sharedByMeFiles = [];
  public sharedWithMeFiles = [];
  public currentResponseAllFiles: any;
  public currentResponseSharedByMeFiles: any;
  public currentResponseSharedWithMeFiles: any;

  constructor(public http: HttpClient, public gs: GlobalService) {
    this.fileManagerRequests = new FileManager(http);
    this.videoRequests = new Videos(http);
  }

  async getAllFiles(query?, type?, page?: number) {

    // let lastItemId = ((!query || !this.allFiles.length) ? null : this.allFiles[this.allFiles.length - 1].id);
    // let lastItemId = this.allFiles.length ? this.allFiles[this.allFiles.length - 1].id : null;
    try {
      if (type == 'video' && false) {
        let req: any = {
          page: 0,
          filters: {
            query: query || ""
          }
        };
        if (page) {
          req.page = page;
        }
        this.currentResponseAllFiles = await this.videoRequests.getFilteredListOfVideos(req);
        if (!page) {
          this.allFiles = this.currentResponseAllFiles;
        }
        else if ((query && page && this.allFiles.length) || (page && this.allFiles.length)) {
          this.allFiles = this.allFiles.concat(this.currentResponseAllFiles);
        }
        else {
          this.allFiles = this.currentResponseAllFiles;
        }
      } else {
        this.currentResponseAllFiles = await this.fileManagerRequests.getUploadList(null, query, type);

        // this.allFiles = !lastItemId? (await this.fileManagerRequests.getUploadList(null, query, type)): this.allFiles.concat((await this.fileManagerRequests.getUploadList(lastItemId, query, type)));
        if (!page) {
          this.allFiles = this.currentResponseAllFiles;
          console.log("-->", this.allFiles)
        }
        else if ((query && page && this.allFiles.length) || (page && this.allFiles.length)) {
          this.currentResponseAllFiles = await this.fileManagerRequests.getUploadList(page, query, type);
          this.allFiles = this.allFiles.concat(this.currentResponseAllFiles);
        }
        else {
          this.allFiles = this.currentResponseAllFiles;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getAllFilesSharedByMe(query?, type?, lastItemId: string = null) {

    try {
      this.currentResponseSharedByMeFiles = await this.fileManagerRequests.getUploadList(lastItemId, query, type, true, "2");
      // this.allFiles = !lastItemId? (await this.fileManagerRequests.getUploadList(null, query, type)): this.allFiles.concat((await this.fileManagerRequests.getUploadList(lastItemId, query, type)));
      if (!lastItemId) {
        this.sharedByMeFiles = this.currentResponseSharedByMeFiles;
      }
      else if ((query && lastItemId && this.sharedByMeFiles.length) || (lastItemId && this.sharedByMeFiles.length)) {
        this.sharedByMeFiles = this.sharedByMeFiles.concat(this.currentResponseSharedByMeFiles);
      }
      else {
        this.sharedByMeFiles = this.currentResponseSharedByMeFiles;
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  async getAllFilesSharedWithMe(query?, type?, lastItemId: string = null) {
    try {
      this.currentResponseSharedWithMeFiles = await this.fileManagerRequests.getUploadList(lastItemId, query, type, true, "1");
      // this.allFiles = !lastItemId? (await this.fileManagerRequests.getUploadList(null, query, type)): this.allFiles.concat((await this.fileManagerRequests.getUploadList(lastItemId, query, type)));
      if (!lastItemId) {
        this.sharedWithMeFiles = this.currentResponseSharedWithMeFiles;
      }
      else if ((query && lastItemId && this.sharedWithMeFiles.length) || (lastItemId && this.sharedWithMeFiles.length)) {
        this.sharedWithMeFiles = this.sharedWithMeFiles.concat(this.currentResponseSharedWithMeFiles);
      }
      else {
        this.sharedWithMeFiles = this.currentResponseSharedWithMeFiles;
      }
    }
    catch (err) {
      if (err.error && err.error.content && err.error.content.errorCode) {
        if (err.error.code == '400') {
          // Bad Request To Be Handled
          if (err.error.content.errorCode == "FM-000") {
            // error details
            // this.gs.showToast("error", "Error", err.error.content.message);
          }
        }
        else {
          // BackEnd Unknown Issue
          // this.gs.showToast("error", "Error", err);
        }
      }
      else {
        // Front end Unknown Issue
        // this.gs.showToast("error", "Error", err);
      }
    }
  }


  async uploadImage(file: File) {

    if (!file) {
      return;
    }

    try {
      let getSignedresponse: HttpResponse<any> = await this.http.get(environment.FILE_MANAGER_API + "upload/get-signed-url?type=image&fileName=" + encodeURIComponent(file.name) + "&fileType=" + file.type, { observe: 'response' }).toPromise();

      if (getSignedresponse.status == 200) {
        let signedUrl = getSignedresponse.body.content.signedUrl;
        let uploadToken = getSignedresponse.body.content.uploadToken;

        if (file) {
          const httpOptions = {
            headers: new HttpHeaders({}),
          };
          const url = signedUrl;

          return this.http.put(
            url,
            file,
            { observe: 'response', ...httpOptions }
          ).toPromise().then((response) => {
            if (response && response.status == 200) {
              let token = { uploadToken: uploadToken };
              return this.http.post(environment.BUSINESS_ADMIN + 'api/business/file-manager/confirm-upload', token, {
                observe: 'response'
              }).toPromise()
                .then((response: any) => {
                  if (response && response.status == 200) {
                    // this.selectedFiles = [response.body.content.accessUrl];
                    console.log(response.body.content);
                    return Promise.resolve(environment.STORAGE_URL + response.body.content.accessUrl);
                  }
                }).catch(err => {
                  {

                    return Promise.reject(err);
                    // if (err.error && err.error.content && err.error.content.errorCode) {
                    //   if (err.error.code == '400') {
                    //     //Bad Request to be handled
                    //     if (err.error.content.errorCode == "FM-000") {
                    //       // <field> is required or <field> is not allowed
                    //       this.gs.showToast("error", "Error", err.error.content.message)
                    //     }
                    //     else if (err.error.content.errorCode == "FM-400") {
                    //       // You cannot get confirm
                    //       this.gs.showToast("error", "Error", err.error.content.message)
                    //     }
                    //   }
                    //   else {
                    //     // BackEnd Unknown Issue
                    //     console.log(err.error);
                    //   }
                    // }
                    // else {
                    //   // Front end Unknown Issue
                    //   console.log(err.error);
                    // }
                  }
                });
            }
          }).catch(err => {

            return Promise.reject(err)
          }
          );
        }
      }
    } catch (e) {
      // console.log(e);
      return Promise.reject(e);
      // this.modalRef.hide();
    }
  }

  _addImageUploadBtn(ckEditor) {
    const editor = ckEditor && ckEditor.instance;

    if (!editor) {
      return;
    }

    editor.config.placeholder = "Your content here...";

    editor.config.removePlugins = 'elementspath,save,flash,iframe,smiley,find,pagebreak,templates,about,maximize,showblocks,newpage,language,source,Maximize';

    editor.config.removeButtons = 'Copy,Cut,Paste,Undo,Redo,Source,Print,Form,TextField,Textarea,Button,SelectAll,CreateDiv,Table,PasteText,PasteFromWord,Select,HiddenField,Maximize';
    editor.addCommand('uploadImage', {
      exec: (editor: any) => {
        // Remove img input.
        [].slice.apply(document.querySelectorAll('.ck-editor-upload-img')).forEach((img: any) => {
          img.remove();
        });
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('class', 'ck-editor-upload-img');
        input.style.display = 'none';
        input.addEventListener(
          'change',
          async e => {
            const file = (e.target as HTMLInputElement).files[0];
            if (file) {
              // do upload then insert img link to editor

              try {

                const filepath = await this.uploadImage(file);
                editor.insertHtml(`<img title="Double click to resize" style="max-width: 100%;" alt="" src="${filepath}"></img>`);

              } catch (error) {
                console.log(error);
              }


            }
          },
          false
        );
        document.body.appendChild(input);
        input.click();
      }
    });
    editor.ui.addButton('uploadImage', {
      icon: 'https://cdn2.iconfinder.com/data/icons/picons-essentials/71/gallery-512.png',
      label: 'Upload Image',
      command: 'uploadImage',
      toolbar: 'insert'
    });
  }

  async uploadFile(file: File, returnType?) {
    try {
      // console.log('returnType', returnType)
      // encodeURIComponent(this.croppedImage.name)
      let fileObj = {
        basic: {
          type: "image",
          fileName: file.name,
          fileType: file.type
        },

        type: "image",
        fileName: file.name,
        fileType: file.type

      };

      let getSignedresponse: HttpResponse<any> = await this.http.post(environment.BUSINESS_ADMIN + "api/business/file-manager/get-signed-url", fileObj, { observe: 'response' }).toPromise();
      console.log(getSignedresponse);
      
      if (getSignedresponse.status == 200) {
        let signedUrl = getSignedresponse.body.details.signedUrl;
        let uploadToken = getSignedresponse.body.details.uploadToken;

        if (file) {
          const httpOptions = {
            headers: new HttpHeaders({}),
          };
          const url = signedUrl;
          console.log(url);
          
         return this.http.put(
            url,
            file,
            { observe: 'response', ...httpOptions }
          ).toPromise().then((response) => {
            if (response && response.status == 200) {
              // this.isProcessing = false;
              let token = { uploadToken: uploadToken }
              return this.http.post(environment.BUSINESS_ADMIN + 'api/business/file-manager/confirm-upload', token, {
                observe: 'response'
              }).toPromise()
                .then((response: any) => {
                  if (response && response.status == 200) {
                    // environment.STORAGE_URL + response.body["basic"].accessUrl
                    return Promise.resolve(environment.STORAGE_URL + response.body["basic"].accessUrl)
                  }
                }).catch(err => {
                  console.log(err);
                  return Promise.reject(err)
                });
            }
          });
        }
      }
      // this.isProcessing = false;
    } catch (err) {
      // console.log(e);
      console.log(err);
      return Promise.reject(err)
      // this.modalRef.hide();
    }
  }

}
