import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ThemeConstantService } from './services/theme-constant.service';
import { SearchPipe } from './pipes/search.pipe';
import { MesbroInputsModule } from './components/mesbro-inputs/mesbro-inputs.module';
import { FormStepsComponent } from './components/form-steps/form-steps.component';
import { FormArrayButtonsComponent } from './components/form-array-buttons/form-array-buttons.component';
import { FormArrayLengthPipe } from './pipes/form-array-length.pipe';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { FilterByKeyAndIdPipe } from './pipes/filter-by-key-and-id.pipe';
import { ObjectFromArrayPipe } from './pipes/object-from-array.pipe';
import { CalculateTotalPipe } from './pipes/calculate-total.pipe';
import { SplitByColonPipe } from './pipes/split-by-colon.pipe';
import { SubtractArrayPipe } from './pipes/subtract-array.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { CasePipe } from './pipes/case.pipe';
import { PermissionPipe } from './pipes/permission.pipe';
import { ContainerOpenOrderPipe } from './pipes/container-open-order.pipe';
import { PrintFromArrayPipe } from './pipes/print-from-array.pipe';
import { TabFilterPipe } from './pipes/tab-filter.pipe';
import { CountInnerDataPipe } from './pipes/count-inner-data.pipe';
import { AmountInWordsPipe } from './pipes/amount-in-words.pipe';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { CalculateRowsPipe } from './calculate-rows.pipe';
import { RemoveDuplicatesPipe } from './pipes/remove-duplicates.pipe';
import { TypeOfPipe } from './pipes/type-of.pipe';
import { ConvertWeightPipe } from './pipes/convert-weight.pipe';
import { MonthInWordsPipe } from './pipes/month-in-words.pipe';
import { ReplaceStrPipe } from './pipes/replace-str.pipe';
import { ArnNoPipe } from './pipes/arn-no.pipe';
import { ConvertStringToFloatPipe } from './pipes/convert-string-to-float.pipe';

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        NgZorroAntdModule,
        PerfectScrollbarModule,
        SearchPipe,
        MesbroInputsModule,
        FormStepsComponent,
        FormArrayButtonsComponent,
        FormArrayLengthPipe,
        SentenceCasePipe,
        FilterByKeyAndIdPipe,
        ObjectFromArrayPipe,
        CalculateTotalPipe,
        SplitByColonPipe,
        SubtractArrayPipe,
        SortPipe,
        CasePipe,
        PermissionPipe,
        ContainerOpenOrderPipe,
        PrintFromArrayPipe,
        TabFilterPipe,
        CountInnerDataPipe,
        AmountInWordsPipe,
        ZXingScannerModule,
        NgxQRCodeModule ,
        CalculateRowsPipe,
        RemoveDuplicatesPipe,
        TypeOfPipe,
        ConvertWeightPipe,
        MonthInWordsPipe,
        ReplaceStrPipe,
        ArnNoPipe,
        ConvertStringToFloatPipe
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgZorroAntdModule,
        PerfectScrollbarModule,
        MesbroInputsModule,
        ZXingScannerModule,
        NgxQRCodeModule 
    ],
    declarations: [
        SearchPipe,
        FormStepsComponent,
        FormArrayButtonsComponent,
        FormArrayLengthPipe,
        SentenceCasePipe,
        FilterByKeyAndIdPipe,
        ObjectFromArrayPipe,
        CalculateTotalPipe,
        CalculateTotalPipe,
        SplitByColonPipe,
        SubtractArrayPipe,
        SortPipe,
        CasePipe,
        PermissionPipe,
        ContainerOpenOrderPipe,
        PrintFromArrayPipe,
        TabFilterPipe,
        CountInnerDataPipe,
        AmountInWordsPipe,
        CalculateRowsPipe,
        RemoveDuplicatesPipe,
        TypeOfPipe,
        ConvertWeightPipe,
        MonthInWordsPipe,
        ReplaceStrPipe,
        ArnNoPipe,
        ConvertStringToFloatPipe,
    ],
    providers: [
        ThemeConstantService
    ]
})

export class SharedModule { }
