import { formatNumber } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertStringToFloat'
})
export class ConvertStringToFloatPipe implements PipeTransform {
  constructor(private _decimalPipe: DecimalPipe) {}
  transform(value, unit): any {
    console.log(value, parseFloat(value));
    let decimal = this.roundTo(value,2)
    
    return decimal
  }

  roundTo = (n, digits) => {
    if (digits === undefined) {
      digits = 0;
    }
  
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    var test =(Math.round(n) / multiplicator);
    return +(test.toFixed(digits));
  }

}
