class Dropdowns {

  days = [
    { name: 'jan 1 jan 16', value: ["2020-01-01T13:48:36.018Z", "2020-01-16T13:48:36.018Z"] },
    { name: 'jan 17 jan 31', value: ["2020-01-17T13:48:36.018Z", "2020-01-31T13:48:36.018Z"] },
    { name: 'feb 1 feb 16', value: ["2020-02-01T13:48:36.018Z", "2020-02-16T13:48:36.018Z"] },
    { name: 'feb 17 feb 29', value: ["2020-02-17T13:48:36.018Z", "2020-02-29T13:48:36.018Z"] },
    { name: 'mar 1 mar 16', value: ["2020-03-01T13:48:36.018Z", "2020-03-16T13:48:36.018Z"] },
    { name: 'mar 17 mar 31', value: ["2020-03-17T13:48:36.018Z", "2020-03-31T13:48:36.018Z"] },
    { name: 'apr 1 apr 16', value: ["2020-04-01T13:48:36.018Z", "2020-04-16T13:48:36.018Z"] },
    { name: 'apr 17 apr 30', value: ["2020-04-17T13:48:36.018Z", "2020-04-30T13:48:36.018Z"] },
    { name: 'may 1 may 16', value: ["2020-05-01T13:48:36.018Z", "2020-05-16T13:48:36.018Z"] },
    { name: 'may 17 may 31', value: ["2020-05-17T13:48:36.018Z", "2020-05-31T13:48:36.018Z"] },
    { name: 'jun 1 jun 16', value: ["2020-06-01T13:48:36.018Z", "2020-06-16T13:48:36.018Z"] },
    { name: 'jun 17 jun 30', value: ["2020-06-17T13:48:36.018Z", "2020-06-30T13:48:36.018Z"] },
    { name: 'jul 1 jul 16', value: ["2020-07-01T13:48:36.018Z", "2020-07-16T13:48:36.018Z"] },
    { name: 'jul 17 jul 31', value: ["2020-07-17T13:48:36.018Z", "2020-07-31T13:48:36.018Z"] },
    { name: 'aug 1 aug 16', value: ["2020-08-01T13:48:36.018Z", "2020-08-16T13:48:36.018Z"] },
    { name: 'aug 17 aug 31', value: ["2020-08-17T13:48:36.018Z", "2020-08-31T13:48:36.018Z"] },
    { name: 'sep 1 sep 16', value: ["2020-09-01T13:48:36.018Z", "2020-09-16T13:48:36.018Z"] },
    { name: 'sep 17 sep 30', value: ["2020-09-17T13:48:36.018Z", "2020-09-30T13:48:36.018Z"] },
    { name: 'oct 1 oct 16', value: ["2020-10-01T13:48:36.018Z", "2020-10-16T13:48:36.018Z"] },
    { name: 'oct 17 oct 31', value: ["2020-10-17T13:48:36.018Z", "2020-10-31T13:48:36.018Z"] },
    { name: 'nov 1 nov 16', value: ["2020-11-01T13:48:36.018Z", "2020-11-16T13:48:36.018Z"] },
    { name: 'nov 17 nov 30', value: ["2020-11-17T13:48:36.018Z", "2020-11-30T13:48:36.018Z"] },
    { name: 'dec 1 dec 16', value: ["2020-12-01T13:48:36.018Z", "2020-12-16T13:48:36.018Z"] },
    { name: 'dec 17 dec 31', value: ["2020-12-17T13:48:36.018Z", "2020-12-31T13:48:36.018Z"] },
  ]
  tdsValues = [
    { year: ["2019-04-01T13:48:36.018Z", "2021-03-31T13:48:36.018Z"], tds: 0, company: 'ulka' },
    { year: ["2021-04-01T13:48:36.018Z", "2022-03-31T13:48:36.018Z"], tds: 0.1, company: 'ulka' },
    { year: ["2022-04-01T13:48:36.018Z", "2023-03-31T13:48:36.018Z"], tds: 0.1, company: 'ulka' },
    { year: ["2019-04-01T13:48:36.018Z", "2021-03-31T13:48:36.018Z"], tds: 0, company: 'shree-ulka' },
    { year: ["2021-04-01T13:48:36.018Z", "2022-03-31T13:48:36.018Z"], tds: 0.1, company: 'shree-ulka' },
    { year: ["2022-04-01T13:48:36.018Z", "2023-03-31T13:48:36.018Z"], tds: 0.1, company: 'shree-ulka' },
  ]
  sourceType = [
    { id: "Exhibition", name: "Exhibition" },
    { id: "Export Data", name: "Export Data" },
    { id: "Friend Reference", name: "Friend Reference" },
    { id: 'Visit', name: 'Visit' },
    { id: 'From Mpeda', name: 'From Mpeda' },
    { id: 'Email', name: 'Email' },
    { id: 'Direct contact', name: 'Direct contact' },
    // { id: 5, name: 'Contractor Staff' }
  ];
  personType = [
    { id: 'SUPPLIER', name: 'SUPPLIER' },
    { id: 'SUPERVISOR', name: 'SUPERVISOR' },
    { id: 'AGENT', name: 'AGENT' },
    { id: 'UNLOADING SUPERVISOR', name: 'UNLOADING SUPERVISOR' },
    { id: 'THAPPY BOY', name: 'THAPPY BOY' },
    { id: 'TRANSPORTER', name: 'TRANSPORTER' },
    // { id: 5, name: 'Contractor Staff' }
  ];

  employeeType = [
    { id: 'PEELING', name: 'PEELING', type: 'peeling', shortName: 'PL' },
    { id: 'STORE', name: 'STORE', type: 'attendance', shortName: 'ST' },
    { id: 'PLANT', name: 'PLANT', type: 'attendance', shortName: 'PLANT' },
    { id: 'INSIDE GRADING', name: 'INSIDE GRADING', type: 'attendance', shortName: 'IG' },
    { id: 'OUTSIDE GRADING', name: 'OUTSIDE GRADING', type: 'attendance', shortName: 'OG' },
    { id: 'INSIDE PACKING', name: 'INSIDE PACKING', type: 'attendance', shortName: 'IP' },
    { id: 'OUTSIDE PACKING', name: 'OUTSIDE PACKING', type: 'attendance', shortName: 'OP' },
    { id: 'KITCHEN', name: 'KITCHEN', type: 'attendance', shortName: 'KIT' },
    { id: 'WASHING', name: 'WASHING', type: 'attendance', shortName: 'WAS' },
    { id: 'HEAD(GIRL)', name: 'HEAD(GIRL)', type: 'attendance', shortName: 'HG' },
    { id: 'SUPERVISORS', name: 'SUPERVISORS', type: 'attendance', shortName: 'SUP' },
    { id: 'SWEEPER', name: 'SWEEPER', type: 'attendance', shortName: 'SWE' },
    { id: 'RAW MATERIAL', name: 'RAW MATERIAL', type: 'attendance', shortName: 'RM' },
    { id: 'HEAD BOY', name: 'HEAD BOY', type: 'attendance', shortName: 'HB' },
    { id: 'OLD SURIMI GIRLS', name: 'OLD SURIMI GIRLS', type: 'attendance', shortName: 'OSU' },
    { id: 'MITREN', name: 'MITREN', type: 'attendance', shortName: 'MET' },
    { id: 'HEAD PLANT BOYS', name: 'HEAD PLANT BOYS', type: 'attendance', shortName: '' },
    { id: 'WASTE', name: 'WASTE', type: 'attendance', shortName: 'WAST' },
    { id: 'INSIDE PACKING (BOYS)', name: 'INSIDE PACKING (BOYS)', type: 'attendance', shortName: 'IP BOY' },
    { id: 'KITCHEN GIRLS', name: 'KITCHEN GIRLS', type: 'attendance', shortName: 'KIT F' },
  ];

  hsnCode = [

    {
      RANI: [
        {
          fish: '03063600'
        }
      ]
    }

  ]

  dutyDrawbackCode = [
    {
      fish: {
        SHRIMPS: '030605B'
      }
    }
  ]

  variety = [
    {
      "division": "both",
      "variety": "SHRIMPS",
      "dbkCode": "030605B",
      "hsnCode": "03061900"
    },
    {
      "division": "both",
      "variety": "SQUID",
      "dbkCode": "030701B",
      "hsnCode": "03074320",
    //  "dbkrate":"2.90%"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "dbkCode": "0000000",
      "hsnCode": "00000000"
    },
    // {
    //     "division": "fish",
    //     "variety": "BARACUDA"
    // },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "dbkCode": "0000000",
      "hsnCode": "00000000"
    },
    {
      "division": "both",
      "variety": "CROAKER",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "both",
      "variety": "BMC",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "both",
      "variety": "HORSE MACKERAL",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "both",
      "variety": "INDIAN MACKERAL",
      "dbkCode": "0303B",
      "hsnCode": "03024400"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "dbkCode": "0000000",
      "hsnCode": "00000000"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "dbkCode": "0000000",
      "hsnCode": "00000000"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "dbkCode": "0000000",
      "hsnCode": "00000000"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "dbkCode": "0000000",
      "hsnCode": "00000000"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "dbkCode": "0000000",
      "hsnCode": "00000000"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "dbkCode": "0000000",
      "hsnCode": "00000000"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "dbkCode": "0000000",
      "hsnCode": "00000000"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "dbkCode": "0000000",
      "hsnCode": "00000000"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //  "dbkCode":"0000000",
    //   "hsnCode" : "00000000"
    // },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "both",
      "variety": "RANI",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "both",
      "variety": "LIZARD",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "both",
      "variety": "GOFAN",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "both",
      "variety": "MIX",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "dbkCode": "030201B",
      "hsnCode": "03021900"
    },
    {
      "division": "surimi",
      "variety": "NO MARK",
      "dbkCode": "",
      "hsnCode": "",
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "dbkCode": "030201B",
      "hsnCode": "03021900",
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "dbkCode": "00000000",
      "hsnCode": "00000000",
    }
  ];
  type = [
    {
      "division": "both",
      "variety": "SHRIMPS",
      "type": "HEAD ON"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "type": "HEAD LESS"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "type": "HLEP"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "type": "PD"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "type": "PUD"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "type": "PDTON"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "type": "PUDTON"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "type": "BUTTER FLY"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "type": "WHOLE"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "type": "TUBE"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "type": "TN"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "type": "THREE CUT"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "type": "WHOLE CLEAND"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "type": "WING"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "type": "TUBE"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "type": "RING"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "type": "TN"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "type": "STRIPS"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "type": "STUFF"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "type": "FILLET"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "type": "CUT"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "type": "TWO CUT"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "type": "WHOLE"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "type": "WHOLE CLEANED"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "type": "FILLET"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "type": "TN"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "type": "WINGS"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "type": "STRIPS"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "type": "EGG"
    },
    // {
    //     "division": "fish",
    //     "variety": "BARAKUDA",
    //     "type": "WHOLE"
    // },
    // {
    //     "division": "fish",
    //     "variety": "BARAKUDA",
    //     "type": "GUTTED"
    // },
    // {
    //     "division": "fish",
    //     "variety": "BARAKUDA",
    //     "type": "STICK"
    // },
    // {
    //     "division": "fish",
    //     "variety": "BARAKUDA",
    //     "type": "PORTION"
    // },
    // {
    //     "division": "fish",
    //     "variety": "BARAKUDA",
    //     "type": "HEAD ON"
    // },
    // {
    //     "division": "fish",
    //     "variety": "BARAKUDA",
    //     "type": "HEAD LESS"
    // },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "type": "PORTION"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "type": "WHOLE"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "type": "GUTTED"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "type": "HEAD LESS"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "type": "STICK"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "type": "U/CUT"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "type": "GUTTED"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "type": "HEAD ON"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "type": "HEAD LESS"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "type": "U/CUT"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "type": "GUTTED"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "type": "HEAD ON"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "type": "HEAD LESS"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "type": "U/CUT"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "type": "GUTTED"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "type": "HEAD ON"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "type": "HEAD LESS"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "type": "U/CUT"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "type": "GUTTED"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "type": "HEAD ON"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "type": "HEAD LESS"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "type": "TAIL"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "type": "PUD"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "type": "HEAD LESS"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "type": "HEAD ON"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "type": "U/CUT"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "type": "GUTTED"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "type": "HEAD ON"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "type": "HEAD LESS"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "type": "WHOLE CLEAN"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "type": "CHOPPED"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "type": "CUT"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "type": "WHOLE"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "type": "WHOLE"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "type": "WHOLE CLEAN"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "type": "CHOPPED"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "type": "CUT"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "type": "U/CUT"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "type": "GUTTED"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "type": "HEAD ON"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "type": "HEAD LESS"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "type": "HEAD ON"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "type": "HEAD LESS"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "type": "U/CUT"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "type": "GUTTED"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "type": "GUTTED"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "type": "HEAD ON"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "type": "HEAD LESS"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "type": "FILLETS"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "type": "U/CUTTING"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "type": "HEAD ON"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "type": "HEAD LESS"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "type": "GUTTED"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "type": "HEAD ON"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "type": "HEAD LESS"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "type": "HEAD ON"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "type": "HEAD LESS"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "type": "U / CUTTING"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "type": "FITLET"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "type": "HEAD ON"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "type": "HEAD LESS"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "type": "FILLET"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "type": "MOON CUT"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "type": "WHOLE SHATTER"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "type": "WHOLE IWP"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "type": "WHOLE CLEAN IWP"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "type": "WHOLE GATTED"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "type": "HEAD ON"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "type": "HEAD LESS"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "type": "U / CUTTING"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "type": "FITLET"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "type": "GUTTED"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "type": "WHOLE SHATTER"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "type": "WHOLE IWP"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "type": "GATTED IWP"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "type": "HEAD ON"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "type": "HEAD LESS"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "type": "U / CUTTING"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "type": "FITLET"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "type": "HEAD LESS GUTTED"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "type": "HEAD LESS GUTTED TAILLESS"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "type": "WHOLE"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "type": "CHUNKS"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "type": "GUTTED"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "type": "HEAD LESS"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "type": "PORTION"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "type": "HEAD ON"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "type": "TAIL LESS"
    },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "type": "CHUNKS"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "type": "GUTTED"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "type": "HEAD LESS"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "type": "PORTION"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "type": "HEAD ON"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "type": "TAIL LESS"
    // },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "type": "FITLET"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "type": "U / CUTTING"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "type": "HEAD ON"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "BARACUDA",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "BARACUDA",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "BARACUDA",
      "type": "U / CUTTING"
    },
    {
      "division": "surimi",
      "variety": "BARACUDA",
      "type": "FITLET"
    },
    {
      "division": "surimi",
      "variety": "BARACUDA",
      "type": "WHOLE"
    },
    {
      "division": "surimi",
      "variety": "BARACUDA",
      "type": "GUTTED"
    },
    {
      "division": "surimi",
      "variety": "BARACUDA",
      "type": "STICK"
    },
    {
      "division": "surimi",
      "variety": "BARACUDA",
      "type": "PORTION"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "type": "U / CUTTING"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "type": "HEAD"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "type": "U / CUTTING"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "type": "FILLET"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "type": "U / CUTTING"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "type": "FITLET"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "type": "U / CUTTING"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "type": "FITLET"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "type": "U / CUTTING"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "type": "FITLET"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "type": "U / CUTTING"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "type": "FITLET"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "type": "U / CUTTING"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "type": "FITLET"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "type": "U / CUTTING"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "type": "FITLET"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "type": "U / CUTTING"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "type": "FITLET"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "type": "U / CUTTING"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "type": "FITLET"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "type": "HEAD"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "type": "U / CUTTING"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "type": "FITLET"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "type": "U / CUTTING"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "type": "FITLET"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "type": "U/CUTTING"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "type": "FILLET"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "type": "U/CUTTING"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "type": "FILLET"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "type": "U/CUTTING"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "type": "FILLET"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "type": "HEAD ON"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "type": "HEAD LESS"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "type": "U/CUTTING"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "type": "FILLET"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "HEAD ON"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "HEAD LESS"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "SKIN"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "MIX"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "SURIMI HEAD"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "SHRIMPS HEAD"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "SKIN-BONE"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "MIX-SURIMI"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "MIX-FISH"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "SLUDGE/DAF"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "SARDINE"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "TARLI"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "SALIT"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "TILAPIA"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "SHRIMPS SKIN"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "INDIAN MACKEREL"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "GOFAN"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "HEAD/SKIN/DAF"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "SKIN/DAF"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "HEAD/SKIN"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "FISH SKIN"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "FISH HEAD"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "FISH SKIN_BONE"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "MIX-WASTE"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "FISH HEAD/SKIN/DAF"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "FISH SKIN/DAF"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "FISH HEAD/SKINA"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "RIBBON"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "FISH HEAD/DAF"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "BEBY SQW"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "RANI"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "DOMA"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "KHATAR"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "type": "PEDI FISH"
    },
  ];
  species = [
    {
      "division": "both",
      "variety": "SHRIMPS",
      "species": "BLACK TIGER"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "species": "KAPSI"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "species": "BROWN"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "species": "DAGADI"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "species": "FLOWER"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "species": "GOINER"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "species": "PINK"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "species": "PATTA"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "species": "POOVALAN"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "species": "SEA TIGER"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "species": "TINY"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "species": "VANNAMI"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "species": "WHITE"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "species": "NONE"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "species": "LOLIGO"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "species": "SEMI NIDDLE"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "species": "NIDDLE"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "species": "NONE"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "species": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "species": "SILK"
    },
    // {
    //     "division": "fish",
    //     "variety": "BARACUDA",
    //     "species": "NONE"
    // },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "species": "NONE"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "species": "SILVER"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "species": "TT"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "species": "YELLOW"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "species": "NONE"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "species": "WHITE"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "species": "BLOCK"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "species": "NONE"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "species": "MUMBAI"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "species": "OUTSTATION"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "species": "OTHER"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "species": "LOCAL"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "species": "H/RED"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "species": "MUMBAI"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "species": "OUTSTATION"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "species": "OTHER"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "species": "LOCAL"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "species": "H/RED"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "species": "SAND"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "species": "ROCK"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "species": "BROWN"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "species": "BLACK"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "species": "YELLOW"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "species": "RED"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "species": "SHORT ARM"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "species": "LONG ARM"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "species": "NONE"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "species": "WHITE"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "species": "CHINESE"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "species": "BLACK"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "species": "NONE"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "species": "NONE"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "species": "NONE"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "species": "WHOLE"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "species": "LOCAL"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "species": "STICK"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "species": "PORTION"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "species": "RED"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "species": "WHITE"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "species": "NONE"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "species": "WHITE"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "species": "BLACK"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "species": "NONE"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "species": "YELLOW FIN"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "species": "BONITO"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "species": "BIG EYE"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "species": "WHOLE"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "species": "STICK"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "species": "PORTION"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "species": "LOINS"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "species": "NONE"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "species": "WHOLE"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "species": "BLACK"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "species": "RED"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "species": "NONE"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "species": "RED BELLY"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "species": "DOTTED"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "species": "BLACK"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "species": "NONE"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "species": "NONE"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "species": "NONE"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "species": "MUMBAI"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "species": "OUTSTATION"
    },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "species": "NONE"
    // },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "species": "NONE"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "species": "PELA PATTI"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "species": "DICSO"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "species": "BLACK"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "species": "RED"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "species": "YELLOW"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "species": "BLACK"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "species": "RED"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "species": "NONE"
    },


    {
      "division": "surimi",
      "variety": "SANDI",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "species": "NONE"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "species": "GREEN"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "species": "NONE"
    },
  ];
  condition = [
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "GOOD"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "SOFT"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "PELLING"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "BLACK SPOT"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "GREADING"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "RED"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "PINK"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "SMELL"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "FRESH, THOWED"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "RED EYES"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "SCARCHES"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "BKN"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "OK"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "NO ICE"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "For E/P"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "condition": "FOR PUD"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "PINK"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "PEELING"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "GREADING"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "CUT"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "SKIN RUPTURE"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "CLUMPING"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "YELLOW"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "FLABBY"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "WING BITING"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "condition": "PINK"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "condition": "PEELING"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "condition": "GREADING"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "condition": "CLUMPING"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "condition": "YELLOW"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "condition": "WING BITING"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "condition": "WING"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "fish",
      "variety": "BARACUDA",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "BARACUDA",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "BARACUDA",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "BARACUDA",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "BARACUDA",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "BARACUDA",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "BARACUDA",
      "condition": "DOWN QUALITY AND SMELL"
    },
    // {
    //     "division": "fish",
    //     "variety": "BARACUDA",
    //     "condition": "RED EYES"
    // },
    // {
    //     "division": "fish",
    //     "variety": "BARACUDA",
    //     "condition": "SCARCHES"
    // },
    // {
    //     "division": "fish",
    //     "variety": "BARACUDA",
    //     "condition": "SOFT"
    // },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "condition": "SOFT"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "condition": "SOFT"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "condition": "RED EYES"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "condition": "SCARCHES"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "condition": "SOFT"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "condition": "RED EYES"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "condition": "SCARCHES"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "condition": "SOFT"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "condition": "RED EYES"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "condition": "SCARCHES"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "condition": "SOFT"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "condition": "RED EYES"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "condition": "SCARCHES"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "condition": "SOFT"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "condition": "RED EYES"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "condition": "SCARCHES"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "condition": "SOFT"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "condition": "RED EYES"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "condition": "SCARCHES"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "condition": "SOFT"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "condition": "RED EYES"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "condition": "SCARCHES"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "condition": "SOFT"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "condition": "RED EYES"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "condition": "SCARCHES"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "condition": "SOFT"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "condition": "RED EYES"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "condition": "SCARCHES"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "condition": "SOFT"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "condition": "RED EYES"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "condition": "SCARCHES"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "condition": "GOOD"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "condition": "BKN"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "condition": "SOFT"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "condition": "SMELL"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "condition": "RED EYES"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "condition": "SCARCHES"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "condition": "OK"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "condition": "NO ICE"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "condition": "SOFT"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "condition": "RED EYES"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "condition": "SCARCHES"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "condition": "BKN"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "condition": "SOFT"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "condition": "SMELL"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "condition": "RED EYES"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "condition": "SCARCHES"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "condition": "OK"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "condition": "NO ICE"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "condition": "GOOD"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "condition": "BKN"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "condition": "SOFT"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "condition": "SMELL"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "condition": "RED EYES"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "condition": "SCARCHES"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "condition": "OK"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "condition": "NO ICE"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "condition": "GOOD"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "condition": "BKN"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "condition": "SOFT"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "condition": "SMELL"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "condition": "RED EYES"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "condition": "SCARCHES"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "condition": "OK"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "condition": "NO ICE"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "condition": "GOOD"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "condition": "BKN"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "condition": "SOFT"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "condition": "SMELL"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "condition": "RED EYES"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "condition": "SCARCHES"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "condition": "OK"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "condition": "NO ICE"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "condition": "GOOD"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "condition": "BKN"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "condition": "SOFT"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "condition": "SMELL"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "condition": "RED EYES"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "condition": "SCARCHES"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "condition": "OK"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "condition": "NO ICE"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "condition": "GOOD"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "condition": "BKN"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "condition": "A GRADE"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "condition": "B GRADE"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "condition": "SHORT LEG"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "condition": "SOFT"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "condition": "SMELL"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "condition": "RED EYES"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "condition": "SCARCHES"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "condition": "OK"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "condition": "NO ICE"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "condition": "DOWN QUALITY AND SMELL"
    },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "condition": "GOOD"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "condition": "BKN"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "condition": "A GRADE"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "condition": "B GRADE"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "condition": "SHORT LEG"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "condition": "SOFT"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "condition": "SMELL"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "condition": "RED EYES"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "condition": "SCARCHES"
    // },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "condition": "SOFT"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "condition": "SMELL"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "condition": "RED EYES"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "condition": "SCARCHES"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "condition": "GOOD"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "condition": "OK"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "condition": "NO ICE"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "condition": "SOFT"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "condition": "SMELL"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "condition": "RED EYES"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "condition": "SCARCHES"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "condition": "OK"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "condition": "NO ICE"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "condition": "RED EYES"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "condition": "SCARCHES"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "condition": "OK"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "condition": "RED EYES"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "condition": "SCARCHES"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "condition": "OK"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "condition": "RED EYES"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "condition": "SCARCHES"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "condition": "OK"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "condition": "RED EYES"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "condition": "SCARCHES"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "condition": "OK"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "condition": "RED EYES"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "condition": "SCARCHES"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "condition": "OK"
    },
    
    {
      "division": "surimi",
      "variety": "GOFAN",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "condition": "RED EYES"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "condition": "SCARCHES"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "condition": "OK"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "condition": "RED EYES"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "condition": "SCARCHES"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "condition": "OK"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "condition": "RED EYES"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "condition": "SCARCHES"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "condition": "OK"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "condition": "RED EYES"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "condition": "SCARCHES"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "condition": "OK"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "condition": "RED EYES"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "condition": "SCARCHES"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "condition": "OK"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "condition": "RED EYES"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "condition": "SCARCHES"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "condition": "OK"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "condition": "RED EYES"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "condition": "SCARCHES"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "condition": "OK"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "condition": "DOWN QUALITY AND SMELL"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "condition": "RED EYE"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "condition": "SCRATCHES"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "condition": "OK"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "condition": "DOWN QUALITY & SMELL"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "condition": "RED EYE"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "condition": "SCRATCHES"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "condition": "OK"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "condition": "DOWN QUALITY & SMELL"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "condition": "RED EYE"
    },

    {
      "division": "surimi",
      "variety": "EEL",
      "condition": "SCRACHES"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "condition": "OK"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "condition": "DOWN QUALITY & SMELL"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "condition": "SOFT"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "condition": "SMELL"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "condition": "RED EYE"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "condition": "SCRACHES"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "condition": "GOOD"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "condition": "OK"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "condition": "NO ICE"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "condition": "DOWN QUALITY & SMELL"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "condition": "GOOD"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "condition": "SMALL"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "condition": "MORE WATER"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "condition": "DOWN QUALITY"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "condition": "MORE ICE"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "condition": "DOWN QUALITY & SMELL"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "condition": "NONE"
    },
    
  ];
  color = [
    {
      "division": "both",
      "variety": "SHRIMPS",
      "color": "RED"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "color": "PINK"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "color": "WHITE-A1"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "color": "WHITE-A2"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "color": "WHITE-A3"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "color": "WHITE-A4"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "color": "BROWN-A1"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "color": "BROWN-A2"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "color": "BROWN-A3"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "color": "BROWN-A4"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "color": "GREEN-A1"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "color": "GREEN-A2"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "color": "GREEN-A3"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "color": "GREEN-A4"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "BARACUDA",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "color": "NONE"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "color": "NONE"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "color": "REGULAR"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "color": "REGULAR"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "color": "NONE"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "color": "REGULAR"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "color": "REGULAR"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "color": "NONE"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "color": "REGULAR"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "color": "NONE"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "color": "REGULAR"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "color": "NONE"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "color": "REGULAR"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "color": "NONE"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "color": "SILVER"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "color": "BLACK"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "color": "REGULAR"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "color": "NONE"
    },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "color": "SILVER"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "color": "BLACK"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "color": "REGULAR"
    // },
    // {
    //     "division": "both",
    //     "variety": "RIBBON FISH OUTSATION",
    //     "color": "NONE"
    // },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "color": "REGULAR"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "BARACUDA",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "color": "NONE"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "color": "NONE"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "color": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "color": "NONE"
    }
  ];
  size = [

    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "REGULAR"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "SMALL"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "MEDIUM"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "BIG"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "MIX"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "8/10"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "13/18"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "16/18"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "U/5"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "U/7"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "U/10"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "U/15"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "U/20"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "1/2"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "2/4"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "4/6"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "6/8"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "6/12"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "6/10"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "8/12"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "11/15"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "13/15"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "10/20"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "14/16"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "16/20"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "16/22"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "18/22"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "21/25"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "20/30"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "20/40"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "21/30"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "26/30"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "31/35"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "31/50"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "36/40"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "30/40"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "31/40"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "40/50"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "41/50"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "50/60"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "51/60"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "60/80"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "61/70"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "71/90"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "91/110"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "111/130"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "131/150"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "151/200"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "80/100"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "140/160"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "160/180"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "51/70"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "70/80"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "41/45"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "40/60"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "71/80"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "80/120"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "81/90"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "101/110"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "141/150"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "91/100"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "100/200"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "100/250"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "111/120"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "121/130"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "131/140"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "151/160"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "200/300"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "300/500"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "500/700"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "700/1000"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "200/250"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "300/400"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "BKN"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "NA"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "B/S"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "F/G"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "P/L"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "45/50"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "130/150"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "21/23"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "25/30"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "20/25"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "21/26"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "51/55"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "25/27"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "31/33"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "20/22"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "21/27"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "6/9"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "100/150"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "13/14"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "13/16"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "21/22"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "46/50"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "200 CT"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "240 CT"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "236 CT"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "46/55"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "60/65"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "30/50"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "10/13"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "10/15"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "33/35"
    },
    {
      "division": "both",
      "variety": "SHRIMPS",
      "size": "10/12"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "U/2"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "U/3"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "U/4"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "3/6"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "U/5"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "6/10"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "U/10"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "10/15"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "10/20"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "20/25"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "20/30"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "20/40"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "30/40"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "40/50"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "20/60"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "40/60"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "60/80"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "60/UP"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "80/UP"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "80/150"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "100/UP"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "40/45"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "U/7"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "F/G"
    },

    {
      "division": "fish",
      "variety": "SQUID",
      "size": "10/12"
    },
    {
      "division": "fish",
      "variety": "SQUID",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "U/1"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "1/2"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "2/4"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "5/7"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "8/12"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "13/20"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "U/20"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "20/40"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "U/30"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "U/50"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "20/50"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "50/100"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "100/150"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "150/300"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "300/500"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "500/700"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "700/1000"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "1000/2000"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "1000/UP"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "2000/UP"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "U/2"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "F/G"
    },
    {
      "division": "fish",
      "variety": "CUTTLE FISH",
      "size": "NA"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "size": "REGULAR"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "size": "SMALL"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "size": "MEDIUM"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "size": "BIG"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "size": "MIX"
    },
    {
      "division": "both",
      "variety": "BARACUDA",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "BARACUDA",
      "size": "1000/2000"
    },
    {
      "division": "fish",
      "variety": "BARACUDA",
      "size": "2000/3000"
    },
    {
      "division": "fish",
      "variety": "BARACUDA",
      "size": "3000/UP"
    },
    {
      "division": "fish",
      "variety": "BARACUDA",
      "size": "3000/4000"
    },
    {
      "division": "fish",
      "variety": "BARACUDA",
      "size": "3000/5000"
    },
    {
      "division": "fish",
      "variety": "BARACUDA",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "BARACUDA",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "100 GM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "200 GM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "300 GM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "400 GM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "500 GM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "600 GM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "700 GM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "800 GM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "900 GM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "1000 GM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "1100 GM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "1200 GM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "1300 GM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "1400 GM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "1500 GM"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "BOMBAY DUCK",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "U/20"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "U/50"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "50/100"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "100/200"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "200/300"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "300/400"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "400/500"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "400/UP"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "500/700"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "700/1000"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "CROAKER",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "U/20"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "U/50"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "50/100"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "100/200"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "200/300"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "300/400"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "400/500"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "400/UP"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "500/700"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "700/1000"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "BMC",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "size": "U/1"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "size": "1/2"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "size": "2/4"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "size": "4/6"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "size": "6/8"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "size": "8/10"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "size": "8/12"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "HORSE MACKERAL",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "size": "U/1"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "size": "1/2"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "size": "2/4"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "size": "4/6"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "size": "6/8"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "size": "8/10"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "size": "8/12"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "INDIAN MACKERAL",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "0/1 oz"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "1/2 oz"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "2/3 oz"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "3/4 oz"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "4/5 oz"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "5/6 oz"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "6/7 oz"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "7/8 oz"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "8/9 oz"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "9/10 oz"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "LOBSTER",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "size": "100/200"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "size": "200/300"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "size": "300/500"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "size": "500/700"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "size": "700/1000"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "size": "1000/2000"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "size": "2000/3000"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "size": "3000/UP"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "MALBAR",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "size": "U/5"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "size": "U/10"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "size": "10/20"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "size": "20/40"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "size": "40/60"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "OCTOPUS",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "U/50"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "50/100"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "100/200"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "200/300"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "300/400"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "400/500"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "500/600"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "600/700"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "700/1000"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "1000/1500"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "1500/2000"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "2000/3000"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "3000/UP"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "POMFRET",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "size": "U/1"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "size": "1/2"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "size": "2/4"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "size": "4/6"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "size": "6/8"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "size": "8/10"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "size": "8/12"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "size": "10/20"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "SCAD",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "size": "100/200"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "size": "200/300"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "size": "300/500"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "size": "500/700"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "size": "700/1000"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "size": "1000/2000"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "size": "2000/3000"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "size": "3000/UP"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "SHEER FISH",
      "size": "NA"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "REGULAR"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "SMALL"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "MEDIUM"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "BIG"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "MIX"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "100/200"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "200/300"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "300/500"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "500/700"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "700/1000"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "1000/2000"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "2000/3000"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "3000/5000"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "5000/UP"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "BKN"
    },
    {
      "division": "both",
      "variety": "SNAPER",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "size": "U/50"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "size": "50/100"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "size": "100/200"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "size": "200/300"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "size": "300/500"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "size": "500/700"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "size": "700/1000"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "SOLE FISH",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "size": "100/500"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "size": "500/1000"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "size": "1000/2000"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "size": "2000/3000"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "size": "3000/5000"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "size": "5000/7000"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "size": "7000/10000"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "size": "BKN"
    },
    {
      "division": "fish",
      "variety": "TUNA",
      "size": "NA"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "size": "REGULAR"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "size": "SMALL"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "size": "MEDIUM"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "size": "BIG"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "size": "MIX"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "size": "1/2"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "size": "2/3"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "size": "3/4"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "size": "3/5"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "size": "BKN"
    },
    {
      "division": "both",
      "variety": "KATTI",
      "size": "NA"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "REGULAR"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "SMALL"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "MEDIUM"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "BIG"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "MIX"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "50/100"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "100/200"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "150/250"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "200/300"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "250/500"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "300/500"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "500/700"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "700/1000"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "1000/UP"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "2000/UP"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "BKN"
    },
    {
      "division": "both",
      "variety": "TILAPIA",
      "size": "NA"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "size": "REGULAR"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "size": "SMALL"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "size": "MEDIUM"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "size": "BIG"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "size": "MIX"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "size": "50/100"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "size": "100/200"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "size": "200/300"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "size": "300/500"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "size": "500/700"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "size": "700/1000"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "size": "1000/UP"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "size": "2000/UP"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "size": "BKN"
    },
    {
      "division": "both",
      "variety": "REEFCOD",
      "size": "NA"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "size": "REGULAR"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "size": "SMALL"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "size": "MEDIUM"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "size": "BIG"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "size": "MIX"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "size": "10/20"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "size": "20/30"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "size": "30/40"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "size": "40/50"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "size": "50/60"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "size": "60/80"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "size": "80/100"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "size": "BKN"
    },
    {
      "division": "both",
      "variety": "SARDINE",
      "size": "NA"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "REGULAR"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "SMALL"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "MEDIUM"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "BIG"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "MIX"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "50/100"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "100/150"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "100/200"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "200/300"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "300/400"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "400/500"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "400/700"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "500/700"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "700/1000"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "1000/UP"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "BKN"
    },
    {
      "division": "both",
      "variety": "RIBBON FISH",
      "size": "NA"
    },

    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "size": "REGULAR"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "size": "SMALL"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "size": "MEDIUM"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "size": "BIG"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "size": "MIX"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "size": "BKN"
    },
    {
      "division": "both",
      "variety": "LEATHER JACKET",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "RANI",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "LIZARD",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "DISCO",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "GITER",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "GOFAN",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "KARGIL",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "KINMEDIA",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "KINTOKIDAI",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "MAMAKARI",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "MIX",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "PILA PATTI",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "VAAM",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "SANDI",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "FLY FISH",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "EEL",
      "size": "NA"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "size": "REGULAR"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "size": "SMALL"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "size": "MEDIUM"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "size": "BIG"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "size": "MIX"
    },
    {
      "division": "surimi",
      "variety": "NEEDLESKIN QUEENFISH",
      "size": "NA"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "size": "REGULAR"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "size": "SMALL"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "size": "MEDIUM"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "size": "BIG"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "size": "MIX"
    },
    {
      "division": "fish",
      "variety": "FISH MEAL",
      "size": "NA"
    },

  ]

  constructor() { }
}

export let dropdowns = new Dropdowns();
