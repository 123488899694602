import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tabFilter'
})
export class TabFilterPipe implements PipeTransform {

  transform(array: any, searchTerm: string): any {

    if(!searchTerm) {
      return array;
    }

    let filteredArray = array.filter(element=>{
      let object = JSON.stringify(element.value).toLowerCase();
      return object.includes(searchTerm.toLowerCase())
    })

    return filteredArray;
  }

}
