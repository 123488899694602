import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'containerOpenOrder'
})
export class ContainerOpenOrderPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let pis = value.piDetails;

    if (pis && pis.pi && pis.pi.length) {


      for (let p = 0; p < pis.pi.length; p++) {
        const pi = pis.pi[p];
        let containers = pi.containers;
        // console.log('containers', containers)
        if (containers.length) {
          for (let i = 0; i < containers.length; i++) {
            const container = containers[i];
            // console.log('container', i + 1, container)
            if (container.openOrder) {
              // console.log('Returned True');
              return true;
            }
          }
        }
      }

    }
    // console.log('Returne d False');

    return false;

  }

}
